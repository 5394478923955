/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { getLocationReviews} from "../services/api";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PageLoader } from "../services/PageLoader";
import Moment from 'moment';
import Rating from '@mui/material/Rating';

const LocationReviews = () => {
  const params = useParams()
  const locationId = params.locationId
  const [data, setData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(false)
  const [pageCount,setPageCount] = useState<any>('')
  const noOfItems = 10
    const getJobData = async () => {
      try{
        const res = await getLocationReviews(locationId,1,noOfItems,searchTerm);
        setData(res?.data?.filteredData)
        setPageCount(res?.data?.count)
        setShowLoader(false)
      }catch(error){
        console.log(error)
        setShowLoader(false)
      }
    }

    const hanldeSearch = async (e) => {
      setSearchTerm(e.target.value)
      setShowLoader(true)
      try{
        const res = await getLocationReviews(locationId,1,noOfItems,e.target.value);
        setData(res?.data?.filteredData)
        setPageCount(res?.data?.count)
        setShowLoader(false)
      }catch(error){
        console.log(error)
        setShowLoader(false)
      }
    }

    const handleChange = async(event,value) => {
      try{
        const res = await getLocationReviews(locationId,value,noOfItems,searchTerm);
        setData(res?.data?.filteredData)
        setPageCount(res?.data?.count)
        setShowLoader(false)
      }catch(error){
        console.log(error)
        setShowLoader(false)
      }
  };

    useEffect(() => {
        setShowLoader(true)
        getJobData();
    }, []);


  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl(data?.locationImage)} alt='location image' />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                     {data?.location_id}
                    </a>
                    <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                  </div>

                  <div className='d-flex flex-column flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='save-deposit' className='fs-4 me-1' />
                      {data?.locationName}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName="technology-3" className='fs-4 me-1' />
                      {data?.locationType}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {data?.locationCity},{data?.locationArea}
                    </a>
                  </div>
                </div>

                {/* <div className='d-flex my-4'>
                  <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTIcon iconName='check' className='fs-3 d-none' />

                    <span className='indicator-label'>Follow</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </a>
                  <a
                    href='#'
                    className='btn btn-sm btn-primary me-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_offer_a_deal'
                  >
                    Hire Me
                  </a>
                  <div className='me-0'>
                    <button
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-three-dots fs-3'></i>
                    </button>
                    <Dropdown1 />
                  </div>
                </div> */}
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='book-open' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>{data?.reviews}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Reviews</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='star' className='fs-3 text-danger me-2' />
                        <div className='fs-2 fw-bolder'>{data?.avarageRating}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Avarage Rating</div>
                    </div>

                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>{data?.experience}</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Experience</div>
                    </div> */}
                  </div>
                </div>

                {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                    <span className='fw-bolder fs-6'>50%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{width: '50%'}}
                    ></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-between overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item nav-link text-active-primary me-6 '>
                <div
                  className={`nav-link text-active-primary me-6 active`}
                >
                  Reviews
                </div>
              </li>
              {/* <li className='nav-item nav-link text-active-primary me-6 '>
                < div
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (tile === 'jobrequests' && 'active')
                  }
                  // onClick={() => onChangeTile('jobrequests')}
                >
                  Job Requests
                </div>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/campaigns' && 'active')
                  }
                  to='/crafted/pages/profile/campaigns'
                >
                  Campaigns
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/documents' && 'active')
                  }
                  to='/crafted/pages/profile/documents'
                >
                  Documents
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/connections' && 'active')
                  }
                  to='/crafted/pages/profile/connections'
                >
                  Connections
                </Link>
              </li> */}
            </ul>
            <div className='card-title'>
              {/* begin::Search */}
              <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                      type='text'
                      data-kt-user-table-filter='search'
                      className='form-control form-control-solid w-250px ps-14'
                      placeholder='Search'
                      value={searchTerm}
                      onChange={hanldeSearch}
                  />
              </div>
              {/* end::Search */}
          </div>
          </div>
        </div>
      </div>
      {data?.review_and_rating && (data?.review_and_rating.map((review) => (
        <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Body */}
        <div className='card-body pb-0'>
          {/* begin::Header */}
          <div className='d-flex align-items-center mb-5'>
            {/* begin::User */}
            <div className='d-flex align-items-center flex-grow-1'>
              {/* begin::Avatar */}
              <div className='symbol symbol-45px me-5'>
                <img src={toAbsoluteUrl(review?.profilePic ? review?.profilePic : '/media/svg/avatars/001-boy.svg')} alt='user profile image' />
              </div>
              {/* end::Avatar */}

              {/* begin::Info */}
              <div className='d-flex flex-column'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  {review?.userName}
                </a>
                <Rating
                  name="readOnly"
                  value={review?.rating}
                  readOnly
                />
                <span className='text-gray-400 fw-semibold'>Reviewed on : {Moment(review?.date).format('DD/MM/YYYY')}</span>
              </div>
              {/* end::Info */}
            </div>
            {/* end::User */}

            {/* begin::Menu */}
            {/* <div className='my-0'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='category' className='fs-2' />
              </button>
              <Dropdown1 />
            </div> */}
            {/* end::Menu */}
          </div>
          {/* end::Header */}

          {/* begin::Post */}
          <div className='mb-7'>
            {/* begin::Text */}
            <div className='mb-3'>
              <span  className='fw-bold'>Booking Id :</span> <span>{review.bookingId}</span>
            </div>
            <div className='mb-3'>
              <span  className='fw-bold'>Event Date :</span> <span>{Moment(review.bookingDate).format('DD/MM/YYYY')}</span>
            </div>
            <div className='mb-3 fw-bold'>
              {review.reviewHeader}
            </div>
            <div className='text-gray-800 mb-5'>
              {review.reviewContent}
            </div>
            {review?.reply && (
              <>
                <div className='mb-1 fw-bold'>
                  {`Reply by host ${review?.replyDate ? `(${Moment(review?.replyDate).format('DD/MM/YYYY')})` : ''} : `}
                </div>
                <div className='text-gray-800 mb-5'>
                  {review.reply}
                </div>
              </>
            )}
            {/* end::Text */}

            {/* begin::Toolbar */}
            {/* <div className='d-flex align-items-center mb-5'>
              <a
                href='#'
                className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
              >
                <KTIcon iconName='message-text-2' className='fs-3' />
                22
              </a>

              <a
                href='#'
                className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
              >
                <KTIcon iconName='heart' className='fs-2' />
                59
              </a>
            </div> */}
            {/* end::Toolbar */}
          </div>
          {/* end::Post */}

          {/* begin::Separator */}
          <div className='separator mb-4'></div>
          {/* end::Separator */}

          {/* begin::Reply input */}
          {/* <form className='position-relative mb-6'>
            <textarea
              className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
              rows={1}
              placeholder='Reply..'
            ></textarea>

            <div className='position-absolute top-0 end-0 me-n5'>
              <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
                <KTIcon iconName='paper-clip' className='fs-3 mb-3' />
              </span>

              <span className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                <KTIcon iconName='geolocation' className='fs-2 mb-3' />
              </span>
            </div>
          </form> */}
          {/* edit::Reply input */}
        </div>
        {/* end::Body */}
      </div>
      )))}
      <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
          <Stack spacing={2}>
              <Pagination count={pageCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
          </Stack>
      </div>
      {showLoader && <PageLoader />}
    </>
  );
};

export default LocationReviews;
