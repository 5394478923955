import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../services/PageLoader";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { getEnquiryData } from "../services/api";
// import { useAuth } from "../../auth";
import Moment from 'moment';
import {Link} from 'react-router-dom'
import {KTIcon} from "../../../../_metronic/helpers";
import Reports from "../Reports/Reports";

const { Header, Footer, Title, Body } = Modal;

const Enquiry = ({type}) => {
    // const {currentUser} = useAuth()
    const location:any = useLocation();
    const locationId = location?.state?.locationId; 
    const [show,setShow] = useState<any>(false);
    // const [modelBody,setModelBody] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);

    // Me
    // const data = ["Subscription", "FindMySpot", "EventPlanning"]
    const [enquiryType,setEnquiryType] = useState<string>(type)
    const [formData, setFormData] = useState<any>();
    const [searchTerm, setSearchTerm] = useState<any>("");
    const [dataCount, setDataCount] = useState<any>(0)
    const [page, setPage] = useState<number>(1)
    const [cardData, setCardData] = useState<any>({});
    const noOfItems = 10;

    const onChangeType = (type : string) => {
        setEnquiryType(type)
    }

    const getData = async (searchTerm : any) => {
        try{
            let data = {
                type,
                page,
                noOfItems,
                searchTerm,
            }
            const resp = await getEnquiryData({...data})
            setFormData(resp?.data?.enquiries)
            setDataCount(resp?.data?.count)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }
    const getSearchData = async (type : any, searchTerm : any) => {
        try{
            let data = {
                type,
                page,
                noOfItems,
                searchTerm,
            }
            const resp = await getEnquiryData({...data})
            setFormData(resp?.data?.enquiries)
            setDataCount(resp?.data?.count)
            if (searchTerm) setPage(1)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }

    const handleSearch = async (e : any) => {
      setSearchTerm(e.target.value)
    }

    useEffect(()=> {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        setShowLoader(true);
        getData(searchTerm);
    }, [page]);

    useEffect(() => {
        const timeOut = setTimeout(()=>{
            getSearchData(type, searchTerm);
        },1500)
        return ()=>{
            clearTimeout(timeOut)
        }
    },[searchTerm])

    useEffect(()=> {
        setShowLoader(true);
        getData('');
        setSearchTerm(locationId || '')
    }, [type]);

    const onClickPopUpDetails = (each : any) => {
        setCardData(each);
        setShow(true);
    } 

    const Subscription1 = (each : any) => {
        // return(
        //     <div className="d-flex flex-column">
        //     {
        //         formData.map((each : any) => {

                return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                            <div className='mb-3'>
                                {/* begin::Text */}
                                <div className='mt-3'>
                                <span  className='fw-bold'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Name :</span> <span>{each?.data?.name}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Email :</span> <span>{each?.data?.email}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Number :</span> <span>{each?.data?.phoneNumber}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Requested Plan :</span> <span>{each?.data?.plan}</span>
                                </div>
                                <div className=' mt-3'>
                                <span  className='fw-bold'>Requested Date :</span> <span>{Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Message :</span> <span>{each?.data?.message ? each?.data?.message : "N/A"}</span>
                                </div>
                                {/* end::Text */}
                            </div>
                        </div>
                    </div>
                )
        //         })
        //     }
        //     </div>
        // )
    }

    const Subscription = () => {
        return (
            <div>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-13-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-100px text-center'>Enquiry Id</th>
                                <th className='min-w-100px text-center'>Enquiry Date</th>
                                <th className='min-w-100px text-center'>Contact Number</th>
                                <th className='min-w-100px text-center'>Requested plan</th>
                                <th className='min-w-100px text-center'>Status</th>
                                <th className='min-w-100px text-center'>Assigned to</th>
                                <th className='min-w-100px text-center'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {formData.map((each : any, index : any) => {
                                return (
                                    <tr>
                                        {/* <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                            </div>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-center d-flex justify-content-center align-items-center'>
                                                {each?.enquiryId || "NA"}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {Moment(each?.createdAt).format('YYYY-MM-DD hh:mm a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.phoneNumber}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {each?.data?.plan}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.status || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-danger d-flex justify-content-center align-items-center'>
                                            {each?.assignedTo || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    title="View Locations">
                                                    <KTIcon iconName='magnifier' className='fs-3' />
                                                </div> */}
                                                {/* <Link to ={`../enquiry-view/${each._id}`} > */}
                                                    <button className='btn btn-bg-warning btn-color-muted btn-active-color-primary btn-sm px-4 me-2' onClick = { () => onClickPopUpDetails(each) } 
                                                        title="Details">
                                                        Details
                                                    </button>
                                                {/* </Link> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
        )
    }

    const FindMySpot1 = (each : any) => {
        // return(
            // <div className="d-flex flex-column">
            // {
            //     formData.map((each : any) => {

                return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                            <div className='mb-3'>
                                {/* begin::Text */}
                                <div className='mt-3'>
                                <span  className='fw-bold'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Name :</span> <span>{each?.data?.fullName}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Email :</span> <span>{each?.data?.email}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Number :</span> <span>{each?.data?.number}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Event Type :</span> <span>{each?.data?.eventType}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Event Date :</span> <span>{each?.data?.eventDate ? Moment(each?.data?.eventDate).format('YYYY-MM-DD') : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Required Location Type :</span> <span>{each?.data?.locationType ? each?.data?.locationType : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Required Duration :</span> <span>{each?.data?.duration ? each?.data?.duration : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Requests :</span> <span>{each?.data?.requests ? each?.data?.requests : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Requirements :</span> <span>{each?.data?.requirements ? each?.data?.requirements : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Budget :</span> <span>{each?.data?.budget ? each?.data?.budget : "N/A"}</span>
                                </div>
                                <div className=' mt-3'>
                                <span  className='fw-bold'>Requested Date :</span> <span>{Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                                </div>
                                {/* end::Text */}
                            </div>
                        </div>
                    </div>
                )
        //         })
        //     }
        //     </div>
        // )
    }

    const FindMySpot = () => {
        return (
            <div>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-13-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-100px text-center'>Enquiry Id</th>
                                <th className='min-w-100px text-center'>Enquiry Date</th>
                                <th className='min-w-100px text-center'>Contact Number</th>
                                <th className='min-w-100px text-center'>Event Type</th>
                                <th className='min-w-100px text-center'>Preferred Location</th>
                                <th className='min-w-100px text-center'>Requested Event Date</th>
                                <th className='min-w-100px text-center'>Status</th>
                                <th className='min-w-100px text-center'>Assigned to</th>
                                <th className='min-w-100px text-center'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {formData.map((each : any, index : any) => {
                                return (
                                    <tr>
                                        {/* <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                            </div>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-center d-flex justify-content-center align-items-center'>
                                                {each?.enquiryId || "NA"}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.number}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.eventType}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {each?.data?.locationType ? each?.data?.locationType : "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {each?.data?.eventDate ? Moment(each?.data?.eventDate).format('YYYY-MM-DD') : "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.status || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-danger d-flex justify-content-center align-items-center'>
                                            {each?.assignedTo || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    title="View Locations">
                                                    <KTIcon iconName='magnifier' className='fs-3' />
                                                </div> */}
                                                {/* <Link to ={`../enquiry-view/${each._id}`} > */}
                                                    <button className='btn btn-bg-warning btn-color-muted btn-active-color-primary btn-sm px-4 me-2' onClick = { () => onClickPopUpDetails(each) } 
                                                        title="Details">
                                                        Details
                                                    </button>
                                                {/* </Link> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
        )
    }

    const EventPlanning1 = (each : any) => {
        // return(
        //     <div className="d-flex flex-column">
        //     {
        //         formData.map((each : any) => {
                    let reqServices : any
                    if (each?.data?.services){
                    const filteredServices = each?.data?.services?.filter((each : any) => each !== "")
                    reqServices = filteredServices.length > 0 ? each?.data?.services.join(", ") : "N/A";
                    }
                    else {
                        reqServices = "N/A"
                    }

                return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                            <div className='mb-3'>
                                {/* begin::Text */}
                                <div className='mt-3'>
                                <span  className='fw-bold'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Name :</span> <span>{each?.data?.fullName}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Email :</span> <span>{each?.data?.email}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Number :</span> <span>{each?.data?.number}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Event Type :</span> <span>{each?.data?.eventType ? each?.data?.eventType : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Preferred Event Date :</span> <span>{each?.data?.eventDate ? Moment(each?.data?.eventDate).format('YYYY-MM-DD') : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Preferred Event Location :</span> <span>{each?.data?.eventLocation ? each?.data?.eventLocation : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Estimated Guests :</span> <span>{each?.data?.guests ? each?.data?.guests : "N/A"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Requested Services :</span> <span>{reqServices}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Applicant Preferences/Requirements :</span> <span>{each?.data?.requirements ? each?.data?.requirements : "N/A"}</span>
                                </div>
                                <div className=' mt-3'>
                                <span  className='fw-bold'>Requested Date :</span> <span>{Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                                </div>
                                {/* end::Text */}
                            </div>
                        </div>
                    </div>
                )
        //         })
        //     }
        //     </div>
        // )
    }

    const EventPlanning = () => {
        return (
            <div>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-13-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-100px text-center'>Enquiry Id</th>
                                <th className='min-w-100px text-center'>Enquiry Date</th>
                                <th className='min-w-100px text-center'>Contact Number</th>
                                <th className='min-w-100px text-center'>Name of the event</th>
                                <th className='min-w-100px text-center'>Requested Event Date</th>
                                <th className='min-w-100px text-center'>Status</th>
                                <th className='min-w-100px text-center'>Assigned to</th>
                                <th className='min-w-100px text-center'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {formData.map((each : any, index : any) => {
                                return (
                                    <tr>
                                        {/* <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                            </div>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-center d-flex justify-content-center align-items-center'>
                                                {each?.enquiryId || "NA"}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.number}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.eventType ? each?.data?.eventType : "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {each?.data?.eventDate ? Moment(each?.data?.eventDate).format('YYYY-MM-DD') : "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.status || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-danger d-flex justify-content-center align-items-center'>
                                            {each?.assignedTo || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    title="View Locations">
                                                    <KTIcon iconName='magnifier' className='fs-3' />
                                                </div> */}
                                                {/* <Link to ={`../enquiry-view/${each._id}`} > */}
                                                    <button className='btn btn-bg-warning btn-color-muted btn-active-color-primary btn-sm px-4 me-2' onClick = { () => onClickPopUpDetails(each) } 
                                                        title="Details">
                                                        Details
                                                    </button>
                                                {/* </Link> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
        )
    }

    const contactHost1 = (each : any) => {
        // return(
            // <div className="d-flex flex-column">
            // {
            //     formData.map((each : any) => {
            //         let reqServices : any
            //         if (each?.data?.services){
            //         const filteredServices = each?.data?.services?.filter((each : any) => each !== "")
            //         reqServices = filteredServices.length > 0 ? each?.data?.services.join(", ") : "N/A";
            //         }
            //         else {
            //             reqServices = "N/A"
            //         }

                return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                        <div className='mb-3'>
						{/* begin::Text */}
                        <div className='mt-3'>
                        <span  className='fw-bold'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                        </div>
						<div className='mt-3'>
						<span  className='fw-bold'>Location Id :</span> <span>{each?.data?.locationId}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Applicant Name :</span> <span>{each?.data?.name}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Applicant Email :</span> <span>{each?.data?.email}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Applicant Number :</span> <span>{each?.data?.mobileNumber ? each?.data?.mobileNumber : "N/A" }</span>
						</div>
						<div className=' mt-3'>
						<span  className='fw-bold'>Requested Date :</span> <span>{Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
						</div>
						<div className=' mt-3'>
						<span  className='fw-bold'>Subscription Type :</span> <span>{each?.data?.subscriptionPlan || "NA"}</span>
						</div>
						{/* end::Text */}
					</div>
                        </div>
                    </div>
                )
        //         })
        //     } 
        //     </div>
        // )
    }

    const contactHost = () => {
        return (
            <div>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-13-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-100px text-center'>Enquiry Id</th>
                                <th className='min-w-100px text-center'>Enquiry Date</th>
                                <th className='min-w-100px text-center'>Property Id</th>
                                <th className='min-w-100px text-center'>Contact Number</th>
                                <th className='min-w-100px text-center'>View Status</th>
                                <th className='min-w-100px text-center'>Status</th>
                                <th className='min-w-100px text-center'>Assigned to</th>
                                <th className='min-w-100px text-center'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {formData.map((each : any, index : any) => {
                                return (
                                    <tr>
                                        {/* <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                            </div>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-center d-flex justify-content-center align-items-center'>
                                                {each?.enquiryId || "NA"}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.locationId}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.mobileNumber ? each?.data?.mobileNumber : "N/A" }
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`badge ${ each?.read ? "badge-light-success" : "badge-light-danger" } d-flex justify-content-center align-items-center`}>
                                            {each?.read ? "Viewed" : "Not Viewed"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.status || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-danger d-flex justify-content-center align-items-center'>
                                            {each?.assignedTo || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    title="View Locations">
                                                    <KTIcon iconName='magnifier' className='fs-3' />
                                                </div> */}
                                                {/* <Link to ={`../enquiry-view/${each._id}`} > */}
                                                    <button className='btn btn-bg-warning btn-color-muted btn-active-color-primary btn-sm px-4 me-2' onClick = { () => onClickPopUpDetails(each) } 
                                                        title="Details">
                                                        Details
                                                    </button>
                                                {/* </Link> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
        )
    }

	const getInTouch1 = (each : any) => {
        // return(
        //     <div className="d-flex flex-column">
        //     {
        //         formData.map((each : any) => {

                return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                        <div className='mb-3'>
						{/* begin::Text */}
                        <div className='mt-3'>
                        <span  className='fw-bold'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                        </div>
						<div className='mt-3'>
						<span  className='fw-bold'>Location Id :</span> <span>{each?.data?.locationId}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Applicant Name :</span> <span>{each?.data?.name}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Applicant Email :</span> <span>{each?.data?.email}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Applicant Number :</span> <span>{each?.data?.mobileNumber}</span>
						</div>
						{/* <div className='mt-3'>
						<span  className='fw-bold'>Applicant Organization :</span> <span>{each?.data?.organization ? each?.data?.organization : "N/A"}</span>
						</div> */}
						<div className='mt-3'>
						<span  className='fw-bold'>Applicant Message :</span> <span>{each?.data?.message ? each?.data?.message : "N/A"}</span>
						</div>
						<div className=' mt-3'>
						<span  className='fw-bold'>Requested Date :</span> <span>{Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
						</div>
						{/* end::Text */}
					</div>
                        </div>
                    </div>
                )
        //         })
        //     } 
        //     </div>
        // )
    }

    const getInTouch = () => {
        return (
            <div>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-13-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-100px text-center'>Enquiry Id</th>
                                <th className='min-w-100px text-center'>Enquiry Date</th>
                                <th className='min-w-100px text-center'>Property Id</th>
                                <th className='min-w-100px text-center'>Contact Person Name</th>
                                <th className='min-w-100px text-center'>Contact Number</th>
                                <th className='min-w-100px text-center'>View Status</th>
                                <th className='min-w-100px text-center'>Status</th>
                                {/* <th className='min-w-100px text-center'>Assigned to</th> */}
                                <th className='min-w-100px text-center'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {formData.map((each : any, index : any) => {
                                return (
                                    <tr>
                                        {/* <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                            </div>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-center d-flex justify-content-center align-items-center'>
                                                {each?.enquiryId || "NA"}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.locationId}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.mobileNumber}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`badge ${ each?.read ? "badge-light-success" : "badge-light-danger" } d-flex justify-content-center align-items-center`}>
                                            {each?.read ? "Viewed" : "Not Viewed"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.status || "NA"}
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div className='badge badge-light-danger d-flex justify-content-center align-items-center'>
                                            {each?.assignedTo || "NA"}
                                            </div>
                                        </td> */}
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    title="View Locations">
                                                    <KTIcon iconName='magnifier' className='fs-3' />
                                                </div> */}
                                                {/* <Link to ={`../enquiry-view/${each._id}`} > */}
                                                    <button className='btn btn-bg-warning btn-color-muted btn-active-color-primary btn-sm px-4 me-2' onClick = { () => onClickPopUpDetails(each) } 
                                                        title="Details">
                                                        Details
                                                    </button>
                                                {/* </Link> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
        )
    }

	const bookingRequest1 = (each : any) => {
        // return(
        //     <div className="d-flex flex-column">
        //     {
        //         formData.map((each : any) => {
        //             let reqServices : any
        //             if (each?.data?.services){
        //             const filteredServices = each?.data?.services?.filter((each : any) => each !== "")
        //             reqServices = filteredServices.length > 0 ? each?.data?.services.join(", ") : "N/A";
        //             }
        //             else {
        //                 reqServices = "N/A"
        //             }

                return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                        <div className='mb-3'>
						{/* begin::Text */}
                        <div className='mt-3'>
                        <span  className='fw-bold'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                        </div>
						<div className='mt-3'>
						<span  className='fw-bold'>Location Id :</span> <span>{each?.data?.locationId}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>User Id :</span> <span>{each?.data?.userId}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Guest Name :</span> <span>{each?.data?.guestName || "NA"}</span> 
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Guest Number :</span> <span>{each?.data?.guestNumber || "NA"}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Guest Email :</span> <span>{each?.data?.guestEmail || "NA"}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Requested Event :</span> <span>{each?.data?.event}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Requested Booking Type :</span> <span>{each?.data?.subEvent}</span>
						</div>
						{ 
						each?.data?.event === "stay"  && 
						 <div className='mt-3'>
						<span  className='fw-bold'>Adults Number :</span> <span>{each?.data?.adults}</span>
						</div>
						}
						{ 
						each?.data?.event === "stay"  && 
						 <div className='mt-3'>
						<span  className='fw-bold'>Children Number :</span> <span>{each?.data?.children}</span>
						</div>
						}
						{ 
						each?.data?.event === "stay"  && 
						 <div className='mt-3'>
						<span  className='fw-bold'>Infants Number :</span> <span>{each?.data?.infants}</span>
						</div>
						}
						{ 
						each?.data?.event === "stay"  && 
						 <div className='mt-3'>
						<span  className='fw-bold'>Check In :</span> <span>{Moment(each?.data?.checkIn).format('YYYY-MM-DD')} </span>
						</div>
						}
						{ 
						each?.data?.event === "stay"  && 
						 <div className='mt-3'>
						<span  className='fw-bold'>Check Out :</span> <span>{Moment(each?.data?.checkOut).format('YYYY-MM-DD')} </span>
						</div>
						}
						{ 
						each?.data?.event !== "stay"  && 
						 <div className='mt-3'>
						<span  className='fw-bold'>Guests Number :</span> <span>{each?.data?.guests}</span>
						</div>
						}
						<div className=' mt-3'>
						<span  className='fw-bold'>Requested Received Date :</span> <span>{Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
						</div>
						{/* { each?.data?.event !== "stay"  && 
							<div className=' mt-3'>
								<span  className='fw-bold'>Requested Dates : </span> 
								<ol  className='fw-bold'>
									{
										each?.data?.requestDates?.map((eachDate) => {
											return (
											<li className="mt-2"> 
												Date :  {Moment(eachDate?.date).format('YYYY-MM-DD')} <br/>
												Start Time :  {eachDate?.start} <br/>
												End Time :  {eachDate?.end} <br/>
											</li>
											)
										} )
									}
								</ol>
							</div>
						} */}
                        { 
						each?.data?.event !== "stay"  && 
						 <div className='mt-3'>
						<span  className='fw-bold'>Start Date :</span> <span>{Moment(each?.data?.startDate).format('YYYY-MM-DD')}</span>
						</div>
						}
                        { 
						each?.data?.event !== "stay"  && 
						 <div className='mt-3'>
						<span  className='fw-bold'>Start Time :</span> <span>{each?.data?.startTime}</span>
						</div>
						}
						{/* end::Text */}
					</div>
                        </div>
                    </div>
                )
        //         })
        //     } 
        //     </div>
        // )
    }

    const bookingRequest = () => {
        return (
            <div>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-13-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-100px text-center'>Enquiry Id</th>
                                <th className='min-w-100px text-center'>Enquiry Date</th>
                                <th className='min-w-100px text-center'>Property Id</th>
                                <th className='min-w-100px text-center'>Contact Number</th>
                                <th className='min-w-100px text-center'>Requested Date</th>
                                <th className='min-w-100px text-center'>Event</th>
                                <th className='min-w-100px text-center'>View Status</th>
                                <th className='min-w-100px text-center'>Status</th>
                                {/* <th className='min-w-100px text-center'>Assigned to</th> */}
                                <th className='min-w-100px text-center'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {formData.map((each : any, index : any) => {
                                return (
                                    <tr>
                                        {/* <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                            </div>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-center d-flex justify-content-center align-items-center'>
                                                {each?.enquiryId || "NA"}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.locationId}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.guestNumber || "NA"}
                                            </div>
                                        </td>
                                        {each?.data?.event === "stay" ? (
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                                {each?.data?.checkIn ? Moment(each?.data?.checkIn).format('YYYY-MM-DD') : "NA"} To { each?.data?.checkOut ? Moment(each?.data?.checkOut).format('YYYY-MM-DD') : "NA"}
                                                </div>
                                            </td>
                                        ) : (
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                                {each?.data?.startDate ? Moment(each?.data?.startDate).format('YYYY-MM-DD') : "NA"} - { each?.data?.startTime ? each?.data?.startTime : "NA"}
                                                </div>
                                            </td>
                                        ) }
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.data?.event || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`badge ${ each?.read ? "badge-light-success" : "badge-light-danger" } d-flex justify-content-center align-items-center`}>
                                            {each?.read ? "Viewed" : "Not Viewed"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.status || "NA"}
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div className='badge badge-light-danger d-flex justify-content-center align-items-center'>
                                            {each?.assignedTo || "NA"}
                                            </div>
                                        </td> */}
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    title="View Locations">
                                                    <KTIcon iconName='magnifier' className='fs-3' />
                                                </div> */}
                                                {/* <Link to ={`../enquiry-view/${each._id}`} > */}
                                                    <button className='btn btn-bg-warning btn-color-muted btn-active-color-primary btn-sm px-4 me-2' onClick = { () => onClickPopUpDetails(each) } 
                                                        title="Details">
                                                        Details
                                                    </button>
                                                {/* </Link>    */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
        )
    }

    const claimProperty1 = (each : any) => {
        // return(
        //     <div className="d-flex flex-column">
        //     {
        //         formData.map((each : any) => {
                return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                            <div className='mb-3'>
                                {/* begin::Text */}
                                <div className='mt-3'>
                                <span  className='fw-bold'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Location Id :</span> <span>{each?.data?.locationId}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>First Name :</span> <span>{each?.data?.firstName}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Last Name :</span> <span>{each?.data?.lastName}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Email :</span> <span>{each?.data?.email}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Number  :</span> <span>{each?.data?.mobileNumber}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Relationship :</span> <span>{each?.data?.relationship}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Additional Information :</span> <span>{each?.data?.additionalInfo}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Status :</span> <span>{each?.status}</span>
                                </div>
                                {/* end::Text */}
                            </div>
                        </div>
                    </div>
                )
        //         })
        //     } 
        //     </div>
        // )
    }

    const claimProperty = () => {
        return (
            <div>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-13-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-100px text-center'>Enquiry Id</th>
                                <th className='min-w-100px text-center'>Enquiry Date</th>
                                <th className='min-w-100px text-center'>Property Id</th>
                                <th className='min-w-100px text-center'>Contact Person Name</th>
                                <th className='min-w-100px text-center'>Contact Number</th>
                                <th className='min-w-100px text-center'>Status</th>
                                <th className='min-w-100px text-center'>Assigned to</th>
                                <th className='min-w-100px text-center'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {formData.map((each : any, index : any) => {
                                return (
                                    <tr>
                                        {/* <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                            </div>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-center d-flex justify-content-center align-items-center'>
                                                {each?.enquiryId || "NA"}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.locationId}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.firstName}{" "}{each?.data?.lastName}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.mobileNumber || "NA"} 
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.status || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-danger d-flex justify-content-center align-items-center'>
                                            {each?.assignedTo || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    title="View Locations">
                                                    <KTIcon iconName='magnifier' className='fs-3' />
                                                </div> */}
                                                {/* <Link to = {`../enquiry-view/${each._id}`} > */}
                                                    <button className='btn btn-bg-warning btn-color-muted btn-active-color-primary btn-sm px-4 me-2' onClick = { () => onClickPopUpDetails(each) } 
                                                        title="Details">
                                                        Details
                                                    </button>
                                                {/* </Link> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
        )
    }

    const IncorrectPropertyInfo1 = (each : any) => {
        // return(
        //     <div className="d-flex flex-column">
        //     {
        //         formData.map((each : any) => {
                return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                            <div className='mb-3'>
                                {/* begin::Text */}
                                <div className='mt-3'>
                                <span  className='fw-bold'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Location Id :</span> <span>{each?.data?.locationId}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>User ID :</span> <span>{each?.data?.userId}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Report Details :</span> <span>{each?.data?.reportDetails?.length > 0 ? each?.data?.reportDetails.join(",") : "NA"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Additional Information :</span> <span>{ each?.data?.additionalInfo ? each?.data?.additionalInfo : "NA"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Status :</span> <span>{each?.status}</span>
                                </div>
                                {/* end::Text */}
                            </div>
                        </div>
                    </div>
                )
        //          })
        //     } 
        //     </div>
        // )
    }

    const IncorrectPropertyInfo = () => {
        return (
            <div>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-13-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-100px text-center'>Enquiry Id</th>
                                <th className='min-w-100px text-center'>Enquiry Date</th>
                                <th className='min-w-100px text-center'>Property Id</th>
                                {/* <th className='min-w-100px text-center'>Contact Number</th>
                                <th className='min-w-100px text-center'>Email</th> */}
                                <th className='min-w-100px text-center'>User Id</th>
                                <th className='min-w-100px text-center'>Status</th>
                                <th className='min-w-100px text-center'>Assigned to</th>
                                <th className='min-w-100px text-center'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {formData.map((each : any, index : any) => {
                                return (
                                    <tr>
                                        {/* <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                            </div>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-center d-flex justify-content-center align-items-center'>
                                                {each?.enquiryId || "NA"}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.locationId}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.userId}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.status || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-danger d-flex justify-content-center align-items-center'>
                                            {each?.assignedTo || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    title="View Locations">
                                                    <KTIcon iconName='magnifier' className='fs-3' />
                                                </div> */}
                                                {/* <Link to ={`../enquiry-view/${each._id}`} > */}
                                                    <button className='btn btn-bg-warning btn-color-muted btn-active-color-primary btn-sm px-4 me-2' onClick = { () => onClickPopUpDetails(each) } 
                                                        title="Details">
                                                        Details
                                                    </button>
                                                {/* </Link> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
        )
    }
    
    const requestsForScheduleProperties1 = (each) => {
        // return(
        //     <div className="d-flex flex-column">
        //     {
        //         formData.map((each : any) => {
                return(
                    <div className='card mb-5 mb-xl-10' >
                        <div className='card-header cursor-pointer'>
                            <div className='mb-3'>
                                {/* begin::Text */}
                                <div className='mt-3'>
                                <span  className='fw-bold'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Location Id :</span> <span>{each?.data?.locationId}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>User ID :</span> <span>{each?.data?.userId}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Date :</span> <span>{each?.data?.date}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Additional Information :</span> <span>{ each?.data?.additionalInfo ? each?.data?.additionalInfo : "NA"}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Status :</span> <span>{each?.status}</span>
                                </div>
                                {/* end::Text */}
                            </div>
                        </div>
                    </div>
                )
        //         })
        //     } 
        //     </div>
        // )
    }

    const requestsForScheduleProperties = () => {
        return (
            <div>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-13-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-100px text-center'>Enquiry Id</th>
                                <th className='min-w-100px text-center'>Enquiry Date</th>
                                <th className='min-w-100px text-center'>Property Id</th>
                                <th className='min-w-100px text-center'>User Id</th>
                                {/* <th className='min-w-100px text-center'>Contact Number</th>
                                <th className='min-w-100px text-center'>Email</th> */}
                                <th className='min-w-100px text-center'>View Status</th>
                                <th className='min-w-100px text-center'>Status</th>
                                {/* <th className='min-w-100px text-center'>Assigned to</th> */}
                                <th className='min-w-100px text-center'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {formData.map((each : any, index : any) => {
                                return (
                                    <tr>
                                        {/* <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                            </div>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-center d-flex justify-content-center align-items-center'>
                                                {each?.enquiryId || "NA"}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.locationId}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.userId}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`badge ${ each?.read ? "badge-light-success" : "badge-light-danger" } d-flex justify-content-center align-items-center`}>
                                            {each?.read ? "Viewed" : "Not Viewed"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.status || "NA"}
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div className='badge badge-light-danger d-flex justify-content-center align-items-center'>
                                            {each?.assignedTo || "NA"}
                                            </div>
                                        </td> */}
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    title="View Locations">
                                                    <KTIcon iconName='magnifier' className='fs-3' />
                                                </div> */}
                                                {/* <Link to ={`../enquiry-view/${each._id}`} > */}
                                                    <button className='btn btn-bg-warning btn-color-muted btn-active-color-primary btn-sm px-4 me-2' onClick = { () => onClickPopUpDetails(each) } 
                                                        title="Details">
                                                        Details
                                                    </button>
                                                {/* </Link> */}                                                
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
        )
    }

    // for host get in touch requests start
    const hostGetInTouch1 = (each : any) => {
        return(
            <div className='card mb-5 mb-xl-10' >
                <div className='card-header cursor-pointer'>
                <div className='mb-3'>
                {/* begin::Text */}
                <div className='mt-3'>
                <span  className='fw-bold'>Enquiry Id :</span> <span>{each?.enquiryId || "NA"}</span>
                </div>
                <div className='mt-3'>
                <span  className='fw-bold'>Property Name :</span> <span>{each?.data?.property || "NA"}</span>
                </div>
                <div className='mt-3'>
                <span  className='fw-bold'>Applicant Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
                <span  className='fw-bold'>Applicant Email :</span> <span>{each?.data?.email}</span>
                </div>
                <div className='mt-3'>
                <span  className='fw-bold'>Applicant Number :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                {/* <div className='mt-3'>
                <span  className='fw-bold'>Applicant Organization :</span> <span>{each?.data?.organization ? each?.data?.organization : "N/A"}</span>
                </div> */}
                <div className='mt-3'>
                <span  className='fw-bold'>Applicant Message :</span> <span>{each?.data?.message ? each?.data?.message : "N/A"}</span>
                </div>
                <div className=' mt-3'>
                <span  className='fw-bold'>Requested Date :</span> <span>{Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}</span>
                </div>
                {/* end::Text */}
            </div>
                </div>
            </div>
        )
    }

    const hostGetInTouch = () => {
        return (
            <div>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                {/* <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-13-check'
                                        />
                                    </div>
                                </th> */}
                                <th className='min-w-100px text-center'>Enquiry Id</th>
                                <th className='min-w-100px text-center'>Enquiry Date</th>
                                {/* <th className='min-w-100px text-center'>Property Id</th> */}
                                <th className='min-w-100px text-center'>User Id</th>
                                <th className='min-w-100px text-center'>Name</th>
                                {/* <th className='min-w-100px text-center'>Property Name</th> */}
                                <th className='min-w-100px text-center'>Contact Number</th>
                                <th className='min-w-100px text-center'>Email</th>
                                {/* <th className='min-w-100px text-center'>View Status</th> */}
                                <th className='min-w-100px text-center'>Status</th>
                                <th className='min-w-100px text-center'>Assigned to</th>
                                <th className='min-w-100px text-center'>Actions</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {formData.map((each : any, index : any) => {
                                return (
                                    <tr>
                                        {/* <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                            </div>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-center d-flex justify-content-center align-items-center'>
                                                {each?.enquiryId || "NA"}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 d-flex justify-content-center align-items-center'>
                                            {Moment(each.createdAt).format('YYYY-MM-DD hh:mm a')}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.userId || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.mobileNumber}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-primary d-flex justify-content-center align-items-center'>
                                            {each?.data?.email}
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div className={`badge ${ each?.read ? "badge-light-success" : "badge-light-danger" } d-flex justify-content-center align-items-center`}>
                                            {each?.read ? "Viewed" : "Not Viewed"}
                                            </div>
                                        </td> */}
                                        <td>
                                            <div className='badge badge-light-info d-flex justify-content-center align-items-center'>
                                            {each?.status || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='badge badge-light-danger d-flex justify-content-center align-items-center'>
                                            {each?.assignedTo || "NA"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    title="View Locations">
                                                    <KTIcon iconName='magnifier' className='fs-3' />
                                                </div> */}
                                                {/* <Link to ={`../enquiry-view/${each._id}`} > */}
                                                    <button className='btn btn-bg-warning btn-color-muted btn-active-color-primary btn-sm px-4 me-2' onClick = { () => onClickPopUpDetails(each) } 
                                                        title="Details">
                                                        Details
                                                    </button>
                                                {/* </Link> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
        )
    }
    // for host get in touch requests end

    const ReqEnquiry = () => {
            switch (type) {
                case "Subscription":
                    return Subscription()
                case "FindMySpot":
                    return FindMySpot()
                case "EventPlanning":
                    return EventPlanning()
                case "Contact Host":
                    return contactHost()
                case "Booking Request":
                    return bookingRequest()
                case "Get In Touch":
                    return getInTouch()
                case "Claim Property":
                    return claimProperty()
                case "Report Incorrect Info":
                    return IncorrectPropertyInfo()
                case "Schedule Property Visit":
                    return requestsForScheduleProperties()
                case "Host Get In Touch":
                    return hostGetInTouch()
                default:
                    break;
            }
    }

    const getDetailsBody = () => {
        switch (type) {
            case "Subscription":
                return Subscription1(cardData)
            case "FindMySpot":
                return FindMySpot1(cardData)
            case "EventPlanning":
                return EventPlanning1(cardData)
            case "Contact Host":
                return contactHost1(cardData)
            case "Booking Request":
                return bookingRequest1(cardData)
            case "Get In Touch":
                return getInTouch1(cardData)
            case "Claim Property":
                return claimProperty1(cardData)
            case "Report Incorrect Info":
                return IncorrectPropertyInfo1(cardData)
            case "Schedule Property Visit":
                return requestsForScheduleProperties1(cardData)
            case "Host Get In Touch":
                return hostGetInTouch1(cardData)
            default:
                break;
        }
    }
    const getReportsTableName = () => {
        switch (type) {
            case "Subscription":
                return "subscription-enquiry-leads"
            case "FindMySpot":
                return "find-my-spot-leads"
            case "EventPlanning":
                return "event-planning-leads"
            case "Contact Host":
                return "contact-host-leads"
            case "Booking Request":
                return "booking-request-leads"
            case "Get In Touch":
                return "get-in-touch-leads"
            case "Claim Property":
                return "claim-property-enquiries"
            case "Report Incorrect Info":
                return "report-incorrect-info-enquiries"
            case "Schedule Property Visit":
                return "schedule-property-visit-leads"
            case "Host Get In Touch":
                return "host-get-in-touch-leads"
            default:
                break;
        }
    }

    // const liStyle = {
    //     "width" : "max-content" ,
    // }

    return (
        <>
            <div className='d-flex justify-content-between'>
                {/* <ul className='w-100 nav nav-stretch nav-line-tabs border-transparent fs-5 fw-bolder flex-nowrap d-flex flex-row justify-content-between' style={{overflowX : "auto",  overflowY : 'hidden'}} >
                <li className='w-100 nav-item nav-link text-active-primary cursor-pointer'>
                    <div style={liStyle}
                    className={`nav-link text-active-primary` +
                        (enquiryType === "FindMySpot" && 'active' )
                    }
                    onClick={() => onChangeType('FindMySpot')}
                    >
                    Find My Spot
                    </div>
                </li>
                <li className='nav-item nav-link text-active-primary cursor-pointer'>
                        <div style={liStyle}
                        className={`nav-link text-active-primary `+
                            (enquiryType === "EventPlanning" && 'active' )
                        }
                        onClick={() => onChangeType('EventPlanning')}
                        >
                        Event Planning
                        </div>
                </li>
                <li className='nav-item nav-link text-active-primary cursor-pointer'>
                    <div style={liStyle}
                    className={`nav-link text-active-primary ` +
                        (enquiryType === 'Subscription' && 'active' )
                    }
                    onClick={() => onChangeType('Subscription')}
                    >
                    Subscription Enquiry
                    </div>
                </li>
                <li className='nav-item nav-link text-active-primary cursor-pointer'>
                        <div style={liStyle}
                        className={`nav-link text-active-primary `+
                            (enquiryType === "Contact Host" && 'active' )
                        }
                        onClick={() => onChangeType('Contact Host')}
                        >
                        Contact Host 
                        </div>
                </li>
                <li className='nav-item nav-link text-active-primary cursor-pointer'>
                        <div style={liStyle}
                        className={`nav-link text-active-primary `+
                            (enquiryType === "Booking Request" && 'active' )
                        }
                        onClick={() => onChangeType('Booking Request')}
                        >
                        Booking Requests
                        </div>
                </li>
                <li className='nav-item nav-link text-active-primary cursor-pointer'>
                        <div style={liStyle}
                        className={`nav-link text-active-primary `+
                            (enquiryType === "Get In Touch" && 'active' )
                        }
                        onClick={() => onChangeType('Get In Touch')}
                        >
                        Property Get In Touch
                        </div>
                </li>
                <li className='nav-item nav-link text-active-primary cursor-pointer'>
                        <div style={liStyle}
                        className={`nav-link text-active-primary `+
                            (enquiryType === "Claim Property" && 'active' )
                        }
                        onClick={() => onChangeType('Claim Property')}
                        >
                        Claim Property Requests
                        </div>
                </li>
                <li className='nav-item nav-link text-active-primary cursor-pointer'>
                        <div style={liStyle}
                        className={`nav-link text-active-primary`+
                            (enquiryType === "Report Incorrect Info" && 'active' )
                        }
                        onClick={() => onChangeType('Report Incorrect Info')}
                        >
                        Property Incorrect Info Reports
                        </div>
                </li>
                <li className='nav-item nav-link text-active-primary cursor-pointer'>
                        <div style={liStyle}
                        className={`nav-link text-active-primary `+
                            (enquiryType === "Schedule Property Visit" && 'active' )
                        }
                        onClick={() => onChangeType('Schedule Property Visit')}
                        >
                        Schedule Property Visit
                        </div>
                </li>
                </ul> */}
                <div className='card-title d-flex flex-row justify-content-between w-100'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            id='leadSearchInput'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search'
                            value={searchTerm}
                            onChange={handleSearch}
                            />
                    </div>
                    {/* end::Search */}
                    {/* begin::Report */}
                    <div className="card-toolbar">
                        <Reports table={getReportsTableName()}/>
                    </div>
                    {/* end::Report */}
                </div>
            </div>
            {
                formData && ReqEnquiry()
            }
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                    <Stack spacing={2}>
                        <Pagination count={Math.ceil(dataCount/noOfItems)} variant="outlined" shape="rounded" color="primary" onChange={(e,value)=>setPage(value)} />
                    </Stack>
            </div>
            {/* Modal Start */}
            <Modal 
                show={show} 
                onHide={() => {
                    setShow(false);
                    setCardData({});
                }}
            >
                <Header closeButton>
                    <Title style={{fontSize : '18px'}}>Details</Title>
                </Header>
                <Body className="verify-email-modal-body" style={{fontSize : '14px'}}>
                    {getDetailsBody()}
                </Body>
                <Footer>
                    <Button 
                        variant="secondary" 
                        onClick={() => {
                            setShow(false);
                            setCardData({});
                        }} 
                        style={{fontSize : '14px'}}
                    >
                    Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleOk} style={{fontSize : '13px'}}>
                    OK
                    </Button> */}
                </Footer>
            </Modal>
            {/* Modal End */}
            {showLoader && <PageLoader/>}
        </>
    );
};

export default Enquiry;
