import React, { useState } from "react";
import { useEffect } from "react";
//import { useLocationStore } from "../../store/location";
//import { ConfirmationModel } from '../Modal/ConfirmationModel';
const Timings = ({ locationData  }) => {
  //const { location: locationData } = useLocationStore();

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");

  const [weeks, setWeeks] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  const [formData, setFormData] = useState({
    monday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    tuesday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    wednesday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    thursday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    friday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    saturday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    sunday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
  });

  const timeOptions = [

    { value: "01:00 am", label: "01:00 am" },
    { value: "02:00 am", label: "02:00 am" },
    { value: "03:00 am", label: "03:00 am" },
    { value: "04:00 am", label: "04:00 am" },
    { value: "05:00 am", label: "05:00 am" },
    { value: "06:00 am", label: "06:00 am" },
    { value: "07:00 am", label: "07:00 am" },
    { value: "08:00 am", label: "08:00 am" },
    { value: "09:00 am", label: "09:00 am" },
    { value: "10:00 am", label: "10:00 am" },
    { value: "11:00 am", label: "11:00 am" },
    { value: "12:00 pm", label: "12:00 pm" },
    { value: "01:00 pm", label: "01:00 pm" },
    { value: "02:00 pm", label: "02:00 pm" },
    { value: "03:00 pm", label: "03:00 pm" },
    { value: "04:00 pm", label: "04:00 pm" },
    { value: "05:00 pm", label: "05:00 pm" },
    { value: "06:00 pm", label: "06:00 pm" },
    { value: "07:00 pm", label: "07:00 pm" },
    { value: "08:00 pm", label: "08:00 pm" },
    { value: "09:00 pm", label: "09:00 pm" },
    { value: "10:00 pm", label: "10:00 pm" },
    { value: "11:00 pm", label: "11:00 pm" },
    { value: "12:00 am", label: "12:00 am" },
  
  ];

  const [monday, setmonday] = useState({
    open: false,
    isSetHours: false,
    time: "all day",
  });
  const [tuesday, settuesday] = useState({
    open: false,
    isSetHours: false,
    time: "all day",
  });
  const [wednesday, setwednesday] = useState({
    open: false,
    isSetHours: false,
    time: "all day",
  });
  const [thursday, setthursday] = useState({
    open: false,
    isSetHours: false,
    time: "all day",
  });
  const [friday, setfriday] = useState({
    open: false,
    isSetHours: false,
    time: "all day",
  });
  const [saturday, setsaturday] = useState({
    open: false,
    isSetHours: false,
    time: "all day",
  });
  const [sunday, setsunday] = useState({
    open: false,
    isSetHours: false,
    time: "all day",
  });
  

  useEffect(() => {
    let tformData = Object.keys(formData).reduce((a, c) => {
      a[c] = formData[c];
      a[c].open = weeks[c];
      return a;
    }, {});
    setFormData({ ...tformData });
  }, [weeks]);

  useEffect(() => {
    if (locationData && locationData?.timings) {
      const { timings } = locationData;
      const tdata = Object.keys(formData).reduce((a, c) => {
        a[c] = timings[c] || "";
        return a;
      }, {});
      setFormData({
        ...formData,
        ...tdata,
      });

      locationData.timings && setmonday(locationData.timings.monday);
      locationData.timings && settuesday(locationData.timings.tuesday);
      locationData.timings && setwednesday(locationData.timings.wednesday);
      locationData.timings && setthursday(locationData.timings.thursday);
      locationData.timings && setfriday(locationData.timings.friday);
      locationData.timings && setsaturday(locationData.timings.saturday);
      locationData.timings && setsunday(locationData.timings.sunday);
    }
  }, [locationData]);

  const handleBack = () => {
    //setTab("pricing");
  }

  const showError = (message) => {
    setConfirmationTitle("Timings");
    setConfirmationBody(message);
    setShowConfirmationModel(true); 
  }
  const handleSubmit = () => {
    if (!monday?.open && !tuesday?.open && !wednesday?.open && !thursday?.open && !friday?.open && !saturday?.open && !sunday?.open)
      return showError("Please fill all required fields");
    if ((monday?.open && monday.isSetHours && (monday.time === "all day"||!monday.time.start||!monday.time.end)) || 
        (tuesday?.open && tuesday.isSetHours && (tuesday.time === "all day"||!tuesday.time.start||!tuesday.time.end)) || 
        (wednesday?.open && wednesday.isSetHours && (wednesday.time === "all day"||!wednesday.time.start||!wednesday.time.end)) || 
        (thursday?.open && thursday.isSetHours && (thursday.time === "all day"||!thursday.time.start||!thursday.time.end)) || 
        (friday?.open && friday.isSetHours &&  (friday.time === "all day"||!friday.time.start||!friday.time.end)) || 
        (saturday?.open && saturday.isSetHours && (saturday.time === "all day"||!saturday.time.start||!saturday.time.end)) || 
        (sunday?.open && sunday.isSetHours && (sunday.time === "all day"||!sunday.time.start||!sunday.time.end)))
      return showError("Please fill time field");
    const timings = {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    };
    //console.log(timings)

    if (localStorage.getItem("locationId")) {
      //callbackFn({ timings: timings });
      //setTab("contact_dev");
    }
  };

  return (
    <>
      <div
        className="tab-pane h-100 w-100 fade timings-form show active"
        id="v-pills-timings"
        role="tabpanel"
        aria-labelledby="v-pills-timings-tab"
      >
        <div className="row g-0 h-100 w-100">
          <div className="col-xl-12">
            <form className="h-100 w-100">
              <div className="step-form h-100 w-100">
                <h3 className="ftitle">Timings</h3>
                <div>
                  <div>
                    <div className="select-time mt-3">
                      <div className="row d-flex align-items-start mt-5">
                      <div className="col-lg-2">
                      <div className="slot slotitle">
                        <h4>Monday</h4>
                      </div>
                    </div>
                        <div className="col-lg-2">
                          <div className="slot">
                            <div className="form-check form-switch">
                              <label className="form-check-label" htmlFor="s1">
                               {monday?.open ? <>Open</> : <>Close</> }
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="s1"
                                checked={monday?.open}
                                onClick={() => {
                                  setmonday({ ...monday, open: !monday?.open })
                                  locationData.timings = {...locationData?.timings,monday : {...locationData?.timings?.monday,open: !monday?.open}}
                              }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: monday?.open ? "block" : "none" }}
                        >
                          <div className="slot dayselect d-flex justify-content-around">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full0"
                                id="full0"
                                checked={!monday?.isSetHours}
                                onClick={() =>{
                                  setmonday({ ...monday, time: "all day", isSetHours: false })
                                  locationData.timings = {...locationData?.timings,monday : {...locationData?.timings?.monday,time: "all day", isSetHours: false}}
                                }}
                              />
                              <label className="form-check-label" htmlFor="full0">
                                Full Day
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full0"
                                id="full00"
                                checked={monday?.isSetHours}
                                onClick={() => {setmonday({ 
                                  ...monday, 
                                  isSetHours: true,
                                  time:{start: "06:00 am", end: "06:00 pm"}
                                })
                                locationData.timings = {...locationData?.timings,monday : {...locationData?.timings?.monday,time:{start: "06:00 am", end: "06:00 pm"}, isSetHours: true}}
                              }}
                              />
                              <label className="form-check-label" htmlFor="full00">
                                Set Hours
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: monday?.open && monday?.isSetHours ? "block" : "none" }}
                        >
                          <div className="slot from-toselect d-flex justify-content-around">
                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                value={monday?.time?.start}
                                defaultValue="06:00 am"
                                onChange={(e) =>{
                                  setmonday({
                                    ...monday, 
                                    time:{ ...monday.time, start:e.target.value}
                                  })
                                  locationData.timings = {...locationData?.timings,monday : {...locationData?.timings?.monday, time:{ ...monday.time, start:e.target.value}}}
                                }}
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                            
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>

                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                value={monday?.time?.end}
                                defaultValue="06:00 pm"
                                onChange={(e) =>{
                                  setmonday({
                                    ...monday,
                                    time: { ...monday.time, end:e.target.value },
                                  })
                                  locationData.timings = {...locationData?.timings,monday : {...locationData?.timings?.monday, time:{ ...monday.time, end:e.target.value}}}
                               } }
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="select-time mt-3">
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-2">
                          <div className="slot slotitle">
                            <h4>Tuesday</h4>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="slot">
                            <div className="form-check form-switch">
                              <label className="form-check-label" htmlFor="s2">
                                {tuesday?.open ? <>Open</> : <>Close</> }
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="s2"
                                checked={tuesday?.open}
                                onClick={() =>{ 
                                  settuesday({ ...tuesday, open: !tuesday?.open })
                                  locationData.timings = {...locationData?.timings,tuesday : {...locationData?.timings?.tuesday, open: !tuesday?.open}}
                               } }
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: tuesday?.open ? "block" : "none" }}
                        >
                          <div className="slot dayselect d-flex justify-content-around">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full1"
                                id="full11"
                                checked={!tuesday?.isSetHours}
                                onClick={() =>{
                                  settuesday({ ...tuesday, time: "all day", isSetHours: false })
                                  locationData.timings = {...locationData?.timings,tuesday : {...locationData?.timings?.tuesday, time: "all day", isSetHours: false}}
                                }}
                              />
                              <label className="form-check-label" htmlFor="full11">
                                Full Day
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full1"
                                id="full12"
                                checked={tuesday?.isSetHours}
                                onClick={() =>{ 
                                  settuesday({ ...tuesday, isSetHours: true, time:{start: "06:00 am", end: "06:00 pm"} })
                                  locationData.timings = {...locationData?.timings,tuesday : {...locationData?.timings?.tuesday,  isSetHours: true, time:{start: "06:00 am", end: "06:00 pm"}}}
                               } }
                              />
                              <label className="form-check-label" htmlFor="full12">
                                Set Hours
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: tuesday?.open && tuesday?.isSetHours ? "block" : "none" }}
                        >
                          <div className="slot from-toselect d-flex justify-content-around">
                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={tuesday?.time?.start}
                                onChange={(e) =>{
                                  settuesday({
                                    ...tuesday, 
                                    time:{ ...tuesday.time, start:e.target.value}
                                  })
                                  locationData.timings = {...locationData?.timings,tuesday : {...locationData?.timings?.tuesday, time:{ ...tuesday.time, start:e.target.value}}}
                               } }
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>

                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                value={tuesday?.time?.end}
                                defaultValue="06:00 pm"
                                onChange={(e) =>{
                                  settuesday({
                                    ...tuesday,
                                    time: { ...tuesday.time, end: e.target.value },
                                  })
                                  locationData.timings = {...locationData?.timings,tuesday : {...locationData?.timings?.tuesday, time:{ ...tuesday.time, end:e.target.value}}}
                               } }
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="select-time mt-3">
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-2">
                          <div className="slot slotitle">
                            <h4>Wednesday</h4>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="slot">
                            <div className="form-check form-switch">
                              <label className="form-check-label" htmlFor="s3">
                                {wednesday?.open ? <>Open</> : <>Close</> }
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="s3"
                                checked={wednesday?.open}
                                onClick={() => {
                                  setwednesday({ ...wednesday, open: !wednesday?.open })
                                  locationData.timings = {...locationData?.timings,wednesday : {...locationData?.timings?.wednesday, open: !wednesday?.open}}
                               } }
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{
                            display: wednesday?.open ? "block" : "none",
                          }}
                        >
                          <div className="slot dayselect d-flex justify-content-around">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full2"
                                id="full2"
                                checked={!wednesday?.isSetHours}
                                onClick={() =>{
                                  setwednesday({ ...wednesday, time: "all day", isSetHours: false })
                                  locationData.timings = {...locationData?.timings,wednesday : {...locationData?.timings?.wednesday, time: "all day", isSetHours: false}}
                                }}
                              />
                              <label className="form-check-label" htmlFor="full2">
                                Full Day
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full2"
                                id="full22"
                                checked={wednesday?.isSetHours}
                                onClick={() =>{ 
                                  setwednesday({ ...wednesday, isSetHours: true ,time:{start: "06:00 am", end: "06:00 pm"}})
                                  locationData.timings = {...locationData?.timings,wednesday : {...locationData?.timings?.wednesday,  isSetHours: true ,time:{start: "06:00 am", end: "06:00 pm"}}}
                               } }
                              />
                              <label className="form-check-label" htmlFor="full22">
                                Set Hours
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{
                            display: wednesday?.open && wednesday.isSetHours ? "block" : "none",
                          }}
                        >
                          <div className="slot from-toselect d-flex justify-content-around">
                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={wednesday?.time?.start}
                                onChange={(e) =>{
                                  setwednesday({
                                    ...wednesday, 
                                    time:{ ...wednesday.time, start:e.target.value}
                                  })
                                  locationData.timings = {...locationData?.timings,wednesday : {...locationData?.timings?.wednesday,  time:{ ...wednesday.time, start:e.target.value}}}
                               } }
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>

                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={wednesday?.time?.end}
                                onChange={(e) =>{
                                  setwednesday({
                                    ...wednesday,
                                    time: { ...wednesday.time, end: e.target.value },
                                  })
                                  locationData.timings = {...locationData?.timings,wednesday : {...locationData?.timings?.wednesday,  time:{ ...wednesday.time, end:e.target.value}}}
                               } }
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="select-time mt-3">
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-2">
                          <div className="slot slotitle">
                            <h4>Thursday</h4>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="slot">
                            <div className="form-check form-switch">
                              <label className="form-check-label" htmlFor="s4">
                                {thursday?.open ? <>Open</> : <>Close</> }
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="s4"
                                checked={thursday?.open}
                                onClick={() => {
                                  setthursday({ ...thursday, open: !thursday?.open })
                                  locationData.timings = {...locationData?.timings,thursday : {...locationData?.timings?.thursday, open: !thursday?.open }}
                               } }
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: thursday?.open ? "block" : "none" }}
                        >
                          <div className="slot dayselect d-flex justify-content-around">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full3"
                                id="full3"
                                checked={!thursday?.isSetHours}
                                onClick={() =>{
                                  setthursday({ ...thursday, time: "all day", isSetHours: false })
                                  locationData.timings = {...locationData?.timings,thursday : {...locationData?.timings?.thursday,  time: "all day", isSetHours: false }}
                               } }
                              />
                              <label className="form-check-label" htmlFor="full3">
                                Full Day
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full3"
                                id="full33"
                                checked={thursday?.isSetHours}
                                onClick={() =>{ 
                                  setthursday({ ...thursday, isSetHours: true ,time:{start: "06:00 am", end: "06:00 pm"}})
                                  locationData.timings = {...locationData?.timings,thursday : {...locationData?.timings?.thursday,  isSetHours: true ,time:{start: "06:00 am", end: "06:00 pm"} }}
                               } }
                              />
                              <label className="form-check-label" htmlFor="full33">
                                Set Hours
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: thursday?.open && thursday.isSetHours ? "block" : "none" }}
                        >
                          <div className="slot from-toselect d-flex justify-content-around">
                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={thursday?.time?.start}
                                onChange={(e) =>{
                                  setthursday({
                                    ...thursday, 
                                    time:{ ...thursday.time, start:e.target.value}
                                  })
                                  locationData.timings = {...locationData?.timings,thursday : {...locationData?.timings?.thursday,  time:{ ...thursday.time, start:e.target.value} }}
                                }}
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>

                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={thursday?.time?.end}
                                onChange={(e) =>{
                                  setthursday({
                                    ...thursday,
                                    time: { ...thursday.time, end: e.target.value },
                                  })
                                  locationData.timings = {...locationData?.timings,thursday : {...locationData?.timings?.thursday,  time:{ ...thursday.time, end:e.target.value} }}
                                }}
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="select-time mt-3">
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-2">
                          <div className="slot slotitle">
                            <h4>Friday</h4>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="slot">
                            <div className="form-check form-switch">
                              <label className="form-check-label" htmlFor="s5">
                                {friday?.open ? <>Open</> : <>Close</> }
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="s5"
                                checked={friday?.open}
                                onClick={() =>{ 
                                  setfriday({ ...friday, open: !friday?.open })
                                  locationData.timings = {...locationData?.timings,friday : {...locationData?.timings?.friday,  open: !friday?.open }}
                               } }
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: friday?.open ? "block" : "none" }}
                        >
                          <div className="slot dayselect d-flex justify-content-around">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full4"
                                id="full4"
                                checked={!friday?.isSetHours}
                                onClick={() =>{
                                  setfriday({ ...friday, time: "all day", isSetHours: false })
                                  locationData.timings = {...locationData?.timings,friday : {...locationData?.timings?.friday,  time: "all day", isSetHours: false }}
                                }}
                              />
                              <label className="form-check-label" htmlFor="full4">
                                Full Day
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full4"
                                id="full44"
                                checked={friday?.isSetHours}
                                onClick={() => {
                                  setfriday({ ...friday, isSetHours: true ,time:{start: "06:00 am", end: "06:00 pm"}})
                                  locationData.timings = {...locationData?.timings,friday : {...locationData?.timings?.friday,  isSetHours: true ,time:{start: "06:00 am", end: "06:00 pm"} }}
                                }}
                              />
                              <label className="form-check-label" htmlFor="full44">
                                Set Hours
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: friday?.open && friday.isSetHours ? "block" : "none" }}
                        >
                          <div className="slot from-toselect d-flex justify-content-around">
                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={friday?.time?.start}
                                onChange={(e) =>{
                                  setfriday({
                                    ...friday, 
                                    time:{ ...friday.time, start:e.target.value}
                                  })
                                  locationData.timings = {...locationData?.timings,friday : {...locationData?.timings?.friday,  time:{ ...friday.time, start:e.target.value} }}
                                }}
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>

                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={friday?.time?.end}
                                onChange={(e) =>{
                                  setfriday({
                                    ...friday,
                                    time: { ...friday.time, end: e.target.value },
                                  })
                                  locationData.timings = {...locationData?.timings,friday : {...locationData?.timings?.friday,  time:{ ...friday.time, end:e.target.value} }}
                               } }
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="select-time mt-3">
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-2">
                          <div className="slot slotitle">
                            <h4>Saturday</h4>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="slot">
                            <div className="form-check form-switch">
                              <label className="form-check-label" htmlFor="s6">
                                {saturday?.open ? <>Open</> : <>Close</> }
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="s6"
                                checked={saturday?.open}
                                onClick={() => {
                                  setsaturday({ ...saturday, open: !saturday?.open })
                                  locationData.timings = {...locationData?.timings,saturday : {...locationData?.timings?.saturday,open: !saturday?.open}}
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: saturday?.open ? "block" : "none" }}
                        >
                          <div className="slot dayselect d-flex justify-content-around">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full5"
                                id="full5"
                                checked={!saturday?.isSetHours}
                                onClick={() =>{
                                  setsaturday({ ...saturday, time: "all day", isSetHours: false })
                                  locationData.timings = {...locationData?.timings,saturday : {...locationData?.timings?.saturday,time: "all day", isSetHours: false}}
                               } }
                              />
                              <label className="form-check-label" htmlFor="full5">
                                Full Day
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full5"
                                id="full55"
                                checked={saturday?.isSetHours}
                                onClick={() =>{
                                   setsaturday({ ...saturday, isSetHours: true, time:{start: "06:00 am", end: "06:00 pm"}})
                                   locationData.timings = {...locationData?.timings,saturday : {...locationData?.timings?.saturday, isSetHours: true, time:{start: "06:00 am", end: "06:00 pm"}}}
                                  }}
                              />
                              <label className="form-check-label" htmlFor="full55">
                                Set Hours
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: saturday?.open && saturday.isSetHours ? "block" : "none" }}
                        >
                          <div className="slot from-toselect d-flex justify-content-around">
                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={saturday?.time?.start}
                                onChange={(e) =>{
                                  setsaturday({
                                    ...saturday, 
                                    time:{ ...saturday.time, start:e.target.value}
                                  })
                                  locationData.timings = {...locationData?.timings,saturday : {...locationData?.timings?.saturday, time:{ ...saturday.time, start:e.target.value}}}
                               } }
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>

                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={saturday?.time?.end}
                                onChange={(e) =>{
                                  setsaturday({
                                    ...saturday,
                                    time: { ...saturday.time, end: e.target.value },
                                  })
                                  locationData.timings = {...locationData?.timings,saturday : {...locationData?.timings?.saturday, time:{ ...saturday.time, end:e.target.value}}}
                               } }
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="select-time mt-3">
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-2">
                          <div className="slot slotitle">
                            <h4>Sunday</h4>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="slot">
                            <div className="form-check form-switch">
                              <label className="form-check-label" htmlFor="s7">
                                {sunday?.open ? <>Open</> : <>Close</> }
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="s7"
                                checked={sunday?.open}
                                onClick={() =>{
                                   setsunday({ ...sunday, open: !sunday?.open })
                                   locationData.timings = {...locationData?.timings,sunday : {...locationData?.timings?.sunday, open: !sunday?.open}}
                                 } }
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: sunday?.open ? "block" : "none" }}
                        >
                          <div className="slot dayselect d-flex justify-content-around">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full6"
                                id="full6"
                                checked={!sunday?.isSetHours}
                                onClick={() =>{
                                  setsunday({ ...sunday, time: "all day", isSetHours: false })
                                  locationData.timings = {...locationData?.timings,sunday : {...locationData?.timings?.sunday, time: "all day", isSetHours: false}}
                               } }
                              />
                              <label className="form-check-label" htmlFor="full6">
                                Full Day
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="full6"
                                id="full66"
                                checked={sunday?.isSetHours}
                                onClick={() => {
                                  setsunday({ ...sunday, isSetHours: true, time:{start: "06:00 am", end: "06:00 pm"} })
                                  locationData.timings = {...locationData?.timings,sunday : {...locationData?.timings?.sunday,  isSetHours: true, time:{start: "06:00 am", end: "06:00 pm"}}}
                              }  }
                              />
                              <label className="form-check-label" htmlFor="full66">
                                Set Hours
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{ display: sunday?.open && sunday.isSetHours ? "block" : "none" }}
                        >
                          <div className="slot from-toselect d-flex justify-content-around">
                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={sunday?.time?.start}
                                onChange={(e) =>{
                                  setsunday({
                                    ...sunday, 
                                    time:{ ...sunday.time, start:e.target.value}
                                  })
                                  locationData.timings = {...locationData?.timings,sunday : {...locationData?.timings?.sunday,   time:{ ...sunday.time, start:e.target.value}}}
                                }}
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>

                            <div className="form-group w-100">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                defaultValue="06:00 pm"
                                value={sunday?.time?.end}
                                onChange={(e) =>{
                                  setsunday({
                                    ...sunday,
                                    time: { ...sunday.time, end: e.target.value },
                                  })
                                  locationData.timings = {...locationData?.timings,sunday : {...locationData?.timings?.sunday,   time:{ ...sunday.time, end:e.target.value}}}
                               } }
                              >
                                {timeOptions.map((item) => (
                                  <option value={item.value}>{item.label}</option>
                                ))}
                              </select>
                              {/* <i className="bi bi-caret-down-fill arrow"></i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <div className="button-bar">
                    <a className="btn btn-gray" onClick={handleBack}>Back</a>
                    <a className="btn btn-theme" onClick={handleSubmit}>
                      Save & Continue
                    </a>
                  </div>
                </div> */}
              </div>
            </form>
          </div>
          {/* <div className="col-xl-4">
            <div className="detail-desc timings"></div>
          </div> */}
        </div>
      </div>
      {showConfirmationModel && (
        <></>
        // <ConfirmationModel
        //   show={showConfirmationModel}
        //   handleClose={() => setShowConfirmationModel(false)}
        //   handleOk={() => setShowConfirmationModel(false)}
        //   title={confirmationTitle}
        //   body={confirmationBody}
        // />
      )}
    </>
  );
};

export default Timings;
