import React, { useState, useEffect, FC } from "react";
//import { useLocationStore } from "../../store/location";

//import GoogleMap from "./GoogleMap";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId ,getLatLng} from 'react-google-places-autocomplete';
//import { ConfirmationModel } from '../Modal/ConfirmationModel';
const googleApiKey = process.env.REACT_APP_G_KEY
const getLocationLink = 'https://maps.googleapis.com/maps/api/geocode/json?latlng'

type Props = {
  locationData?: any
}

const Location: FC<Props> = ({locationData}) => {

  //const { location: locationData } = useLocationStore();
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [searchGoogle, setSearchGoogle] = useState(true)

  const [formData, setFormData] = useState({
    country: "",
    state: "",
    city: "",
    pincode: "",
    address: "",
    area: "",
    landmark: "",
    location_detail: "",
    googlePlaceId: "",
    longitude: 0,
    latitude: 0,
    mapLink:"",
    googlePlaces:false,
    liveLocation:false,
  });
  const [ formErrors, setFormErrors] = useState({});
  const [ formErrorMessages, setFormErrorMessages] = useState({
    address: "Location Address is required",
  });
  const [showmap, setShowmap] = useState(false);
  const [useLiveLocation, setUseLiveLocation] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!searchGoogle || !useLiveLocation) {  //adding changes in to address filed.
      const { location_detail, landmark, area, city, state, country, pincode } = formData;
      let updatedAddress = "";
      let updatedValue = value;
      if (name === "location_detail") {
        updatedAddress = [value, landmark, area, city, state, country, pincode].filter(Boolean).join(", ");
      } else if (name === "landmark") {
        updatedAddress = [location_detail, value, area, city, state, country, pincode].filter(Boolean).join(", ");
      } else if (name === "area") {
        updatedAddress = [location_detail, landmark, value, city, state, country, pincode].filter(Boolean).join(", ");
      } else if (name === "city") {
        updatedAddress = [location_detail, landmark, area, value, state, country, pincode].filter(Boolean).join(", ");
      } else if (name === "state") {
        updatedAddress = [location_detail, landmark, area, city, value, country, pincode].filter(Boolean).join(", ");
      } else if (name === "country") {
        updatedAddress = [location_detail, landmark, area, city, state, value, pincode].filter(Boolean).join(", ");
      } else if (name === "pincode") {
        updatedAddress = [location_detail, landmark, area, city, state, country, value].filter(Boolean).join(", ");
        updatedValue = value.replace(/\D/g, ""); // Remove non-digit characters from the pincode
      }
      setFormData({
        ...formData,
        [name]: updatedValue,
        address: updatedAddress,
      });
      locationData.property_address =   {...formData,[name] : updatedValue,addess : updatedAddress}
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      locationData.property_address =   {...formData,[name] : value}
    }
  };
  
  const showLocation = () => {
    setShowmap(false);
    setTimeout(() => {
      setShowmap(true);
    }, 500);
  };

  const handleBack = () => {
    //setTab("property_desc");
  };

  const handleSubmit = () => {
    const payload = formData;
    //console.log(payload)
    if (
      formData?.location_detail?.trim().length == 0 ||
      formData?.country?.trim().length == 0 ||
      formData?.state?.trim().length == 0 ||
      formData?.city?.trim().length == 0 ||
      formData?.pincode?.trim().length == 0 ||
      formData?.area?.trim().length == 0
    ){
      setFormErrors({
        ...formErrors,
        address: formErrorMessages.address,
      });
      setShowConfirmationModel(true);
      return;
    }
    if (localStorage.getItem("locationId")) {
      //console.log(payload)
      //callbackFn({ property_address: payload });
      //setTab("amenities");
    }
  };


  useEffect(() => {
    if (locationData && locationData?.property_address) {
      const { property_address } = locationData;
      //console.log(property_address)
      const tdata = Object.keys(formData).reduce((a, c) => {
        a[c] = property_address[c] || "";
        return a;
      }, {});
      //console.log(tdata)
      setFormData({
        ...formData,
        ...tdata,
      });
      showLocation();
      setSearchGoogle(locationData?.property_address?.googlePlaces)
      setUseLiveLocation(locationData?.property_address?.liveLocation)
    }
  }, [locationData]);

  const handleLocationSelect = async (value) => {
    //console.log(value)

    const [place] = await geocodeByPlaceId(value.place_id);
    //console.log("place :: ",place);

    setFormErrors({
      ...formErrors,
      address: ""
    });
    const { long_name: postalCode = '' } =
      place.address_components.find(c => c.types.includes('postal_code')) || {};

    const { long_name: country = '' } =
      place.address_components.find(c => c.types.includes('country')) || {};

    const { long_name: state = '' } =
      place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};

    const { long_name: city = '' } =
      place.address_components.find(c => c.types.includes('locality')) || {};

    const { long_name: sublocality3 = '' } =
      place.address_components.find(c => c.types.includes('sublocality_level_3')) || {};

    const { long_name: sublocality2 = '' } =
      place.address_components.find(c => c.types.includes('sublocality_level_2')) || {};

    const { long_name: sublocality1 = '' } =
      place.address_components.find(c => c.types.includes('sublocality_level_1')) || {};

    const { long_name: landmark = '' } =
      place.address_components.find(c => c.types.includes('neighborhood')) || {};

    let area = '';
    if (sublocality3 && sublocality3 !== '') {
      area = sublocality3;
    }
    if (sublocality2 && sublocality2 !== '') {
      area = area !== '' ? area + ', ' + sublocality2 : sublocality2;
    }
    if (sublocality1 && sublocality1 !== '') {
      area = area !== '' ? area + ', ' + sublocality1 : sublocality1;
    }
    if (!area) {
      area = city;
    }
    // const formattedAddress = place.formatted_address;

    const gPlaceId = place?.place_id;

    let lat = 0;
    let lng = 0;

    // await geocodeByPlaceId(gPlaceId)
    //   //.then(results => console.log("geocodeByPlaceId ",results))
    //   .then(results => getLatLng(results[0]))
    //   .then(latLng => {
    //     //console.log('geocodeByPlaceId Success latLng : ', latLng);
    //     lat = latLng.lat
    //     lng = latLng.lng
    //   })
    //   .catch(error => console.error(error));  

    
    //console.log(lat,lng)
    setFormData({
      ...formData,
      longitude: lng,
      latitude: lat,
      pincode: postalCode,
      country: country,
      state: state,
      city: city,
      area: area,
      landmark: landmark,
      address: value.description,
      location_detail: value.description,
      googlePlaceId: gPlaceId,
      mapLink:`https://www.google.com/maps/place/?q=place_id:${value.place_id}`,
      googlePlaces:true,
      liveLocation:false,
    });
    locationData.property_address = {
      ...formData,
      longitude: lng,
      latitude: lat,
      pincode: postalCode,
      country: country,
      state: state,
      city: city,
      area: area,
      landmark: landmark,
      address: value.description,
      location_detail: value.description,
      googlePlaceId: gPlaceId,
      mapLink:`https://www.google.com/maps/place/?q=place_id:${value.place_id}`,
      googlePlaces:true,
      liveLocation:false,
    }
    setUseLiveLocation(false);
    showLocation();
  }

  const changeLocationSearch = (event) => {
    const checked = event.target.checked;
    setSearchGoogle(checked);
    if(checked){
      setUseLiveLocation(false)
    }
    setFormData({
      country: "",
      state: "",
      city: "",
      pincode: "",
      area: "",
      landmark: "",
      address: "",
      location_detail: "",
      googlePlaceId: "",
      longitude: 0,
      latitude: 0,
      mapLink:"",
      googlePlaces:false,
      liveLocation:false,
    });
  };
  
  

  const handleLiveLocationChange = async (event) => {
    const { checked } = event.target;
    setUseLiveLocation(checked);
    setSearchGoogle(false);
    if (event.target.checked) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const response = await fetch(
                `${getLocationLink}=${latitude},${longitude}&key=${googleApiKey}`
              );
              const data = await response.json();
              if (data.results.length > 0) {
                const { address_components, formatted_address, place_id } = data.results[0];
        
                const postalCodeComponent = address_components.find(c => c.types.includes('postal_code'));
                const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';
        
                const countryComponent = address_components.find(c => c.types.includes('country'));
                const country = countryComponent ? countryComponent.long_name : '';
        
                const stateComponent = address_components.find(c => c.types.includes('administrative_area_level_1'));
                const state = stateComponent ? stateComponent.long_name : '';
        
                const cityComponent = address_components.find(c => c.types.includes('locality'));
                const city = cityComponent ? cityComponent.long_name : '';
        
                const sublocality3Component = address_components.find(c => c.types.includes('sublocality_level_3'));
                const sublocality3 = sublocality3Component ? sublocality3Component.long_name : '';
        
                const sublocality2Component = address_components.find(c => c.types.includes('sublocality_level_2'));
                const sublocality2 = sublocality2Component ? sublocality2Component.long_name : '';
        
                const sublocality1Component = address_components.find(c => c.types.includes('sublocality_level_1'));
                const sublocality1 = sublocality1Component ? sublocality1Component.long_name : '';
        
                const politicalComponent = address_components.find(c => c.types.includes('political'));
                const political = politicalComponent ? politicalComponent.long_name : '';
        
                let area = '';
                if (sublocality3 && sublocality3 !== '') {
                  area = sublocality3;
                }
                if (sublocality2 && sublocality2 !== '') {
                  area = area !== '' ? area + ', ' + sublocality2 : sublocality2;
                }
                if (sublocality1 && sublocality1 !== '') {
                  area = area !== '' ? area + ', ' + sublocality1 : sublocality1;
                }
                if (!area) {
                  area = city;
                }
                setFormData({
                  ...formData,
                  longitude: longitude,
                  latitude: latitude,
                  pincode: postalCode,
                  country: country,
                  state: state,
                  city: city,
                  area: area,
                  landmark: "",
                  address: formatted_address,
                  location_detail: formatted_address,
                  googlePlaceId: place_id,
                  mapLink:`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
                  googlePlaces:false,
                  liveLocation:true,
                });
                locationData.property_address = {
                  ...formData,
                  longitude: longitude,
                  latitude: latitude,
                  pincode: postalCode,
                  country: country,
                  state: state,
                  city: city,
                  area: area,
                  landmark: "",
                  address: formatted_address,
                  location_detail: formatted_address,
                  googlePlaceId: place_id,
                  mapLink:`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
                  googlePlaces:false,
                  liveLocation:true,
                }
        
                showLocation();
              }
            } catch (error) {
              console.error("Error fetching current location:", error);
            }
          },
          (error) => {
            console.error("Error getting current location:", error);
          }
        );
      }
    } else {
      setFormData({
        ...formData,
        country: "",
        state: "",
        city: "",
        pincode: "",
        area: "",
        landmark: "",
        address: "",
        location_detail: "",
        googlePlaceId: "",
        mapLink:"",
        googlePlaces:false,
        liveLocation:false,
      });
    }
  };

  const setFormDataFromLocation = async(latitude, longitude, googlePlaces, liveLocation) => {
    try {
      setUseLiveLocation(true);
      setSearchGoogle(false);
      const response = await fetch(
        `${getLocationLink}=${latitude},${longitude}&key=${googleApiKey}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const { address_components, formatted_address, place_id } = data.results[0];

        const postalCodeComponent = address_components.find(c => c.types.includes('postal_code'));
        const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';

        const countryComponent = address_components.find(c => c.types.includes('country'));
        const country = countryComponent ? countryComponent.long_name : '';

        const stateComponent = address_components.find(c => c.types.includes('administrative_area_level_1'));
        const state = stateComponent ? stateComponent.long_name : '';

        const cityComponent = address_components.find(c => c.types.includes('locality'));
        const city = cityComponent ? cityComponent.long_name : '';

        const sublocality3Component = address_components.find(c => c.types.includes('sublocality_level_3'));
        const sublocality3 = sublocality3Component ? sublocality3Component.long_name : '';

        const sublocality2Component = address_components.find(c => c.types.includes('sublocality_level_2'));
        const sublocality2 = sublocality2Component ? sublocality2Component.long_name : '';

        const sublocality1Component = address_components.find(c => c.types.includes('sublocality_level_1'));
        const sublocality1 = sublocality1Component ? sublocality1Component.long_name : '';

        const politicalComponent = address_components.find(c => c.types.includes('political'));
        const political = politicalComponent ? politicalComponent.long_name : '';

        let area = '';
        if (sublocality3 && sublocality3 !== '') {
          area = sublocality3;
        }
        if (sublocality2 && sublocality2 !== '') {
          area = area !== '' ? area + ', ' + sublocality2 : sublocality2;
        }
        if (sublocality1 && sublocality1 !== '') {
          area = area !== '' ? area + ', ' + sublocality1 : sublocality1;
        }
        if (!area) {
          area = city;
        }
        setFormData({
          ...formData,
          longitude: longitude,
          latitude: latitude,
          pincode: postalCode,
          country: country,
          state: state,
          city: city,
          area: area,
          landmark: "",
          address: formatted_address,
          location_detail: formatted_address,
          googlePlaceId: place_id,
          mapLink:`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
          googlePlaces: googlePlaces,
          liveLocation: liveLocation,
        });

        showLocation();
      }
    } catch (error) {
      console.error("Error fetching current location:", error);
    }
  }

  const updateSelectLocation = (selectedGooglePlaceId,latitude, longitude) => {
    console.log('Select location from maps.', selectedGooglePlaceId);
    setFormDataFromLocation(latitude, longitude, true, false);
  };

  return (
    <>
      {/*LOCATION START */}
      <div
        className="tab-pane h-100 w-100 fade show active"
        id="v-pills-location"
        role="tabpanel"
        aria-labelledby="v-pills-location-tab"
      >
        <div className="row g-0 h-100 w-100">
          <div className="col-xl-12">
            <form className="h-100 w-100">
              <div className="step-form h-100 w-100">
                <div>
                  <h3 className="ftitle">Location</h3>
                  <div className="scroolbox">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-check-label" htmlFor="location">
                            Google places
                          </label>
                          <span>&nbsp;</span>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="location"
                            checked={searchGoogle}
                            onChange={(event) => {changeLocationSearch(event);}}
                          />
                        </div>
                      </div>
                      <div className=" mt-1 col-lg-6">
                        <div className="mb-4 form-check form-switch">
                          <label className="form-check-label" htmlFor="liveLocation">
                            Live location
                          </label>
                          <span>&nbsp;</span>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="liveLocation"
                            checked={useLiveLocation}
                            onChange={handleLiveLocationChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label htmlFor="tags2" className="form-label ">
                            Location Address<span>*</span>
                          </label>
                          <div className="form-group">
                            <div className="ui-widget">
                              {searchGoogle ? (
                                <GooglePlacesAutocomplete apiKey={googleApiKey}
                                  selectProps={{
                                    //searchValue,
                                    onChange: (text) => { handleLocationSelect(text?.value) }
                                  }}
                                />
                              ) :
                                <input
                                  type="text"
                                  className="form-control"
                                  id="location_detail"
                                  name="location_detail"
                                  value={formData?.location_detail}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  disabled = {useLiveLocation}
                                />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label htmlFor="exampleInputEmail1" className="form-label ">
                            Country<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="countyname"
                            name="country"
                            value={formData?.country}
                            onChange={handleChange}
                            autoComplete="off"
                            disabled={searchGoogle || useLiveLocation}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label htmlFor="exampleInputEmail1" className="form-label ">
                            State<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="statename"
                            name="state"
                            value={formData?.state}
                            onChange={handleChange}
                            autoComplete="off"
                            disabled={searchGoogle || useLiveLocation}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label htmlFor="exampleInputEmail1" className="form-label ">
                            City<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="cityname"
                            name="city"
                            value={formData?.city}
                            onChange={handleChange}
                            autoComplete="off"
                            // disabled={searchGoogle || useLiveLocation}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label htmlFor="exampleInputEmail1" className="form-label ">
                            Pincode<span>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="pincode"
                            name="pincode"
                            value={formData?.pincode}
                            onChange={handleChange}
                            autoComplete="off"
                            disabled={searchGoogle || useLiveLocation}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label htmlFor="exampleInputEmail1" className="form-label ">
                            Area<span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="areaname"
                            name="area"
                            value={formData?.area}
                            onChange={handleChange}
                            autoComplete="off"
                            // disabled={searchGoogle || useLiveLocation}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label htmlFor="exampleInputEmail1" className="form-label ">
                            Landmark
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="landmarkname"
                            name="landmark"
                            value={formData?.landmark}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label "
                          >
                            Address
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            //rows="5"
                            name="address"
                            value={formData?.address}
                            onChange={handleChange}
                            autoComplete="off"
                            disabled={searchGoogle || useLiveLocation}
                          ></textarea>
                        </div>
                      </div>
                      {formData?.mapLink && (
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label "
                          >
                            Map link
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            //rows="1"
                            name="mapLink"
                            value={formData?.mapLink}
                            onChange={handleChange}
                            autoComplete="off"
                            disabled={searchGoogle || useLiveLocation}
                          ></textarea>
                        </div>
                      </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div>
                  <div className="button-bar">
                    <a className="btn btn-gray" onClick={handleBack}>Back</a>
                    <a className="btn btn-theme" onClick={handleSubmit}>
                      Save & Continue
                    </a>
                  </div>
                </div> */}
              </div>
            </form>
          </div>
          {/* <div className="col-xl-4">
            <div className="detail-desc" style={{ height: "100%" }}>
              <div style={{ width: "350px", height: "500px" }}>
                {showmap && (searchGoogle || useLiveLocation) && (
                  <div>
                    <GoogleMap
                      address={currentLocation ? currentLocation.address : formData?.address}
                      place_id={formData?.googlePlaceId}
                      updateSelectLocation={updateSelectLocation}
                    />
                  </div>
                )}

              </div>
            </div>
          </div> */}
        </div>
      </div>
      {showConfirmationModel && (
        <></>
        // <ConfirmationModel
        //   show={showConfirmationModel}
        //   handleClose={() => setShowConfirmationModel(false)}
        //   handleOk={() => setShowConfirmationModel(false)}
        //   title={"Location"}
        //   body={"Please enter all fields !"}
        // />
      )}
      {/*LOCATION END */}
    </>
  );
};

export default Location;