import {useEffect, useState} from 'react'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { KTIcon } from '../../../../_metronic/helpers'

const PaymentFilter = ({filterFormData}) => {

  const [start, setStart] = useState<any>(null)
  const [end, setEnd] = useState<any>(null)
  const [count, setCount] = useState<any>(null)
  const [skip, setSkip] = useState<any>(0)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    const el1 : any = document.getElementById('paymentDetailsDateStart')
    if(el1) el1.value = null
    const el2 : any = document.getElementById('paymentDetailsDateEnd')
    if(el2) el2.value = null
    const el3 : any = document.getElementById('paymentDetailsCount')
    if(el3) el3.value = null
    setStart(null);
    setEnd(null);
    setCount(null);
    setSkip(0);
    filterFormData(null,null, null, 0);
    }

  const filterData = (e) => {
    e.preventDefault()
    filterFormData({start,end, count, skip});
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        //disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5'  id='paymentFilterForm'>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Date from</label>
            <input className='form-control form-control-solid' id='paymentDetailsDateStart'  type='date' max={new Date().toISOString().split("T")[0]} onChange={(e) => {
              setStart(e.target.value)
              setEnd(null)
              const el : any = document.getElementById('paymentDetailsDateEnd')
              if(el) el.value = null
              }} value={start}/>
          </div>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Date to</label>
            <input className='form-control form-control-solid' id='paymentDetailsDateEnd'  type='date' min={new Date(new Date(start).setDate(new Date(start).getDate() + 1)).toISOString().split("T")[0]} max={new Date().toISOString().split("T")[0]} onChange={(e) => setEnd(e.target.value)} value={end}/>
          </div>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Count</label>
            <input className='form-control form-control-solid' id='paymentDetailsCount'  type='number'  onChange={(e) => setCount(e.target.value)} value={count}/>
          </div>
          {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Skip</label>
            <input className='form-control form-control-solid'  type='number'  onChange={(e) => setSkip(e.target.value)} value={skip}/>
          </div> */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              //disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              //disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {PaymentFilter}
