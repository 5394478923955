import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { adminNotifications,updateNotification } from "../../services/api";
import Moment from "moment";
import { PageLoader } from "../../services/PageLoader";
import {Modal} from 'react-bootstrap'
import { useAuth } from "../../../auth";

const NotificationsList = () => {
	const {getSideBarCount} = useAuth()
	const [data, setData] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [show,setShow] = useState(false);
	const [modalData,setModalData] = useState({});

	const userColumns = [
		{
			field: "type", headerName: "type", width: 230,
			renderHeader: () => (
				<th className='min-w-150px'>Type</th>
			),
			renderCell: (record) => (
				<div className={`text-dark text-hover-primary fs-6 ${record?.row?.status === 'Read'  ? '' : 'fw-bold'}`}>
					{record?.row?.type}
				</div>
			),
		},
		{
			field: "notificationHeader",
			headerName: "notificationHeader",
			width: 230,
			renderHeader: () => (
				<th className='min-w-180px'>Notification Header</th>
			),
			renderCell: (record) => {
				return (
					<div className={`text-dark text-hover-primary fs-6 ${record?.row?.status === 'Read'  ? '' : 'fw-bold'}`}					
					>
						{record?.row?.notificationHeader}
					</div>
				);
			},
		},
		{
			field: "createdDate",
			headerName: "createdDate",
			width: 200,
			renderHeader: () => (
				<th className='min-w-150px'>Notification Date</th>
			),
			renderCell: (record) => (
				<div className={`text-dark text-hover-primary fs-6 ${record?.row?.status === 'Read'  ? '' : 'fw-bold'}`}>
					{Moment(record?.row?.createdDate).format('DD-MM-YYYY h:mm:ss a')}
				</div>
			),
		},
		{
			field: "status",
			headerName: "status",
			width: 100,
			renderHeader: () => (
				<th className='min-w-150px'>Status</th>
			),
			renderCell: (record) => (
				<div className={`text-dark text-hover-primary fs-6 ${record?.row?.status === 'Read' ? '' : 'fw-bold'}`}>
					{record?.row?.status}
				</div>
			),
		},
		{
			field: "viewedBy",
			headerName: "viewedBy",
			width: 200,
			renderHeader: () => (
				<th className='min-w-150px'>Viewed By</th>
			),
			renderCell: (record) => (
				<div className={`text-dark text-hover-primary fs-6 ${record?.row?.status === 'Read' ? '' : 'fw-bold'}`}>
					{record?.row?.viewedBy}
				</div>
			),
		},
	];

	const getData = async () => {
		try{
			const resp = await adminNotifications();
			setOriginalData(resp?.data);
			const temp = resp?.data?.map((item)=> {
				return {
					id : item?._id,
					type : item?.type || 'NA',
					notificationHeader : item?.notificationHeader || "NA" ,
					notificationContent : item?.notificationContent || 'NA',
					createdDate : item?.createdAt,
					status : item?.read ? 'Read' : 'Unread',
					viewedBy : item?.viewedBy || "NA",
				}
			})
			setData(temp);
			setShowLoader(false)
		}catch(error){
			console.log(error)
			setShowLoader(false)
		}
	}
	const hanldeViewNotification = async(id) => {
		try{
			await updateNotification(id)
			getData()
			getSideBarCount()
		}catch(error){
			console.log(error)
		}
	}
	const handleView = (data) => {
		// const temp = originalData.filter((each) => each?._id === data?.id )
		// setModalData(temp[0]);
		setModalData(data);
		hanldeViewNotification(data?.id)
		setShow(true)
	}


	const actionColumn = [
		{
			field: "action",
			headerName: "Action",
			width: 200,
			renderHeader: () => (
				<th className='min-w-150px'>Action</th>
			  ),
			renderCell: (params) => {
				return (
					<div className="text-end">
						<div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'  title='View notification' onClick={()=>{
							handleView(params.row)
						}}>
							<KTIcon iconName='shield-search' className='fs-3'/>
						</div>
					</div>
				);
			},
		},
	];


	const modalBody = (each) => {
        return(
			<div className='card mb-5 mb-xl-10' >
				<div className='card-header cursor-pointer'>
					<div className='mb-3'>
						{/* begin::Text */}
						<div className='mt-3'>
						<span  className='fw-bold'>Type :</span> <span>{each?.type}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Notification Header :</span> <span>{each?.notificationHeader}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Notification Content :</span> <span dangerouslySetInnerHTML={{__html: each?.notificationContent}}></span>
						</div>
						<div className=' mt-3'>
						<span  className='fw-bold'>Notification Date :</span> <span>{Moment(each.createdDate).format('DD/MM/YYYY hh:mm a')}</span>
						</div>
						{/* end::Text */}
					</div>
				</div>
			</div>
        )
    }

	useEffect(() => {
		setShowLoader(true)
		getData()
	}, []);

	return (
		<div className="card">
			<div>
				{/* begin::Body */}
				<div className='card-body'>
					{/* begin::Table container */}
					<div className='table-responsive'>
						{/* begin::Table */}
						<div className="datatable">
							<DataGrid
								className="datagrid"
								rows={data}
								columns={userColumns.concat(actionColumn)}
								pageSize={50}
								rowsPerPageOptions={[9]}
								sx={{
									"& .MuiDataGrid-row:hover": {
										backgroundColor: "inherit"
									},
									"& .MuiDataGrid-cell": {
										borderBottom: "1px solid var(--bs-gray-100)"
									}

								}}
							/>
						</div>
						{/* end::Table */}
					</div>
					{/* end::Table container */}
				</div>
				{/* begin::Body */}
			</div>
			{showLoader && <PageLoader />}
			{show && (
				<Modal
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={show}
				onHide={() => setShow(false)}
				>
					<Modal.Header closeButton>
					<Modal.Title>Notification Details</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="d-flex flex-column">
							{modalBody(modalData)}
						</div>
					</Modal.Body>
				</Modal>
			)}
		</div>
	);
};

export default NotificationsList;
