import {useEffect, useState} from "react";
import { resetPassword } from "../../apps/services/api";
import { useNavigate } from "react-router-dom";

export const ResetPassword = () => {

    const navigate = useNavigate()
    const [password, setPassword] = useState<any>("")
    const [showPassword, setShowPassword] = useState<any>(false)
    const [id, setId] = useState<any>("")

    const getQueryParams = () => {
        let queryString : any
        queryString = window.location.search.substring(1).split("=");
        setId(queryString[queryString.length-1])
    }

    const handleResetPassword = async() => {
        try{
            let res :any
            res = await resetPassword(id, password)
            setPassword("");
            navigate("/auth", { replace: true })
            alert(res?.data?.message)
        } catch(error :any) {
            alert(error?.response?.data?.message)
        }
    }
    useEffect(() => {
        getQueryParams()
    },[])

    const handleSubmit = (e:any) => {
        e.preventDefault();
        handleResetPassword();
    }

    return (
        <>
            <form className="d-flex flex-column align-items-center" onSubmit = {handleSubmit} >
                <label className="text-dark fw-bolder mb-3" style={{fontSize:"24px"}}> New Password </label>
                <div className="d-flex w-100">
                    <input 
                        type={showPassword? "input" : "password"}
                        className="form-control bg-transparent"
                        onChange={(e:any)=>setPassword(e.target.value)}
                        pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$"
                        title="Password must contain at least 8 characters including a capital letter, a small letter, a special character, and a number."
                    />
                    <span className="cursor-pointer" style={{marginLeft:"-50px", marginTop:"13px "}} onClick={() => setShowPassword(!showPassword) } > {showPassword ? "Hide" : "Show"} </span>
                </div>
                <button type = "submit" className="btn btn-primary mt-3" > Submit </button>
            </form>
        </>
    )
}