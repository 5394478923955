import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
import { getDonts, getDos, getFeatures, getRules, createLookUp } from '../../../services/api';
import Multiselect from 'multiselect-react-dropdown';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

type Props = {
  locationData?: any
}

const DosDontsRules: FC<Props> = ({locationData}) => {
  console.log(locationData)

  const [dosData, setDosData] = useState<any>([]);
	const [dontsData, setDontsData] = useState<any>([]);
  const [rulesData, setRulesData] = useState<any>([]);

  const [do_,setDo] = useState<any>([]);
  const [dont_,setDont] = useState<any>([]);
  const [rule_,setRule] = useState<any>([]);

  const [openDo, setOpenDo] = useState(false);
  const [newDo, setNewDo] = useState("");

  const [openDont, setOpenDont] = useState(false);
  const [newDont, setNewDont] = useState("");

  const [openRule, setOpenRule] = useState(false);
  const [newRule, setNewRule] = useState("");

  const [showDialog,setShowDialog] = useState(false)
  const[dialogContent,setDialogContent] = useState('')

  useEffect(() => {
		const fetchDos = async () => {
			try {
				const response = await getDos();
        const options = response?.data?.map((item) => ({
          value: item.value,
          label: item.name,
        }));
				setDosData(options);
			} catch (error) {
				console.error("Error fetching dos:", error);
			}
		};

    const fetchDonts = async () => {
			try {
				const response = await getDonts();
        const options = response?.data?.map((item) => ({
          value: item.value,
          label: item.name,
        }));
				setDontsData(options);
			} catch (error) {
				console.error("Error fetching donts:", error);
			}
		};

    const fetchRules = async () => {
			try {
				const response = await getRules();
        const options = response?.data?.map((item) => ({
          value: item.value,
          label: item.name,
        }));
				setRulesData(options);
			} catch (error) {
				console.error("Error fetching dos:", error);
			}
		};

		fetchDonts();
		fetchDos();
    fetchRules();

    if( locationData?.do_and_dont?.do_s){
      const doo = locationData?.do_and_dont?.do_s.map(item =>  ({ value: item ,label : item}))
      setDo(doo);
    }

    if(locationData?.do_and_dont?.dont_s){
      const dont = locationData?.do_and_dont?.dont_s.map(item =>  ({ value: item ,label : item}))
      setDont(dont);
    }

    if( locationData?.rules){
      const rulee = locationData?.rules.map(item =>  ({ value: item ,label : item}))
      setRule(rulee);
    }
    
	}, [locationData]);

  const handleDosSelect = (selectedList, selectedItem) => {
    locationData.do_and_dont = {...locationData?.do_and_dont,do_s : selectedList.map((opt) => opt.value)}
    setDo(selectedList.map((opt) => ({ value: opt.value ,label : opt.value})))
  }
  const handleDontsSelect = (selectedList, selectedItem) => {
    locationData.do_and_dont = {...locationData?.do_and_dont,dont_s : selectedList.map((opt) => opt.value)}
    setDont(selectedList.map((opt) => ({ value: opt.value ,label : opt.value})))
  }
  const handleRulesSelect = (selectedList, selectedItem) => {
    locationData.rules = selectedList.map((opt) => opt.value);
    setRule(selectedList.map((opt) => ({ value: opt.value ,label : opt.value})))
  }

  //new lookup/item add section

  const handleCreateNewDo = async () => {
    let isExisted = true
    dosData.map((obj) => {
      if (obj.value === newDo) {
        setOpenDo(false);
        isExisted  = false
        setDialogContent('Do already existed')
        setShowDialog(true)
        return null
      }
    });

    if (isExisted) {
      try {
        await createLookUp({
          name: newDo,
          value: newDo,
          type: "dos_type",
        });

        locationData.do_and_dont = {...locationData?.do_and_dont, do_s : [newDo,...locationData?.do_and_dont?.do_s]}
        const doo = locationData?.do_and_dont?.do_s.map(item =>  ({ value: item ,label : item}))
        setDo(doo);

        const response = await getDos();
        const options = response?.data?.map((item) => ({
          value: item.value,
          label: item.name,
        }));
				setDosData(options);
      } catch (error) {
        alert(error || "Error adding amenity");
      }
    }
    setNewDo("");
    setOpenDo(false);
  };

  const handleCreateNewDont = async () => {
    let isExisted = true
    dontsData.map((obj) => {
      if (obj.value === newDont) {
        setOpenDont(false);
        isExisted  = false
        setDialogContent("Don't already existed")
        setShowDialog(true)
        return null
      }
    });

    if (isExisted) {
      try {
        await createLookUp({
          name: newDont,
          value: newDont,
          type: "dontdos_type",
        });

        locationData.do_and_dont = {...locationData?.do_and_dont, dont_s : [newDont,...locationData?.do_and_dont?.dont_s]}
        const dont = locationData?.do_and_dont?.dont_s.map(item =>  ({ value: item ,label : item}))
        setDont(dont);

        const response = await getDonts();
        const options = response?.data?.map((item) => ({
          value: item.value,
          label: item.name,
        }));
				setDontsData(options);
      } catch (error) {
        alert(error || "Error adding amenity");
      }
    }
    setNewDont("");
    setOpenDont(false);
  };

  const handleCreateNewRule = async () => {
    let isExisted = true
    rulesData.map((obj) => {
      if (obj.value === newRule) {
        setOpenRule(false);
        isExisted  = false
        setDialogContent('Rule already existed')
        setShowDialog(true)
        return null
      }
    });

    if (isExisted) {
      try {
        await createLookUp({
          name: newRule,
          value: newRule,
          type: "rules_type",
        });

        locationData.rules = [newRule,...locationData?.rules]
        const rulee = locationData?.rules.map(item =>  ({ value: item ,label : item}))
        setRule(rulee);;

        const response = await getRules();
        const options = response?.data?.map((item) => ({
          value: item.value,
          label: item.name,
        }));
				setRulesData(options);
      } catch (error) {
        alert(error || "Error adding amenity");
      }
    }
    setNewRule("");
    setOpenRule(false);
  };

  //delete items section

  const removeDo = (item,i) => {

    locationData?.do_and_dont?.do_s.splice(i,1)

    const newDos = do_.filter((file) => {
      return file !== item
    })
    setDo(newDos)
  }

  const removeDont = (item,i) => {
    
    locationData?.do_and_dont?.dont_s.splice(i,1)

    const newDonts = dont_.filter((file) => {
      return file !== item
    })
    setDont(newDonts)
  }

  const removeRule = (item,i) => {
    
    locationData?.rules.splice(i,1)

    const newRules = rule_.filter((file) => {
      return file !== item
    })
    setRule(newRules)
  }


  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Do’s & Don’ts and Rules of the host</h2>
      </div>

      <div className='fv-row mb-10'>
        <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <label className="d-flex align-items-center form-label">
                <span className="required">Do's</span>
              </label>
            </div>
            <div >
              <button
                type="button"
                className="btn btn-lg btn-primary p-2 mb-1"
                onClick={() => setOpenDo(true)}
              >
                Add New Do
              </button>
            </div>
          </div>

        <Multiselect
            displayValue="value"
            showArrow={true}
            hideSelectedList
            options={dosData}
            showCheckbox
            onSelect={handleDosSelect}
            onRemove={handleDosSelect}
            selectedValues={locationData?.do_and_dont?.do_s?.map((item) => ({ value: item }))}
         />
         {do_ && do_.map((item, i) => (
              <div className="chips mt-1" key={i}>
                  <span key={i}>
                  {item.value}
                  </span>
                  <button
                      title="Remove"
                      aria-label="Remove"
                      key={i}
                      type="button"
                  onClick={() => removeDo(item,i)}
                    >
                      {/* <i className="material-icons"></i> */}
                      <CancelIcon/>
                    </button>
                </div>
              ))}

        <div className='text-danger mt-2'>
          <ErrorMessage name='businessDescriptor' />
        </div>
      </div>
      <Dialog
        open={openDo}
        onClose={() => setOpenDo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add New Do</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              id="filled-select-currency"
              name="contact_name"
              value={newDo}
              // onClick={handleClickname}
              fullWidth
              size="small"
              sx={{
                padding: "8px",
              }}
              onChange={(e) => setNewDo(e.target.value)}
              variant="outlined"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDo(false)} color="error">
            Close
          </Button>
          <Button onClick={handleCreateNewDo} autoFocus variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <div className='fv-row mb-10'>
      <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <label className="d-flex align-items-center form-label">
                <span className="required">Dont's</span>
              </label>
            </div>
            <div >
              <button
                type="button"
                className="btn btn-lg btn-primary p-2 mb-1"
                onClick={() =>  setOpenDont(true)}
              >
                Add New Don't
              </button>
            </div>
          </div>
        <Multiselect
            displayValue="value"
            showArrow={true}
            hideSelectedList
            options={dontsData}
            showCheckbox
            onSelect={handleDontsSelect}
            onRemove={handleDontsSelect}
            selectedValues={locationData?.do_and_dont?.dont_s?.map((item) => ({ value: item }))}
         />
         {dont_ && dont_.map((item, i) => (
              <div className="chips mt-1" key={i}>
                  <span key={i}>
                  {item.value}
                  </span>
                  <button
                      title="Remove"
                      aria-label="Remove"
                      key={i}
                      type="button"
                  onClick={() => removeDont(item,i)}
                    >
                      {/* <i className="material-icons"></i> */}
                      <CancelIcon/>
                    </button>
                </div>
              ))}

        <div className='text-danger mt-2'>
          <ErrorMessage name='businessDescriptor' />
        </div>
      </div>
      <Dialog
        open={openDont}
        onClose={() => setOpenDont(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add New Don't</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              id="filled-select-currency"
              name="contact_name"
              value={newDont}
              // onClick={handleClickname}
              fullWidth
              size="small"
              sx={{
                padding: "8px",
              }}
              onChange={(e) => setNewDont(e.target.value)}
              variant="outlined"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDont(false)} color="error">
            Close
          </Button>
          <Button onClick={handleCreateNewDont} autoFocus variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      
      <div className='fv-row mb-10'>
        <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <label className="d-flex align-items-center form-label">
                <span className="required">Rules of the Host</span>
              </label>
            </div>
            <div >
              <button
                type="button"
                className="btn btn-lg btn-primary p-2 mb-1"
                onClick={() =>  setOpenRule(true)}
              >
                Add New Rule
              </button>
            </div>
          </div>

         <Multiselect
            displayValue="value"
            showArrow={true}
            hideSelectedList
            options={rulesData}
            showCheckbox
            onSelect={handleRulesSelect}
            onRemove={handleRulesSelect}
            selectedValues={locationData?.rules?.map((item) => ({ value: item }))}
         />
         {rule_ && rule_.map((item, i) => (
              <div className="chips mt-1" key={i}>
                  <span key={i}>
                  {item.value}
                  </span>
                  <button
                      title="Remove"
                      aria-label="Remove"
                      key={i}
                      type="button"
                  onClick={() => removeRule(item,i)}
                    >
                      {/* <i className="material-icons"></i> */}
                      <CancelIcon/>
                    </button>
                </div>
              ))}

        {/* <Field
          as='select'
          name='details.locationType'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          {rulesData && 
            rulesData.map(item => (
                                <option value={item.value}>{item.label}</option>
                            ))}
        </Field> */}
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessDescriptor' />
        </div>
      </div>
      <Dialog
        open={openRule}
        onClose={() => setOpenRule(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add New Rule</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              id="filled-select-currency"
              name="contact_name"
              value={newRule}
              // onClick={handleClickname}
              fullWidth
              size="small"
              sx={{
                padding: "8px",
              }}
              onChange={(e) => setNewRule(e.target.value)}
              variant="outlined"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRule(false)} color="error">
            Close
          </Button>
          <Button onClick={handleCreateNewRule} autoFocus variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogContent}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export {DosDontsRules}
