import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import Select from "react-select";
import {createLookUp} from "../../services/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function AddNewRecordModal({
  open,
  handleSuccess,
  handleClose,
}) {

  const [details,setDetails] = useState({
    type : '',
    value : ''
  })

  const handleSubmit = async () => {
    const { type, value } = details;
    if (value.trim() === "") {
      return alert("please new record");
    }
    if(!type){
      return alert('please fill location type')
    }
    try{
      const response = await createLookUp(details);
      handleSuccess()
      alert('New record added successfully')
    }catch(error){
      console.log(error)
    }
  };

  const handleChangeType = (selectedOption) => {
		setDetails({
			...details,
			type : selectedOption.value
		})
	}

  const options = [
    // {label : 'List type',value : 'list_type'},
    {label : 'Location type',value : 'location_type'},
    {label : 'Amenities type',value : 'amenities_type'},
    {label : 'Features type',value : 'feature_type'},
    {label : 'Dos type',value : 'dos_type'},
    {label : 'Donts type',value : 'dontdos_type'},
    {label : 'Rules type',value : 'rules_type'},
  ]
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
        Add Record
        </Typography>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "40ch" },
          }}
          noValidate
          autoComplete="off"
        >
          
          <TextField
            label="New Record"
            id="outlined-size-normal"
            fullWidth
            value={details.value}
            onChange={(e) => setDetails({...details,value : e.target.value})}
            name="value"
          />
          <Select
							id="lookupType"
							defaultValue={details.type}
							value = {details.type}
							placeholder = {details.type}
							fullWidth
							size="small"
							variant="outlined"
							styles={{ control: (provided) => ({ ...provided, padding: "8px"}) }}
							options={options}
							onChange={handleChangeType}
						/>
          <Stack direction="row" spacing={2} justifyContent="flex-end" pt={2}>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="success" onClick={handleSubmit}>
              Submit
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
