import axios from "axios";

const auth_domain = process.env.REACT_APP_AUTH_DOMAIN;
const spotlet_services = process.env.REACT_APP_SPOTLET_SERVICES;

//signin
export const SignIn = (data) => {
  let regionId = localStorage.getItem("regionId");
  return axios.post(auth_domain + "/signin", {...data, region : regionId});
};
//sendOtp
export const sendOtp = (data) => {
  let regionId = localStorage.getItem("regionId");
  return axios.post(auth_domain + "/sendOtp", {...data, region : regionId});
};
//ValidateQrCode
export const ValidateQrCode = (data) => {
  let regionId = localStorage.getItem("regionId");
  return axios.post(auth_domain + "/validateQrCode", {...data, region : regionId});
};
//get Employee details
export const getAdminDetails = (id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/admin/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get no of users
export const noOfUsers = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/noofusers/",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//get user data by id
export const getUserData = (jwt_id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/user/" + jwt_id,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

//**********LOCATIONS**********/

//get all incomplete locations`
export const getAllIncompleteLocations = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/incomplocations/?page=${data.page}&items_per_page=${data.items}`;
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.locationType) {
    url = url + `&locationType=${data.locationType}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get location by status`
export const getLocationByStatus = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/locationbystatus/?page=${data.page}&items_per_page=${data.items}`;
  if (data.status) {
    url = url + `&status=${data.status}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.subscriptionPlan) {
    url = url + `&subscriptionPlan=${data.subscriptionPlan}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get count ofl incomplete locations`
export const getCountIncompleteLocations = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + "/noofTemplocations";
  if (data.locationType) {
    url = url + `?locationType=${data.locationType}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get count off locations by status`
export const getCounLocationsByStatus = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/nooflocationsbystatus/?count=50`;
  if (data.status) {
    url = url + `&status=${data.status}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.subscriptionPlan) {
    url = url + `&subscriptionPlan=${data.subscriptionPlan}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get temp location
export const getTempLocation = (location_id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/templocation/" + location_id,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//update location
export const updateIncompleteLocation = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/templocation/", data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//get approved location
export const getLocationById = (location_id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/locations/" + location_id,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const getLocationTypes = async () => {
  const auth = localStorage.getItem("admin");
  const response = await axios.get(auth_domain + "/location_type_lookup", {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};
//update location
export const updateLocation = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/updatelocation/", data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//upload location pics
export const uploadLocationPics = (formData) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/uploadlocpic", formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
//upload gst docs
export const uploadGstDocs = (formData) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/uploadgst", formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
//delete files from storage
export const deleteFiles = (form) => {
  const auth = localStorage.getItem("admin");
  return axios.delete(auth_domain + `/deletefile`, {
    headers: {
      Authorization: `Bearer ${auth}`
    },
    data: form
  });
}
//Update location status
export const updateLocationStatus = (locationId, status) => {
  const auth = localStorage.getItem("admin");
  axios.put(auth_domain + `/locations/${locationId}/status/${status}`, {},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

//**********LOOKUPS**********/
export const getListTypes = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/list_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getLocations = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/location_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getAmenities = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/amenities_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getDonts = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/dontdos_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getDos = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/dos_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getFeatures = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/feature_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const getRules = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + "/rules_type_lookup",
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const viewLookup = (details) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + `/view_lookup`, { ...details }, {
    headers: {
      Authorization: `Bearer ${auth}`
    }
  });
}
export const deleteLookUp = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.delete(auth_domain + `/delete_lookup`, {
    headers: {
      Authorization: `Bearer ${auth}`
    },
    data: data
  });
}
export const createLookUp = (details) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/create_lookup", { ...details },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}
export const updateLookup = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/update_lookup", { ...data },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}

//**********BOOKINGS**********/
export const getBookings = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/booking/?page=${data.page}&items_per_page=${data.items}`;
  if (data.status) {
    url = url + `&status=${data.status}`;
  }
  if (data.paymentStatus) {
    url = url + `&paymentStatus=${data.paymentStatus}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const noOfBookings = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/noofbookings/?`;
  if (data.status) {
    url = url + `status=${data.status}`;
  }
  if (data.paymentStatus) {
    url = url + `&paymentStatus=${data.paymentStatus}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const locationBookings = (id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(auth_domain + `/locationbookings/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********CAREER/JOBS**********/
export const getJobs = (page, offSet) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/jobs/?page=${page}&items_per_page=${offSet}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getJobRequests = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/requestedjobs?page=${data.page}&noOfItems=${data.noOfItems}&searchTerm=${data?.searchTerm}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteJob = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/jobs/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const createJob = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + `/jobs`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const getJobDetails = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/jobDetails/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateJob = (data, id) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/jobs`, { data, id },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};


//**********COUPONS**********/
export const createCoupon = (data) => {
  const auth = localStorage.getItem("admin");
  const regionId = localStorage.getItem("regionId");
  return axios.post(spotlet_services + `/coupon/?region=${regionId}`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const getCoupons = (data) => {
  const auth = localStorage.getItem("admin");
  const regionId = localStorage.getItem("regionId");
  var url = spotlet_services + `/coupons` ///?page=${data?.page}&items_per_page=${data?.offSet}&status=${data?.status}&plan=${data?.plan}&userId=${data?.userId}&subscription=${data?.subscription}&region=${regionId}`;
  // if (data?.searchTerm) {
  //   url = url + `&searchTerm=${data?.searchTerm}`;
  // }

  return axios.post(url, {...data, region : regionId}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getCouponDetails = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/coupon/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateCoupon = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.put(spotlet_services + `/coupon`, data,
  {
    headers: {
      Authorization: `Bearer ${auth}`,
      },
    });
  };
  export const deleteCoupon = (id) => {
    const auth = localStorage.getItem("admin");
    var url = spotlet_services + `/coupon/${id}`;
    return axios.delete(url, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
  };
  
  export const getRazerPayCoupons = () => {
    const auth = localStorage.getItem("admin");
    var url = spotlet_services + `/razerpaycoupon`;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
  };

//**********REVIEWS**********/
export const getAllLocationsReviews = (searchTerm, pageNo, noOfItems) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/review/?searchTerm=${searchTerm}&pageNo=${pageNo}&noOfItems=${noOfItems}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getLocationReviews = (locationId, pageNo, noOfItems, searchTerm) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/review/${locationId}/?pageNo=${pageNo}&noOfItems=${noOfItems}&searchTerm=${searchTerm}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********PERMISSIONS**********/
export const getPermissions = () => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/permission`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const createPermission = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/permission`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updatePermission = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/permission`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deletePermission = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/permission/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********ROLES**********/
export const getRoles = (role) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/role/?role=${role}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const createRole = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/role`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateRole = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/role`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteRole = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/role/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********STAFF**********/
export const getEmployees = (searchTerm) => {
  const auth = localStorage.getItem("admin");
  const regionId = localStorage.getItem("regionId");
  var url = auth_domain + `/employee/?searchTerm=${searchTerm}&regionId=${regionId}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const createEmployee = (data) => {
  const auth = localStorage.getItem("admin");
  let regionId = localStorage.getItem("regionId");
  var url = auth_domain + `/employee`;
  return axios.post(url, {...data, "region":regionId}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateEmployee = (data) => {
  const auth = localStorage.getItem("admin");
  const regionId = localStorage.getItem("regionId");
  var url = auth_domain + `/employee`;
  return axios.put(url, {...data, "region":regionId}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteEmployee = (id) => {
  const auth = localStorage.getItem("admin");
  const regionId = localStorage.getItem("regionId");
  var url = auth_domain + `/employee/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deactivateEmployee = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/employee/${id}`;
  return axios.put(url, {}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********AUDIT_LOGS**********/
export const getLogs = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/logs/?searchTerm=${data?.searchTerm}&date=${data?.date}&level=${data?.level}&page=${data?.page}&noOfItems=${data?.noOfItems}&category=${data?.category}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********TESTIMONIALS**********/
export const createTestimonials = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/testimonials`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getTestimonials = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/testimonials/?page=${data.page}&noOfItems=${data.noOfItems}&searchTerm=${data?.searchTerm}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteTestimonial = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/testimonials/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getTestimonialsDetails = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/testimonials/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateTestimonials = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/testimonials`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********FEATURE_MANAGEMENT**********/
export const addToFeatureProperty = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/feature`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getFeatureProperties = (id) => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region')
  var url = auth_domain + `/feature?region=${region}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateFeatureProperties = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/feature`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteFeatureProperties = (locationId) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/feature/${locationId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********TOP_RATED**********/
export const addToTopRatedProperty = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/toprated`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getTopRatedProperties = (id) => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region')
  var url = auth_domain + `/toprated/?region=${region}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateTopRatedProperties = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/toprated`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteTopRatedProperties = (locationId) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/toprated/${locationId}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********EMAIL_TAMPLATE**********/
export const getAllEmailTemplates = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/email?page=${data.page}&noOfItems=${data.noOfItems}&searchTerm=${data?.searchTerm}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const getEmailTemplate = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/email/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const addEmailTemplate = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/email`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateEmailTemplate = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/email`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteEmailTemplate = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/email/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//**********NOTIFICATION_TAMPLATE**********/
export const getAllNotificationTemplates = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/notification?page=${data.page}&noOfItems=${data.noOfItems}&searchTerm=${data?.searchTerm}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const getNotificationTemplate = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/notification/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const addNotificationTemplate = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/notification`;
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const updateNotificationTemplate = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/notification`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const deleteNotificationTemplate = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/notification/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// For Getting Booking details
export const getBookingDetails = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/bookingdetails/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//Convenience fee
export const updateConvenienceFee = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/convenienceFee`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const getConvenienceFee = () => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/convenienceFee`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

// Adding Videos to location
export const uploadLocationVideos = async (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/uploadlocvid`;
  const response = await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// Deleting Videos from storage
export const deleteVid = (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/deletevid`;
  const response = axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
    data: formData,
  });
  return response.data;
};

// Adding Cms page
export const addCmsPage = async (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cms`;
  return await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get single page details
export const getPageDetails = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cms/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//get all pages details
export const getPages = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cms/?page=${data.page}&items_per_page=${data.items}`;
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//get all pages details
export const getPagesCount = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cmsCount`;
  if (data.searchTerm) {
    url = url + `/?searchTerm=${data.searchTerm}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//update page
export const updatePage = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cms`;
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
// Deleting a page
export const deletePage = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/cms/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// For getting Enquiries data
export const getEnquiryData = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/enquirydetails`;
  return axios.post(url, 
  {
    ...data,
  }, 
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

//listing
//update incomplete listing
export const updateIncompleteListing = (data) => {
  const auth = localStorage.getItem("admin");
   return axios.put(
     `${auth_domain}/incompleteListing`,
     data,
      {
     headers: {
       Authorization: `Bearer ${auth}`,
     },
   }
      );
 };
//get incomplete listings
 export const getIncompleteListings = (data) => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region')
  var url = auth_domain + `/incompleteListing/?region=${region}&page=${data.page}&items_per_page=${data.items}`;
  if (data.status) {
    url = url + `&status=${data.status}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.subscriptionPlan) {
    url = url + `&subscriptionPlan=${data.subscriptionPlan}`;
  }
  if (data.locationType) {
    url = url + `&locationType=${data.locationType}`;
  }
  if (data.dateFrom) {
    url = url + `&dateFrom=${data.dateFrom}`;
  }
  if (data.dateTo) {
    url = url + `&dateTo=${data.dateTo}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//get incomplete listing
 export const getIncompleteListing = (id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/incompleteListing/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//get count off incomplete listings
export const getCountIncompleteListings = (data) => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region')
  var url = auth_domain + `/noofincompleteListing/?region=${region}&`;
  if (data.locationType) {
    url = url + `locationType=${data.locationType}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get count off listings by status
export const getCounListingsByStatus = (data) => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region')
  var url = auth_domain + `/nooflistingsbystatus/?region=${region}&count=50`;
  if (data.status) {
    url = url + `&status=${data.status}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.subscriptionPlan) {
    url = url + `&subscriptionPlan=${data.subscriptionPlan}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//get  listings
export const getListings = (data) => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region')
  var url = auth_domain + `/listing/?region=${region}&page=${data.page}&items_per_page=${data.items}`;
  if (data.status) {
    url = url + `&status=${data.status}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.subscriptionPlan) {
    url = url + `&subscriptionPlan=${data.subscriptionPlan}`;
  }
  if (data.locationType) {
    url = url + `&locationType=${data.locationType}`;
  }
  if (data.dateFrom) {
    url = url + `&dateFrom=${data.dateFrom}`;
  }
  if (data.dateTo) {
    url = url + `&dateTo=${data.dateTo}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//get  listing
export const getListing = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${auth_domain}/listing/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//update listing
export const updateListing = (data) => {
  const auth = localStorage.getItem("admin");
   return axios.put(
     `${auth_domain}/listing`,
     data,
      {
     headers: {
       Authorization: `Bearer ${auth}`,
     },
   }
      );
 };

 //Update listing status
export const updateListingStatus = (locationId, status,data) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/listing/${locationId}/status/${status}`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const deleteListing = (location_id) => {
  const auth = localStorage.getItem("admin");
  return axios.delete(auth_domain + "/listing/" + location_id,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//Edited listing
export const getEditedListings = (data) => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region')
  var url = auth_domain + `/editedListings/?region=${region}&page=${data.page}&items_per_page=${data.items}`;
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.dateFrom) {
    url = url + `&dateFrom=${data.dateFrom}`;
  }
  if (data.dateTo) {
    url = url + `&dateTo=${data.dateTo}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const getEditedListingsCount = () => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region')
  var url = auth_domain + `/editedListingsCount/?region=${region}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
 export const updateEditedListingStatus = (locationId, status) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/editedListing/${locationId}/status/${status}`, {},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const deleteEditListing = (location_id) => {
  const auth = localStorage.getItem("admin");
  return axios.delete(auth_domain + "/editedListing/" + location_id,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const getEditedListing = (id) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/editedListing/${id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

export const updateEditListing = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/editedListing`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

//admin notifications
export const adminNotifications = () => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/adminnotification`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const updateNotification = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/adminnotification/${data}`, {},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

//get type of properties list
export const getTypeOfProperties = () => {
  var url = auth_domain + `/typeOfProperties`;
  return axios.get(url);
};
//add type of property
export const addNewTypeOfProperty = async (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/typeOfProperties`;
  return await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//update type of property
export const updateNewTypeOfProperty = async (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/typeOfProperties`;
  return await axios.put(url, formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//delete type of property
export const deleteTypeOfProperty = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.delete(auth_domain + `/typeOfProperties`, {
    headers: {
      Authorization: `Bearer ${auth}`
    },
    data: data
  });
}

//get amenities list
export const getListingAmenities = async () => {
  const auth = localStorage.getItem("auth");
  var url = auth_domain + `/amenities`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

//add type of amenity
export const addNewAmenity = async (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/amenities`;
  return await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//update type of property
export const editAmenity = async (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/amenities`;
  return await axios.put(url, formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//delete type of property
export const deleteAmenity = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.delete(auth_domain + `/amenities`, {
    headers: {
      Authorization: `Bearer ${auth}`
    },
    data: data
  });
}

// get cities
export const getCities = async() => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region');
  var url = auth_domain + `/getcities/?region=${region}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

//add new city
export const addNewCity = async (formData) => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region');
  var url = auth_domain + `/createcity`;
  return await axios.post(url, {...formData, region}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//update City 
export const updateCity = async (formData) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/updatecity`;
  return await axios.put(url, formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//delete city
export const deleteCity = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.delete(auth_domain + `/deletecity`, {
    headers: {
      Authorization: `Bearer ${auth}`
    },
    data: data
  });
}

// get leads
export const getLeads = () => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/leads`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
// get priority support details
export const getPrioritySupportDetails = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/prioritysupport`;
  return axios.post(url, {...data}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//Update priority support status
export const updatePrioritySupportTicket = (id) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/prioritysupport/${id}`, {},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

// get userDeactivaterequests details
export const getUserDeactivaterequestsDetails = (data) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/userDeactivateRequest`;
  return axios.post(url, {...data}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//Update Approve userDeactivaterequests status
export const updateUserDeactivaterequests = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/userDeactivateRequest`, {data},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
//get subscriptions
export const getSubscriptions = (data) => {
  const auth = localStorage.getItem("admin");
  const region = localStorage.getItem('region');
  var url = auth_domain + `/subscription/?page=${data.page}&items_per_page=${data.items}&region=${region}`;
  if (data.status) {
    url = url + `&status=${data.status}`;
  }
  if (data.sort) {
    url = url + `&sort=${data.sort}`;
  }
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  if (data.subscriptionPlan) {
    url = url + `&subscriptionPlan=${data.subscriptionPlan}`;
  }
  return axios.get(url ,{
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const createUser = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + `/createUser`,data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
        "Content-Type":"multipart/form-data",
      },
    });
};
export const addListingToEdit = (id) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/listingEdit/${id}`,{},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const subscription = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(`${auth_domain}/payment/subscription`, { data }, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

export const saveSubscription = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(`${spotlet_services}/payment/subscription/save`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
// to get plan Amounts 
export const getPlans = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/plans`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//Add user
export const createSpotletUser = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(`${auth_domain}/user`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//Update user
export const updateSpotletUser = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.put(`${auth_domain}/user`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
// to get listing ids 
export const getListingIds = (status) => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/listingId/${status}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//Send credentials mail
export const sendCredMail = (id) => {
  const auth = localStorage.getItem("admin");
  return axios.post(`${auth_domain}/sendmail/${id}`, {}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
// to get users 
export const getUsers = (type) => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/users/${type}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//Asign user to listing
export const assignUser = (userId,listingId) => {
  const auth = localStorage.getItem("admin");
  return axios.put(`${auth_domain}/assignUserlisting`, {userId,listingId}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// to get regions  
export const getRegions = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/regions`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

//upload Region pics
export const uploadRegionImages = (formData) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/uploadRegionImages", formData, {
    headers: {
      Authorization: `Bearer ${auth}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

//create a new Region
export const createNewRegion = (regionData) => {
  const auth = localStorage.getItem("admin");
  return axios.post(auth_domain + "/createRegion", regionData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

//create tag
export const createTag = (data) => {
  const auth = localStorage.getItem('admin');
  return axios.post(`${spotlet_services}/tag`,data,{
    headers : {
      Authorization : `Bearer ${auth}`
    },
  })
}
//get all tags
export const getTags = (data) => {
  const auth = localStorage.getItem("admin");
  var url = spotlet_services + `/tag/?page=${data.page}&items_per_page=${data.items}`;
  if (data.searchTerm) {
    url = url + `&searchTerm=${data.searchTerm}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//delete tag
export const deleteTag = (id) => {
  const auth = localStorage.getItem('admin');
  return axios.delete(`${spotlet_services}/tag/${id}`,{
    headers : {
      Authorization : `Bearer ${auth}`
    }
  })
}
//update tag
export const updateTag = (data) => {
  const auth = localStorage.getItem('admin');
  return axios.put(`${spotlet_services}/tag`,data,{
    headers : {
      Authorization : `Bearer ${auth}`
    },
  })
}

// for subscription with single time option
export const createPaymentOrder = (form) => {
  const auth = localStorage.getItem("admin");
  return axios.post(`${spotlet_services}/payment/orders`, form, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

export const uploadPics = (formData) => {
  const auth = localStorage.getItem("admin");
  return axios.post(`${spotlet_services}/user/upload-pic`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${auth}`,
    },
  });
};

export const getSidbarCount = () => {
  let region = localStorage.getItem("region");
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/getSidebarCount/${region}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

export const getDashboardDetails = () => {
  let region = localStorage.getItem("region");
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/getDashboard/${region}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

//razor pay start
export const getRazorPayPayments = (filterData) => {
  const auth = localStorage.getItem("admin");
  return axios.post(`${auth_domain}/razorpay/payments`,filterData, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const getRazorPayPaymentById = (id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/razorpay/payments/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const getRazorPaySubscriptionPlans = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/razorpay/plans`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const getRazorPaySubscriptionPlanById = (id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/razorpay/plans/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const getRazorPayInvoices = () => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/razorpay/invoices`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const getRazorPayInvoicesById = (id) => {
  const auth = localStorage.getItem("admin");
  return axios.get(`${auth_domain}/razorpay/invoices/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//razor pay end

//forgot-password
export const forgotPassword = (email) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/forgotPassword`, {email},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

//reset-password
export const resetPassword = (id, password) => {
  const auth = localStorage.getItem("admin");
  return axios.put(auth_domain + `/resetPassword`, {id, password},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
// Apply coupon
export const applyCouponCode = async (couponId, plan) => {
  const auth = localStorage.getItem("admin");
  return axios.post(spotlet_services + "/coupon/apply", {couponId, plan}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
// to get subscription details
export const getSubscription = (id) => {
  const auth = localStorage.getItem("admin");
  const response = axios.post( spotlet_services + `/subscription` , {"subscriptionId" : id}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}
//get services
export const fetchRequiredServiceData = (data) => {
  const auth = localStorage.getItem("admin");
  var url = spotlet_services + `/allServices/${data}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
//update-services
export const updateRequiredServiceData = (name, settings) => {
  const auth = localStorage.getItem("admin");
  return axios.put(spotlet_services + `/allServices`, {name, settings},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
// to get all tags
export const getAllTags = (data) => {
  const auth = localStorage.getItem("admin");
  const response = axios.post( spotlet_services +`/allTags`, {...data} ,
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}

export const deleteUser = (id, status) => {
  const auth = localStorage.getItem("admin");
  var url = auth_domain + `/user/${id}?status=${status}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

// to get cities based on the country and state in listing locations
export const getAllCities = (data) => {
  return axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`,data);
};

// to get all user listings
export const getUserListings = (id) => {
  const auth = localStorage.getItem("admin");
  const response = axios.get( spotlet_services +`/userlisting/${id}`,
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}
// to get all user incomplete listings
export const getUserIncompleteListings = (id) => {
  const auth = localStorage.getItem("admin");
  const response = axios.get( spotlet_services +`/incompleteListings/${id}`,
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}
// to get all user incomplete listings
export const getUserRequestedBookings = (id) => {
  const auth = localStorage.getItem("admin");
  const response = axios.get( spotlet_services +`/bookings/user/${id}`,
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}
// to get user given leads
export const getUserLeads = (data) => {
  const auth = localStorage.getItem("admin");
  const response = axios.post( spotlet_services +`/getUserLeads`, {...data} ,
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}
// to get user given leads
export const generateReport = (data) => {
  const auth = localStorage.getItem("admin");
  const regionId = localStorage.getItem("regionId");
  const response = axios.post( auth_domain +`/report`,{...data, regionId},
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}

// to get user requested leads
export const deleteFilesFromBucket = (fileUrls) => {
  const auth = localStorage.getItem("admin");
  const response = axios.delete(spotlet_services + `/deletefiles`, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      data: {fileUrls},
  });
  return response;
}


// api's for calendar section start
export const getDashCalendar = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.post(`${spotlet_services}/get_dash_calendar`, data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const getDashCalendarByListing = (id,date) => {
  const auth = localStorage.getItem("admin");
  return axios.post(`${spotlet_services}/get_dash_calendar_id/${id}`,{id,date}, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const updatePricing = (listing,data) => {
  const auth = localStorage.getItem("admin");
  return axios.put(`${spotlet_services}/update_pricing/${listing}`,data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const blockDay = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.put(`${spotlet_services}/block_date`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const updatePrice = (data) => {
  const auth = localStorage.getItem("admin");
  return axios.put(`${spotlet_services}/update_price`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
// api's for calendar section end
