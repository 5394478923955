import {Modal} from 'react-bootstrap';
import { KTIcon } from '../../../../_metronic/helpers';
import { useState} from 'react';
import { generateReport } from '../services/api';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useAuth } from '../../auth';

const OPTIONS = [
    {label :'All Users List',value : 'all-users'},
    {label :'User List',value : 'users'},
    {label :'Host List',value : 'hosts'},
    {label :'All Listings',value : 'all-listings'},
    {label :'Listing Requests',value : 'requested'},
    {label :'Listing for Approval',value : 'verified'},
    {label :'Approved Listings',value : 'approved'},
    {label :'Rejected Listings',value : 'rejected'},
    {label :'Deactivated Listings',value : 'deactivated'},
    {label :'Edited Listings',value : 'edited'},
    {label :'Staff List',value : 'staff'},
    {label :'Permissions List',value : 'permissions'},
    {label :'Activities List',value : 'activities'},
    {label :'Career List',value : 'careers'},
    {label :'Job Request List',value : 'requested-jobs'},
    {label :'Coupon List',value : 'coupons'},
    {label :'Subscriptions List',value : 'subscriptions'},
    {label :'Booking Requests',value : 'bookings'},
    {label :'Find My Spot',value : 'find-my-spot-leads'},
    {label :'Event Planning',value : 'event-planning-leads'},
    {label :'Subscription Enquiry',value : 'subscription-enquiry-leads'},
    {label :'Contact Host',value : 'contact-host-leads'},
    {label :'Lead Booking Requests',value : 'booking-request-leads'},
    {label :'Property Get In Touch',value : 'get-in-touch-leads'},
    {label :'Claim Property Requests',value : 'claim-property-enquiries'},
    {label :'Property Incorrect Info Reports',value : 'report-incorrect-info-enquiries'},
    {label :'Schedule Property Visit',value : 'schedule-property-visit-leads'},
    {label :'Host Get In Touch',value : 'host-get-in-touch-leads'},
]
const REPORT_OPTIONS = [
    {label :'XL Sheet',value : 'xl'},
    // {label :'PDF',value : 'pdf'},
]

const Reports = ({table}) => {
    const [formData,setFormData] = useState<any>({
        table : table,
        reportType : 'xl'
    })
    const [tableHTML, setTableHTML] = useState('');
    const [show,setShow] = useState(false)
    const [reportName,setReportName] = useState('');
    const {currentUser} = useAuth();
    
    const onOpen = () => {
        setShow(true)
        setFormData({
            table : table,
            reportType : 'xl'
        })
    }
    const onHide = () => {
        setFormData({})
        setTableHTML('')
        setShow(false)
    }
    const onchange = (e) => {
        const {name,value} = e.target
        setFormData({...formData,[name] : value})
    }
    const resetData = () => {
        setFormData({
            table : table,
            reportType : 'xl'
        })
        setTableHTML('')
    }

    // Helper function to recursively flatten the object
    const flattenObject = (obj: any, parentKey: string = '', res: any = {}): any => {
        for (let key in obj) {
        if (obj?.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
            flattenObject(obj[key], key, res);
            } else {
            res[key] = obj[key];
            }
        }
        }
        return res;
    };

    const createTable = (dataArray: any) => {
        // Flatten the data array to include nested objects
        const flattenData = dataArray?.map(obj => flattenObject(obj));
        // Get all unique keys from flattened objects
        const allKeys = flattenData.reduce((keys: any, obj : any) => {
          Object.keys(obj).forEach(key => {
            if (!keys.includes(key)) {
              keys.push(key);
            }
          });
          return keys;
        }, []);
      
        // Filter out keys that are not needed for table headers (like nested objects)
        const tableHeaders = allKeys.filter(key => typeof flattenData[0][key] !== 'object');
      
        // Build the table HTML with id attribute
        let tableHTML = `<table border="1" id="reportTable"><thead><tr>`;
      
        // Create table headers
        tableHeaders.forEach(header => {
            let capitalizedHeader = "";
            // if ((table === "users" || table === "hosts") && header === "full_name") {
            //     capitalizedHeader = "CREATEDBY";
            // } else {
                capitalizedHeader = header.toUpperCase();
            // }

            tableHTML += `<th>${capitalizedHeader}</th>`;
        });
      
        tableHTML += `</tr></thead><tbody>`;
      
        // Create table rows from flattened data array
        // Inside the createTable function, within the loop that generates table rows
        flattenData?.forEach(obj => {
            tableHTML += `<tr>`;
            tableHeaders.forEach(header => {
            let cellValue : any = ""
            // if ( (table === "users" || table === "hosts") && header === "full_name") {
            //     cellValue = obj["full_name"];
            //     if (!cellValue) cellValue = "Self";
            // } else {
                cellValue = obj[header];
            // }
            if (header.includes('createdAt') || header.includes('updatedAt') || header.includes('checkIn') || header.includes('checkOut') || header.includes('activationDate') || header.includes('expiryDate')) {
              tableHTML += `<td>${cellValue ? moment(cellValue).format('DD-MM-YYYY') : 'NA'}</td>`;
            } else if (header.includes('number') || header.includes('contact_person_name')) {
                // Prefix with a single quote to treat as text in Excel
                tableHTML += `<td>'${cellValue ? cellValue : "NA"}</td>`;
            } else {
              tableHTML += `<td>${cellValue ? cellValue : "NA"}</td>`;
            }
            });
            tableHTML += `</tr>`;
        });
      
        tableHTML += `</tbody></table>`;
        setTableHTML(tableHTML)
        // return tableHTML;
      };

    const onSubmit = async (e : any) => {
        e.preventDefault()
        try{
            const resp = await generateReport(formData)
            // console.log(resp?.data);
            createTable(resp?.data);
            OPTIONS?.map((each)=>{
                if(each.value === formData?.table){
                    setReportName(each.label)
                }
            })
        }catch(error : any){
            console.log(error)
            alert(error?.response?.data)
        }
    }
    return(
        <div>
        { currentUser?.permissions?.Reports?.read && (
            <button
                //disabled={isLoading}
                type='button'
                className='btn btn-light-primary m-3'
                onClick={onOpen}
            >
                <KTIcon iconName='filter' className='fs-2' />
                Report
            </button>
        )}
            {show && (
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show}
                    onHide={onHide}
                    size="lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Generate Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="row" onSubmit={onSubmit}>
                            <div className='mb-10 col-lg-6'>
                                <label className='form-label fs-6 fw-bold required' htmlFor='table'>Select Table</label>
                                <select
                                    id='table'
                                    name='table'
                                    className='form-select form-select-solid fw-bolder'
                                    value={formData?.table}
                                    onChange={onchange}
                                    required
                                    disabled
                                >
                                    <option selected hidden disabled value=''>Select Table</option>
                                    {OPTIONS?.map((each)=>{
                                        return(
                                            <option value={each.value}>{each.label}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='mb-10 col-lg-6'>
                                <label className='form-label fs-6 fw-bold required' htmlFor='table'>Report Type</label>
                                <select
                                    id='reportType'
                                    name='reportType'
                                    className='form-select form-select-solid fw-bolder'
                                    value={formData?.reportType}
                                    onChange={onchange}
                                    required
                                >
                                    <option selected hidden disabled value=''>Select Report Type</option>
                                    {REPORT_OPTIONS?.map((each)=>{
                                        return(
                                            <option value={each.value}>{each.label}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='mb-10 col-lg-6'>
                                <label className='form-label fs-6 fw-bold' htmlFor='dateFrom'>Date From</label>
                                <input
                                    className='form-control form-control-solid ps-14'
                                    id='dateFrom'
                                    name='dateFrom'
                                    value={formData?.dateFrom || ''}
                                    onChange={onchange}
                                    type='date'
                                    placeholder='Enter Date'
                                    max={new Date().toISOString().split("T")[0]}
                                    // required
                                />
                            </div>
                            <div className='mb-10 col-lg-6'>
                                <label className='form-label fs-6 fw-bold' htmlFor='dateTo'>Date To</label>
                                <input
                                    className='form-control form-control-solid ps-14'
                                    id='dateTo'
                                    name='dateTo'
                                    value={formData?.dateTo || ''}
                                    onChange={onchange}
                                    type='date'
                                    placeholder='Enter Date'
                                    min={new Date(formData?.dateFrom || null).toISOString().split("T")[0]}
                                    max={new Date().toISOString().split("T")[0]}
                                    // required
                                />
                            </div>
                            <div className='mb-10 col-lg-6'>
                                <label className='form-label fs-6 fw-bold' htmlFor='count'>No of Rows</label>
                                <input
                                    className='form-control form-control-solid ps-14'
                                    id='count'
                                    name='count'
                                    value={formData?.count || ''}
                                    onChange={onchange}
                                    type='number'
                                    placeholder='Enter No of Rows'
                                    min={1}
                                    // max={500}
                                    // required
                                />
                            </div>
                            <div className='mb-10 col-lg-12 d-flex justify-content-end'>
                                <button
                                    type='button'
                                    //disabled={isLoading}
                                    onClick={resetData}
                                    className='btn btn-light btn-active-light-primary fw-bold me-3 px-6'
                                >
                                    Reset
                                </button>
                                {tableHTML ? (
                                    <>
                                    <div id="report-show-container" style={{display :'none'}} dangerouslySetInnerHTML={{ __html: tableHTML }} />
                                    <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    table="reportTable"
                                    filename={`${reportName} report`}
                                    sheet="tablexls"
                                    buttonText="Download report"
                                    type='button'
                                    className='btn btn-success'
                                    />
                                    </>
                                ) : (
                                    <button
                                        //disabled={isLoading}
                                        type='submit'
                                        className='btn btn-primary fw-bold px-6'
                                    >
                                        Generate
                                    </button>
                                )}
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    )
}
export default Reports;