import { useState, useEffect } from "react";
import { PageLoader } from "../../services/PageLoader";
import { KTIcon } from "../../../../../_metronic/helpers";
import {deleteCity, getCities} from "../../services/api";
import { AddNewLookupModal } from "./modals/AddNewLookupModal";
import { useAuthStore } from "../../store/auth";
import VerifyModal from "../../Modals/VerifyModal";

const CitiesLookups = () => {
    const [data,setData] = useState([])
    const [showLoader, setShowLoader] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [title, setTitle] = useState('');
    const [showNewRecord, setShowNewRecord] = useState(false);
    const [editIndex, setEditIndex] = useState('');
    const [editValue, setEditValue] = useState('');
    const [deleteValue,setDeleteValue] = useState('')
    const [show,setShow] = useState(false)
    const [modalTitle,setModalTitle] = useState('')
    const [modalBody,setModalBoby] = useState('')
    const getData = async () => {
        try{
            const resp = await getCities();
            setData(resp)

        }catch(error){
            console.log(error?.response?.data?.message)
        }
    }

    const handleEdit = (index,value) => {
        setEditIndex(index);
        setEditValue(value);
        setTitle('Update City');
        setShowNewRecord(true);
    }
    const handleDelete = async (value) => {
        try{
          const resp = await deleteCity({deleteCity : deleteValue})
          alert(resp?.data?.message)
          getData()
        }catch(error){
            console.log(error.response.data?.message)
            alert(error?.response?.data?.message)
        }
    }
    const handleVerify = (value) => {
      setDeleteValue(value)
      setModalBoby(`Are you sure, You want to delete ${value} ?`)
      setModalTitle('Type of property')
      setShow(true)
  }
    useEffect(() => {
        getData()
    },[]);

    return(
        <div className="card">
      <div className="card-header border-0 pt-6">
        <div className='card-title'>
          {/* begin::Search */}
          {/* <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search Lookup'
              onChange={hanldeSearch}
            />
          </div> */}
          {/* end::Search */}
        </div>
        {/* {currentUser?.permissions?.Lookup_Management?.write && ( */}
        <div className="card-toolbar">
          <button type='button' className='btn btn-primary' onClick={() => {
            setTitle('Add New City')
            setShowNewRecord(true)}}>
            <KTIcon iconName='plus' className='fs-2' />
            Add New City
          </button>
        </div>
        {/* )} */}
      </div>
      <div className={`card mb-5 mb-xl-12`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
                <div class="card-body row">
                    {/*begin::Nav*/}
                    {/* <div className="w-100 d-flex flex-row" > */}
                        <div className="nav nav-pills nav-pills-custom mb-3 d-flex flex-row align-items-center flex-wrap col-lg-5">
                            {data?.map((key,i) => {
                                return(
                                    <div class="nav-item mb-3 me-3  w-100 d-flex flex-row align-items-center" onClick={()=>setActiveTab(i)}>
                                        <a style={{backgroundColor : activeTab === i && '#1b84ff'}} class="nav-link d-flex justify-content-between flex-row flex-center overflow-hidden active w-100 h-40px py-4 mx-5" data-bs-toggle="pill" href={`#kt_stats_widget_1_tab_${i}`}>
                                        <span class="fw-bold fs-6 lh-1" style={{color : activeTab === i ? '#ffffff' : '#000000'}}>{key?.label}</span>
                                        </a>
                                        <span className="mt-1 cursor-pointer" onClick={()=> handleEdit(i, key?.value)}><KTIcon iconName='pencil' className='fs-3 me-5'/></span>
                                        <span className="mt-1 cursor-pointer" onClick={()=> handleVerify(key?.value)}><KTIcon iconName='trash' className='fs-3' /></span>
                                    </div>
                                )})}
                        </div>
                    {/* </div> */}
                    {/*end::Nav*/}
                </div>
          </div>
        </div>
      </div>
      {showNewRecord && (
        <AddNewLookupModal
            show = {showNewRecord}
            onHide = {()=>{
                setShowNewRecord(false)
                setEditIndex('')
                setEditValue('')
                getData()
            }}
            title = {title}
            data={data}
            editIndex = {editIndex}
            editValue = {editValue}
        />
      )}
      {show && (
        <VerifyModal
            show={show}
            title={modalTitle}
            body={modalBody}
            onHide={()=> setShow(false)}
            OnSuccess={() => {
                setShow(false)
                handleDelete()
            }}
         />
      )}
      {showLoader && <PageLoader />}
    </div>
    )
}
export default CitiesLookups;