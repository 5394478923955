import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import TestimonialsList from './list/TestimonialsList'
import AddTestimonials from './AddTestimonial'
import EditTestimonials from './EditTestimonials'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Testimonials Management',
    path: '/apps/testimonials/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TestimonialPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Testimonials list</PageTitle>
              <TestimonialsList />
            </>
          }
        />
        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Add Testimonials</PageTitle>
              <AddTestimonials />
            </>
          }
        />
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Testimonials</PageTitle>
              <EditTestimonials />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/testimonials/list' />} />
    </Routes>
  )
}

export default TestimonialPage
