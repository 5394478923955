import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
// import LeadsList from './list/LeadsList'
import Enquiry from '../enquiry-management/Enquiry'

const locationsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Lead Management',
    path: '/apps/lead/find-my-spot',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LeadManagement = () => {
  return (
    <Routes>
      {/* <Route element={<Outlet />}> */}
        {/* <Route
          path='list'
          element={
            <> */}
              {/* <PageTitle breadcrumbs={locationsBreadcrumbs}>Leads List</PageTitle> */}
              {/* <LeadsList/> */}
              {/* <Enquiry/> */}
            {/* </>
          }
        /> */}
       {/* </Route>  */}
      <Route element={<Outlet />}>
        <Route
          path='find-my-spot'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Find My Spot</PageTitle>
              <Enquiry type  = "FindMySpot" />
            </>
          }
        />
      </Route> 
      <Route element={<Outlet />}>
        <Route
          path='event-planning'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Event Planning</PageTitle>
              <Enquiry type  = "EventPlanning" />
            </>
          }
        />
      </Route> 
      <Route element={<Outlet />}>
        <Route
          path='subscription-enquiry'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Subscription Enquiry</PageTitle>
              <Enquiry type  = "Subscription" />
            </>
          }
        />
      </Route> 
      <Route element={<Outlet />}>
        <Route
          path='contact-host'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Contact Host</PageTitle>
              <Enquiry type  = "Contact Host" />
            </>
          }
        />
      </Route> 
      <Route element={<Outlet />}>
        <Route
          path='booking-requests'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Booking Requests</PageTitle>
              <Enquiry type  = "Booking Request" />
            </>
          }
        />
      </Route> 
      <Route element={<Outlet />}>
        <Route
          path='get-in-touch'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Property Get In Touch</PageTitle>
              <Enquiry type  = "Get In Touch" />
            </>
          }
        />
      </Route> 
      <Route element={<Outlet />}>
        <Route
          path='claim-property'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Claim Property Requests</PageTitle>
              <Enquiry type  = "Claim Property" />
            </>
          }
        />
      </Route> 
      <Route element={<Outlet />}>
        <Route
          path='incorrect-info-reports'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Property Incorrect Info Reports</PageTitle>
              <Enquiry type  = "Report Incorrect Info" />
            </>
          }
        />
      </Route> 
      <Route element={<Outlet />}>
        <Route
          path='schedule-visit'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Schedule Property Visit</PageTitle>
              <Enquiry type  = "Schedule Property Visit" />
            </>
          }
        />
      </Route> 
      <Route element={<Outlet />}>
        <Route
          path='host-get-in-touch'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Host Get In Touch</PageTitle>
              <Enquiry type  = "Host Get In Touch" />
            </>
          }
        />
      </Route> 
      <Route index element={<Navigate to='/apps/lead/find-my-spot' />} />
    </Routes>
  )
}

export default LeadManagement
