import { useEffect, useState } from 'react';
import {addNewTypeOfProperty,updateNewTypeOfProperty} from "../../../services/api";
import Modal from 'react-bootstrap/Modal';

export function AddNewLookupModal({show,onHide,title,data,editIndex,editValue}) {
    const [formData,setFormData] = useState<any>({})
    const [newCat,setNewCat] = useState<any>(false)
    const [error,setError] = useState<any>('')

    useEffect(() => {
        if(editIndex){
            setFormData((prev) => {
                return {
                    ...prev,
                    category : editIndex
                }
            })
        }
        if(editValue){
            setFormData((prev) => {
                return {
                    ...prev,
                    type : editValue
                }
            })
        }
    },[]);

    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value,
        })
        setError('')
    }

    const submitForm = async (e) => {
        e.preventDefault()
        let resp : any 
        try{
            if(editIndex){
                resp= await updateNewTypeOfProperty({...formData,editValue})
            }else{
                resp= await addNewTypeOfProperty({...formData,newCat})
            }
            console.log(resp.data)
            alert(resp?.data)
            onHide()
        }catch(error : any){
            console.log(error?.response?.data?.message)
            setError(error?.response?.data?.message)
        }
    }

  return (
    <Modal
    show={show}
    onHide={onHide}
    keyboard={false}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form className="col" onSubmit={submitForm}>
                {!editIndex && <span className='text-active-primary active' style={{cursor : 'pointer'}} onClick={()=>{
                    setFormData({
                        ...formData,
                        category : '',
                    })
                    setNewCat(!newCat)}}>{newCat ? '(Want to add in existing Category)' : '(Want to add new Category)'}</span>}
                {!newCat && (
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mt-3">
                            <span  className='required'>Category</span>
                        </label>
                        <div className="w-100">
                            <select disabled={editIndex} required onChange={onChange} value={formData?.category} id="kt_ecommerce_select2_country" className="form-select form-select-solid" name="category" data-kt-ecommerce-settings-type="select2_flags">
                                <option value='' disabled selected>Select a Category</option>
                                {data?.map((key) => (
                                    <option value={key?.category}>{key?.category}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                {newCat && (
                    <div className="col">
                        <div className="fv-row mb-7">
                            <label className="fs-6 fw-semibold form-label mt-3">
                                <span className='required'>Category</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Enter the type of the property">
                                    <i className="ki-duotone ki-information fs-7">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                            </label>
                            <input disabled={editIndex} required type="text"  name="category" onChange={onChange} value={formData?.category}  className="form-control form-control-solid"/>
                        </div>
                    </div>
                )}
                <div className="col">
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mt-3">
                            <span className='required'>Type</span>
                            <span className="ms-1" data-bs-toggle="tooltip" title="Enter the type of the property">
                                <i className="ki-duotone ki-information fs-7">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>
                            </span>
                        </label>
                        <input required type="text"  name="type" onChange={onChange} value={formData?.type} className="form-control form-control-solid"/>
                    </div>
                </div>
                {error && <span className='text-danger'>{error}</span>}
            <div>
                <button className='btn btn-secondary me-2' onClick={onHide}>Cancel</button>           
                <button className='btn btn-primary' type='submit'>Submit</button>           
            </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}