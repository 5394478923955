import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import EmailTemplateList from './list/EmailTemplateList'
import AddEmailTemplate from './AddEmailTemplate'
import EditEmailTemplate from './EditEmailTemplate'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Email Management',
    path: '/apps/email/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EmailTemplatePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Email Template list</PageTitle>
              <EmailTemplateList />
            </>
          }
        />
        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Create Email Template</PageTitle>
              <AddEmailTemplate />
            </>
          }
        />
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Email Template</PageTitle>
              <EditEmailTemplate />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/email/list' />} />
    </Routes>
  )
}

export default EmailTemplatePage
