import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useEffect, useState } from "react";
import { useParams, Link, useNavigate,useLocation} from 'react-router-dom';
import { getListing, getUsers,assignUser,addToFeatureProperty,addToTopRatedProperty,updateListing } from '../services/api';
import { PageLoader } from "../services/PageLoader";
import Moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ConfirmationModel from '../../../modals/ConfirmationModal/ConfirmationModal';
import Calendar from './Calendar/Calendar';

const Listing = () => {
    const params = useParams()
    const location = useLocation();
    const edited = new URLSearchParams(location.search).get('edited')
    const navigate = useNavigate();
    const listingId : any = params.id
    const [data, setData] = useState<any>([]);
    const [usersData, setUsersData] = useState<any>([]);
    const [dropDownData, setDropDownData] = useState<any>([]);
    const [showLoader, setShowLoader] = useState(false)
    const [selectedUser,setSelectedUser] = useState<any>(null)
    const [showUserSelectionDiv, setShowUserSelectionDiv] = useState(false)
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [tile, setTile] = useState('Details')
    const onChangeTile = (value) => {
      setTile(value)
    }
    const addTags = async(tag) => {
      try{
          let resp : any;
          if(tag === 'featured'){
              resp = await addToFeatureProperty({locationId : data?.location_id});
          }
          if(tag === 'toprated'){
              resp = await addToTopRatedProperty({locationId : data?.location_id});
          }
          if(resp?.data){
              setModelBody(resp?.data?.message)
              setShow(true)
            }
          }catch(error:any){
            console.log(error?.response?.data?.message)
            setModelBody(error?.response?.data?.message)
            setShow(true)
      }
   }
    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  //manage start
  const [bookingServices,setBookingServices] = useState<any>(
    {
      film : false,
      tv : false,
      event : false,
      stay : false,
    }
  );
  const onSettingsSubmit = async(e:any) => {
    e.preventDefault()
    setShowLoader(true)
    try{
        const resp = await updateListing({bookingServices,location_id : data?.location_id})
        console.log(resp?.data)
        setModelBody(resp?.data?.message)
        setShow(true)
    }catch(error:any) {
        console.log(error?.response?.data?.message)
        alert(error?.response?.data?.message)
    }
    setShowLoader(false)
}
  //manage end
  
  const renderData = (data) => {
    if (data === null || data === undefined || data === '') {
        return <span className='text-info'>**</span>;
    }

    if (Array.isArray(data)) {
        if (data.length === 0) {
            return <span className='text-info'>**</span>;
        }
        return (
            <ul className="list-group">
                {data.map((item, index) => (
                    <li key={index} className="list-group-item">
                        {renderData(item)}
                    </li>
                ))}
            </ul>
        );
    } else if (typeof data === 'object' && data !== null) {
        if (isEmptyObject(data)) {
            return <span className='text-info'>**</span>;
        }
        return (
            <ul className="list-group">
                {Object.entries(data).map(([key, value]) => (
                    <li key={key} className="list-group-item d-flex justify-content-between">
                        <strong>{key}:</strong> {renderData(value)}
                    </li>
                ))}
            </ul>
        );
    } else {
        return <span className='text-info'>{data.toString()}</span>;
    }
};
    const getData = async() => {
        setShowLoader(true)
        try{
            const resp = await getListing(listingId)
            setData(resp?.data)
            if(resp?.data?.bookingServices) setBookingServices(resp?.data?.bookingServices)
        }catch(error : any){
            console.log(error?.response?.data?.message)
        }
        setShowLoader(false)
    }
    const handleUser = async()=> {/*  */
        if(usersData.length === 0){
            try{
                const resp = await getUsers('all')
                setUsersData(resp?.data)
                const temp = resp?.data.map((user)=> {
                    return {
                        label : `${user?.personalInfo?.email} ${user?.personalInfo?.mobile}`,
                        year : user?._id,
                    }
                })
                setDropDownData(temp)
            }catch(error){
                console.log(error)
            }
        }
        setShowUserSelectionDiv(true)
    }
    const handleSelect = (value) => {
        console.log(value)
        if(value){
            const user = usersData.filter((user) => user._id === value?.year)
            setSelectedUser(user[0])
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(selectedUser)
        if(!selectedUser) return alert('Select user')
        try{
            const resp = await assignUser(selectedUser?._id,listingId)
            setShowUserSelectionDiv(false)
            setSelectedUser(null)
            getData()
            alert(resp?.data?.message)
        }catch(error : any){
            console.log(error?.response?.data?.message)
            alert(error?.response?.data?.message)
        }
    }
    useEffect(()=>{
        getData()
    },[])

    // console.log("data?.user", data?.user);

    return(
      <>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  <img src={toAbsoluteUrl(data?.images?.[listingId]?.[0]?.image)} alt='location image' />
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                </div>
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {data?.location_id}
                      </a>
                      <a href='#'>
                        <KTIcon iconName='verify' className='fs-1 text-primary' />
                      </a>
                    </div>

                    <div className='d-flex flex-column flex-wrap fw-bold fs-6 mb-4 pe-2'>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTIcon iconName='save-deposit' className='fs-4 me-1' />
                        {data?.basic_info?.property_name}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTIcon iconName='technology-3' className='fs-4 me-1' />
                        {data?.user?.personalInfo?.fullName}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTIcon iconName="technology-2" className='fs-4 me-1' />
                        {data?.location_type}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTIcon iconName='geolocation' className='fs-4 me-1' />
                        {data?.address?.city},{data?.address?.area}
                      </a>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between align-items-start my-4'>
                    <div className='me-2'>
                          {!showUserSelectionDiv && (<button className='btn btn-primary' onClick={handleUser}>Assign User</button>)}
                          {showUserSelectionDiv && (
                              <form  onSubmit={handleSubmit}>
                                  <div>
                                      {/* <select
                                          onChange={handleSelect}
                                          required
                                      >
                                          <option value='' hidden selected>Select user</option>
                                          {usersData?.map((user) => {
                                              return(
                                                  <option value={user?._id}>{user?.personalInfo?.email}{' '}{user?.personalInfo?.mobile}</option>
                                              )
                                          })}
                                      </select> */}
                                      <Autocomplete
                                          disablePortal
                                          id="disable-clearable"
                                          disableClearable
                                          options={dropDownData}
                                          sx={{ width: 300 }}
                                          renderInput={(params) => <TextField {...params} label='Select user' />}
                                          onChange={(event, newValue) => {
                                              handleSelect(newValue)
                                            }} 
                                          />
                                  </div>
                                  {selectedUser && (
                                      <div className='d-flex flex-column mt-2'>
                                          <span>Name : {selectedUser?.personalInfo?.fullName}</span>
                                          <span>Email : {selectedUser?.personalInfo?.email}</span>
                                          <span>Mobile : {selectedUser?.personalInfo?.mobile}</span>
                                      </div>
                                  )}
                                  <div className='d-flex justify-content-between mt-2'>
                                  <button type='button' className='btn btn-secondary' onClick={()=>{
                                      setShowUserSelectionDiv(false)
                                      setSelectedUser(null)
                                  }}>Cancel</button>
                                  <button type='submit' className='btn btn-primary'>Assign</button>
                                  </div>
                              </form>
                          )}
                    </div>
                    <div className="w-100">
                        <select onChange={(e)=> addTags(e.target.value)} id="kt_ecommerce_select2_country" className="form-select form-select-solid" name="add-category" data-kt-ecommerce-settings-type="select2_flags">
                        <option value='' disabled selected>Add to</option>
                            <option value='featured'>Featured</option>
                            <option value='toprated'>Exclusive</option>
                        </select>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTIcon iconName='book-open' className='fs-3 text-success me-2' />
                        <div className='fw-bold fs-6 text-gray-400'>Status</div>
                        </div>
                          <div className='fs-2 fw-bolder'>{data?.status}</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTIcon iconName='star' className='fs-3 text-danger me-2' />
                        <div className='fw-bold fs-6 text-gray-400'>Plan</div>
                        </div>

                          <div className='fs-2 fw-bolder'>{data?.subscription_id}</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fw-bold fs-6 text-gray-400'>Views</div>
                        </div>

                          <div className='fs-2 fw-bolder'>{data?.propertyViews}</div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                    <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                      <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                      <span className='fw-bolder fs-6'>50%</span>
                    </div>
                    <div className='h-5px mx-3 w-100 bg-light mb-3'>
                      <div
                        className='bg-success rounded h-5px'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-between overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item nav-link text-active-primary me-6 '>
                  <div
                  style={{cursor:"pointer"}}
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (tile === 'Details' && 'active')
                  }
                  onClick={() => onChangeTile('Details')}
                  >
                    Property Details
                  </div>
                </li>
                <li className='nav-item nav-link text-active-primary me-6 '>
                  < div
                    style={{cursor:"pointer"}}
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (tile === 'Manage' && 'active')
                    }
                    onClick={() => onChangeTile('Manage')}
                  >
                    Manage
                  </div>
                </li>
                <li className='nav-item nav-link text-active-primary me-6 '>
                  < div
                    style={{cursor:"pointer"}}
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (tile === 'Calendar' && 'active')
                    }
                    onClick={() => onChangeTile('Calendar')}
                  >
                    Calendar
                  </div>
                </li>
                {/* <li className='nav-item nav-link text-active-primary me-6 '>
                  < div
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (tile === 'Leads' && 'active')
                    }
                    onClick={() => onChangeTile('Leads')}
                  >
                    Leads
                  </div>
                </li> */}
                {/* <li className='nav-item nav-link text-active-primary me-6 '>
                  < div
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (tile === 'Bookings' && 'active')
                    }
                    onClick={() => onChangeTile('Bookings')}
                  >
                    Bookings
                  </div>
                </li> */}
              </ul>
              <div className='btn btn-primary align-self-center'
                onClick={() => {
                  if(edited === 'true'){
                    navigate(`/apps/location-management/editedlisting/${data?.location_id}`);
                  }else{
                    navigate(`/apps/location-management/listing/${data?.location_id}`);
                  }
                }}
              >
                Edit
              </div>
            </div>
          </div>
      </div>
      {tile === 'Details' && (
        <>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-primary'>Basic information</h3>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Property Name</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.basic_info?.property_name}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Property Type</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data?.location_type}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Size</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data?.basic_info?.property_size}{' '}{data?.basic_info?.area}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>
                Description
                {/* <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Phone number must be active'
                ></i> */}
              </label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{data?.basic_info?.description || '-'}</span>

                {/* <span className='badge badge-success'>Verified</span> */}
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>House Parking</label>

              <div className='col-lg-8'>
                <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                  {data?.basic_info?.house_parking ? 'Yes' : 'No'}
                </a>
              </div>
            </div>

            {data?.basic_info?.no_of_cars && <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>House Parking</label>

              <div className='col-lg-8'>
                <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                  {data?.basic_info?.no_of_cars}
                </a>
              </div>
            </div>}

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>
                Street Parking
                {/* <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Country of origination'
                ></i> */}
              </label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.basic_info?.street_parking ? 'Yes' : 'No'}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>
                Valet Parking
                {/* <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Country of origination'
                ></i> */}
              </label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.basic_info?.valet_parking ? 'Yes' : 'No'}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Security Camera</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.basic_info?.security_camera ? 'Yes' : 'No'}</span>
              </div>
            </div>

            {/* <div className='row mb-10'>
              <label className='col-lg-4 fw-bold text-muted'>Allow Changes</label>

              <div className='col-lg-8'>
                <span className='fw-bold fs-6'>Yes</span>
              </div>
            </div> */}

            {/* <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
              <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
                  <div className='fs-6 text-gray-600'>
                    Your payment was declined. To start using tools, please
                    <Link className='fw-bolder' to='/crafted/account/settings'>
                      {' '}
                      Add Payment Method
                    </Link>
                    .
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-primary'>Address</h3>
            </div>
          </div>

          <div className='card-body p-9'>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Country</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.address?.country}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>State</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data?.address?.state}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>City</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data?.address?.city}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Area</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data?.address?.area}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>
                Pincode
                {/* <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Phone number must be active'
                ></i> */}
              </label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{data?.address?.pincode}</span>

                {/* <span className='badge badge-success'>Verified</span> */}
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Landmark</label>

              <div className='col-lg-8'>
                <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                  {data?.address?.landmark || '-'}
                </a>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>
                Full Address
                {/* <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Country of origination'
                ></i> */}
              </label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.address?.address || '-'}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>
                map
                {/* <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Country of origination'
                ></i> */}
              </label>

              <div className='col-lg-8'>
                {data?.address?.mapLink ? (
                  <a className='fw-bolder fs-6 text-success' href={data?.address?.mapLink} target="_blank">View location</a>
                ) : (
                  <span className='fw-bolder fs-6 text-dark'>-</span>
                )}
              </div>
            </div>

            {/* <div className='row mb-10'>
              <label className='col-lg-4 fw-bold text-muted'>Allow Changes</label>

              <div className='col-lg-8'>
                <span className='fw-bold fs-6'>Yes</span>
              </div>
            </div> */}

            {/* <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
              <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
                  <div className='fs-6 text-gray-600'>
                    Your payment was declined. To start using tools, please
                    <Link className='fw-bolder' to='/crafted/account/settings'>
                      {' '}
                      Add Payment Method
                    </Link>
                    .
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-primary'>Amenities</h3>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='row mb-3'>
              {data?.amenities?.map((item,i)=> {
                return(
                  <div className='col-lg-4'>
                    <span className='fw-bolder fs-6 text-dark'>{i+1}) {item?.name}</span>
                  </div>
                )
              })}
              </div>
          </div>
        </div>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-primary'>Rooms/Spaces</h3>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='row mb-3'>
              {data?.features?.map((item,i)=> {
                return(
                  <>
                    <span className='fw-bolder mb-1 fs-6 text-success'>{item?.name}</span>
                    <div className='row mb-1'>
                      <label className='col-lg-4 fw-bold text-muted'>Size</label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{item?.size || '-'}{' '}{item?.size  && item?.area_type}</span>
                      </div>
                    </div>
                    <div className='row mb-1'>
                      <label className='col-lg-4 fw-bold text-muted'>Length</label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{item?.length || '-'}</span>
                      </div>
                    </div>
                    <div className='row mb-1'>
                      <label className='col-lg-4 fw-bold text-muted'>Width</label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{item?.width || '-'}</span>
                      </div>
                    </div>
                    <div className='row mb-1'>
                      <label className='col-lg-4 fw-bold text-muted'>Height</label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{item?.heigth || '-'}</span>
                      </div>
                    </div>
                    <div className='row mb-1'>
                      <label className='col-lg-4 fw-bold text-muted'>Description</label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>{item?.description || '-'}</span>
                      </div>
                    </div>
                    <hr/>
                  </>
                )
              })}
              </div>
          </div>
        </div>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-primary'>Pricing</h3>
            </div>
          </div>

          <div className='card-body p-9'>
              <div className='row mb-3'>
                <div className='col-lg-6  mb-3'>
                  <label className='fw-bold text-success'>Film Shooting</label>
                    {data?.pricing?.film?.available ? (<div className="container">{renderData(data?.pricing?.film)}</div>) : (
                      <div className='list-group'>
                      <li className="list-group-item d-flex justify-content-between">
                        <strong>Available:{' '}</strong> <span>No</span>
                      </li>
                    </div>
                    )}
                </div>
                <div className='col-lg-6  mb-3'>
                  <label className='fw-bold text-success'>Tv Shooting</label>
                    {data?.pricing?.tv?.available ? (<div className="container">{renderData(data?.pricing?.tv)}</div>) : (
                      <div className='list-group'>
                      <li className="list-group-item d-flex justify-content-between">
                        <strong>Available:{' '}</strong> <span>No</span>
                      </li>
                    </div>
                    )}
                </div>
                <div className='col-lg-6  mb-3'>
                  <label className='fw-bold text-success'>Event</label>
                    {data?.pricing?.event?.available ? (<div className="container">{renderData(data?.pricing?.event)}</div>) : (
                      <div className='list-group'>
                      <li className="list-group-item d-flex justify-content-between">
                        <strong>Available:{' '}</strong> <span>No</span>
                      </li>
                    </div>
                    )}
                </div>
                <div className='col-lg-6  mb-3'>
                  <label className='fw-bold text-success'>Staycation</label>
                    {data?.pricing?.stay?.available ? (<div className="container">{renderData(data?.pricing?.stay)}</div>) : (
                      <div className='list-group'>
                        <li className="list-group-item d-flex justify-content-between">
                          <strong>Available:{' '}</strong> <span>No</span>
                        </li>
                      </div>
                    )}
                </div>
              </div>
          </div>
        </div>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-primary'>Gallery</h3>
            </div>
          </div>

          <div className='card-body p-9'>
              {data?.images && Object.keys(data?.images)?.map((key,i)=> {
                return(
                  <>
                    <span className='fw-bolder fs-6 text-success'>{key}</span>
                    <div className='d-flex flex-row flex-wrap'>
                      {data?.images?.[key]?.map((item)=> {
                        return(
                          <div className='mb-2 me-2'>
                            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative d-flex flex-column'>
                              <img src={toAbsoluteUrl(item?.image)} alt='location image' />
                              {item?.title && <span className='fw-bolder fs-6 text-light position-absolute bottom-0'>{item?.title}</span>}
                            </div>
                        </div>
                        )
                      })}
                    </div>
                  </>
                )
              })}
              {/* <iframe className="embed-responsive-item rounded h-300px w-100" src={toAbsoluteUrl(data?.videos[0]?.[data?.location_id])}></iframe> */}
          </div>
        </div>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-primary'>Policies</h3>
            </div>
          </div>

          <div className='card-body p-9'>
              <div className='row mb-3'>
                <div className='col-lg-6  mb-3'>
                  <label className='fw-bold text-success'>Film/Tv Shooting</label>
                    {data?.policies?.film?.status ? (<div className="container">{renderData(data?.policies?.film)}</div>) : (
                      <div className='list-group'>
                      <li className="list-group-item d-flex justify-content-between">
                        <strong>Available:{' '}</strong> <span>No</span>
                      </li>
                    </div>
                    )}
                </div>
                <div className='col-lg-6  mb-3'>
                  <label className='fw-bold text-success'>Event</label>
                    {data?.policies?.event?.status ? (<div className="container">{renderData(data?.policies?.event)}</div>) : (
                      <div className='list-group'>
                      <li className="list-group-item d-flex justify-content-between">
                        <strong>Available:{' '}</strong> <span>No</span>
                      </li>
                    </div>
                    )}
                </div>
                <div className='col-lg-6  mb-3'>
                  <label className='fw-bold text-success'>Staycation</label>
                    {data?.policies?.stay?.status ? (<div className="container">{renderData(data?.policies?.stay)}</div>) : (
                      <div className='list-group'>
                        <li className="list-group-item d-flex justify-content-between">
                          <strong>Available:{' '}</strong> <span>No</span>
                        </li>
                      </div>
                    )}
                </div>
              </div>
          </div>
        </div>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-primary'>Activities</h3>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='row mb-3'>
              {data?.tags?.map((item,i)=> {
                return(
                  <div className='col-lg-4 mb-2'>
                    <span className='fw-bolder fs-6 text-dark'>{i+1}) {item?.name}{' '}<span className='text-success'>({item?.type})</span></span>
                  </div>
                )
              })}
              </div>
          </div>
        </div>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0 text-primary'>Property Manager Details</h3>
            </div>
          </div>

          <div className='card-body p-9'>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Name</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.property_manager_details?.contact_person_name}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Designation</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data?.property_manager_details?.contact_person_designation}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Mobile Number</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data?.property_manager_details?.contact_person_number}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Email</label>

              <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>{data?.property_manager_details?.contact_person_email}</span>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>
                Alternate Person Name
              </label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{data?.property_manager_details?.alternate_person_name || '-'}</span>

                {/* <span className='badge badge-success'>Verified</span> */}
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>Alternate Mobile Number</label>

              <div className='col-lg-8'>
                <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                  {data?.property_manager_details?.alternate_person_number || '-'}
                </a>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-4 fw-bold text-muted'>
              Alternate Email
                {/* <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  data-bs-toggle='tooltip'
                  title='Country of origination'
                ></i> */}
              </label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{data?.property_manager_details?.alternate_person_email || '-'}</span>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
      {tile === 'Manage' && (
        <>
          <form className="card p-2 mt-3"  onSubmit={onSettingsSubmit} >
                    <h1 className="mt-3"> Booking Services Control </h1>
                    <hr className="mt-1 mb-2"/>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Booking for Film</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Booking for film">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setBookingServices({...bookingServices,[e.target.name] : e.target.checked})} name="film" checked={bookingServices?.film} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Booking for Tv</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Booking for Tv">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setBookingServices({...bookingServices,[e.target.name] : e.target.checked})} name="tv" checked={bookingServices?.tv} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Booking for Event</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Booking for Event">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setBookingServices({...bookingServices,[e.target.name] : e.target.checked})} name="event" checked={bookingServices?.event} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Booking for Stay</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Booking for Stay">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setBookingServices({...bookingServices,[e.target.name] : e.target.checked})} name="stay" checked={bookingServices?.stay} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="align-self-end mt-2 mb-2">
                        <button className="btn btn-primary" > Submit </button>
                    </div>
                </form>
        </>
      )}
      {tile === "Calendar" && (
        <Calendar userId = {data?.user_id} locationId = {data?.location_id} />
      )}
      {showLoader && <PageLoader />}
      {show && (
        <ConfirmationModel
          show={show}
          handleClose={() => setShow(false)}
          handleOk={() => setShow(false)}
          title={data?.location_id}
          body={modelBody}
        />
      )}
      </>
    )
}
export default Listing;