import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel,SidebarCountModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {jwtDecode} from "jwt-decode";
import { getAdminDetails,getSidbarCount } from '../../apps/services/api'


type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  sidebarCountObject : SidebarCountModel | undefined
  setSideBarCountObject :  Dispatch<SetStateAction<SidebarCountModel | undefined>>
  logout: () => void
  getUser: () => void
  getSideBarCount : () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  sidebarCountObject : undefined,
  setSideBarCountObject: () => {},
  logout: () => {},
  getUser: () => {},
  getSideBarCount : () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [sidebarCountObject, setSideBarCountObject] = useState<SidebarCountModel | undefined>()
  const getUser = async () => {
    try{
      const apiToken : any = localStorage.getItem('admin')
      if(apiToken){
        const decodeInfo : any = jwtDecode(apiToken);
        const response = await getAdminDetails(decodeInfo?._id);
        setCurrentUser(response.data)
      }else{
        logout()
      }
    }catch(error){
      console.log(error)
    }
  }
  const getSideBarCount = async () => {
    try{
      const resp = await getSidbarCount()
      setSideBarCountObject(resp?.data)
    }catch(error){
      console.log(error)
    }
  }
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }
  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.removeItem("region")
    localStorage.removeItem("regionId")
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout,getUser,sidebarCountObject,setSideBarCountObject,getSideBarCount}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser,setSideBarCountObject} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: any) => {
      try {
        if (!didRequest.current) {
          // const {data} = await getUserByToken(apiToken)
          if (apiToken) {
            const decodeInfo : any = jwtDecode(apiToken);
            const response = await getAdminDetails(decodeInfo?._id);
            setCurrentUser(response.data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
    const requestSideBarCount = async () => {
      try{
        const resp = await getSidbarCount()
        setSideBarCountObject(resp?.data)
      }catch(error){
        console.log(error)
      }
    }
    if (auth) {
      requestUser(auth)
      requestSideBarCount()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
