import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

const ViewCouponModal = ({data,show,onHide,title}) => {
    
    const razorCouponView = () => {
        return (
            <div style={{height :'550px',overflowY : 'auto'}}>
                    {/* {Object.keys(data).map((key) => (
                    <p key={key}>
                        <strong>{key}: </strong>{data?.[key]?.toString() || 'NA'}
                    </p>
                    ))} */}
                    <p><strong>Created By : </strong>{data?.createdBy || 'NA'}</p>
                    <p><strong>Coupon Name  :</strong>{data?.couponName || 'NA'}</p>
                    <p><strong> Coupon Id :</strong>{data?.couponId || 'NA'}</p>
                    <p><strong> Coupon Type :</strong>{data?.couponType || 'NA'}</p>
                    <p><strong> Coupon For :</strong>{data?.plan || 'NA'}</p>
                    {Object.keys(data?.razerOfferDetails)?.map((key) =>{
                                if(key === 'starts_at' ||key === 'ends_at' ||key === 'created_at' ){
                                    const date : any = new Date(data?.razerOfferDetails[key] * 1000)
                                    return(<p><strong>{key} </strong>{moment(date).format('DD-MM-YYYY') || 'NA'}</p>)

                                }else{
                                    return(<p><strong>{key} </strong>{data?.razerOfferDetails[key] || 'NA'}</p>)
                                }
                            })}
                    {/* <p><strong> Discount Type :</strong>{data?.discountType || 'NA'}</p>
                    {data?.discountType === 'Amount' && <p><strong> Coupon Value :</strong>{data?.couponValue || 'NA'}</p>}
                    {data?.discountType === 'Percentage' && <p><strong> Coupon Percentage :</strong>{data?.couponPercentage || 'NA'}</p>}
                    <p><strong> Activation Date :</strong>{data?.activationDate || 'NA'}</p>
                    <p><strong> Expiry Date :</strong>{data?.expiryDate || 'NA'}</p>
                    <p><strong>Usage Type :</strong>{data?.single ? 'Single' : 'Multi' || 'NA'}</p>
                    {data?.maximumDiscount && <p><strong> Maximum Discount :</strong>{data?.maximumDiscount || 'NA'}</p>}
                    {data?.minimunBooking &&<p><strong> Minimun Booking :</strong>{data?.minimunBooking || 'NA'}</p>} */}
                    {data?.userEmailId &&<p><strong> User Email Id :</strong>{data?.userEmailId || 'NA'}</p>}
                    {/* <p><strong> Description :</strong>{data?.description?.map((item) => {
                        return(
                            <span>{item || 'NA'}</span>
                        )
                    })}</p> */}
                    <p><strong> Used By :</strong>
                        <div className='d-flex flex-column'>
                            {data?.usedBy?.map((item) => {
                                return(
                                    <span>{item}</span>
                                )
                            })}
                        </div>
                    </p>
                    <p><strong> Status :</strong>{data?.status || 'NA'}</p>
                    <p><strong> Created At :</strong>{moment(data?.createdAt).format('YYYY-MM-DD') || 'NA'}</p>
                    <p><strong> Updated At :</strong>{moment(data?.updatedAt).format('YYYY-MM-DD') || 'NA'}</p>
            </div>
        )
    }
    
    const getCouponStatus = (start : any, end : any, status : any) => {
        let currentDate = new Date();
        currentDate.setHours(0,0,0,0)
        let startDate = new Date(start)
        startDate.setHours(0,0,0,0)
        let endDate = new Date(end)
        endDate.setHours(0,0,0,0)

        if (status === "Active") {
            if (currentDate < startDate) {
                return "Not Active Yet"
            } else if (currentDate > endDate) {
                return "Expired"
            } else {
                return status
            }
        }
        return status
    }

    const spotletCouponView = () => {
        return(
            <div style={{height :'550px',overflowY : 'auto'}}>
                <p><strong>Created By : </strong>{data?.createdBy || 'NA'}</p>
                <p><strong>Coupon Name : </strong>{data?.couponName || 'NA'}</p>
                <p><strong>Coupon Description : </strong>{data?.description || 'NA'}</p>
                <p><strong>Coupon Terms : </strong>{data?.terms || 'NA'}</p>
                <p><strong> Coupon Id : </strong>{data?.couponId || 'NA'}</p>
                <p><strong> Coupon Type : </strong>{data?.couponType || 'NA'}</p>
                <p><strong> Coupon For : </strong>{data?.plan + " Plan" || 'NA'}</p>
                <p><strong> Discount Type : </strong>{data?.discountType || 'NA'}</p>
                {data?.discountType === "Percentage" ? 
                    <p><strong> Discount Percentage : </strong>{data?.couponPercentage + "%" || 'NA'}</p> :
                    <p><strong> Discount Value : </strong>{data?.couponValue || 'NA'}</p>
               }
               {data?.userEmailId &&<p><strong> User Email Id :</strong>{data?.userEmailId || 'NA'}</p>}
               <p><strong> Used By : </strong>
                {data?.usedBy?.length > 0 ?
                        <div className='d-flex flex-column'>
                            { data?.usedBy?.map((item : any) => {
                                return(
                                    <span>{item}</span>
                                )
                            })}
                        </div> : 
                         'NA'
                    }
                </p>
               <p><strong> Status : </strong>{getCouponStatus(data?.activationDate, data?.expiryDate, data?.status) || 'NA'}</p>
               <p><strong> Usage : </strong>{data?.single ? "Single" : "Multi" || 'NA'}</p>
                <p><strong> Created At : </strong>{moment(data?.createdAt).format('YYYY-MM-DD') || 'NA'}</p>
                <p><strong> Updated At : </strong>{moment(data?.updatedAt).format('YYYY-MM-DD') || 'NA'}</p>
                <p><strong> Activation Date : </strong>{data?.activationDate || 'NA'}</p>
                <p><strong> Expiry Date : </strong>{data?.expiryDate || 'NA'}</p>
            </div>
        )
    }

    const noDetailsView = () => {
        return (
            <div style={{height :'550px',overflowY : 'auto'}}>
                <p> No Data Available </p>
            </div>
        )
    }

    const getView = () => {
        switch (data?.couponFrom) {
            case "Razorpay":
                return razorCouponView()
            case "SpotLet":
                return spotletCouponView()
            default:
                return noDetailsView();
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getView()}
            </Modal.Body>
        </Modal>
    )
}
export default ViewCouponModal;