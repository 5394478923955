import {useState} from 'react'
import {Modal} from 'react-bootstrap'
const { Header, Footer, Title, Body } = Modal;

const AuthenticateEmailModal = ({
    handleClose,
    handleOk,
    show,
    originalOtp
}) => {
        const [loading, setLoading] = useState(false)
        const [otp, setOtp] = useState('')
        const [error, setError] = useState('')
        const handleSubmit = async (e : any) => {
            e.preventDefault()
            if(originalOtp === otp){
                handleOk()
                return null
            }
            setError('Wrong otp, please enter valid otp')
        }
    return(
        <Modal show={show} onHide={handleClose} centered>
        <Header closeButton>
            <Title style={{fontSize : '16px'}}>Verify your email</Title>
        </Header>
        <Body className="verify-email-modal-body" style={{fontSize : '13px'}}>
            <form onSubmit={handleSubmit}>
                <p className='mb-2'>We have sent an email containing a One-Time Password (OTP) to verify your email address for Two-Factor Authentication (2FA).</p>
                <input
                    type='number'
                    className='form-control bg-transparent mb-2'
                    value={otp}
                    // min={4}
                    // max={4}
                    onChange={(e) => {
                        setOtp(e.target.value)
                        if(error) setError('')
                    }}
                    required
                    placeholder='Enter 4 digit otp'
                />
                {error && <p style={{color : 'red'}}>{error}</p>}
                <div className='d-flex flex-row justify-content-between align-items-center mb-10 w-100'>
                <button className='btn btn-secondary me-2 w-100' onClick={handleClose}>Close</button>
                <button
                    type='submit'
                    id='kt_auth_submit'
                    className='btn btn-primary w-100'
                >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    )}
                </button>
                </div>
            </form>
        </Body>
        </Modal>
    )
}
export default AuthenticateEmailModal;