import { useState,useEffect } from 'react';
import { createCoupon,getRazerPayCoupons } from '../../services/api';
import Modal from 'react-bootstrap/Modal';
import { PageLoader } from '../../services/PageLoader';
import moment from 'moment';
import { getPlans } from '../../services/api';

const CreateCouponModal = ({show,onHide,title,onSuccess}) => {
    const region = localStorage.getItem("region")
    const [razerPayCoupons,setRazerPayCoupons] = useState<any>({})
    const [selectedCoupon,setSelectedCoupon] = useState<any>('')
    const [plansData, setPlansData] = useState<any>([])
    const [formData, setFormData] = useState<any>({
        // createdBy : "Admin",
        couponFrom : "SpotLet",
        couponType : "Subscription",
        single: true,
      });
    const [loader,setLoader] = useState(false)
    const getCouponsData = async() => {
        try{
            const resp = await getRazerPayCoupons()
            setRazerPayCoupons(resp?.data)
        }catch(error : any){
            console.log(error?.response?.data?.message)
        }
    }
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value?.trimStart()
        })
    }
    const onChangeCoupon = (e) => {
        const {name,value} = e.target
        const filterCoupon = razerPayCoupons.filter((item) => item?.name === value)
        setSelectedCoupon(filterCoupon[0])
        setFormData({
            ...formData,
            [name] : value.trimStart(),
            razerOfferId : filterCoupon[0]?.id,
            razerOfferDetails : filterCoupon[0],
        })
    }
    const submitForm = async(e) => {
        e.preventDefault()
        if(window.confirm('Are you sure you want to create coupon ?') == true){
            setLoader(true)
            try{
                const resp = await createCoupon(formData);
                alert(resp?.data?.message)
                onSuccess()
            }catch(error : any){
                console.log(error)
                alert(error?.response?.data?.message)
            }
            setLoader(false)
        }
    }
    const getPlansPrices = async() => {
        try{
            const res = await getPlans()
            setPlansData(res?.data)
        }catch(error){
            console.log("get plans pricing error", error)
        }
    }
    useEffect(()=>{
        getCouponsData()
        getPlansPrices()
    },[])

    const getMaxFlatDiscount = (plan : String) => {
        let totalPrice : any = "0"
        if (plan === "StdToPrm") {
            return 2000
        }
        let currency = "INR"
        if (region === "India") {
            currency = "INR"
        } else if (region === "United States") {
            currency = "USD"
        }
        plansData.map((each : any) => {
            if(each?.plan === plan) {
                const reqArray = each?.amount
                reqArray?.filter((each : any) => {
                    if (each?.currency === currency) {
                        totalPrice = each?.discountAmount
                    }
                } )
            } 
        } )
        const CGST = 9
        const SGST = 9
        const originalPrice = Math.floor( totalPrice * (100/(100+CGST+SGST)) )
        const maxDiscountPrice = Math.floor(originalPrice * (90/100)) 
        return maxDiscountPrice
    }

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={submitForm} id='tagManagementAddTag' className='d-flex flex-column' style={{height :'550px',overflowY : 'auto'}}>
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold mb-2 required">Coupon Name</label>
                        <input type="text" minLength={5} maxLength={20} required onChange={onChange} className="form-control form-control-solid" placeholder="Enter Coupon Name" name="couponName" value={formData?.couponName} />
                    </div>
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold mb-2 required">Coupon Id</label>
                        <input type="text" pattern="^[a-zA-Z0-9]{5,12}$" minLength={5} maxLength={12}  required onChange={(e)=> setFormData({...formData , couponId : e.target.value.toUpperCase()})} title='Minimum 5 and maximum 12 characters are allowed and only Alphabets & numbers Ex : SPOT2000'    className="form-control form-control-solid" placeholder="Enter Coupon Id" name="couponId" value={formData?.couponId} />
                    </div>
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold mb-2 required">Coupon Description</label>
                        <input type="text"  minLength={5}  required onChange={(e)=> setFormData({...formData , description : e.target.value})} title='Minimum 5 characters'  className="form-control form-control-solid" placeholder="Enter Coupon Description" name="description" value={formData?.description} />
                    </div>
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold mb-2 required">Coupon Terms and Conditions</label>
                        <input type="text"  minLength={5}  required onChange={(e)=> setFormData({...formData , terms : e.target.value})} title='Minimum 5 characters'  className="form-control form-control-solid" placeholder="Enter Coupon Terms" name="description" value={formData?.terms} />
                    </div>
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold mb-2 required">Coupon Type</label>
                        <select
                            className='form-select form-select-solid fw-bolder'
                            data-kt-select2='true'
                            data-placeholder='Select Coupon Type'
                            data-allow-clear='true'
                            data-kt-user-table-filter='role'
                            data-hide-search='true'
                            onChange={onChange}
                            name='couponType'
                            value={formData?.couponType}
                            required
                            >
                            <option value='' hidden selected>Select Coupon Type</option>
                            <option value='Subscription'>Subscription</option>
                            <option value='Booking' disabled>Booking</option>
                        </select>
                    </div>
                    {formData?.couponType === 'Subscription' && (
                        <>
                            <div className="fv-row mb-7">
                                <label className="fs-6 fw-semibold mb-2 required">Coupon For</label>
                                <select
                                    className='form-select form-select-solid fw-bolder'
                                    data-kt-select2='true'
                                    data-placeholder='Select Subscription Plan'
                                    data-allow-clear='true'
                                    data-kt-user-table-filter='role'
                                    data-hide-search='true'
                                    onChange={onChange}
                                    name='plan'
                                    value={formData?.plan}
                                    required
                                    >
                                    <option value='' hidden selected>Select Subscription Plan</option>
                                    {/* <option value='Basic'>Basic</option> */}
                                    <option value='Standard'>Standard</option>
                                    <option value='Premium'>Premium</option>
                                    <option value='StdToPrm'>Standard to Premium</option>
                                </select>
                            </div>
                            {/* Spotlet Coupons or Razor Pay Coupons Selection Start */}
                            {/* <div className='d-flex flex-row mb-2' >
                                <div className='me-2'>
                                    <input className='me-2' type="radio" id="spotletCoupon" required name="couponFrom" checked = {formData?.couponFrom === "SpotLet"} onChange={onChange} value="SpotLet"/>
                                    <label htmlFor="spotletCoupon">SpotLet Coupon</label>
                                </div>
                                <div>
                                    <input className='me-2' type="radio" id="razorpayCoupon" required name="couponFrom" checked = {formData?.couponFrom === "Razorpay"} onChange={onChange} value="Razorpay"/>
                                    <label htmlFor="razorpayCoupon">Razorpay Coupon</label>
                                </div>
                            </div> */}
                            {/* Spotlet Coupons or Razor Pay Coupons Selection End */}

                            {/* Razor Pay Coupon Section Start  */}
                            {/* { formData?.couponFrom === "Razorpay" && (
                                <div className="fv-row mb-7">
                                    <label className="fs-6 fw-semibold mb-2 required">Razerpay Coupon</label>
                                    <select
                                        className='form-select form-select-solid fw-bolder'
                                        data-kt-select2='true'
                                        data-placeholder='Select Coupon Type'
                                        data-allow-clear='true'
                                        data-kt-user-table-filter='role'
                                        data-hide-search='true'
                                        onChange={onChangeCoupon}
                                        name='razerCoupon'
                                        value={formData?.razerCoupon}
                                        required
                                        >
                                        <option value='' hidden selected>Select Razerpay Coupon</option>
                                        {razerPayCoupons?.map((item) => {
                                            return(
                                                <option value={item?.name}>{item?.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            )} */}
                            {/* { formData?.couponFrom === "Razorpay" && (
                                <div className="fv-row mb-7">
                                    <label className="fs-6 fw-semibold mb-2">User Email</label>
                                    <input type="email" onChange={onChange} className="form-control form-control-solid" placeholder="Enter Email" name="userEmailId" value={formData?.userEmailId} />
                                </div>
                            )} */}
                            {/* Razor Pay Coupon Section End  */}
                        </>
                    )}
                    {formData?.couponFrom === "SpotLet"  && (
                        <>
                            <div className="fv-row mb-7">
                                <label className="fs-6 fw-semibold mb-2 required">Discount Type</label>
                                <select
                                    className='form-select form-select-solid fw-bolder'
                                    data-kt-select2='true'
                                    data-placeholder='Select Discount Type'
                                    data-allow-clear='true'
                                    data-kt-user-table-filter='role'
                                    data-hide-search='true'
                                    onChange={onChange}
                                    name='discountType'
                                    value={formData?.discountType}
                                    required
                                    >
                                    <option value='' hidden selected>Select Discount Type</option>
                                    <option value='Flat'>Flat</option>
                                    <option value='Percentage'>Percentage</option>
                                </select>
                            </div>
                            {formData?.discountType === 'Flat' && (
                                <div className="fv-row mb-7">
                                    <label className="fs-6 fw-semibold mb-2 required">Coupon Amount</label>
                                    <input type="number" min={1} max={getMaxFlatDiscount(formData?.plan)}  required onChange={onChange} className="form-control form-control-solid" placeholder="Enter Coupon Amount" name="couponValue" value={formData?.couponValue} />
                                </div>
                            )}
                            {formData?.discountType === 'Percentage' && (
                                <div className="fv-row mb-7">
                                    <label className="fs-6 fw-semibold mb-2 required">Coupon Percentage</label>
                                    <input type="number" min='1' max='90' required onChange={onChange} className="form-control form-control-solid" placeholder="Enter Coupon Percentage" name="couponPercentage" value={formData?.couponPercentage} />
                                </div>
                            )}
                            <div className="fv-row mb-7">
                                <label className="fs-6 fw-semibold mb-2 required">Activation Date</label>
                                <input type="date" min={new Date().toISOString().split("T")[0]}  required onChange={onChange} className="form-control form-control-solid" placeholder="Enter Activation Date" name="activationDate" value={formData?.activationDate} />
                            </div>
                            <div className="fv-row mb-7">
                                <label className="fs-6 fw-semibold mb-2 required">Expiry Date</label>
                                <input type="date" min={formData?.activationDate || new Date().toISOString().split("T")[0]}  required onChange={onChange} className="form-control form-control-solid" placeholder="Enter Expiry Date" name="expiryDate" value={formData?.expiryDate} />
                            </div>
                            <label className="fs-6 fw-semibold mb-2 required">Usage Type</label>
                            <div className="fv-row mb-7 d-flex flex-row justify-content-start align-items-center">
                                <div>
                                    <input type="radio" onChange={(e)=> setFormData({...formData,single : true})} name="single" id='single'  className='me-2'  checked={formData?.single} />
                                    <label className="fs-6 fw-semibold mb-2 me-2" htmlFor='single'>Single</label>
                                </div>
                                <div>
                                    <input type="radio" onChange={(e)=> setFormData({...formData,single : false})} name="single" id='multi'  className='me-2'  checked={!formData?.single} />
                                    <label className="fs-6 fw-semibold mb-2 me-2" htmlFor='multi'>Multi</label>
                                </div>
                            </div>
                            <div className="fv-row mb-7">
                                <label className="fs-6 fw-semibold mb-2">User Email{' '}</label>
                                <input type="email" onChange={onChange} className="form-control form-control-solid" placeholder="Enter Email" name="userEmailId" value={formData?.userEmailId} />
                            </div>
                        </>
                    )}
                    {selectedCoupon && (formData?.couponFrom !== "SpotLet") &&  (
                        <div>
                            {Object.keys(selectedCoupon)?.map((key) =>{
                                if(key === 'starts_at' ||key === 'ends_at' ||key === 'created_at' ){
                                    const date : any = new Date(selectedCoupon[key] * 1000)
                                    return(<p><strong>{key} </strong>{moment(date).format('DD-MM-YYYY') || 'NA'}</p>)

                                }else{
                                    return(<p><strong>{key} </strong>{selectedCoupon[key] || 'NA'}</p>)
                                }
                            })}
                        </div>
                    )}
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary">
                            <span className="indicator-label">Submit</span>
                        </button>
                    </div>
                </form>
                {loader && <PageLoader/>}
            </Modal.Body>
        </Modal>
    )
}
export default CreateCouponModal;