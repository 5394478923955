import { useEffect, useState ,useRef} from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { KTIcon } from "../../../../_metronic/helpers";
import { createJob,getJobDetails ,updateJob} from "../services/api";
import { Formik } from "formik";
import * as yup from "yup";
import { Form} from "react-bootstrap";
import { PageLoader } from "../services/PageLoader";
const schema = yup.object().shape({
  jobTitle: yup.string().required('Job title is required'),
  company: yup.string().required('Company name is required'),
  experience: yup.string().required('Experience is required'),
  salary: yup.string().required('Salary is required'),
  location: yup.string().required('Location is required'),
  openings: yup.string().required('Openings is required'),
  role: yup.string().required('Role is required'),
  IndustryType: yup.string().required('Industry Type is required'),
  Department: yup.string().required('Department is required'),
  employmentType: yup.string().required('Employment Type is required'),
  roleCategory: yup.string().required('Role Category is required'),
});

const CreateJob = () => {
  const params = useParams()
  const navigate = useNavigate();
  const jobId = params.id
  const formikRef = useRef<any>();
  const [formData, setFormData] = useState<any>({
    description: [],
  });
  const [showLoader, setShowLoader] = useState(false)

  const [initialValues, setInitialValues] = useState({
    jobTitle: "",
    company: "",
    experience: "",
    salary: "",
    location: "",
    openings: "",
    role: "",
    IndustryType: "",
    Department: "",
    employmentType: "",
    roleCategory: "",
  });

  const [description, setDescription] = useState<string>("");

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddDescription = () => {
    if(description){
      formData?.description.push(description);
      setFormData({ ...formData });
      setDescription("");
    }
  };

  const handleDeleteDescription = (i) => {
    formData?.description.splice(i, 1);
    setFormData({ ...formData });
  };

  const handleCreateJob = async (formValues) => {
    try{
      const data = {...formData,...formValues}
      let res;

      if(jobId){
        res = await updateJob(data,jobId);
      }else{
        res = await createJob(data);
      }

      alert(res?.data?.message)
      navigate("/apps/career");
    }catch(err : any){
        console.log(err?.response?.data?.message)
        alert(err?.response?.data?.message)
    }
  };

   //console.log(formData);

  useEffect(() => {
    const getJob = async () => {
      try {
        const res = await getJobDetails(jobId);
        setFormData(res.data)
        setInitialValues(res.data)
        setShowLoader(false)
        if (formikRef.current) {
          formikRef.current.setFieldValue(
            "jobTitle",
            res.data.jobTitle
          );
          formikRef.current.setFieldValue(
            "company",
            res.data.company
          );
          formikRef.current.setFieldValue(
            "experience",
            res.data.experience
          );
          formikRef.current.setFieldValue(
            "salary",
            res.data.salary
          );
          formikRef.current.setFieldValue(
            "location",
            res.data.location
          );
          formikRef.current.setFieldValue(
            "openings",
            res.data.openings
          );
          formikRef.current.setFieldValue(
            "role",
            res.data.role
          );
          formikRef.current.setFieldValue(
            "IndustryType",
            res.data.IndustryType
          );
          formikRef.current.setFieldValue(
            "Department",
            res.data.Department
          );
          formikRef.current.setFieldValue(
            "employmentType",
            res.data.employmentType
          );
          formikRef.current.setFieldValue(
            "roleCategory",
            res.data.roleCategory
          );
  
        }
      } catch (error:any) {
        console.log(error?.response?.data?.message)
        setShowLoader(false)
      }
    }
    if(jobId){
      setShowLoader(true)
      getJob();
    }
  }, []);

  return (
    <div className="card">
      <div className="card-header border-0 pt-6">
      <Formik
        validationSchema={schema}
        onSubmit={handleCreateJob}
        initialValues={initialValues}
        innerRef={formikRef}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="Position">
                      Position<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.jobTitle}
                      id="Position"
                      className="form-control form-control-lg form-control-solid"
                      name="jobTitle"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.jobTitle && !!errors.jobTitle}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="company">
                      Company<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.company}
                      id="company"
                      className="form-control form-control-lg form-control-solid"
                      name="company"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.company && !!errors.company}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="experience">
                      Experience<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.experience}
                      id="experience"
                      className="form-control form-control-lg form-control-solid"
                      name="experience"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.experience && !!errors.experience}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="salary">
                      Salary<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.salary}
                      id="salary"
                      className="form-control form-control-lg form-control-solid"
                      name="salary"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.salary && !!errors.salary}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="location">
                      location<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.location}
                      id="location"
                      className="form-control form-control-lg form-control-solid"
                      name="location"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.location && !!errors.location}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="openings">
                      Openings<span className="required"></span>
                    </label>
                    <Form.Control
                      type="number"
                      id="openings"
                      value={values?.openings}
                      className="form-control form-control-lg form-control-solid"
                      name="openings"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.openings && !!errors.openings}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="role">
                      Role<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.role}
                      id="role"
                      className="form-control form-control-lg form-control-solid"
                      name="role"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.role && !!errors.role}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="industryType">
                      Industry Type<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.IndustryType}
                      id="industryType"
                      className="form-control form-control-lg form-control-solid"
                      name="IndustryType"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.IndustryType && !!errors.IndustryType}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="department">
                      Department<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.Department}
                      id="department"
                      className="form-control form-control-lg form-control-solid"
                      name="Department"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.Department && !!errors.Department}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="employmentType">
                      Employment Type<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.employmentType}
                      id="employmentType"
                      className="form-control form-control-lg form-control-solid"
                      name="employmentType"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.employmentType && !!errors.employmentType}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="roleCategory">
                      Role Category<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.roleCategory}
                      id="roleCategory"
                      className="form-control form-control-lg form-control-solid"
                      name="roleCategory"
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.roleCategory && !!errors.roleCategory}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-1">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3">Description</label>
                    <ol>
                      {formData?.description?.map((text, i) => {
                        return (
                          <li key={i}>
                            <div onClick={() => handleDeleteDescription(i)}>
                              {text}
                              <KTIcon iconName="trash" className="fs-3" />
                            </div>
                          </li>
                        );
                      })}
                    </ol>
                    <textarea
                      className="form-control form-control-lg form-control-solid"
                      name="description"
                      rows={2}
                      cols={50}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary m-2"
                    onClick={handleAddDescription}
                  >
                    <KTIcon iconName="plus" className="fs-2" />
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                  >
                    {jobId ? "Update Job" : 'Create Job'}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showLoader && <PageLoader />}
      </div>
    </div>
  );
};

export default CreateJob;
