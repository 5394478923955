import { useState, useEffect, useCallback } from "react";
import { PageLoader } from "../../services/PageLoader";
import { KTIcon } from "../../../../../_metronic/helpers";
import {getListingAmenities, deleteAmenity} from "../../services/api";
import { AddNewLookupModal } from "./modals/AddNewLookupModal";
// import { useAuthStore } from "../../store/auth";
import { useAuth } from "../../../auth";
import VerifyModal from "../../Modals/VerifyModal";

const AmenitiesLookups = () => {
    const {currentUser} = useAuth()
    const [data,setData] = useState([])
    const [showLoader, setShowLoader] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [title, setTitle] = useState('');
    const [showNewRecord, setShowNewRecord] = useState(false);
    const [editData, setEditData] = useState();
    const [editIndex, setEditIndex] = useState('');
    const [editValue, setEditValue] = useState('');
    const [deleteValue,setDeleteValue] = useState('')
    const [deleteCategory,setDeleteCategory] = useState('')
    const [show,setShow] = useState(false)
    const [modalTitle,setModalTitle] = useState('')
    const [modalBody,setModalBoby] = useState('')
    const getData = async () => {
        try{
            const resp = await getListingAmenities();
            setData(resp?.slice(0,resp?.length-1))

        }catch(error){
            console.log(error?.response?.data?.message)
        }
    }

    // const hanldeSearch = (e) => {
    //     // if (e.target.value === '') {
    //     //   setSearchData(data)
    //     // }
    //     // const newArrey = [];
    //     // data.map((file, i) => {
    //     //   const isIncluded = file.value.includes(e.target.value)
    //     //   if (isIncluded) {
    //     //     return newArrey.push(file)
    //     //   }
    //     // })
    //     // setSearchData(newArrey)
    //   }
    const handleEdit = (index,value, data) => {
        setEditData(data);
        setEditIndex(index)
        setEditValue(value)
        setTitle('Update Record')
        setShowNewRecord(true)
    }
    const handleDelete = async () => {
        try{
            const resp = await deleteAmenity({deleteCategory: deleteCategory,deleteValue : deleteValue})
            alert(resp?.data?.message)
            getData()
        }catch(error){
            console.log(error.response.data?.message)
            alert(error?.resp?.data?.message)
        }
    }
    const handleVerify = (category,value) => {
        setDeleteValue(value)
        setDeleteCategory(category)
        setModalBoby(`Are you sure, You want to delete ${value} ?`)
        setModalTitle('Type of property')
        setShow(true)
    }
    useEffect(() => {
        getData()
    },[]);

    return(
        <div className="card">
      <div className="card-header border-0 pt-6">
        <div className='card-title'>
          {/* begin::Search */}
          {/* <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search Lookup'
              onChange={hanldeSearch}
            />
          </div> */}
          {/* end::Search */}
        </div>
        {/* {currentUser?.permissions?.Lookup_Management?.write && ( */}
        <div className="card-toolbar">
          <button type='button' className='btn btn-primary' onClick={() => {
            setTitle('Add New Record')
            setShowNewRecord(true)}}>
            <KTIcon iconName='plus' className='fs-2' />
            Add New Record
          </button>
        </div>
        {/* )} */}
      </div>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
                <div class="card-body row">
                    {/*begin::Nav*/}
                    <ul class="nav nav-pills nav-pills-custom mb-3 col-lg-4 d-flex flex-column jusitify-content-start align-items-center">
                        {data?.map((key,i) => {
                            return(
                                <li class="nav-item mb-3 me-3 me-lg-6 w-100" onClick={()=>setActiveTab(i)}>
                                    <a style={{backgroundColor : activeTab === i && '#1b84ff'}} class="nav-link d-flex justify-content-between flex-row flex-center overflow-hidden active w-100 h-40px py-4" data-bs-toggle="pill" href={`#kt_stats_widget_1_tab_${i}`}>
                                        <span class="fw-bold fs-6 lh-1" style={{color : activeTab === i ? '#ffffff' : '#000000'}}>{key?.category}</span>
                                        {/* <KTIcon iconName='trash' className='fs-3' /> */}
                                    </a>
                                </li>
                            )})}
                    </ul>
                    {/*end::Nav*/}
                    {/*begin::Tab Content*/}
                    <div class="tab-content col-lg-8">
                        {/*begin::Tap pane*/}
                        <div class="tab-pane fade show active" id={`kt_stats_widget_1_tab_${activeTab}`}>
                            {/*begin::Table container*/}
                            <div class="table-responsive">
                                {/*begin::Table*/}
                                <table class="table align-middle gs-0 gy-4 my-0">
                                    {/*begin::Table head*/}
                                    <thead>
                                        <tr class="fs-7 fw-bold text-gray-500">
                                            <th class="p-0 min-w-50px pt-3">Amenites</th>
                                            <th class="p-0 min-w-50px  pt-3">Type</th>
                                            <th class="pe-0 text-end min-w-50px pt-3">Actions</th>
                                        </tr>
                                    </thead>
                                    {/*end::Table head*/}
                                    {/*begin::Table body*/}
                                    <tbody>
                                        {data?.[activeTab]?.types && (Object.keys(data?.[activeTab]?.types).map((value) => (
                                            <tr>
                                                <td>
                                                    <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{value}</a>
                                                </td>
                                                <td>
                                                    <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{data?.[activeTab]?.types?.[value]?.type}</a>
                                                </td>
                                                <td class="text-end">
                                                {/* <KTIcon iconName='pencil' className='fs-3 me-3'/>
                                                <KTIcon iconName='trash' className='fs-3' /> */}
                                                    <span onClick={()=> handleEdit(data?.[activeTab]?.category,value, data?.[activeTab]?.["types"]?.[value])}><KTIcon iconName='pencil' className='fs-3 me-3'/></span>
                                                    <span onClick={()=> handleVerify(data?.[activeTab]?.category,value)}><KTIcon iconName='trash' className='fs-3' /></span>
                                                </td>
                                            </tr>
                                        )))}
                                    </tbody>
                                    {/*end::Table body*/}
                                </table>
                                {/*end::Table*/}
                            </div>
                            {/*end::Table container*/}
                        </div>
                        {/*end::Tap pane*/}
                    </div>
                    {/*end::Tab Content*/}
                </div>
          </div>
        </div>
      </div>
      {showNewRecord && (
        <AddNewLookupModal
            show = {showNewRecord}
            onHide = {()=>{
                setShowNewRecord(false)
                setEditIndex('')
                setEditValue('')
                getData()
            }}
            title = {title}
            data={data}
            editIndex = {editIndex}
            editValue = {editValue}
            editData = {editData}
        />
      )}
      {show && (
        <VerifyModal
            show={show}
            title={modalTitle}
            body={modalBody}
            onHide={()=> setShow(false)}
            OnSuccess={() => {
                setShow(false)
                handleDelete()
            }}
         />
      )}
      {showLoader && <PageLoader />}
    </div>
    )
}
export default AmenitiesLookups;