import { useEffect, useState } from "react"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Moment from 'moment';
import { getUserListings } from "../../services/api";
import { KTIcon } from "../../../../../_metronic/helpers";
import { PageLoader } from "../../services/PageLoader";
 


const UserListings = ({userId}) => {
    const [data, setData] = useState<any>([]);
    const [loader, setLoader] = useState<any>(false);
    const [pagedData, setPagedData] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [pageCount, setPageCount] = useState<any>(0);
    const [searchedData, setSearchedData] = useState<any>([]);
    const [page, setPage] = useState<any>(1)
    const PAGE_COUNT = 20;

    const getData = async() => {
        setLoader(true)
        try {
            const res = await getUserListings(userId);
            const listingsData = res?.data;
            setData(listingsData);
            setPage(1);
            setPageCount(Math.ceil(listingsData?.length/PAGE_COUNT));
            setPagedData([...listingsData.slice(0,PAGE_COUNT)]);
        } catch (error:any) {
            console.log(error);
            alert(error?.response?.data?.message);
        }
        setLoader(false)
    }

    useEffect(() => {
        getData();
    }, [])

    const handleChange = async (event:any, value:any) => {
        const sliceStartValue = (value-1) * PAGE_COUNT;
        setPage(value)
        if (searchTerm) {
            setPagedData([...searchedData?.slice(sliceStartValue, (PAGE_COUNT * value) )]);
        }else {
            setPagedData([...data?.slice(sliceStartValue, ((PAGE_COUNT * value)))]);
        }
    };

    const handleSearch = (e : any) => {
		setSearchTerm(e.target.value)
        if (e?.target?.value) {
            const reqData = data?.filter((each : any) => {
                if ( ((each?.location_id?.toLowerCase())?.includes((e.target.value).toLowerCase())) ||
                    ((each?.basic_info?.property_name?.toLowerCase())?.includes((e.target.value).toLowerCase())) ||
                    ((each?.status?.toLowerCase())?.includes((e.target.value).toLowerCase())) ||
                    ((each?.subscription_id?.toLowerCase())?.includes((e.target.value).toLowerCase())) )
                    {
                    return each
                }
            })
            setPageCount(Math.ceil(reqData?.length/PAGE_COUNT));
            setSearchedData([...reqData]);
            setPagedData([...reqData?.slice(0, PAGE_COUNT)]);
        } else {
            setPageCount(Math.ceil(data?.length/PAGE_COUNT));
            setSearchedData([]);
            setPagedData([...data?.slice(0, PAGE_COUNT)]);
        }
        setPage(1)
	}

    return (
        <>
        { data?.length > 0 ? (
            <div className="card">
                <div className="card-header border-0 pt-6">
                    <div className='card-title'>
                        {/* begin::Search */}
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                className='form-control form-control-solid w-250px ps-14'
                                placeholder='Search Listings'
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                        {/* end::Search */}
                    </div>
                    <div className="card-toolbar">
                        {/* <BookingListFilter filterFormData={filterData} /> */}
                    </div>
                </div>

                <div>
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        {/* <th className='w-25px'>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value='1'
                                                    data-kt-check='true'
                                                    data-kt-check-target='.widget-13-check'
                                                />
                                            </div>
                                        </th> */}
                                        <th className='min-w-100px'>Location Id</th>
                                        <th className='min-w-100px'>Location Name</th>
                                        <th className='min-w-100px'>Status</th>
                                        <th className='min-w-100px'>Created Date</th>
                                        <th className='min-w-140px'>Last Updated Date</th>
                                        <th className='min-w-140px'>Plan</th>
                                        {/* <th className='min-w-100px'>Action</th> */}
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {pagedData?.map((item : any, index :any) => {
                                        return (
                                            <tr>
                                                {/* <td>
                                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                        <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                    </div>
                                                </td> */}
                        
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {item?.location_id}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {item?.basic_info?.property_name}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {item?.status}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {Moment(item.createdAt).format('DD-MM-YYYY')}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {Moment(item.updatedAt).format('DD-MM-YYYY')}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {item?.subscription_id}
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' >
                                                    <button className="btn btn-light btn-active-primary btn-flex btn-center btn-sm" onClick = {() => onClickViewDetails(item)}> View </button>
                                                    </div>
                                                </td> */}
                                            </tr>

                                        )
                                    })}


                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                    <Stack spacing={2}>
                        <Pagination page = {page} count={pageCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                    </Stack>
                </div>
            </div>
        ) : (
            <div className="card">
                <div className="card-header border-0 pt-6 d-flex justify-content-center">
                    <span className="" style={{fontSize: "24px"}} > No Requests </span>
                </div>
            </div>
        )

        }
            {/* {show && (
                <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Booking Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column">
                            {bookingRequest(modalData)}
                        </div>
                    </Modal.Body>
                </Modal>
            )} */}
            {loader && <PageLoader/> }
        </>
    )
} 

export default UserListings