import { useState } from 'react';
import { updateTag } from '../../services/api';
import Modal from 'react-bootstrap/Modal';
import { PageLoader } from '../../services/PageLoader';

const UpdataTagModal = ({show,onHide,title,data}) => {
    const [formData,setFormData] = useState<any>({...data,name : ''})
    const [loader,setLoader] = useState(false)

    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value.trimStart()
        })
    }
    const submitForm = async(e) => {
        e.preventDefault()
        setLoader(true)
        try{
            if (data?.name?.trim() === formData?.name?.trim()) {
                alert("Old Activity Name and New Activity Name cannot be same")
                setLoader(false)
                return null
            }
            const resp = await updateTag({...formData,name : formData.name.trim(),oldName : data?.name});
            alert(resp?.data?.message)
            // document.getElementById('tagManagementAddTag').reset()
            onHide()
        }catch(error : any){
            console.log(error)
            alert(error?.response?.data?.message)
        }
        setLoader(false)
    }
    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={submitForm} id='tagManagementAddTag'>
                <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold mb-2 required">Activity Type</label><br/>
                        <select
                            className='form-select form-select-solid fw-bolder'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            data-kt-user-table-filter='role'
                            data-hide-search='true'
                            onChange={onChange}
                            value={data?.type}
                            name = "type"
                            disabled
                        >
                            <option selected hidden value = "">Select Type</option>
                            <option value = "film" >  FILM/TV SHOOTINGS </option>
                            {/* <option value= "event"> Event </option> */}
                            <option value= "corporateEvent"> CORPORATE EVENTS </option>
                            <option value= "individualEvent"> PERSONAL EVENTS </option>
                            <option value="stay"> STAYCATIONS </option>
                        </select>
                    </div>
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold mb-2">Old Activity Name</label>
                        <input type="text" disabled className="form-control form-control-solid" placeholder="Enter Activity Name" name="name" value={data?.name} />
                    </div>
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold mb-2 required">New Activity Name</label>
                        <input type="text" required onChange={onChange} className="form-control form-control-solid" placeholder="Enter New Activity Name" name="name" value={formData?.name} />
                    </div>
                    <div className="modal-footer flex-end">
                        <button type="submit" className="btn btn-primary">
                            <span className="indicator-label">Submit</span>
                        </button>
                    </div>
                </form>
                {loader && <PageLoader/>}
            </Modal.Body>
        </Modal>
    )
}
export default UpdataTagModal;