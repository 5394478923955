import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'
import { getLocationTypes } from '../../../services/api';

type Props = {
  locationData?: any
}

const Details: FC<Props> = ({locationData}) => {
  const [locationTypesData, setLocationTypesData] = useState<any>([]);
  const [description,SetDescription] = useState('');

  useEffect(() => {
		const initLocationTypes = async () => {
			const data = await getLocationTypes();
      const options = data.map((item) => ({
        value: item.value,
        label: item.name,
        }));

			setLocationTypesData(options);
		};
		initLocationTypes();
    if(locationData?.property_desc?.property_info){
      SetDescription(locationData?.property_desc?.property_info)
    }
	}, [locationData]);


  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Details & Description</h2>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Name of the Location</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='property_desc.property_name'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='property_desc.property_name' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Location Type</label>

        <Field
          as='select'
          name='property_desc.location_type'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          {locationTypesData &&
            locationTypesData.map((locationType, index) => (
                                <option key={index} value={locationType.value}>{locationType.label}</option>
                            ))}
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='property_desc.locationType' />
        </div>
      </div>

      <div className='mb-10 fv-row d-flex'>
        <div className="col-lg-6 m-1">
          <div className="mb-4">
            <label className='form-label mb-3'>Property Size</label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='property_desc.property_size'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='property_desc.property_size' />
            </div>
          </div>
        </div>
        <div className="col-lg-6 m-1">
          <div className="mb-4">
            <label className='form-label mb-3'> Area type</label>
            <Field
                as='select'
                name='property_desc.property_size_area'
                className='form-select form-select-lg form-select-solid'
              >
                <option value='' disabled selected hidden></option>
                <option value='Sq.Ft'>Sq.Ft</option>
                <option value='Sq.Mts'>Sq.Mts</option>
                <option value='Sq.Yds'>Sq.Yds</option>
              </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='property_desc.property_size_area' />
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-stack mb-2'>
        <div className='me-5'>
          <label className='fs-6 fw-bold form-label'>In-House Parking Facility Available</label>
        </div>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <Field className='form-select form-select-lg form-select-solid'
                as='select'
                name="property_desc.house_parking">
              <option value="yes">Yes</option>
              <option value="no">No</option>
          </Field>
        </label>
      </div>

      <div className='d-flex flex-stack mb-2'>
        <div className='me-5'>
          <label className='fs-6 fw-bold form-label'>Street Parking Facility Available</label>
        </div>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <Field className='form-select form-select-lg form-select-solid'
                as='select'
                name="property_desc.street_parking">
              <option value="yes">Yes</option>
              <option value="no">No</option>
          </Field>
        </label>
      </div>

      <div className='d-flex flex-stack mb-2'>
        <div className='me-5'>
          <label className='fs-6 fw-bold form-label'>Security Camera Available</label>
        </div>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <Field className='form-select form-select-lg form-select-solid'
                as='select'
                name="property_desc.security_camera">
              <option value="yes">Yes</option>
              <option value="no">No</option>
          </Field>
        </label>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Description of the location</label>

        <textarea
          className='form-control form-control-lg form-control-solid'
          name='property_desc.property_info'
          rows={6}
          cols={50}
          value = {description}
          onChange={(e) => {
            locationData.property_desc.property_info = e.target.value
            SetDescription(e.target.value)
          }}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='property_desc.property_info' />
        </div>
      </div>

    </div>
  )
}

export {Details}
