import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import NotificationTemplateList from './list/NotificationTemplateList'
import AddNotificationTemplate from './AddNotificationTemplate'
import EditNotificationTemplate from './EditNotificationTemplate'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Notification Management',
    path: '/apps/notification/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EmailTemplatePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Notification Template list</PageTitle>
              <NotificationTemplateList />
            </>
          }
        />
        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Create Notification Template</PageTitle>
              <AddNotificationTemplate />
            </>
          }
        />
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Edit Notification Template</PageTitle>
              <EditNotificationTemplate />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/notification/list' />} />
    </Routes>
  )
}

export default EmailTemplatePage
