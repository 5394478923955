import { useState,useEffect} from "react";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import info from "../../../../../_metronic/assets/images/i.svg";
import "./ListYourSpace"

const PropertyManagerDetails =  ({data,handleBack,handleNext, handleSubmit, isSubPropertyActive, editChanges}) => {


    const [details, setDetails] = useState({
        contact_person_name :"",
        contact_person_designation : "",
        contact_person_number : "",
        contact_person_email : "",
        alternate_person_name :"",
        alternate_person_number : "",
        alternate_person_email : "",
    })

    const [numberError, setNumberError] = useState("");
    const [alternateNumberError, setAlternateNumberError] = useState("");

    const onClickSubmit = (e) => {
        e.preventDefault()
        setNumberError((prev) => '');
        setAlternateNumberError((prev) => '');

        let phoneNumberError = false
        let altNumberError = false

         //check phone number validation
         if (details?.contact_person_number === undefined) {
            phoneNumberError = true
             setNumberError("Enter phone number")
        } 
        if(!phoneNumberError){
            if (details?.contact_person_number.slice(0,4) === "+911" || !isValidPhoneNumber(details?.contact_person_number)) {
                phoneNumberError = true
                setNumberError((prev) => "Enter valid phone number")
            } 
        }
        //

        //check alt phone number validation
        let reqAltNumber = ""
        if (details?.alternate_person_number !== undefined) reqAltNumber = details?.alternate_person_number
        if(reqAltNumber.length > 0){
            if (reqAltNumber.slice(0,4) === "+911" || !isValidPhoneNumber(details?.alternate_person_number)) {
                altNumberError = true
                setAlternateNumberError((prev) => "Enter valid phone number")
            } 
        }
        //

        //if no error handle below function
        if(!phoneNumberError && !altNumberError) {
            if (!isSubPropertyActive) {
                handleSubmit({property_manager_details : {...details, alternate_person_number : reqAltNumber}})
            }
            else {
                handleNext({property_manager_details : {...details, alternate_person_number : reqAltNumber}})
            }
        }

    }

    useEffect(() => {
        if(data){
            setDetails(data)    
        }
    },[data])

    return(
        <>
        <div className="locationtype-page container-fluid " >
        <h1 className="locationtype-head" > Property Manager Details {' '}
                <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title="Provide contact info for effective communication with the property owner"
                >
                    <img src={info} />
                </a>
            </h1>
        </div>
        <form className=" mx-4 mt-1 tabCard row" onSubmit={onClickSubmit}>
            <div className="d-flex flex-column mt-1 mb-2 col-lg-6">
                <label htmlFor="manager_name" className="form-label">
                    Contact Person Name <span className="required">*</span> : 
                    { editChanges?.contact_person_name ? <span className="text-danger" > (Edited) </span> : "" }
                </label>
                <input
                    type="text"
                    required
                    placeholder="Enter name"
                    id="manager_name"
                    name="contact_person_name"
                    className="textFiled "
                    value={details?.contact_person_name}
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            contact_person_name : e.target.value
                        })
                    }
                />
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="manager_designation" className="form-label">
                Contact Person Designation <span className="required">*</span> : 
                { editChanges?.contact_person_designation ? <span className="text-danger" > (Edited) </span> : "" }
                </label>
                <select 
                    value = {details?.contact_person_designation}
                    required
                    name = "contact_person_designation"
                    className="textFiledSelect"
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            contact_person_designation : e.target.value
                        })
                    }
                >
                    <option value="" selected hidden > Select Designation </option>
                    <option value = "Property Owner"> Property Owner </option>
                    <option value = "Agent"> Agent </option>
                    <option value = "Manager"> Manager</option>
                    <option value = "Housing Association"> Housing Association </option>
                    <option value = "Tenant"> Tenant  </option>
                </select>
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="manager_number" className="form-label">
                    Phone Number <span className="required">*</span> : 
                    { editChanges?.contact_person_number ? <span className="text-danger" > (Edited) </span> : "" }
                </label>
                 <PhoneInput
                    required
                    international
                    countryCallingCodeEditable={false}
                    flags
                    placeholder="Enter phone number"
                    style={{height:"35px", border : "gray solid 1px", marginBottom : "2px", paddingLeft:"13px", borderRadius :"5px"}}
                    value={details?.contact_person_number}
                    defaultCountry="IN"
                    onChange={(e) => {
                        setDetails({
                            ...details,
                            contact_person_number : e
                            })
                        }  
                    }
                    />
                    {
                        numberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {numberError} </p> 
                    }
                </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="manager_email" className="form-label">
                    Email Address <span className="required">*</span> : 
                    { editChanges?.contact_person_email ? <span className="text-danger" > (Edited) </span> : "" }
                </label>
                <input
                    type="email"
                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                    required
                    placeholder="Enter email"
                    id="manager_email"
                    name="contact_person_email"
                    className="textFiled "
                    value={details?.contact_person_email}
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            contact_person_email : e.target.value
                        })
                    }
                />
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="alternate_name" className="form-label">
                Alternate Person's Name : 
                { editChanges?.alternate_person_name ? <span className="text-danger" > (Edited) </span> : "" }
                </label>
                <input
                    type="text"
                    placeholder="Enter name"
                    id="alternate_name"
                    name="alternate_person_name"
                    className="textFiled "
                    value={details?.alternate_person_name}
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            alternate_person_name : e.target.value
                        })
                    }
                />
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="alternate_number" className="form-label">
                    Alternate Person's Mobile Number : 
                    { editChanges?.alternate_person_number ? <span className="text-danger" > (Edited) </span> : "" }
                </label>
                <PhoneInput
                    // required
                    international
                    countryCallingCodeEditable={false}
                    flags
                    placeholder="Enter phone number"
                    style={{height:"35px", border : "gray solid 1px", marginBottom : "2px", paddingLeft:"13px"}}
                    value={details?.alternate_person_number}
                    defaultCountry="IN"
                    onChange={(e) => {
                        setDetails({
                            ...details,
                            alternate_person_number : e
                        })
                    }  
                 }
                 />
                {
                    alternateNumberError && <p style={{color:"Red", fontSize:"14.5px"}} className="m-0" > {alternateNumberError} </p> 
                }
            </div>
            <div className="col-lg-6" ></div>
            <div className="d-flex flex-column col-lg-6 mt-1 mb-2">
                <label htmlFor="manager_name" className="form-label">
                    Alternate Person's Email: 
                    { editChanges?.alternate_person_email ? <span className="text-danger" > (Edited) </span> : "" }
                </label>
                <input
                    type="email"
                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
                    placeholder="Enter email"
                    id="manager_name"
                    name="alternate_person_email"
                    className="textFiled "
                    value={details?.alternate_person_email}
                    onChange={(e) => 
                        setDetails({
                            ...details,
                            alternate_person_email : e.target.value
                        })
                    }
                />
            </div>
        {/* Save & Continue start */}
        <div className="controlContainer">
        <div>
            <button
            type="button"
            class="btn btn-outline-danger"
            onClick={handleBack}
            >
                Back
            </button>
        </div>
        <div>
            <button
            type="submit"
            className="btn btn-primary"
            >
                 {isSubPropertyActive === false ?  "Submit" : "Save & Continue"}
            </button>
        </div>
        </div>
        {/* Save & Continue End   */}
        </form>
        </>
    )
}

export default  PropertyManagerDetails

