import { useState, useEffect} from "react";

// Images
import info from "../../../../../_metronic/assets/images/i.svg";

const BasicInfo = ({edited, data,handleBack,handleNext, editChanges}) => {
    const [formData,setFormData] = useState({
        area : 'Sq.Ft',
        house_parking : false,
        street_parking : false,
        valet_parking : false,
        security_camera : false,
        description : '',
        no_of_cars : '',
    });
    const [pageRendered,setPageRendered] = useState(false);
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({...formData,[name] : value})
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        handleNext({basic_info :formData});
    }
    useEffect(() => {
        if(data) setFormData(data)
        if(!data) setPageRendered(true)
        const element = document.getElementById(`listBasicInfo`)
        if (element) element.scrollIntoView({behavior: "smooth",block: "start"});
    },[data])

    // useEffect(() => {
    //     if(pageRendered){
    //         document.getElementById("parking").setCustomValidity("Select any one of the options");
    //         document.getElementById("street_parking").setCustomValidity("Select any one of the options");
    //         // document.getElementById("valet_parking").setCustomValidity("Select any one of the options");
    //         document.getElementById("security_camera").setCustomValidity("Select any one of the options");
    //     }
    // },[pageRendered])

    return(
        <form onSubmit={handleSubmit}>
        <div className="locationtype-page" >
            <h1 className="locationtype-head" id='listBasicInfo'> Basic Information {' '}
                <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title="Name, size, parking; be captivating and informatively concise"
                >
                    <img src={info} />
                </a>
            </h1>
            <div className="row tabCard">
                <div className="col-lg-8">
                    <div className="mb-4 d-flex flex-column">
                        <label htmlFor="property_name" className="form-label required">
                            Property Name
                            { editChanges?.property_name ? <span className="text-danger" > (Edited) </span> : "" }
                        </label>
                        <input
                            type="text"
                            required
                            disabled = {edited}
                            placeholder="Enter Property Name"
                            id="property_name"
                            name="property_name"
                            className="textFiled"
                            value={formData?.property_name}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="mb-4 d-flex flex-column">
                        <label htmlFor="property_size" className="form-label required">
                            Property Size
                            { editChanges?.property_size ? <span className="text-danger" > (Edited) </span> : "" }
                        </label>
                        <div class="buttonIn">
                            <input
                                type="number"
                                min='1'
                                required
                                placeholder="00"
                                id="property_size"
                                name="property_size"
                                className="textFiled"
                                style={{width : '100%'}}
                                value={formData?.property_size}
                                onChange={onChange}
                            />
                            <div className='propertySizeButtonContainer'>
                            <button type="button" onClick={()=> setFormData({...formData,area : 'Sq.Ft'})} className={formData.area === 'Sq.Ft' ?"propertySizeButtonActive" :  "propertySizeButton"}>Sq.Ft</button>
                            <button type="button" onClick={()=> setFormData({...formData,area : 'Sq.Mts'})} className={formData.area === 'Sq.Mts' ?"propertySizeButtonActive" :  "propertySizeButton"}>Sq.Mts</button>
                            <button type="button" onClick={()=> setFormData({...formData,area : 'Sq.Yds'})} className={formData.area === 'Sq.Yds' ?"propertySizeButtonActive" :  "propertySizeButton"}>Sq.Yds</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-4"></div> */}
                <div className="col-lg-8">
                    <div className="d-flex justify-content-between flex-wrap align-items-center mb-4">
                        <label
                            htmlFor="house_parking"
                            className="form-label required"
                            >
                            In-House Parking Facility Available
                            { editChanges?.house_parking ? <span className="text-danger" > (Edited) </span> : "" }
                        </label>
                        <div className="d-flex">
                            <div className="form-check me-3">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id="parking"
                                name="house_parking"
                                onChange={() =>
                                    setFormData({
                                    ...formData,
                                    house_parking: true,
                                    })
                                }
                                checked={formData?.house_parking}
                                />
                                <label
                                className="form-check-label"
                                htmlFor="parking"
                                >
                                Yes
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                name="house_parking"
                                id="parking2"
                                onChange={() =>
                                    setFormData({
                                    ...formData,
                                    house_parking: false,
                                    })
                                }
                                checked={!formData?.house_parking}
                                />
                                <label
                                className="form-check-label"
                                htmlFor="parking2"
                                >
                                No
                                </label>
                            </div>
                        </div>
                    </div>
                    {formData?.house_parking && (
                    <>
                        <div className="col-lg-12">
                            <div className="mb-4 d-flex flex-column">
                                <label htmlFor="no_of_cars" className="form-label required">
                                How many cars are allowed to park in this area?
                                { editChanges?.no_of_cars ? <span className="text-danger" > (Edited) </span> : "" }
                                </label>
                                <input
                                    type="number"
                                    min={1}
                                    required = {formData?.house_parking ? true : false}
                                    placeholder="Enter No of Cars"
                                    id="no_of_cars"
                                    name="no_of_cars"
                                    className="textFiled"
                                    value={formData?.no_of_cars}
                                    onChange={onChange}
                                />
                            </div>
                        </div>  
                        {/* <div className="col-lg-4"></div> */}
                    </>
                )}
                </div>
                <div className="col-lg-8">
                    <div className="d-flex justify-content-between flex-wrap align-items-center mb-4">
                        <label
                        htmlFor="street_parking"
                        className="form-label required"
                        >
                       Street Parking Facility Available
                       { editChanges?.street_parking ? <span className="text-danger" > (Edited) </span> : "" }
                        </label>
                        <div className="d-flex">
                        <div className="form-check me-3">
                            <input
                            type="checkbox"
                            className="form-check-input"
                            id="street_parking"
                            name="street_parking"
                            onChange={() =>
                                setFormData({
                                ...formData,
                                street_parking: true,
                                })
                            }
                            checked={formData?.street_parking}
                            />
                            <label
                            className="form-check-label"
                            htmlFor="street_parking"
                            >
                            Yes
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                            type="checkbox"
                            className="form-check-input"
                            name="street_parking"
                            id="street_parking2"
                            onChange={() =>
                                setFormData({
                                ...formData,
                                street_parking: false,
                                })
                            }
                            checked={!formData?.street_parking}
                            />
                            <label
                            className="form-check-label"
                            htmlFor="street_parking2"
                            >
                            No
                            </label>
                        </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-4"></div> */}
                <div className="col-lg-8">
                      <div className="d-flex justify-content-between flex-wrap align-items-center mb-4">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label required"
                        >
                          Security Camera Available
                          { editChanges?.security_camera ? <span className="text-danger" > (Edited) </span> : "" }
                        </label>
                        <div className="d-flex">
                          <div className="form-check me-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="security_camera"
                              id="security_camera"
                              onChange={() =>
                                setFormData({
                                  ...formData,
                                  security_camera: true,
                                })
                              }
                              checked={formData.security_camera}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="security_camera"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="security_camera"
                              id="security_camera2"
                              onChange={() =>
                                setFormData({
                                  ...formData,
                                  security_camera: false,
                                })
                              }
                              checked={!formData.security_camera}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="security_camera2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                <div className="col-lg-8">
                    <div className="d-flex justify-content-between flex-wrap align-items-center mb-4">
                    <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                    >
                        Valet Parking Available
                        { editChanges?.valet_parking ? <span className="text-danger" > (Edited) </span> : "" }
                    </label>
                    <div className="d-flex">
                        <div className="form-check me-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="valet_parking"
                            id="valet_parking"
                            onChange={() =>
                            setFormData({
                                ...formData,
                                valet_parking: true,
                            })
                            }
                            checked={formData.valet_parking}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="valet_parking"
                        >
                            Yes
                        </label>
                        </div>
                        <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="valet_parking"
                            id="valet_parking2"
                            onChange={() =>
                            setFormData({
                                ...formData,
                                valet_parking: false,
                            })
                            }
                            checked={formData.valet_parking === false}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="valet_parking2"
                        >
                            No
                        </label>
                        </div>
                    </div>
                    </div>
                </div>
                {/* <div className="col-lg-4"></div> */}
                <div className="col-lg-8">
                    <div className="d-flex flex-column mb-4">
                        <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                        >
                            Description of the Location
                            { editChanges?.description ? <span className="text-danger" > (Edited) </span> : "" }
                        </label>
                        <textarea
                            className="basicInfoDescription"
                            placeholder="Enter Description"
                            id="exampleFormControlTextarea1"
                            rows='5'
                            name='description'
                            onChange={(e) => {
                            setFormData({
                                ...formData,
                                description: e.target.value,
                            });
                            }}
                            value={formData.description}
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div className="controlContainer">
            <div>
                <button
                type="button"
                class="btn btn-outline-danger"
                onClick={handleBack}
                >
                    Back
                </button>
            </div>
            <div>
                <button
                type="submit"
                className="btn btn-primary"
                >
                    Save & Continue
                </button>
            </div>
        </div>
        </form>
    )
}
export default BasicInfo