/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { useEffect, useState } from "react";
import { getTopRatedProperties,updateTopRatedProperties,deleteTopRatedProperties} from "../../services/api";
import { PageLoader } from "../../services/PageLoader";
import SortableList, { SortableItem } from 'react-easy-sort'
import {arrayMoveImmutable} from 'array-move'
import { Radio } from '@mui/material'
import { useNavigate } from "react-router-dom";
import {Modal,Button} from 'react-bootstrap'
import { useAuth } from '../../../auth';

const TopRatedList = () => {
  const {currentUser,getSideBarCount} = useAuth()
  const navigate = useNavigate();
    const [locations, setLocaions] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [featuredList, setFeaturedList] = useState<any>([]);
    const [tile,setTile] = useState<any>('toprated')
    const [showLoader, setShowLoader] = useState(false)
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');

    const getData = async () => {
    try{
        const resp = await getTopRatedProperties();
        setData(resp?.data?.data)
        setFeaturedList(resp?.data?.data?.locations)
        setLocaions(resp?.data?.locations)
        setShowLoader(false)
    }catch(error:any){
        console.log(error?.response?.data?.message)
    }
    }

    useEffect(() => {
        setShowLoader(true)
        getData();
    }, []);

    const onChangeTile = (tile) => {
      setTile(tile)
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            settings : {
                ...data.settings,
                [name] : value,
            }
        })
    }

  const updateData = async() => {
    try{
      setShowLoader(true)
      data.locations = featuredList
      const resp = await updateTopRatedProperties(data)
      getData()
      setModelBody(resp?.data?.message)
      setShow(true)
      setShowLoader(false)
    }catch(error:any){
      console.log(error?.response?.data?.message)
      setShowLoader(false)
    }
  }

  const removeProperty = async(locationId) => {
    try{
      setShowLoader(true)
      const resp : any = await deleteTopRatedProperties(locationId)
      getData()
      getSideBarCount()
      setModelBody(resp?.data?.message)
      setShow(true)
      setShowLoader(false)
    }catch(error:any){
      console.log(error?.response?.data?.message)
      setShowLoader(false)
    }
  } 
  const addProperties = ()=> {
    navigate(`/apps/location-management/approved`)
  }

  const onSortEnd = (oldIndex, newIndex) => {
      setFeaturedList((array) => arrayMoveImmutable(array, oldIndex, newIndex))
      setLocaions((array) => arrayMoveImmutable(array, oldIndex, newIndex))
    }
  
  const toprated = () => {
    return(
      <div className=''>
          {currentUser?.permissions?.Top_Rated_Locations?.write && (<div className="container">
            <div className="row">
              <div className="col-lg-3 mb-2">
                  <div className="d-flex align-items-center">
                      <p className="form-label mb-3">
                          Autoplay
                      </p>
                      <div className='d-flex'>
                          <div className="d-flex align-items-center">
                          <Radio
                              name="autoPlay"
                              value='active'
                              id = 'active'
                              checked={data?.settings?.autoPlay === 'active'}
                              onChange={onChange}
                          />
                          <div>
                          <label htmlFor="active">On</label>
                          </div>
                          </div>
                          <div className="d-flex align-items-center">
                              <Radio
                                  name="autoPlay"
                                  id = 'deactive'
                                  value='deactive'
                                  checked={data?.settings?.autoPlay === 'deactive'}
                                  onChange={onChange}
                                  />
                              <div>
                              <label htmlFor="deactive" >Off</label>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-lg-3 mb-2">
                  <div className="d-flex align-items-center">
                    <label className="form-label" htmlFor="interval">
                        Interval
                    </label>
                    <input
                        type="number"
                        value = {data?.settings?.interval}
                        id="interval"
                        className="form-control form-control-lg form-control-solid"
                        name="interval"
                        placeholder='time in seconds'
                        onChange={onChange}
                    />
                  </div>
              </div>
              <div className="col-lg-2 mb-2"></div>
              <div className="col-lg-4 mb-2 d-flex flex-row justify-content-end" style={{height : '45px'}}>
                <button onClick={addProperties} type='button' className='btn btn-light-primary me-3'>Add Properties</button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={updateData}
                >
                  Save
                </button>
              </div>
            </div>
          </div>)}
          <SortableList onSortEnd={onSortEnd} className="row g-5 g-xl-8">
          {locations?.map((location,i)=> {
              return(
                  <SortableItem key={i} >
                  <div className='col-xl-12'>
                      <div className="card card-xl-stretch mb-xl-8">
                          {/* begin::Body */}
                          <div className='card-body d-flex align-items-center pt-3 pb-0' style={{paddingRight : '0px'}}>
                              <div className='d-flex flex-column flex-grow-1 py-2 py-lg-7 me-2'>
                              <div className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
                                  {i+1}.{'  '}{location.location_id}
                                  {currentUser?.permissions?.Top_Rated_Locations?.delete && (<div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => removeProperty(location?.location_id)}
                                    title="Remove from featured properties">
                                    <KTIcon iconName='trash' className='fs-3' />
                                  </div>)}
                              </div>
                              <span
                                  className='fw-semibold text-muted fs-5'
                              >{location?.basic_info?.property_name}</span>
                              <span
                                  className='fw-semibold text-muted fs-5'
                              >{location?.location_type}</span>
                              <span
                                  className='fw-semibold text-muted fs-5'
                              >{location?.address?.area}{location?.address?.city}</span>
                              </div>
                              <div>
                                <img src={toAbsoluteUrl(location?.images[0]?.image)} alt='image' className='align-self-end h-100px w-100px rounded' />
                              </div>
                          </div>
                          {/* end::Body */}
                      </div>
                  </div>
                  </SortableItem>
              )
          })}
          </SortableList>
      </div>
    )
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              {/* <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metornic' />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div> */}
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item nav-link text-active-primary me-6 '>
                <div
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (tile === 'toprated' && 'active')
                  }
                  onClick={() => onChangeTile('toprated')}
                >
                  Exclusive Properties
                </div>
              </li>
              {/* <li className='nav-item nav-link text-active-primary me-6 '>
                < div
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (tile === 'jobrequests' && 'active')
                  }
                  onClick={() => onChangeTile('jobrequests')}
                >
                  Job Requests
                </div>
              </li> */}
              {/* <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/campaigns' && 'active')
                  }
                  to='/crafted/pages/profile/campaigns'
                >
                  Campaigns
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/documents' && 'active')
                  }
                  to='/crafted/pages/profile/documents'
                >
                  Documents
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/connections' && 'active')
                  }
                  to='/crafted/pages/profile/connections'
                >
                  Connections
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      {tile === 'toprated' && toprated()}
      <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Top rated Locations</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modelBody}</Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={() => setShow(false)}>
                Ok
            </Button>
            </Modal.Footer>
        </Modal>
      {showLoader && <PageLoader />}
    </>
  );
};

export default TopRatedList;
