import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function VerifyModal({show,title,body,onHide,OnSuccess}) {

  return (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
        Cancel
        </Button>
        <Button variant="primary" onClick={OnSuccess}>
        Continue
        </Button>
    </Modal.Footer>
    </Modal>
  );
}

export default VerifyModal;