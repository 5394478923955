import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ListingDetailsModal({show,title,data,onHide}) {

  return (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <p><strong>Updated BY :</strong>{" "}{data?.updatedBy}</p>
        <p><strong>Status :</strong>{" "}{data?.status}</p>
        <p><strong>Reason :</strong>{" "}{data?.reason}</p>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
        Cancel
        </Button>
        <Button variant="primary" onClick={onHide}>
        OK
        </Button>
    </Modal.Footer>
    </Modal>
  );
}

export default ListingDetailsModal;