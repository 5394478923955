import { useEffect, useState ,useRef} from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { createCoupon,getCouponDetails ,updateCoupon} from "../services/api";
import { Formik } from "formik";
import * as yup from "yup";
import {Form} from "react-bootstrap";
import { PageLoader } from "../services/PageLoader";

import {Radio } from '@mui/material'

const Createcoupon = () => {
  const params = useParams()
  const navigate = useNavigate();
  const couponId = params.couponId
  const formikRef = useRef<any>();
  const [formData, setFormData] = useState<any>({
    createdBy : "Admin",
    discountType: 'Amount',
    couponType : 'multi',
  });

  const schema = yup.object().shape({
    couponName: yup.string().required('Coupon name is required'),
    couponId: yup.string().required('Coupon id is required'),
    couponValue: yup.string().required('Coupon value is required'),
    minimunBooking: yup.string().required('Minimum booking is required'),
    activationDate: yup.string().required('Activation date is required'),
    expiryDate: yup.string().required('Expiry date is required'),
    propertyId: (formData.couponType === 'single') ? yup.string().required('Property Id is required') : yup.string(),
    userEmailId: (formData.couponType === 'single') ? yup.string().email('Invalid email').required('User Email Id is required') : yup.string(),
  });

  const [showLoader, setShowLoader] = useState(false)

  const [initialValues, setInitialValues] = useState({
    couponName: "",
    couponId: "",
    couponValue: "",
    minimunBooking: "",
    activationDate: "",
    expiryDate: "",
    propertyId: "",
    userEmailId: "",
  });

  const [error, setError] = useState<boolean>(false);


  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const onChangeType = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError(false)
  };

  const handleChangeDiscountType = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCreateCoupon = async (formValues) => {
    if(new Date(formValues.expiryDate) < new Date(formValues.activationDate)){
      alert('Expiry date should be in feature')
      return  null
    }
    try{
      const data = {...formData,...formValues,couponId : formValues.couponId.toUpperCase()}
      let res;

      if(couponId){
        res = await updateCoupon(data);
      }else{
        res = await createCoupon(data);
      }
      alert(res.data?.message)
      navigate("/apps/coupon");
    }catch(error : any){
        console.log(error.response.data?.message)
        alert(error.response.data?.message)
    }
  };

   //console.log(formData);

  useEffect(() => {
    const getCoupon = async () => {
      try{
        const res = await getCouponDetails(couponId);
        setFormData({
          createdBy : res.data?.createdBy,
          discountType: res.data?.discountType,
          couponType : res.data?.couponType,
          _id : couponId,
        })
        setInitialValues({
          couponName: res.data?.couponName,
          couponId: res.data?.couponId,
          couponValue: res.data?.couponValue,
          minimunBooking: res.data?.minimunBooking,
          activationDate: res.data?.activationDate,
          expiryDate: res.data?.expiryDate,
          propertyId: res.data?.propertyId,
          userEmailId: res.data?.userEmailId,
        })
        setShowLoader(false)
        if (formikRef.current) {
          formikRef.current.setFieldValue(
            "couponName",
            res.data.couponName
          );
          formikRef.current.setFieldValue(
            "couponId",
            res.data.couponId
          );
          formikRef.current.setFieldValue(
            "couponValue",
            res.data.couponValue
          );
          formikRef.current.setFieldValue(
            "minimunBooking",
            res.data.minimunBooking
          );
          formikRef.current.setFieldValue(
            "activationDate",
            res.data.activationDate
          );
          formikRef.current.setFieldValue(
            "expiryDate",
            res.data.expiryDate
          );
          formikRef.current.setFieldValue(
            "propertyId",
            res.data.propertyId
          );
          formikRef.current.setFieldValue(
            "userEmailId",
            res.data.userEmailId
          );
        }
      }catch(error:any){
        console.log(error?.response?.data?.message)
      }
    }
    if(couponId){
      setShowLoader(true)
      getCoupon();
    }
  }, []);

  return (
    <div className="card">
      <div className="card-header border-0 pt-6">
      <Formik
        validationSchema={schema}
        onSubmit={handleCreateCoupon}
        initialValues={initialValues}
        innerRef={formikRef}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="couponName">
                      Coupon Name<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.couponName}
                      id="couponName"
                      className="form-control form-control-lg form-control-solid"
                      name="couponName"
                      disabled={couponId ? true : false}
                      style={{opacity : couponId ? '0.5' : ''}}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.couponName && !!errors.couponName}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="couponId">
                      Coupon Id<span className="required"></span>
                    </label>
                    <Form.Control
                      type="text"
                      value = {values?.couponId.toUpperCase()}
                      id="couponId"
                      className="form-control form-control-lg form-control-solid"
                      name="couponId"
                      disabled={couponId ? true : false}
                      style={{opacity : couponId ? '0.5' : ''}}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.couponId && !!errors.couponId}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="discountType">
                      Coupon Type<span className="required"></span>
                    </label>
                    <select
                      value = {formData?.discountType}
                      id="discountType"
                      className="form-control form-control-lg form-control-solid"
                      name="discountType"
                      disabled={couponId ? true : false}
                      style={{opacity : couponId ? '0.5' : ''}}
                      onChange={(e) => handleChangeDiscountType(e)}
                    >
                      <option value={'Amount'}>Discount Amount</option>
                      <option value={'Percentage'}>Discount %</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="couponValue">
                      Coupon Value<span className="required"></span>
                    </label>
                    <Form.Control
                      type="number"
                      value = {values?.couponValue}
                      id="couponValue"
                      className="form-control form-control-lg form-control-solid"
                      name="couponValue"
                      disabled={couponId ? true : false}
                      style={{opacity : couponId ? '0.5' : ''}}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.couponValue && !!errors.couponValue}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="activationDate">
                      Activation Date<span className="required"></span>
                    </label>
                    <Form.Control
                      type="date"
                      id="activationDate"
                      value={values?.activationDate}
                      className="form-control form-control-lg form-control-solid"
                      name="activationDate"
                      min={new Date().toISOString().split("T")[0]}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.activationDate && !!errors.activationDate}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="expiryDate">
                      Expiry Date<span className="required"></span>
                    </label>
                    <Form.Control
                      type="date"
                      value = {values?.expiryDate}
                      id="expiryDate"
                      className="form-control form-control-lg form-control-solid"
                      name="expiryDate"
                      disabled = {!values?.activationDate}
                      min={values?.activationDate}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.expiryDate && !!errors.expiryDate}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="d-flex flex-column mt-5">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <Radio
                          sx={{
                            color: error ? 'red' : '#3e97ff',
                            '&.Mui-checked': {
                              color: error ? 'red' : '#3e97ff',
                            },
                          }}
                          name="couponType"
                          value="single"
                          id = 'single'
                          disabled={couponId ? true : false}
                          style={{opacity : couponId ? '0.5' : ''}}
                          checked={formData?.couponType === 'single'}
                          onChange={onChangeType}
                        />
                        <div>
                        <label htmlFor="single" style={{color : error ? 'red' : '#071437'}}>Single-Usage Coupons</label>
                        </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <Radio
                            sx={{
                              color: error ? 'red' : '#3e97ff',
                              '&.Mui-checked': {
                                color: error ? 'red' : '#3e97ff',
                              },
                            }}
                            name="couponType"
                            id = 'multi'
                            value="multi"
                            disabled={couponId ? true : false}
                            style={{opacity : couponId ? '0.5' : ''}}
                            checked={formData?.couponType === 'multi'}
                            onChange={onChangeType}
                          />
                          <div>
                          <label htmlFor="multi"  style={{color : error ? 'red' : '#071437'}}>Multi-Usage Coupons</label>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <div className="d-flex flex-column">
                    <label className="form-label mb-3" htmlFor="minimunBooking">
                      Minimum Booking<span className="required"></span>
                    </label>
                    <Form.Control
                      type="number"
                      value = {values?.minimunBooking}
                      id="minimunBooking"
                      className="form-control form-control-lg form-control-solid"
                      name="minimunBooking"
                      disabled={couponId ? true : false}
                      style={{opacity : couponId ? '0.5' : ''}}
                      onChange={(e) => handleChange(e)}
                      isInvalid={!!touched.minimunBooking && !!errors.minimunBooking}
                    />
                  </div>
                </div>
                {formData.couponType === 'single' && (
                  <>
                    <div className="col-lg-6 mb-2">
                    <div className="d-flex flex-column">
                      <label className="form-label mb-3" htmlFor="propertyId">
                      Specific to Property ID <span className="required"></span>
                      </label>
                      <Form.Control
                        type="text"
                        value = {values?.propertyId}
                        id="propertyId"
                        className="form-control form-control-lg form-control-solid"
                        name="propertyId"
                        disabled={couponId ? true : false}
                        style={{opacity : couponId ? '0.5' : ''}}
                        onChange={(e) => handleChange(e)}
                        isInvalid={!!touched.propertyId && !!errors.propertyId}
                      />
                    </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="d-flex flex-column">
                        <label className="form-label mb-3" htmlFor="userEmailId">
                        Specific to User Email ID <span className="required"></span>
                        </label>
                        <Form.Control
                          type="text"
                          id="userEmailId"
                          value={values?.userEmailId}
                          className="form-control form-control-lg form-control-solid"
                          name="userEmailId"
                          disabled={couponId ? true : false}
                          style={{opacity : couponId ? '0.5' : ''}}
                          onChange={(e) => handleChange(e)}
                          isInvalid={!!touched.userEmailId && !!errors.userEmailId}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary mb-5"
                  >
                    {couponId ? "Update Coupon" : 'Create Coupon'}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showLoader && <PageLoader />}
      </div>
    </div>
  );
};

export default Createcoupon;
