import { useEffect, useState, useRef } from "react";
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'
import imageCompression from "browser-image-compression";
import fileUploadIcon from "../../../../../../_metronic/assets/images/file-upload.svg";
import "../../edit/EditLocation.css"
import { uploadLocationPics, deleteFiles } from "../../../services/api";
import watermark from "watermarkjs"

// Images
import watermarklogo from "../../../../../../../src/_metronic/assets/images/Spotlet_V1_Watermark ReqSize.webp"
// For Showing Images
import { Button, Modal } from "react-bootstrap";

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], Date.now() + filename, { type: mimeType });
    });
}

const Photos = ({ locationData }) => {

  //const { location: locationData } = useLocationStore();
  const [init, setInit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [images, setImages] = useState([]);
  const image = useRef([]);

  const [files, setFiles] = useState([]);
  const [noOfFiles, setNoOfFiles] = useState(5);
  const [imagesData, setImagesData] = useState([]);
  let x = window.matchMedia("(max-width: 576px)");

  const [loaded, setLoaded] = useState(false);

  const [showImageModel, setShowImageModel] = useState(false);
  const [imageData, setImageData] = useState();

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");

  const [deleteFileName, setDeleteFileName] = useState("");
  const [deleteFileIndex, setDeleteFileIndex] = useState("");
  const [deleteImg, setDeleteImg] = useState(false)

  const { Header, Footer, Title, Body } = Modal;

  useEffect(() => {
    if (locationData && locationData?.imagesData) {
      const { imagesData } = locationData;
      setImagesData(imagesData);

      if (files.length < 1) {
        imagesData?.map((imageData, id) => {
          const obj = {
            image: imageData?.image,
            watermarkImage: imageData?.image,
            uploaded: true,
            name: imageData?.name
          }
          files.push(obj)
        });
      }
    }
    switch (locationData?.subscriptionPlan) {
      case 'premium':
        setNoOfFiles(200);
        break;
      case 'standard':
        setNoOfFiles(100);
        break;
      case 'basic':
        setNoOfFiles(50);
        break;
      default:
        setNoOfFiles(50)
    }
  }, [locationData]);

  const handleClick = () => {
    setInit(true);
  };

  const showImage = (image) => {
    setImageData(image);
    setShowImageModel(true);
  }

  const uploadFiles = async (item, i) => {
    if (item.uploaded) {
      return {
        image: item.watermarkImage,
        imageRef: item.fileRef,
        name: item.name,
        uploaded: item.uploaded
      };
    }
    const file = await urltoFile(
      item.watermarkImage,
      item.name,
      "text/plain"
    );
    const formData = new FormData();
    formData.append("pic", file);
    formData.append("fileName", item.name);
    formData.append("locationId", locationData?.location_id);
    const response = await uploadLocationPics(formData);
    response.name = item.name;
    item.uploaded = true;
    item.image = response.url;

    // const imgData = {
    //   image: response.url,
    //   imageRef: response.fileRef,
    //   fileName: fileName,
    // };
    // setImages((prev) => [
    //     ...prev,
    //     imgData
    //   ]);

    // setImagesData((prev) => [
    //   ...prev,
    //   imgData
    //   {
    //     image: response.url,
    //     imageRef: response.fileRef,
    //     fileName: fileName,
    //   },
    // ]);

    //console.log("images : ", images);

    // setFiles((prevData) => {
    //   prevData[i].uploaded = true;
    //   return [ ...prevData ];
    // });
    return response;
  };

  const handleUpload = async () => {

    try {
      if (files?.length < 5) {
        setConfirmationTitle("Photos");
        setConfirmationBody("Please add minimum 5 Photos");
        setShowConfirmationModel(true);
        return;
      }
      setLoaded(true);
      //const promises = files.map((item,i) => uploadFiles(item,i));
      //const result = await Promise.all(promises);
      const tdata = files.map((item) => {
        if (!item)
          return;
        return {
          image: item.url ? item.url : item.image,
          imageRef: item.fileRef,
          name: item.name,
        };
      });
      setImages([...images, tdata]);
      handleSubmit(tdata);
    } catch (error) {
      console.error(error);
    }
  };

  const handleBack = () => {
    //setTab("contact_dev");
  }

  const handleSubmit = (payload) => {
    setImages([...images, payload]);
    setImagesData([...imagesData, payload]);

    if (localStorage.getItem("locationId")) {
      // callbackFn({ imagesData: payload });
      // setLoaded(false);
      // setTab("review_app");
    }
  };

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFiles((prevFiles) => {
        const filesToAdd = [...event.target.files];
        const imageObj = {};
        filesToAdd.forEach((file) => {

          imageObj[file.name] = {
            file,
            imageSrc: URL.createObjectURL(file),
            croppedImage: "",
            watermarkImage: "",
            preview: false,
            uploaded: false
          };
        });
        return { ...prevFiles, ...imageObj };
      });
    }
  };

  const addWatermark = (file) => {
    // Load the uploaded image to get its dimensions
    // const img = new Image();
    // img.src = URL.createObjectURL(file);
    // img.onload = () => {
    // Calculate percentage based on the uploaded image size
    // const scaleFactor = 5; // Adjust as needed
    // const logoWidth = `${(img.width * scaleFactor) / 100}%`;
    // const logoHeight = `${(img.height * scaleFactor) / 100}%`;
    // console.log(logoWidth);
    // console.log(logoHeight);

    // Apply watermark with resizing and position at the lower right corner
    watermark([file, watermarklogo])
      .image(watermark.image.lowerRight())
      .then(async (watermarkedImage) => {
        // Use the watermarked image, for example, set it as state or perform any other action
        const imageObj = {
          file,
          imageSrc: URL.createObjectURL(file),
          croppedImage: "",
          watermarkImage: watermarkedImage.src,
          preview: false,
          uploaded: false,
          name: file.name
        };
        const promise = uploadFiles(imageObj)
        const result = await Promise.resolve(promise)
        result.image = result.data.url
        result.watermarkImage = watermarkedImage.src
        result.uploaded = true
        setFiles((prevFiles) => {
          return [...prevFiles, result]
        });
        locationData.imagesData = [...locationData?.imagesData, { name: result.name, image: result.image }]
      });
    // };
  }



  const onUploadFileOld = (event) => {
    if (event.target.files.length + files.length > noOfFiles) {
      alert(`You can add only ${noOfFiles} Images for selected plan`)
      return null
    }
    setLoaded(true);
    if (event.target.files && event.target.files.length > 0) {
      // setFiles((prevFiles) => {
      const filesToAdd = [...event.target.files];
      filesToAdd.forEach((file) => {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 800,
          useWebWorker: true,
          fileType: 'image/jpeg',
        };
        if (options.maxSizeMB < file.size / 1000000) {
          imageCompression(file, options).then(compressedObj => {
            addWatermark(compressedObj);
          });
        } else {
          addWatermark(file);
        }

        //watermark([file, logo])
        //.image(watermark.image.upperLeft())
        ;

        // imageObj[file.name] = {
        //   watermarkedImage,
        //   imageSrc: URL.createObjectURL(file),
        //   croppedImage: "",
        //   watermarkImage: "",
        //   preview: false,
        //   uploaded: false
        // };

      });
      //return { ...prevFiles, ...imageObj };
      //});
    }
    setTimeout(() => {
      setLoaded(false)
    }, 3000)
  };
  // const deleteImage = async (fileName, index) => {
  //   setConfirmationTitle("Confirmation");
  //   setConfirmationBody("Do you want to delete the image ?");
  //   setShowConfirmationModel(true);

  //   setDeleteImg(true)
  //   setDeleteFileName(fileName);
  //   setDeleteFileIndex(index);
  // }

  // const handleConfirmOk = async () => {
  //   setShowConfirmationModel(false)
  //   if(deleteImg){
  //     confirmDeleteImage(deleteFileIndex);
  //   }
  // }

  const confirmDeleteImage = async (index) => {

    const fileToDelete = files[index];
    try {
      if (fileToDelete) {
        await deleteFiles({
          image: fileToDelete.image
        });
        setFiles((prevData) => {
          prevData.splice(index, 1)
          if (prevData[0] !== undefined) {
            return [...prevData];
          } else {
            return [];
          }
        });
        setImagesData((imagesData) => imagesData.filter((img, i) => i !== index));

        locationData.imagesData = locationData.imagesData.filter((file, i) => { return index !== i })


      }

    } catch (error) {
      alert(error.response.data);
    }
  };

  const onSortEnd = (oldIndex, newIndex) => {
    setFiles((array) => arrayMoveImmutable(array, oldIndex, newIndex))
    locationData.imagesData = arrayMoveImmutable(locationData.imagesData, oldIndex, newIndex)
  }

  return (
    <>
      <div
        className="tab-pane h-100 w-100 fade photo-form show active"
        id="v-pills-photos"
        role="tabpanel"
        aria-labelledby="v-pills-photos-tab"
      >
        <div className="row g-0 h-100 w-100">
          <div className="col-xl-12">
            <form className="h-100 w-100">
              <div className="step-form h-100 w-100">
                <div>
                  <h3 className="ftitle">Photos</h3>
                  <div className="scroolbox">
                    <div className="row">

                      <div className="col-lg-12">
                        <div className="upload__box" onClick={handleClick}>
                          {files?.length < 1 && (
                            <div className="upload__btn-box">
                              <label className="upload__btn">
                                <img src={fileUploadIcon} />
                                <h3>Upload Your jpg or png files</h3>
                                <p>Max. file size: 25 MB</p>
                                <input
                                  type="file"
                                  multiple
                                  data-max_length="20"
                                  className="upload__inputfile"
                                  id="upload__inputfile"
                                  accept='image/png, image/jpeg, image/jpg'
                                  onChange={onUploadFileOld}
                                />
                              </label>
                            </div>
                          )}
                          <SortableList onSortEnd={onSortEnd} className="upload__img-wrap" >
                            {files?.map((file, i) => {
                              const { image, watermarkImage, uploaded } = file;
                              return (
                                <SortableItem key={i} >
                                  <div className="upload__img-box" key={i} onClick={() => showImage({ name: file.name, image: watermarkImage })}>
                                    <div className="img-bg" style={{
                                      backgroundImage: `url(${image})`,
                                      backgroundRepeat: 'no-repeat'
                                    }}>
                                      <div className="upload__img-close"
                                        onClick={(e) => {
                                          confirmDeleteImage(i);
                                          e.stopPropagation();
                                        }}
                                      />
                                    </div>
                                  </div>
                                </SortableItem>
                              );
                            })}
                            {files?.length > 0 && (
                              <div className="upload__img-box" onClick={handleClick}>
                                <label className="upload__btn_mini" >
                                  <img src={fileUploadIcon} />
                                  <p>Add more</p>
                                  <input
                                    type="file"
                                    multiple
                                    data-max_length="20"
                                    className="upload__inputfile"
                                    id="upload__inputfile"
                                    accept='image/png, image/jpeg, image/jpg'
                                    onChange={onUploadFileOld}
                                  />
                                </label>
                              </div>
                            )}
                          </SortableList>
                        </div>
                      </div>
                      {/* <div className="col-lg-12">
                      <div className="mb-4"></div>
                    </div> */}

                    </div>
                  </div>
                </div>
                <div>
                  {/* <div className="button-bar">
                    <a className="btn btn-gray" onClick={handleBack}>Back</a>
                    <Oval
                      height={50}
                      width={50}
                      color="#4fa94d"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={loaded}
                      ariaLabel='oval-loading'
                      secondaryColor="#4fa94d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}

                    />
                    <a
                      className="btn btn-theme"
                      onClick={() => {
                        handleUpload();
                      }}
                    >
                      Save & Continue
                    </a>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
          {/* <div className="col-xl-4">
            <div className="detail-desc photos"></div>
          </div> */}
        </div>
      </div>
      {showImageModel &&
        <Modal show={showImageModel} onHide={() => setShowImageModel(false)}>
          <Header closeButton>
            <Title>{imageData?.name}</Title>
          </Header>
          <Body className="verify-email-modal-body">
            <img src={imageData?.image}
              style={{ width: "100%", height: "100%" }}
            />
          </Body>
          <Footer>
            <Button variant="secondary" onClick={() => setShowImageModel(false)}>
              OK
            </Button>

          </Footer>
        </Modal>
      }

      {/* {showConfirmationModel && (
        <ConfirmationModel
          show={showConfirmationModel}
          //handleClose={() => {setShowConfirmationModel(false),setDeleteImg(false)}}
          handleOk={() => handleConfirmOk()}
          title={confirmationTitle}
          body={confirmationBody}
        />
      )} */}
    </>
  );
};

export default Photos;
