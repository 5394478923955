/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC,useEffect,useState,useRef} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../helpers'
import clsx from 'clsx'
import { adminNotifications } from '../../../../app/modules/apps/services/api'
import Moment from 'moment';
import { useNavigate } from "react-router-dom";

const DrawerMessenger:FC = () =>{
  const navigate = useNavigate();
  const timerId = useRef<any>(null);
  const [notifications,setNotifications] = useState<any>([])
  const getData = async () => {
    try{
      const resp = await adminNotifications()
      setNotifications(resp.data)

    }catch(error){
      console.log(error)
    }
  }
  useEffect(()=>{
    timerId.current = setInterval(() => {
      getData()
    }, 5000);

    return () => {
      clearInterval(timerId.current);
    };
  },[]);

  return (
    <div
      id='kt_drawer_chat'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
        <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <a href='#' className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                Notifications
              </a>
  
              {/* <div className='mb-0 lh-1'>
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-gray-400'>Active</span>
              </div> */}
            </div>
          </div>
  
          <div className='card-toolbar'>
            {/* <div className='me-2'>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <i className='bi bi-three-dots fs-3'></i>
              </button>
            </div> */}
  
            <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
              <KTIcon iconName='cross' className='fs-2' />
            </div>
          </div>
        </div>
  
        {/* <ChatInner isDrawer={true} /> */}
        <div
        className='card-body'
        id={true ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
      >
        <div
          className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !true})}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={
            true
              ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={
            true
              ? '#kt_drawer_chat_messenger_body'
              : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
          }
          data-kt-scroll-offset={true ? '0px' : '5px'}
        >
          {notifications && notifications.map((notification)=>{
            return(
              <>
                  <div className='symbol  symbol-35px symbol-circle '>
                    {/* <img alt='Pic' src={toAbsoluteUrl(`/media/${userInfo.avatar}`)} /> */}
                  </div>
                  <div className='ms-3'>
                    <Link to = {notification.type === 'listing' ? `/apps/location-management/requests` : `/apps/users/list`}>
                      <a
                        href='#'
                        className={`fs-5 text-gray-900 text-hover-primary me-1 ${notification?.read ? '' : 'fw-bolder'}`}
                      >
                        {notification?.notificationHeader}
                      </a>
                    </Link>
                    <span className='text-muted fs-7 mb-1'>{Moment(notification?.updatedAt).format('DD-MM-YYYY h:mm:ss a')}</span>
                  </div>
                  <div
                    className={clsx(
                      'p-5 rounded',
                      `bg-light-info`,
                      `text-dark mw-lg-400px ${notification?.read ? '' : 'fw-bold'}`,
                      `text-start`
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{__html: notification?.notificationContent}}
                    onClick={()=> navigate('/apps/notifications/list')}
                  ></div>
              </>
            )
          })}
        </div>
      </div>
      </div>
    </div>
  )
} 

export {DrawerMessenger}
