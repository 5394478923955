/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import { SignIn, ValidateQrCode, sendOtp } from '../../apps/services/api'
import AuthenticateEmailModal from './AuthenoticateEmailModal'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [qrCode, setQrCode] = useState('')
  const [qrValue, setQrValue] = useState('')
  const [ascii, setAscii] = useState<any>('')
  const [stepOne, setStepOne] = useState<any>(false)
  const [formData, setFormData] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const {saveAuth, getUser,getSideBarCount} = useAuth()
  const [showModal,setShowModal] = useState(false)
  const [otp, setOtp] = useState('')

  const submitQr = async (e) => {
    e.preventDefault()
    setLoading(true)
    try{
      const {data} : any= await ValidateQrCode({...formData,ascii : ascii, code : qrValue})
      localStorage.setItem("admin", data?.token);
      localStorage.setItem("regionId", data?.regionId);
      if (data?.regionId === "UN38886001") {
        localStorage.setItem("region", 'United States')
      } else {
        localStorage.setItem("region", 'India')
      }
      if(!localStorage.getItem('region')) localStorage.setItem("region", 'India');
      if(!localStorage.getItem('regionId')) localStorage.setItem("regionId", 'IN72549630');
      setLoading(false)
      saveAuth(data.token)
      getUser()
      getSideBarCount()
    }catch(error : any){
      console.log(error?.response?.data)
      setLoading(false)
      alert(error?.response?.data)
    }
  }

  const reGenerateQR = async () => {
    setLoading(true)
    try{
      const {data} = await SignIn({...formData,reGenerateQR : true})
        setQrCode(data?.qr)
        setStepOne(true)
        setAscii(data?.asciiCode)
        setShowModal(false)
        setLoading(false)
    }catch(error){
      console.log(error)
      setLoading(false)
    }
  } 

  const onRecovery = async () => {
    setShowModal(true)
    try{
      const resp = await sendOtp(formData)
      setOtp(resp?.data?.otp)
      setLoading(false)
    }catch(error){
      console.log(error)
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        setFormData({ email: values.email, password: values.password })
        const {data} = await SignIn({ email: values.email, password: values.password})
        if(data?.ascii){
          setStepOne(data?.ascii)
        } else{
          setQrCode(data?.qr)
          setStepOne(true)
          setAscii(data?.asciiCode)
        }
        setLoading(false)
        // localStorage.setItem("admin", data?.token);
        // await localStorage.setItem("regionId", data?.regionId);
        // if (data?.regionId === "UN38886001") {
        //   await localStorage.setItem("region", 'United States')
        // } else {
        //   await localStorage.setItem("region", 'India')
        // }
        // if(!localStorage.getItem('region')) localStorage.setItem("region", 'India');
        // if(!localStorage.getItem('regionId')) localStorage.setItem("regionId", 'IN72549630');

        // saveAuth(data.token)
        // getUser()
        // getSideBarCount()
        //const {data: auth} = await login(values.email, values.password)
        //saveAuth(auth)
        //const {data: user} = await getUserByToken(auth.api_token)
        //setCurrentUser(user)
      } catch (error:any) {
        console.error(error)
        saveAuth(undefined)
        console.log("error", error?.response?.data?.error);
        setLoading(false)
        if (error?.response?.data?.error) {
          setStatus(error?.response?.data?.error)
        } else {
          setStatus('The login details are incorrect')
        }
        setSubmitting(false)
      }
    },
  })

  return (
    <>
    {stepOne ? (
      <form onSubmit={submitQr} className='d-flex flex-column justify-content-center align-items-center'>
        {ascii ? (
          <>
            <p className='mb-2'>To continue, please scan the QR code with the Google Authenticator app on your mobile device and enter the generated code below.</p>
            {qrCode ? <img alt='2FA-qr code' loading="lazy" src={qrCode}/> : <p>Qr image loading...</p>}
          </>
        ) : (
          <div>
            <p className='mb-2'>To continue, please open the Google Authenticator app on your mobile device and enter the current code generated for this account below.</p>
            <a className='mb-2' style={{cursor : 'pointer'}} onClick={onRecovery}>Try 2FA recovery</a>
          </div>
        )}
        <input
          type='number'
          className='form-control bg-transparent mb-2'
          placeholder='Enter 6 digit code'
          value={qrValue}
          onChange={(e)=> setQrValue(e.target.value)}
          name='qrValue'
          required
        />
        <div className='d-flex flex-row justify-content-between align-items-center mb-10 w-100'>
        <button className='btn btn-secondary me-2 w-100' type='button' onClick={()=>{
          setQrCode('');
          setQrValue("");
          setStepOne(false);
          }}>Back</button>
          <button
            type='submit'
            id='kt_auth_submit'
            className='btn btn-primary w-100'
            disabled={!(qrValue?.length === 6)}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    ) : (
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
          {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
        </div>
        {/* begin::Heading */}
  
        {/* begin::Login options */}
        <div className='row g-3 mb-9'>
          {/* begin::Col */}
          <div className='col-md-6'>
            {/* begin::Google link */}
            {/* <a
              href='#'
              className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                className='h-15px me-3'
              />
              Sign in with Google
            </a> */}
            {/* end::Google link */}
          </div>
          {/* end::Col */}
  
          {/* begin::Col */}
          <div className='col-md-6'>
            {/* begin::Google link */}
            {/* <a
              href='#'
              className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
                className='theme-light-show h-15px me-3'
              />
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
                className='theme-dark-show h-15px me-3'
              />
              Sign in with Apple
            </a> */}
            {/* end::Google link */}
          </div>
          {/* end::Col */}
        </div>
        {/* end::Login options */}
  
        {/* begin::Separator */}
        {/* <div className='separator separator-content my-14'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
        </div> */}
        {/* end::Separator */}
  
        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          <></>
          // <div className='mb-10 bg-light-info p-8 rounded'>
          //   <div className='text-info'>
          //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
          //     continue.
          //   </div>
          // </div>
        )}
  
        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}
  
        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <div className='d-flex flex-row'>
            <input
              type={showPassword? "input" : "password"}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <span className="cursor-pointer" style={{marginLeft:"-67px", marginTop:"13px "}} onClick={() => setShowPassword(!showPassword) } > {showPassword ? "Hide" : "Show"} </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
  
        {/* Forgot Password Start  */}
        <a className='d-flex flex-row justify-content-end' href="/auth/forgot-password">
          <span  style={{color:"red", textDecoration:"underline", cursor:"pointer"}}> Forgot password? </span>
        </a>
        {/* Forgot Password End */}
  
        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />
  
          {/* begin::Link */}
          {/* <Link to='/auth/forgot-password' className='link-primary'>
            Forgot Password ?
          </Link> */}
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}
  
        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
  
        {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
          Not a Member yet?{' '}
          <Link to='/auth/registration' className='link-primary'>
            Sign up
          </Link>
        </div> */}
      </form>
    )}
    {showModal && (
      <AuthenticateEmailModal
        show={showModal}
        handleClose = {() => setShowModal(false)}
        handleOk={reGenerateQR}
        originalOtp={otp}
       />
    )}
    </>
  )
}
