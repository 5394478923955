import { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import { KTIcon } from "../../../../../_metronic/helpers";
import {getAllLocationsReviews} from "../../services/api";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PageLoader } from "../../services/PageLoader";

const ReviewList = () => {

    const [data, setData] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<any>([]);
    const [showLoader, setShowLoader] = useState(false)
    const [pageCount,setPageCount] = useState<any>('')
    const noOfItems = 50
    const getDetails = async () => {
        try{
            const res = await getAllLocationsReviews(searchTerm,1,noOfItems);
            setData(res.data.filterData)
            setPageCount(res?.data?.count)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }
    useEffect(() => {
        setShowLoader(true)
        getDetails();
    }, []);

    const hanldeSearch = async (e) => {
        setSearchTerm(e.target.value)
        setShowLoader(true)
        try{
            const res = await getAllLocationsReviews(e.target.value,1,noOfItems);
            setData(res.data.filterData)
            setPageCount(res?.data?.count)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
      }

      const handleChange = async(event,value) => {
        try{
            const res = await getAllLocationsReviews(searchTerm,value,noOfItems);
            setData(res.data.filterData)
            setPageCount(res?.data?.count)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    };

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search Location Id/Name'
                            value={searchTerm}
                            onChange={hanldeSearch}
                        />
                    </div>
                    {/* end::Search */}
                </div>
            </div>
            
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    <th className='min-w-100px text-start'>Location Id</th>
                                    <th className='min-w-100px text-start'>Location Name</th>
                                    <th className='min-w-100px text-start'>Reviews</th>
                                    <th className='min-w-100px text-start'>Rating</th>
                                    <th className='min-w-100px text-start'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {data?.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6 text-start'>
                                                    {item.locationId}
                                                </a>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary fs-6 text-start'>
                                                    {item?.locationName}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-start'>
                                                    {item?.totalReviews}
                                                </div>
                                            </td>
                                            <td>
                                            <div className='me-6'>
                                                <i className='fa fa-star-half-alt me-1 text-warning fs-5'></i>
                                                <span className='text-gray-800 fw-bold'>{item.avarageRating}</span>
                                            </div>
                                            </td>
                                            <td>
                                                <div className="text-start">
                                                    <Link to ={`../location-review-view/${item.locationId}`} >
                                                        <div className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                            title="View">
                                                            View Reviews
                                                        </div>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={pageCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                </Stack>
            </div>
            {showLoader && <PageLoader />}
        </div>

    );
};

export default ReviewList;
