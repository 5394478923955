import * as Yup from 'yup'

export interface ICreateLocation {
  accountType: string,
  property_desc: {
    user_id: string,
    property_name: string,
    location_type: string,
    property_size: string,
    property_size_area: string,
    property_info: string,
    street_parking: boolean,
    house_parking: boolean,
    security_camera: boolean,
  },
  property_address: {},
  amenities: [],
  features: [],
  do_and_dont: {
    do_s: [], dont_s: []
  },
  rules: [],
  pricing: {},
  timings: {},
  imagesData: [],
  videosData: [],
  bankDetails: {},
  bookedDates: [],
  contact_det: {},
  createdAt: '',
  gst: {},
  locationPageUrl: '',
  location_id: '',
  planPaymentId: '',
  review_and_rating: [],
  status: '',
  subscriptionPlan: '',
  updatedAt: '',
}

const createLocationSchemas = [
  Yup.object({
    accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    property_name: Yup.string().required().label('Name of Location'),
  }),
  Yup.object({
    businessName: Yup.string().required().label('Business Name'),
    businessDescriptor: Yup.string().required().label('Shortened Descriptor'),
    businessType: Yup.string().required().label('Corporation Type'),
    businessEmail: Yup.string().required().label('Contact Email'),
  }),
  Yup.object({
    nameOnCard: Yup.string().required().label('Name On Card'),
    cardNumber: Yup.string().required().label('Card Number'),
    cardExpiryMonth: Yup.string().required().label('Expiration Month'),
    cardExpiryYear: Yup.string().required().label('Expiration Year'),
    cardCvv: Yup.string().required().label('CVV'),
  }),
]

const inits: ICreateLocation = {
  accountType: '',
  property_desc: {
    user_id: "",
    property_name: "",
    property_info: "",
    location_type: "",
    property_size: "",
    property_size_area: "",
    house_parking: false,
    street_parking: false,
    security_camera: false,
  },
  property_address: {
    country: "",
    state: "",
    city: "",
    pincode: "",
    address: "",
    area: "",
    landmark: "",
    location_detail: "",
    googlePlaceId: "",
    longitude: "",
    latitude: "",
    mapLink: "",
    googlePlaces: false,
    liveLocation: false,
  },
  amenities: [],
  features: [],
  do_and_dont: {
    do_s: [], dont_s: []
  },
  rules: [],
  pricing: {
    paymentAgreed: '',
    film_webseries_ad: {
      type: "Film",
      isPresent: false,
      hourly_rate: null,
      attendees: {
        min: '',
        max: '',
      },
      hourly_discount: 10,
      day_discount: 20,
      halfDayPrice: null,
      fullDayPrice: null,
      cleaningFee: '',
      securityDeposite: '',
    },
    tv_series_other: {
      type: "TV",
      isPresent: false,
      hourly_rate: null,
      attendees: {
        min: '',
        max: '',
      },
      hourly_discount: 10,
      day_discount: 20,
      halfDayPrice: null,
      fullDayPrice: null,
      cleaningFee: '',
      securityDeposite: '',
    },
    corporate: {
      type: "Corporate",
      isPresent: false,
      hourly_rate: null,
      attendees: {
        min: '',
        max: '',
      },
      hourly_discount: 10,
      day_discount: 20,
      halfDayPrice: null,
      fullDayPrice: null,
      eightHrsPrice: null,
      cleaningFee: '',
      securityDeposite: '',
    },
    individual: {
      type: "Individual",
      isPresent: false,
      hourly_rate: null,
      attendees: {
        min: '',
        max: '',
      },
      hourly_discount: 10,
      day_discount: 20,
      halfDayPrice: null,
      fullDayPrice: null,
      eightHrsPrice: null,
      cleaningFee: '',
      securityDeposite: '',
    }
  },
  timings: {
    monday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    tuesday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    wednesday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    thursday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    friday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    saturday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
    sunday: {
      open: false,
      isSetHours: false,
      time: "all day",
    },
  },
  imagesData: [],
  videosData: [],
  bankDetails: {
    account_holder_name: "",
    account_number: "",
    bank_name: "",
    ifsc_code: ""
  },
  bookedDates: [],
  contact_det: {
    aadhar_no: '',
    alt_mobile: '',
    alt_name: '',
    contact_name: '',
    designation: '',
    email: '',
    img: '',
    mobile_num: '',
    pan_no: '',
  },
  createdAt: '',
  gst: { doc_no: '', docs: [] },
  locationPageUrl: '',
  location_id: '',
  planPaymentId: '',
  review_and_rating: [],
  status: '',
  subscriptionPlan: '',
  updatedAt: '',
}

export { createLocationSchemas, inits }
