import { useEffect, useState, useRef } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { getLogs} from "../../services/api";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Moment from 'moment';
import { LogListFilter } from "../LogsListFilter";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';

const LogList = () => {

    const reportTemplateRef = useRef<any>('');
    const [data, setData] = useState<any>([]);
    const [dataCount, setDataCount] = useState<any>(0)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [date, setDate] = useState<number>(-1)
    const [level, setLevel] = useState<string>('')
    const [category, setCategory] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const noOfItems : number = 50
 
    const getData = async () => {
        try{
            const resp = await getLogs({searchTerm,date,level,page,noOfItems, category});
            setData(resp?.data?.logs);
            setDataCount(resp?.data?.count);
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [searchTerm, date, level, page, category]);

    const filterData = async (date:number, level: string, category : string) => {
        setDate(date);
        setLevel(level);
        setCategory(category);
    }

    const handleGeneratePdf = () => {
        const options : any = {
            method: 'download',
            resolution: Resolution.HIGH,
            page: {
                margin: Margin.SMALL,
                format: 'A4',
                orientation: 'landscape',
   },
        }
        generatePDF(reportTemplateRef,options)
    }

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-350px ps-14'
                            placeholder='Search by Category / Info'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                <div className="card-toolbar">
                <div className="me-0">
                    <button className="btn btn-light-primary me-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                        {/* <i className="ki-solid ki-dots-horizontal fs-2x"></i> */}
                        Export
                    </button>
                    {/*<!--begin::Menu 3-->*/}
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">
                        <div className="menu-item px-3">
                            <a className="menu-link px-3">
                                    <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    table="table-to-xls"
                                    filename="logsData"
                                    sheet="tablexls"
                                    buttonText="Excel"
                                    type='button'
                                    className='border-0 bg-transparent'
                                    />
                            </a>
                        </div>
                        <div className="menu-item px-3">
                            <a onClick={handleGeneratePdf} className="menu-link px-3" style={{marginLeft : '7px'}}>Pdf</a>
                        </div>
                    </div>
                </div>
                <LogListFilter filterFormData={filterData}/>
                </div>
            </div>
            
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table ref={reportTemplateRef} className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3' id ='table-to-xls'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    {/* <th className='min-w-100px'> Request Url</th> */}
                                    <th className='min-w-100px'>Category</th>
                                    <th className='min-w-100px'>Type</th>
                                    <th className='min-w-100px'>Info</th>
                                    <th className='min-w-100px'>Message</th>
                                    <th className='min-w-100px'>Date</th>
                                    <th className='min-w-100px'>Time</th>
                                    <th className='min-w-100px'>User Machine</th>
                                    <th className='min-w-100px'>Level</th>
                                    {/* <th className='min-w-100px text-end'>Actions</th> */}
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {data?.map((item, index) => {
                                    let reqInfo
                                    if (item?.meta?.reqBody?.email) {
                                        reqInfo = item?.meta?.reqBody?.email
                                    }else if (item?.meta?.reqBody?.mobile){
                                        reqInfo = item?.meta?.reqBody?.mobile
                                    }else if (item?.meta?.bookingId){
                                        reqInfo = `Booking Id: ${item?.meta?.bookingId}`
                                    }
                                    else if (item?.meta?.reqBody?.listingId) {
                                        reqInfo = `Location Id: ${item?.meta?.reqBody?.listingId}`
                                    }
                                    else if (item?.meta?.reqBody?.location_id){
                                        reqInfo = `Location Id: ${item?.meta?.reqBody?.location_id}`
                                    } // App Notification
                                    else if (item?.meta?.category === "App Notification"){
                                        reqInfo = "App Notification"
                                    }
                                    else if (item?.meta?.category === "Admin Notification"){
                                        reqInfo = "Admin Notification"
                                    }
                                    else if (item?.meta?.category === "Email Notification"){
                                        reqInfo = "Email Notification"
                                    }
                                    else if (item?.meta?.category === "Whatsapp Notification" || item?.meta?.category === "Sms Notification"){
                                        reqInfo = `Ph No: ${item?.meta?.reqBody?.mobileNumber}`
                                    }

                                    let reqType
                                    if (item?.meta?.reqBody?.type) {
                                        reqType = item?.meta?.reqBody?.type?.replaceAll("_"," ");
                                    }
                                    else if (item?.meta?.reqBody?.templateType) {
                                        reqType = item?.meta?.reqBody?.templateType?.replaceAll("_"," ");
                                    }
                                    else if (item?.meta?.reqBody?.template) {
                                        reqType = item?.meta?.reqBody?.template?.replaceAll("_"," ").toUpperCase();
                                    }
                                    else {
                                        reqType = item?.meta?.category?.replaceAll("_"," ");;
                                    }

                                    return (
                                        <tr>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            {/* <td  style={{maxWidth:"280px"}} >
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    {item?.meta?.reqUrl}
                                                </a>
                                            </td> */}
                                             <td>
                                                <div className='badge badge-light-info'>
                                                    {item?.meta?.category}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {reqType}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {reqInfo}
                                                </div>
                                            </td>
                                            <td>
                                                <div className={`badge ${item?.level === 'error' ? 'badge-danger' : 'badge-success'}`}>
                                                    {item?.level === 'error' ? item?.message : 'Success'}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {Moment(item?.timestamp).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {Moment(item?.timestamp).format('hh:mm a')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.hostname}
                                                </div>
                                            </td>
                                            <td>
                                                <div className={`badge ${item?.level === 'error' ? 'badge-danger' : 'badge-success'}`}>
                                                    {item?.level}
                                                </div>
                                            </td>                                    
                                        </tr>
                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={dataCount} variant="outlined" shape="rounded" color="primary" onChange={(e,value)=>setPage(value)} />
                </Stack>
            </div>
        </div>

    );
};

export default LogList;
