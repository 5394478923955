import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { getSubscriptions,getPlans } from "../../services/api";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Moment from 'moment';
import { SubscriptionsListFilter } from "./SubscriptionsListFilter";
import {Modal} from 'react-bootstrap'
import SubscriptionModal from "../Modals/SubscriptionModal";
import { PageLoader } from "../../services/PageLoader";
import Reports from "../../Reports/Reports";

const SubscriptionsList = () => {

    const [data, setData] = useState<any>([]);
    const [dataCount, setDataCount] = useState<any>(0)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [subscriptionPlan, setSubscriptionPlan] = useState<string | undefined>()
    const [status, setStatus] = useState<string | undefined>()
    const [sort, setSort] = useState<string>('-1')
    const [show,setShow] = useState<boolean>(false);
    const [modalData,setModalData] = useState<any>({});
    const getLocationData = async () => {
        try{
            const resp = await getSubscriptions({page : 1,items : 50, subscriptionPlan: subscriptionPlan, searchTerm: searchTerm, status : status,sort : sort});
            setDataCount(Math.ceil(resp?.data?.data.length / 50)) //items per page is 50 So, dividing with 50 to make no of pages appear
            setData(resp?.data?.data);
        }catch(error:any){
            console.log(error?.response?.data?.message)
        }

    }
    useEffect(() => {
        getLocationData();
    }, [searchTerm, subscriptionPlan,status,sort]);
    const handleChange = async (event, value) => {
        try{
            const resp = await getSubscriptions({page : value,items : 50, subscriptionPlan: subscriptionPlan, searchTerm: searchTerm});
            setData(resp?.data?.data);
        }catch(error:any){
            console.log(error?.response?.data?.message)
        }
    };
    const handleView = (data) => {
        setModalData(data)
        setShow(true)
    }
    const filterData = async (subscriptionPlan:string,status:string,sort:string) => {
       setSubscriptionPlan(subscriptionPlan);
       setStatus(status);
       setSort(sort)
    }
    //subscription update start
    const region = localStorage.getItem('region')
    const [loader, setLoader] = useState(false)
    const [showSubscriptionModel, setShowSubscriptionModel] = useState<any>(false);
    const [standardPlan, setStandardPlan] = useState<any>({})
    const [premiumPlan, setPremiumPlan] = useState<any>({})
    const [activePlan,setActivePlan] = useState<any>({})
    const [selectedSubscription,setSelectedSubscription] = useState<any>({})
    const onClickUpgrade = async (item) => {
        setSelectedSubscription(item)
        setLoader(true)
        try {
        const res = await getPlans();
        let reqCurrency 
        if (region === "India") {
            reqCurrency = "INR"
        } else {
            reqCurrency = "USD"
        }
        if(res?.data) {
            let standard,premium
            for (let each of  res?.data) {
                if (each?.plan === "Standard") {
                    for (let eachCountryPlan of each?.amount) {
                        if (eachCountryPlan?.currency === reqCurrency) {
                            standard = {
                                plan : each?.plan,
                                originalAmount : eachCountryPlan?.originalAmount,
                                amount : eachCountryPlan?.discountAmount,
                                planId : eachCountryPlan?.plan_id,
                                currency : eachCountryPlan?.currency,
                            }
                            setStandardPlan(standard)
                        }
                    }
                }
                if (each?.plan === "Premium") {
                    for (let eachCountryPlan of each?.amount) {
                        if (eachCountryPlan?.currency === reqCurrency) {
                            premium = {
                                plan : each?.plan,
                                originalAmount : eachCountryPlan?.originalAmount,
                                amount : eachCountryPlan?.discountAmount,
                                planId : eachCountryPlan?.plan_id,
                                currency : eachCountryPlan?.currency,
                            }
                            setPremiumPlan(premium)
                        }
                    }
                }
            }
            switch (item?.planName) {
                case 'Basic':
                    setActivePlan(standard)
                    break;
                case 'Standard':
                    setActivePlan(premium)
                    break;
                default:
                    break;
            }
            setShowSubscriptionModel(true)
        }
        } catch (error) {
        console.log(error)
        }
        setLoader(false)
    }
    const handleChangePlan = () => {
        if(activePlan?.plan === 'Standard'){
            setActivePlan(premiumPlan)
        }else{
            setActivePlan(standardPlan)
        }
    }
    const completeTask = () => {
        setShowSubscriptionModel(false)
        getLocationData()
    }
    //subscription update end

    return (
        <div className="card">
             <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                <div className="card-toolbar">
                    <Reports table='subscriptions'/>
                    <SubscriptionsListFilter filterFormData={filterData}/>
                </div>
            </div>
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    <th className='min-w-100px'>Location Id</th>
                                    <th className='min-w-100px'>Status</th>
                                    <th className='min-w-100px'>User Id</th>
                                    <th className='min-w-140px'>Created Date</th>
                                    <th className='min-w-140px'>Activation Date</th>
                                    <th className='min-w-140px'>Expity Date</th>
                                    <th className='min-w-100px'>Plan</th>
                                    <th className='min-w-100px'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {data?.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    {item?.listingId || 'Not Created'}
                                                </a>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {item?.status}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.userId}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {Moment(item?.createdAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.activationDate ? Moment(item?.activationDate).format('DD-MM-YYYY') : 'Not Activated'}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.expiryDate ? Moment(item?.expiryDate).format('DD-MM-YYYY') : 'Not Activated'}
                                                </div>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.planName}
                                                </span>
                                            </td>
                                            <td className="d-flex flex-row justify-content-between align-items-center w-100">
                                                <div className=''
                                                    onClick={()=>handleView(item)}
                                                    title="VIew">
                                                    <span className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm me-2'>
                                                        View
                                                    </span>
                                                </div>
                                                {item?.planName !== 'Premium' && (
                                                    <div className=''
                                                        onClick={()=>onClickUpgrade(item)}
                                                        title="Upgrade">
                                                        <span className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm'>
                                                            Upgrade
                                                        </span>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>

                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                        {data.length === 0 && (
                                <div style={{ textAlign: "center", height: "200px",width : '100%',display : 'flex', flexDirection : 'column',justifyContent : 'center', alignItems : 'center' }}>
                                <span>No Data</span>
                              </div>
                            )}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={dataCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                </Stack>
            </div>
            {show && (
				<Modal
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={show}
				onHide={() => setShow(false)}
				>
					<Modal.Header closeButton>
					<Modal.Title>Subscription Details</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{height : '500px',overflowY : 'auto'}}>
						<div className="d-flex flex-column">
                                    {modalData?.paymentDetails?.map((item,index)=>{
                                        return(
                                            <div className='card mb-5 mb-xl-10' >
                                                <div className='card-header cursor-pointer'>
                                                    <div className='mb-3' id='subscriptionDataTable'>
                                                <span className="fw-bold">Payment Details {index+1}</span>
                                                {Object.keys(item)?.map((key) => {
                                                    if(key === 'razorpayPaymentDetails' || key === 'razorpaySignature' || key === 'billingAddress') return null
                                                    const value = item[key]
                                                    return(
                                                    <div className='mt-3'>
                                                    <span  className='fw-bold'>{key} :</span> <span>{value ?? "NA"}</span>
                                                    </div>
                                                    )
                                                })}
                                                <hr/>
                                                <span className="fw-bold mt-5">Billing Address</span>
                                                <hr/>
                                                {Object.keys(item?.billingAddress)?.map((key) => {
                                                    const value = item?.billingAddress[key]
                                                    return(
                                                    <div className='mt-3'>
                                                    <span  className='fw-bold'>{key} :</span> <span>{value ?? "NA"}</span>
                                                    </div>
                                                    )
                                                })}
                                                <hr/>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })}
						</div>
					</Modal.Body>
				</Modal>
			)}
            {showSubscriptionModel && (
                <SubscriptionModal
                    show={showSubscriptionModel}
                    onHide={()=>setShowSubscriptionModel(false)}
                    plan ={activePlan}
                    handleChangePlan={handleChangePlan}
                    completeTask={completeTask}
                    title = {'Upgrade Plan'}
                    selectedSubscription = {selectedSubscription}
                    subscriptionId = {selectedSubscription?._id}
                />
            )}
        {loader && <PageLoader />}

        </div>

    );
};

export default SubscriptionsList;
