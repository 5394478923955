import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function UpdateRecordModal({
  open,
  handleClose,
  data,
  createLookUp,
}) {
  const [details, setDetails] = useState({
    Existing:"",
    New: "",
  });

  const handleChange = (event) => {
    setDetails({ ...details, New: event.target.value });
  };

  useEffect(() => {
    if (data) {
      setDetails({...data,Existing: data && data.name ? data.name : "",});
    }
  }, [data]);

  const handleSubmit = () => {
    const { Existing, New } = details;
    if (New.trim() === "") {
      return alert("please fill all the fields");
    }
    
    createLookUp(details);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {data && data._id ? "Update Record" : "Add Record"}
        </Typography>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "40ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Existing"
            id="outlined-size-normal"
            fullWidth
            value={details.Existing}
            // onChange={handleChange}
            name="name"
            disabled
          />
          <TextField
            label="New"
            id="outlined-size-normal"
            fullWidth
            value={details.New}
            onChange={handleChange}
            name="value"
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end" pt={2}>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="success" onClick={handleSubmit}>
              Submit
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
