import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { updateLocationStatus, getListings, getCounListingsByStatus,updateListingStatus, deleteEditListing } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { LocationsListFilter } from "../LocationsListFilter";
import { Box, CircularProgress } from "@mui/material";
import { LocationDataTable } from "../LocationDataTable";
import { useAuth } from "../../../auth";
import Reports from "../../Reports/Reports";
import { PageLoader } from "../../services/PageLoader";



const ReviewLocations = () => {
    const {getSideBarCount} = useAuth()
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>([]);
    const [locationCount, setLocationCount] = useState<any>(0)
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [filterData,setFilterData] = useState<any>({});
    const [loader, setLoader] = useState(false);

    const getLocationData = async () => {
        try{
            // const countResp = await getCounListingsByStatus({status : "Approved", searchTerm: searchTerm.toLocaleUpperCase(),...filterData});
            const resp = await getListings({page : 1,items : 50,status : "Verified", searchTerm: searchTerm.toLocaleUpperCase(),...filterData});
            setLocationCount(Math.ceil(resp?.data?.count/50)) //items per page is 50 So, dividing with 50 to make no of pages appear
            const locData = resp?.data?.data;
            setData(locData);
            setIsLoading(false)
        }catch(error:any){
            console.log(error?.response?.data?.message)
        }
    }
    useEffect(() => {
        getLocationData();
    }, [searchTerm, filterData]);

    const handleChange = async (event, value) => {
        try{
            const resp = await getListings({ page: value, items: 50, status: "Verified", searchTerm: searchTerm.toLocaleUpperCase(),...filterData });
            setData(resp?.data?.data);
        }catch(error:any){
            console.log(error?.response?.data?.message)
        }
    };

    const editLocation = (location_id) => {
        navigate(`../listing/${location_id}`);
    }

    const approveLocation = async (location_id,data) => {
        setLoader(true);
        try{
            await updateListingStatus(location_id, "Approved",data);
            alert("Location : " + location_id + " " + 'Approved' + " sucessfully")
            getLocationData();
            getSideBarCount()
        }catch(error:any){
            console.log(error?.response?.data?.message);
            alert(error?.response?.data?.message);
        }
        setLoader(false);
    }

    const rejectLocation = async (location_id,type,data) => {
        try{
            await updateListingStatus(location_id, "Rejected",data);
            alert("Location : " + location_id + " " + 'Rejected' + " sucessfully")
            getLocationData();
            getSideBarCount()
        }catch(error:any){
            console.log(error?.response?.data?.message);
            alert(error?.response?.data?.message);
        }
    }

    const onDeleteListing = async (location_id) => {
        try{
            const resp : any = await deleteEditListing(location_id)
            alert("Location : " + location_id + ` Deleted sucessfully`)
        }catch(error : any){
            console.log(error?.response?.data?.message)
        }
    }


    useEffect(() => {
        getLocationData();
    }, [searchTerm, filterData])

    const getView = () => {
        if (isLoading) {
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="85vh"
            >
              <CircularProgress size={50} />
            </Box>
          );
        }
    
        return (
          <>
            <LocationDataTable
                    tableName="Location_for_Approval"
                    data={data} 
                    totalCount={locationCount}
                    editLocation={editLocation} 
                    deactivateLocation={undefined}
                    verifyLocation={undefined}
                    approveLocation={approveLocation}
                    rejectLocation={rejectLocation}
                    activateLocation={undefined}
                    deleteListing={undefined}
                    editedLisitng={false}
                    moveListing={false}
                />
          </>
        );
      };

    return (
        <>
            <div className="card">
                <div className="card-header border-0 pt-6">
                    <div className='card-title'>
                        {/* begin::Search */}
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                className='form-control form-control-solid w-250px ps-14'
                                placeholder='Search by id/type/user/city'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        {/* end::Search */}
                    </div>
                    <div className="card-toolbar">
                        <Reports table='verified'/>
                        <LocationsListFilter filterFormData={setFilterData} />
                    </div>
                </div>
                <div className={`card mb-5 mb-xl-8`}>
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            {getView()}
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                    <Stack spacing={2}>
                        <Pagination count={locationCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                    </Stack>
                </div>
            </div>
            {loader && <PageLoader />}
        </>

    );
};

export default ReviewLocations;