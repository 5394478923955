import { create } from "zustand";
import {jwtDecode} from "jwt-decode";
import { getAdminDetails } from "../services/api";

export const useAuthStore = create((set) => ({
  isAuthenticated: false,
  setAuthenticated: () =>
    set(() => ({ isAuthenticated: !!localStorage.getItem("admin") })),
  userProfile: null,
  setUserProfile: async () => {
    const jwtToken = localStorage.getItem("admin");
    if (!!jwtToken) {
      const decodeInfo = jwtDecode(jwtToken);
      const response = await getAdminDetails(decodeInfo._id);
      set(() => ({ userProfile: response.data }));
    }
  },
}));