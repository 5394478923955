import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import { useEffect, useState, useRef } from "react";
import globe from "../../../../_metronic/assets/images/vector-globe-icon.webp";
import india from "../../../../_metronic/assets/images/India.webp"
import unitedStates from "../../../../_metronic/assets/images/unitedStates.webp"
import profile from '../../../assets/images/profile-avatar.webp'
import { useAuth } from '../../../../app/modules/auth';
import Badge from '@mui/material/Badge';
import { adminNotifications, getRegions } from '../../../../app/modules/apps/services/api';

//regions
// const REGIONS = [{name : 'India', flag : india}, {name : 'United States', flag : unitedStates}]  

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth()

  //region
  const savedReg = localStorage.getItem('region')
  const savedRegId = localStorage.getItem('regionId')
  // let selectedReg : any 
  // if(savedReg && savedReg === 'India') selectedReg = {country_name : 'India', flag : india}
  // if(savedReg && savedReg === 'United States') selectedReg= {country_name : 'United States', flag : unitedStates}
  const [allRegions, setAllRegions] = useState<any>();
  const [region,setRegion] = useState<any>()

  const selectRegion = (reg : any) => {
    setRegion(reg)
    localStorage.setItem('region',reg.country_name) //set region
    localStorage.setItem('regionId',reg.country_id) //set region id
    if(reg.country_name !== region.country_name) window.location.reload();
  }

  //notifications
  const timerId = useRef<any>(null);
  const [notifications,setNotifications] = useState<any>([])
  const getData = async () => {
    try{
      const resp = await adminNotifications()
      setNotifications(resp.data)

    }catch(error){
      console.log(error)
    }
  }
  useEffect(()=>{
    timerId.current = setInterval(() => {
      getData()
    }, 5000);

    return () => {
      clearInterval(timerId.current);
    };
  },[]);

  const getAllRegions = async() => {
    try {
      const response = await getRegions();
      setAllRegions(response?.data);
      if (!savedReg) {
        setRegion(response?.data[0])
        localStorage.setItem('region', response?.data[0]?.country_name) //set region
        localStorage.setItem('regionId', response?.data[0]?.country_id) //set region id
      } else {
        const reqRegion = response?.data?.filter((each : any) => each?.country_id === savedRegId)
        setRegion(reqRegion[0])
      }
    } catch(error) {
      console.log("get regions error", error)
    }
  }

  useEffect(() => {
    getAllRegions()
  }, [])


  return (
    <div className='app-navbar flex-shrink-0'>
      {region  && 
      <div className={clsx('app-navbar-item align-items-center dropdown', itemClass)}>
        <a className="dropdown-toggle region-toggle" title="Region" style={{color:'#000'}} href="#" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"
        >
        {region && <img src={ region?.country_image ? region?.country_image  : globe} style={{height:'30px',width:'30px',borderRadius:'50%'}}/> }
        </a>
        <div className="dropdown-menu" style={{ backgroundColor: "white" }} aria-labelledby="navbarDropdownMenuLink">
          { allRegions?.length > 0 &&
          allRegions?.map((reg : any) => (
            <div className={`dropdown-item d-flex justify-content-between align-center ${currentUser?.role_id === "Super Admin" ? "" : "disabled"}`} onClick={() => selectRegion(reg)}>
            <div className="d-flex justify-content-center align-center">
              <img style={{height:'30px',width:'30px',borderRadius:'50%',marginRight:'10px'}} alt = {reg.country_name} src = {reg?.country_image}/>
                <p className="m-0">{reg.country_name}</p>  
                {region.country_name === reg.country_name && <i className="bi bi-check2"></i>} 
            </div>
            </div>
          ))}
        </div>
      </div>
      }
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
      <Badge badgeContent={notifications?.length} invisible={notifications?.length === 0} color="primary">
          <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
            <KTIcon iconName='message-text-2' className={btnIconClass} />
            {/* <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50' />   */}
            {/* animation-blink */}
          </div>
        </Badge>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={currentUser?.avatar || profile} alt='profile image' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
