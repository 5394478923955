import { useEffect, useState } from 'react';
import {updateSpotletUser, getUserData, getListingIds } from '../../services/api';
import Modal from 'react-bootstrap/Modal';
import { PageLoader } from '../../services/PageLoader';

//multi salect start
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
  //multi salect end

export function UpdateUserModal({show,onHide,onSucces,title,id}) {
    const [formData,setFormData] = useState({listingIds : []})
    const [loader,setLoader] = useState(false)
    const [listingIds,setLisitngIds] = useState([])
    const [isGoogleEmail,setIsGoogleEmail] = useState(false);
    const [isEmailVerified,setIsEmailVerified] = useState(false);
    const [mobileVerified,setMobileVerified] = useState(false);

    const submitForm = async (e) => {
        e.preventDefault()
        setLoader(true)
       try{
        const resp = await updateSpotletUser(formData)
        alert(resp?.data?.message)
        document.getElementById('userPageAddUserForm').reset()
        onSucces()
       }catch(error){
        console.log(error)
        alert(error?.response?.data?.message)
       }
       setLoader(false)
    }
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value
        })
    }
    const handleListingIds = (data) => {
        const temp = []
        data?.map((item) => {
            temp.push(item.title)
        })
        setFormData({
            ...formData,
            listingIds : temp
        })
      };
    const getData = async()=>{
        try{
            const resp = await getUserData(id)
            const data = resp?.data?.personalInfo
            if (data?.googleLogin) {
                setIsGoogleEmail(true)
            }
            if (data?.emailVerified) {
                setIsEmailVerified(true)
            }
            if (data?.whatsappLogin) {
                setMobileVerified(true)
            }
            setFormData({
                fullName : data?.fullName,
                email : data?.email,
                mobile : data?.mobile.startsWith("+91") ? data?.mobile?.slice(3) : data?.mobile ,
                // password : data?.password,
                id : resp?.data?._id,
            })
        }catch(error){
            console.log(error?.response?.data?.message)
        }
    }
    const getIds = async() => {
        try{
            const resp = await getListingIds('Approved')
            const temp = resp?.data?.map((item)=> {
                return {
                    title : item,
                    year : item,
                }
            })
            setLisitngIds(temp)
        }catch(error){
            console.log(error?.response?.data?.message)
        }
    }
    useEffect(()=> {
        getData()
        getIds()
    },[]);

  return (
    <Modal
    show={show}
    onHide={onHide}
    keyboard={false}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={submitForm} id='userPageAddUserForm'>
            {/* <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold mb-2 required">First Name</label>
                <input type="text" required onChange={onChange} className="form-control form-control-solid" placeholder="Enter First Name" name="firstName" value={formData?.firstName} />
            </div>
            <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold mb-2">Last Name</label>
                <input type="text" onChange={onChange} className="form-control form-control-solid" placeholder="Enter Last Name" name="lastName" value={formData?.lastName} />
            </div> */}
            <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold mb-2">Full Name</label>
                <input type="text" onChange={onChange} className="form-control form-control-solid" placeholder="Enter Full Name" name="fullName" value={formData?.fullName} />
            </div>
            <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold mb-2">
                    <span className="required">Email</span>
                    <span className="ms-1" data-bs-toggle="tooltip" title="Email address must be active">
                        <i className="ki-duotone ki-information fs-7">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                        </i>
                    </span>
                </label>
                <input type="email" disabled = {isEmailVerified} pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$" onChange={onChange} className="form-control form-control-solid" placeholder="Enter Email" name="email" value={formData?.email}/>
            </div>
            {/* <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold mb-2">
                    <span className="">Phone Number</span>
                </label>
                <input type="text" disabled = {mobileVerified} onChange={onChange} className="form-control form-control-solid" placeholder="Enter Mobile Number" name="mobile" value={formData?.mobile}/>
            </div> */}
            <div className={`d-flex flex-column ${true ? "mb-0" : "mb-4"}`}>
                <label htmlFor="registerMobile"  className="fs-6 fw-semibold mb-2">Mobile</label>
                <div className="w-100 d-flex flex-row justify-content-start align-items-center">
                    <div className="form-control form-control-solid text-muted w-20 mx-1" style={{width : "50px"}}>+91</div>
                    <input
                        disabled = {mobileVerified}
                        className="form-control form-control-solid"
                        type="text"
                        id="registerMobile"
                        name="mobile"
                        value={formData?.mobile}
                        onChange={onChange}
                        placeholder="Mobile"
                        title="Enter 10-digit indian mobile number"
                        pattern="^[6-9]\d{9}$"
                    />
                </div>
                <p className="mt-2">Enter 10-digit indian mobile number.</p>
            </div>
            {
               (!isGoogleEmail && !mobileVerified) && (
                <>
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold mb-2">
                            <span className="required">Update Password</span>
                        </label>
                        <input type="text" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}" title="Must contain at least one number and one uppercase and lowercase letter and one special symbol, and at least 8 or more characters" onChange={onChange} className="form-control form-control-solid" placeholder="Enter Password" name="password" value={formData?.password}/>
                    </div>
                    <p className="mb-2">Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters.</p>
                </>
                )
            }
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Trasfer Listings</span>
              </label>

              <div className='col-lg-8 fv-row'>
              <Autocomplete
                    multiple
                    id="tags-standard"
                    options={listingIds}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, newValue) => {
                        handleListingIds(newValue)
                      }}    
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Select Listing Ids"
                        placeholder="Select Listing Ids"
                    />
                    )}
                />
              </div>
            </div> */}
            {
            (!isGoogleEmail && !mobileVerified) && 
            <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold mb-2">
                    <span className="">Send credentials through </span>
                </label>
                <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                    <input className="form-check-input" type="checkbox" checked={formData?.sendCred} name="sendCred"  onChange={(e) => setFormData({...formData,sendCred : e.target.checked})}/>
                    <span className="form-check-label">Email</span>
                </label>
                {/* <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                    <input className="form-check-input" type="checkbox" checked={formData?.sendCred} name="sendCred"  onChange={(e) => setFormData({...formData,sendCred : e.target.checked})}/>
                    <span className="form-check-label">Mobile</span>
                </label>
                <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                    <input className="form-check-input" type="checkbox" checked={formData?.sendCred} name="sendCred"  onChange={(e) => setFormData({...formData,sendCred : e.target.checked})}/>
                    <span className="form-check-label">Whats app</span>
                </label> */}
            </div>
            }
            <div className="modal-footer flex-end">
                <button type="submit" className="btn btn-primary">
                    <span className="indicator-label">Submit</span>
                </button>
            </div>
        </form>
        {loader && <PageLoader/>}
      </Modal.Body>
    </Modal>
  );
}