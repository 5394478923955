import {useState} from 'react'
import { KTIcon } from '../../../../_metronic/helpers'

const LocationsListFilter = ({filterFormData}) => {
  const [formData,setFormData] = useState<any>({})
  const resetData = () => {
    setFormData({})
    filterFormData({});
  }
  const filterData = () => {
    filterFormData(formData);
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        //disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10 d-flex flex-column'>
            <label className='form-label fs-6 fw-bold'>Date from:</label>
            <input
              className='form-control form-control-solid w-250px ps-14'
              type='date'
              name='dateFrom'
              value={formData?.dateFrom || ''}
              onChange={(e) => setFormData({dateFrom : e.target.value,dateTo : ''})}
              placeholder='Enter date'
              max={new Date().toISOString().split("T")[0]}
             />
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='mb-10 d-flex flex-column'>
            <label className='form-label fs-6 fw-bold'>Date to:</label>
            <input
              className='form-control form-control-solid w-250px ps-14'
              type='date'
              name='dateTo'
              value={formData?.dateTo || ''}
              onChange={(e) => setFormData({...formData,dateTo : e.target.value})}
              placeholder='Enter date'
              min={new Date(formData?.dateFrom || null).toISOString().split("T")[0]}
              max={new Date().toISOString().split("T")[0]}
             />
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              //disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              //disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {LocationsListFilter}
