import { useEffect, useRef, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { ListingType } from './steps/ListingType'
import { Details } from './steps/Details'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import { Step5 } from './steps/Step5'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { Form, Formik, FormikValues } from 'formik'
import { createLocationSchemas, ICreateLocation, inits } from './CreateLocationHelper'
import { Amenities } from './steps/Amenities'
import { DosDontsRules } from './steps/DosDontsRules'
import { getLocationById, getTempLocation, updateIncompleteLocation, updateLocation } from '../../services/api'
import { useParams } from 'react-router-dom'
import Location from './steps/Location'
import Pricing from './steps/Pricing'
import Timings from './steps/Timings'
import PropertyMangerDetails from './steps/PropertyMangerDetails'
import Photos from './steps/Photos'
import Videos from './steps/Videos'

const EditLocation = ({ isTempLocation }) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createLocationSchemas[0])
  const [initValues, setInitValues] = useState<any>(inits)

  const params = useParams();

  const fetchData = async () => {

    var locationData;
    if (isTempLocation) {
      locationData = await getTempLocation(params?.locationId);
    } else {
      locationData = await getLocationById(params?.locationId);
    }
    console.log("locationData = ", locationData?.data);
    setInitValues({ ...initValues, ...locationData?.data });

  };

  useEffect(() => {
    fetchData();
  }, []);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createLocationSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: ICreateLocation, actions: FormikValues) => {
    console.log(values);
    if (!stepper.current) {
      return
    }

    if (isTempLocation) {
      const response = await updateIncompleteLocation({ data: values, location_id: params?.locationId });

    } else {
      const response = await updateLocation({ newLocData: values, location_id: params?.locationId });
      console.log(response);
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }

    // setCurrentSchema(createLocationSchemas[stepper.current.currentStepIndex - 1])
  }

  const goToStep = (stepNum: number) => {
    stepper.current && stepper.current.goto(stepNum)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      {/* begin::Aside*/}
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        {/* begin::Wrapper*/}
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => goToStep(1)}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Details & Description</h3>
                  <div className='stepper-desc fw-semibold'>Setup Your Property Details</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}

            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => goToStep(2)}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Location</h3>
                  <div className='stepper-desc fw-semibold'>Set Your Location address</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>

            {/* begin::Step 3*/}
            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => goToStep(3)}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Amenities & Features</h3>
                  <div className='stepper-desc fw-semibold'>Amenities & Features Info</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 4*/}
            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => goToStep(4)}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Do’s & Don’ts</h3>
                  <div className='stepper-desc fw-semibold'>Set Your Do’s & Don’ts</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 4*/}

            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => goToStep(5)}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>5</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Pricing</h3>
                  <div className='stepper-desc fw-semibold'>Set Your Pricing</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => goToStep(6)}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>6</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Timings</h3>
                  <div className='stepper-desc fw-semibold'>Set Your Timings</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => goToStep(7)}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>7</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Property Manager</h3>
                  <div className='stepper-desc fw-semibold'>Set Your Property Manager details</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>

            {/* begin::Step 5*/}
            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => goToStep(8)}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>8</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Photos</h3>
                  <div className='stepper-desc fw-semibold'>Upload your photos</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              <div className='stepper-line h-40px'></div>
            </div>
            {/* end::Step 5*/}

            <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => goToStep(9)}>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>9</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Videos</h3>
                  <div className='stepper-desc fw-semibold'>Upload your Videos</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}

      <div className='d-flex flex-row-fluid bg-body rounded'>
        {/* <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}> */}
        <Formik
          initialValues={initValues}
          onSubmit={submitStep}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              {/* <div className='current' data-kt-stepper-element='content'>
                <ListingType />
              </div> */}

              <div className='current' data-kt-stepper-element='content'>
                <Details locationData={values} />
              </div>

              <div data-kt-stepper-element='content'>
                <Location locationData={values} />
              </div>

              <div data-kt-stepper-element='content'>
                <Amenities locationData={values} />
              </div>

              <div data-kt-stepper-element='content'>
                <DosDontsRules locationData={values} />
              </div>

              <div data-kt-stepper-element='content'>
                <Pricing locationData={values} />
              </div>

              <div data-kt-stepper-element='content'>
                <Timings locationData={values} />
              </div>

              <div data-kt-stepper-element='content'>
                <PropertyMangerDetails locationData={values} />
              </div>

              <div data-kt-stepper-element='content'>
                <Photos locationData={values} />
              </div>


              <div data-kt-stepper-element='content'>
                <Videos locationData={values} />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                </div>

                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex !==
                        stepper.current?.totalStepsNumber! - 1 && 'Continue'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totalStepsNumber! - 1 && 'Submit'}
                      <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export { EditLocation }
