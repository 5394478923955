import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import info from "../../../../../../_metronic/assets/images/i.svg";
//import { useLocationStore } from "../../store/location";
//import { ConfirmationModel } from '../Modal/ConfirmationModel';

type Props = {
  locationData?: any
}

const Pricing = ({ locationData}) => {
  //const { location: locationData } = useLocationStore();
  // const [formData, setFormData] = useState({});
  const [cleaningFee, setCleaningFee] = useState("");
  const [securityDeposite, setSecurityDeposite] = useState("");

  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationBody, setConfirmationBody] = useState("");
  const [showAttendeesError, setShowAttendeesError] = useState(false);

  const [paymentAgreed, setPaymentAgreed] = useState(false);

  const [film, setFilm] = useState({
    type: "Film",
    isPresent: false,
    hourly_rate: 0,
    attendees:{
      min : '',
      max : ''
    },
    hourly_discount: 10,
    day_discount: 20,
    halfDayPrice: 0,
    fullDayPrice: 0,
    cleaningFee: '',
    securityDeposite: '',
  });
  const [tv, setTV] = useState({
    type: "TV",
    isPresent: false,
    hourly_rate: 0,
    attendees:{
      min : '',
      max : ''
    },
    hourly_discount: 10,
    day_discount: 20,
    halfDayPrice: 0,
    fullDayPrice: 0,
    cleaningFee: '',
    securityDeposite: '',
  });
  const [corporate, setCorporate] = useState({
    type: "Corporate",
    isPresent: false,
    hourly_rate: 0,
    attendees:{
      min : '',
      max : ''
    },
    hourly_discount: 10,
    day_discount: 20,
    halfDayPrice: 0,
    fullDayPrice: 0,
    eightHrsPrice: 0,
    cleaningFee: '',
    securityDeposite: '',
  });
  const [individual, setIndividual] = useState({
    type: "Individual",
    isPresent: false,
    hourly_rate: 0,
    attendees:{
      min : '',
      max : ''
    },
    hourly_discount: 10,
    day_discount: 20,
    halfDayPrice: 0,
    fullDayPrice: 0,
    eightHrsPrice: 0,
    cleaningFee: '',
    securityDeposite: '',
  });

  useEffect(() => {
    if (locationData && locationData?.pricing) {
      locationData.pricing && setFilm(locationData.pricing.film_webseries_ad);
      locationData.pricing && setTV(locationData.pricing.tv_series_other);
      locationData.pricing && setCorporate(locationData.pricing.corporate);
      locationData.pricing && setIndividual(locationData.pricing.individual);
      locationData.pricing && setCleaningFee(locationData.pricing.cleaningFee);
      locationData.pricing && setSecurityDeposite(locationData.pricing.securityDeposite);
      locationData.pricing && setPaymentAgreed(locationData.pricing.paymentAgreed);
    }
  }, [locationData]);

  const handleBack = () => {
    //setTab("do_dont");
  };

  const showError = (message) => {
    setConfirmationTitle("Pricing");
    setConfirmationBody(message);
    setShowConfirmationModel(true);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!film?.isPresent && !tv?.isPresent && !corporate?.isPresent && !individual?.isPresent)
      return showError("Please add atleast one event type!");

    if (film?.isPresent && !film?.hourly_rate)
      return showError("Please enter hourly rate of Film!");
    if (tv?.isPresent && !tv?.hourly_rate)
      return showError("Please enter hourly rate of Tv!");
    if (corporate?.isPresent && !corporate?.hourly_rate)
      return showError("Please enter hourly rate of Corporate!");
    if (individual?.isPresent && !individual?.hourly_rate)
      return showError("Please enter hourly rate of Individual!");

    // if (film?.isPresent) {
    //   if (!film.attendees || film.attendees === "") {
    //     return showError("Max capacity of the location is missing of Film Event!");
    //   }

    // }
    // if (tv?.isPresent) {
    //   if (!tv.attendees || tv.attendees === "") {
    //     return showError("Max capacity of the location is missing of TV Event!");
    //   }
    // }
    // if (corporate?.isPresent) {
    //   if (!corporate.attendees || corporate.attendees === "") {
    //     return showError("Max capacity of the location is missing of Corporate Event!");
    //   }
    // }
    // if (individual?.isPresent) {
    //   if (!individual.attendees || individual.attendees === "") {
    //     return showError("Max capacity of the location is missing of Individual Event!");
    //   }
    // }

    // if (!cleaningFee)
    //   return showError("Please enter cleaning fee!");

    if (!paymentAgreed)
      return showError("Please accept the payment gatway charges!");

    // let payload = {
    //   paymentAgreed,
    //   cleaningFee,
    //   securityDeposite,
    //   film_webseries_ad:
    //     film,
    //   tv_series_other:
    //     tv,

    //   individual:
    //     individual,

    //   corporate:
    //     corporate,
    // }

    if (localStorage.getItem("locationId")) {
      //callbackFn({ pricing: payload });
      //setTab("timing");
    }

  };
  //Film Web Series
  //Film Half Day Price
  useMemo(() => {
    const hourlyPrice = film?.hourly_rate || 0;
    const hourlyDiscount = film?.hourly_discount || 0;
    const discountPercentage = (100 - hourlyDiscount) / 100;
    const newHalfDayPrice = parseFloat((hourlyPrice * 12 * discountPercentage).toFixed(2));
    setFilm((prevFilm) => ({
      ...prevFilm,
      halfDayPrice: newHalfDayPrice,
    }));
    locationData.pricing = {...locationData?.pricing,film_webseries_ad : {...locationData?.pricing?.film_webseries_ad,halfDayPrice : newHalfDayPrice}}
  }, [film?.hourly_rate, film?.hourly_discount]);

  //Film Full Day Price
  useMemo(() => {
    const hourlyPrice = film?.hourly_rate || 0;
    const dayDiscount = film?.day_discount || 0;
    const discountPercentage = (100 - dayDiscount) / 100;
    const newFullDayPrice = parseFloat((hourlyPrice * 24 * discountPercentage).toFixed(2));
    setFilm((prevFilm) => ({
      ...prevFilm,
      fullDayPrice: newFullDayPrice,
    }));
    locationData.pricing = {...locationData?.pricing,film_webseries_ad : {...locationData?.pricing?.film_webseries_ad,fullDayPrice: newFullDayPrice}}
  }, [film?.hourly_rate, film?.day_discount]);

  //Tv Serial
  //Tv Half Day Price
  useMemo(() => {
    const hourlyPrice = tv?.hourly_rate || 0;
    const hourlyDiscount = tv?.hourly_discount || 0;
    const discountPercentage = (100 - hourlyDiscount) / 100;
    const newHalfDayPrice = parseFloat((hourlyPrice * 12 * discountPercentage).toFixed(2));
    setTV((prevTv) => ({
      ...prevTv,
      halfDayPrice: newHalfDayPrice,
    }));
    locationData.pricing = {...locationData?.pricing,tv_series_other : {...locationData?.pricing?.tv_series_other, halfDayPrice: newHalfDayPrice}}
  }, [tv?.hourly_rate, tv?.hourly_discount]);

  //Tv Full Day Price
  useMemo(() => {
    const hourlyPrice = tv?.hourly_rate || 0;
    const dayDiscount = tv?.day_discount || 0;
    const discountPercentage = (100 - dayDiscount) / 100;
    const newFullDayPrice = parseFloat((hourlyPrice * 24 * discountPercentage).toFixed(2));
    setTV((prevTv) => ({
      ...prevTv,
      fullDayPrice: newFullDayPrice,
    }));
    locationData.pricing = {...locationData?.pricing,tv_series_other : {...locationData?.pricing?.tv_series_other, fullDayPrice: newFullDayPrice}}
  }, [tv?.hourly_rate, tv?.day_discount]);

  // Coporate
  //Corporate Eight Hours Price
  useMemo(() => {
    const hourlyPrice = corporate?.hourly_rate || 0;
    const newEightHrsPrice = parseFloat((hourlyPrice * 8).toFixed(2));
    setCorporate((prevCorporate) => ({
      ...prevCorporate,
      eightHrsPrice: newEightHrsPrice,
    }))
    locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate, eightHrsPrice: newEightHrsPrice}}
  }, [corporate?.hourly_rate]);

  //Corporate Half Day Price
  useMemo(() => {
    const hourlyPrice = corporate?.hourly_rate || 0;
    const hourlyDiscount = corporate?.hourly_discount || 0;
    const discountPercentage = (100 - hourlyDiscount) / 100;
    const newHalfDayPrice = parseFloat((hourlyPrice * 12 * discountPercentage).toFixed(2));
    setCorporate((prevCorporate) => ({
      ...prevCorporate,
      halfDayPrice: newHalfDayPrice,
    }))
    locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate, halfDayPrice: newHalfDayPrice}}
  }, [corporate?.hourly_rate, corporate?.hourly_discount]);

  //Corporate Full Day Price
  useMemo(() => {
    const hourlyPrice = corporate?.hourly_rate || 0;
    const dayDiscount = corporate?.day_discount || 0;
    const discountPercentage = (100 - dayDiscount) / 100;
    const newFullDayPrice = parseFloat((hourlyPrice * 24 * discountPercentage).toFixed(2));
    setCorporate((prevCorporate) => ({
      ...prevCorporate,
      fullDayPrice: newFullDayPrice,
    }));
    locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate, fullDayPrice: newFullDayPrice}}
  }, [corporate?.hourly_rate, corporate?.day_discount]);

  // Individual
  // Individual Eight Hours Price
  useMemo(() => {
    const hourlyPrice = individual?.hourly_rate || 0;
    const newEightHrsPrice = parseFloat((hourlyPrice * 8).toFixed(2));
    setIndividual((prevIndividual) => ({
      ...prevIndividual,
      eightHrsPrice: newEightHrsPrice,
    }));
    locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual, eightHrsPrice: newEightHrsPrice}}
  }, [individual?.hourly_rate]);

  // Individual Half Day Price
  useMemo(() => {
    const hourlyPrice = individual?.hourly_rate || 0;
    const hourlyDiscount = individual?.hourly_discount || 0;
    const discountPercentage = (100 - hourlyDiscount) / 100;
    const newHalfDayPrice = parseFloat((hourlyPrice * 12 * discountPercentage).toFixed(2));
    setIndividual((prevIndividual) => ({
      ...prevIndividual,
      halfDayPrice: newHalfDayPrice,
    }));
    locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual, halfDayPrice: newHalfDayPrice}}
  }, [individual?.hourly_rate, individual?.hourly_discount]);

  // Individual Full Day Price
  useMemo(() => {
    const hourlyPrice = individual?.hourly_rate || 0;
    const dayDiscount = individual?.day_discount || 0;
    const discountPercentage = (100 - dayDiscount) / 100;
    const newFullDayPrice = parseFloat((hourlyPrice * 24 * discountPercentage).toFixed(2));
    setIndividual((prevIndividual) => ({
      ...prevIndividual,
      fullDayPrice: newFullDayPrice,
    }));
    locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual, fullDayPrice: newFullDayPrice}}
  }, [individual?.hourly_rate, individual?.day_discount]);



  return (
    <>
      <div
        className="tab-pane h-100 w-100 fade pricing-form show active"
        id="v-pills-pricing"
        role="tabpanel"
        aria-labelledby="v-pills-pricing-tab"
      >
        <div className="row g-0 h-100 w-100">
          <div className="col-xl-12">
            <form className="h-100 w-100">
              <div className="step-form h-100 w-100">
                <div>
                  <h3 className="ftitle">Pricing</h3>
                  <div className="scroolbox">
                    <div className="priceing-part">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Film/ Ad Film/ Web Series Shoot
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                            style={{ border: '1px solid black', borderRadius: "4px" }}
                          >
                            <div className="accordion-body">
                              <div className="form-check form-switch ms-auto pb-2">
                                <input
                                  className="form-check-input headingOne"
                                  type="checkbox"
                                  id="switchOne"
                                  onChange={(e) => {
                                    setFilm({
                                      ...film,
                                      isPresent: e.target.checked,
                                    });
                                    locationData.pricing = {...locationData?.pricing,film_webseries_ad : {...locationData?.pricing?.film_webseries_ad,isPresent : e.target.checked}}
                                  }}
                                  checked={film?.isPresent}
                                />
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="hourlyprice" className="form-label">
                                      Enter hourly price in INR<span>*</span>
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="hourlyprice"
                                        onChange={(e) => {
                                          setFilm({ ...film, hourly_rate: parseInt(e.target.value?.replace(/\,/, "")) })
                                          locationData.pricing = {...locationData?.pricing,film_webseries_ad : {...locationData?.pricing?.film_webseries_ad,hourly_rate : parseInt(e.target.value?.replace(/\,/, ""))}}
                                        }}
                                        value={film?.hourly_rate}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      // hmlFor="locationcapacity"
                                      className="form-label "
                                    >
                                      No of Attendees Allowed<span>*</span>
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                      <div className="d-flex">
                                        <input 
                                          type="number"
                                          className="form-control"
                                          style={{marginRight : '10px'}}
                                          placeholder="Min"
                                          value={film.attendees.min}
                                          onChange={(e) =>{
                                            setFilm({
                                              ...film,
                                              attendees: {
                                                max :film.attendees.max,
                                                min :e.target.value
                                              },
                                            })
                                            locationData.pricing = {...locationData?.pricing,film_webseries_ad : {...locationData?.pricing?.film_webseries_ad,attendees: {...locationData?.pricing?.film_webseries_ad?.attendees,min : e.target.value}}}
                                          }}
                                          />
                                          <input 
                                          type="number"
                                          className="form-control"
                                          placeholder="Max"
                                          value={film.attendees.max}
                                          onChange={(e) =>{
                                            setFilm({
                                              ...film,
                                              attendees: {
                                                min :film.attendees.min,
                                                max :e.target.value
                                              },
                                            })
                                            locationData.pricing = {...locationData?.pricing,film_webseries_ad : {...locationData?.pricing?.film_webseries_ad,attendees: {...locationData?.pricing?.film_webseries_ad?.attendees,max : e.target.value}}}
                                          }}
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                      Discount for half day booking(6AM-6PM)
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart d-flex flex-row align-items-center">
                                      <input 
                                        type="number"
                                        className="form-control"
                                        onChange={(e) =>{
                                          setFilm({
                                            ...film,
                                            hourly_discount: parseInt(e.target.value),
                                          })
                                          locationData.pricing = {...locationData?.pricing,film_webseries_ad : {...locationData?.pricing?.film_webseries_ad,hourly_discount: parseInt(e.target.value)}}
                                        }}
                                        value={film?.hourly_discount}
                                        autoComplete="off"
                                      />
                                      <i className="percentage">%</i>
                                      {/* <p>% Discount</p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="halfdayprice" className="form-label">
                                      Half day price in INR
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        disabled
                                        value={
                                          film?.halfDayPrice}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fullday" className="form-label">
                                      Discount for full day booking(6AM-2AM)
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart d-flex flex-row align-items-center">
                                      <input 
                                        type="number"
                                        className="form-control"
                                        onChange={(e) =>{
                                          setFilm({
                                            ...film,
                                            day_discount: parseInt(e.target.value),
                                          })
                                          locationData.pricing = {...locationData?.pricing,film_webseries_ad : {...locationData?.pricing?.film_webseries_ad,day_discount: parseInt(e.target.value)}}
                                        }}
                                        value={film?.day_discount}
                                      />
                                      <i className="percentage">%</i>
                                      {/* <p>% Discount</p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fulldayinr" className="form-label">
                                      Full day price in INR
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        disabled
                                        value={film?.fullDayPrice}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fullday" className="form-label">
                                      Cleaning Fee
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart">
                                      <input 
                                        type="text"
                                        className="form-control"
                                        value={film.cleaningFee}
                                        onChange={(e) =>{
                                          setFilm({
                                            ...film,
                                            cleaningFee: e.target.value,
                                          })
                                          locationData.pricing = {...locationData?.pricing,film_webseries_ad : {...locationData?.pricing?.film_webseries_ad,cleaningFee: e.target.value,}}
                                        }
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fulldayinr" className="form-label">
                                      Security Deposit
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon">
                                      {/* <i className="bi bi-currency-rupee"></i> */}
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={film.securityDeposite}
                                        onChange={(e) =>{
                                          setFilm({
                                            ...film,
                                            securityDeposite: e.target.value,
                                          })
                                          locationData.pricing = {...locationData?.pricing,film_webseries_ad : {...locationData?.pricing?.film_webseries_ad,securityDeposite: e.target.value,}}
                                        }
                                      }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              TV Series & Other Video Shoot
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                            style={{ border: '1px solid black', borderRadius: '4px' }}
                          >
                            <div className="accordion-body">
                              <div className="form-check form-switch ms-auto pb-2">

                                <input
                                  className="form-check-input headingOne"
                                  type="checkbox"
                                  id="switchTwo"
                                  onChange={(e) => {
                                    setTV({ ...tv, isPresent: e.target.checked })
                                    locationData.pricing = {...locationData?.pricing,tv_series_other : {...locationData?.pricing?.tv_series_other, isPresent: e.target.checked }}
                                  }}
                                  checked={tv?.isPresent}
                                />

                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="hourlyprice" className="form-label">
                                      Enter hourly price in INR<span>*</span>
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>

                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        onChange={(e) =>{
                                          setTV({
                                            ...tv,
                                            hourly_rate: parseInt(e.target.value.replace(/\,/, "")),
                                          })
                                          locationData.pricing = {...locationData?.pricing,tv_series_other : {...locationData?.pricing?.tv_series_other, hourly_rate: parseInt(e.target.value.replace(/\,/, "")) }}
                                        }}
                                        value={tv?.hourly_rate}
                                        autoComplete="off"

                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      // hmlFor="locationcapacity"
                                      className="form-label "
                                    >
                                      No of Attendees Allowed<span>*</span>
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                      <div className="d-flex">
                                        <input 
                                          type="number"
                                          className="form-control"
                                          style={{marginRight : '10px'}}
                                          placeholder="Min"
                                          value={tv.attendees.min}
                                          onChange={(e) =>{
                                            setTV({
                                              ...tv,
                                              attendees: {
                                                max :tv.attendees.max,
                                                min :e.target.value
                                              },
                                            })
                                            locationData.pricing = {...locationData?.pricing,tv_series_other : {...locationData?.pricing?.tv_series_other,attendees: {...locationData?.pricing?.tv_series_other?.attendees,min : e.target.value}}}
                                          }}
                                          />
                                          <input 
                                          type="number"
                                          className="form-control"
                                          placeholder="Max"
                                          value={tv.attendees.max}
                                          onChange={(e) =>{
                                            setTV({
                                              ...tv,
                                              attendees: {
                                                min :tv.attendees.min,
                                                max :e.target.value
                                              },
                                            })
                                            locationData.pricing = {...locationData?.pricing,tv_series_other : {...locationData?.pricing?.tv_series_other,attendees: {...locationData?.pricing?.tv_series_other?.attendees,max : e.target.value}}}
                                          }}
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                      Discount for half day booking(6AM-6PM){" "}
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart d-flex flex-row align-items-center">
                                      <input
                                        type="number"
                                        className="form-control"
                                        onChange={(e) =>{
                                          setTV({
                                            ...tv,
                                            hourly_discount: parseInt(e.target.value),
                                          })
                                          locationData.pricing = {...locationData?.pricing,tv_series_other : {...locationData?.pricing?.tv_series_other, hourly_discount: parseInt(e.target.value) }}
                                       } }
                                        value={tv?.hourly_discount}
                                        autoComplete="off"

                                      />
                                      <i className="percentage">%</i>
                                      {/* <p>% Discount</p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="halfdayprice" className="form-label">
                                      Half day price in INR{" "}
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>

                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        disabled
                                        value={tv?.halfDayPrice}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fullday" className="form-label">
                                      Discount for full day booking(6AM-2AM)
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart d-flex flex-row align-items-center">
                                      <input 
                                        type="number"
                                        className="form-control"
                                        onChange={(e) =>{
                                          setTV({
                                            ...tv,
                                            day_discount: parseInt(e.target.value),
                                          })
                                          locationData.pricing = {...locationData?.pricing,tv_series_other : {...locationData?.pricing?.tv_series_other, day_discount: parseInt(e.target.value) }}
                                        }}
                                        value={tv?.day_discount}
                                        autoComplete="off"

                                      />
                                      <i className="percentage">%</i>
                                      {/* <p>% Discount</p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fulldayinr" className="form-label">
                                      Full day price in INR{" "}
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>

                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        disabled
                                        //value={tv?.hourly_rate && (tv?.hourly_rate * 24 * ((100 - tv?.day_discount)/100)).toFixed(2)}
                                        value={tv?.fullDayPrice}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fullday" className="form-label">
                                      Cleaning Fee
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart">
                                      <input 
                                        type="text"
                                        className="form-control"
                                        value={tv.cleaningFee}
                                        onChange={(e) =>{
                                          setTV({
                                            ...tv,
                                            cleaningFee: e.target.value,
                                          })
                                          locationData.pricing = {...locationData?.pricing,tv_series_other : {...locationData?.pricing?.tv_series_other,cleaningFee: e.target.value,}}
                                        }
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fulldayinr" className="form-label">
                                      Security Deposit
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon">
                                      {/* <i className="bi bi-currency-rupee"></i> */}
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={tv.securityDeposite}
                                        onChange={(e) =>{
                                          setTV({
                                            ...tv,
                                            securityDeposite: e.target.value,
                                          })
                                          locationData.pricing = {...locationData?.pricing,tv_series_other : {...locationData?.pricing?.tv_series_other,securityDeposite: e.target.value,}}
                                        }
                                      }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Corporate Event
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                            style={{ border: '1px solid black', borderRadius: '4px' }}>
                            <div className="accordion-body">
                              <div className="form-check form-switch ms-auto pb-2">
                                <input
                                  className="form-check-input headingOne"
                                  type="checkbox"
                                  id="switchThree"
                                  onChange={(e) => {
                                    setCorporate({ ...corporate, isPresent: e.target.checked })
                                    locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate, isPresent: e.target.checked }}
                                  }}
                                  checked={corporate?.isPresent}
                                />
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="hourlyprice"
                                      className="form-label "
                                    >
                                      Enter hourly price in INR<span>*</span>{" "}
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>

                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="hourlyprice"
                                        onChange={(e) =>{
                                          setCorporate({
                                            ...corporate,
                                            hourly_rate: parseInt(e.target.value.replace(/\,/, "")),
                                          })
                                          locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate, hourly_rate: parseInt(e.target.value.replace(/\,/, ""))}}
                                        }}
                                        value={corporate?.hourly_rate}
                                        autoComplete="off"

                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      // hmlFor="locationcapacity"
                                      className="form-label "
                                    >
                                      No of Attendees Allowed<span>*</span>
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                      <div className="d-flex">
                                        <input 
                                          type="number"
                                          className="form-control"
                                          style={{marginRight : '10px'}}
                                          placeholder="Min"
                                          value={corporate.attendees.min}
                                          onChange={(e) =>{
                                            setCorporate({
                                              ...corporate,
                                              attendees: {
                                                max :corporate.attendees.max,
                                                min :e.target.value
                                              },
                                            })
                                            locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate,attendees: {...locationData?.pricing?.corporate?.attendees,min : e.target.value}}}
                                          }}
                                          />
                                          <input 
                                          type="number"
                                          className="form-control"
                                          placeholder="Max"
                                          value={corporate.attendees.max}
                                          onChange={(e) =>{
                                            setCorporate({
                                              ...corporate,
                                              attendees: {
                                                min :corporate.attendees.min,
                                                max :e.target.value
                                              },
                                            })
                                            locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate,attendees: {...locationData?.pricing?.corporate?.attendees,max : e.target.value}}}
                                          }}
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label "
                                    >
                                      8 hour Price
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input style={{width:"100%"}}
                                        type="number"
                                        className="form-control"
                                        disabled
                                        value={corporate?.eightHrsPrice}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label "
                                    >
                                      Discount for 12 hour Price
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart d-flex flex-row align-items-center">
                                      <input
                                        type="number"
                                        className="form-control"
                                      onChange={(e) =>{
                                        setCorporate({
                                          ...corporate,
                                          hourly_discount: parseInt(e.target.value),
                                        })
                                        locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate, hourly_discount: parseInt(e.target.value)}}
                                     } }
                                        value={corporate?.hourly_discount}
                                        autoComplete="off"
                                      />
                                      <i className="percentage">%</i>
                                      {/* <p>% Discount</p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="halfdayprice"
                                      className="form-label "
                                    >
                                      12 hour Price in INR
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>

                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        disabled
                                        value={corporate?.halfDayPrice}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="fullday"
                                      className="form-label "
                                    >
                                      Discount for 24 hour Price
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart d-flex flex-row align-items-center">
                                      <input
                                        type="number"
                                        className="form-control"

                                        onChange={(e) =>{
                                          setCorporate({
                                            ...corporate,
                                            day_discount: parseInt(e.target.value),
                                          })
                                          locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate, day_discount: parseInt(e.target.value)}}
                                        }}
                                        value={corporate?.day_discount}
                                        autoComplete="off"
                                      />
                                      <i className="percentage">%</i>
                                      {/* <p>% Discount</p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="fulldayinr"
                                      className="form-label "
                                    >
                                      24 hour Price in INR{" "}
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>

                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        disabled
                                        value={corporate?.fullDayPrice}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fullday" className="form-label">
                                      Cleaning Fee
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart">
                                      <input 
                                        type="text"
                                        className="form-control"
                                        value={corporate.cleaningFee}
                                        onChange={(e) =>{
                                          setCorporate({
                                            ...corporate,
                                            cleaningFee: e.target.value,
                                          })
                                          locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate,cleaningFee: e.target.value,}}
                                        }
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fulldayinr" className="form-label">
                                      Security Deposit
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon">
                                      {/* <i className="bi bi-currency-rupee"></i> */}
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={corporate.securityDeposite}
                                        onChange={(e) =>{
                                          setCorporate({
                                            ...corporate,
                                            securityDeposite: e.target.value,
                                          })
                                          locationData.pricing = {...locationData?.pricing,corporate : {...locationData?.pricing?.corporate,securityDeposite: e.target.value,}}
                                        }
                                      }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Individual Event
                            </button>
                          </h2>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                            style={{ border: '1px solid black', borderRadius: '4px' }}
                          >
                            <div className="accordion-body">
                              <div className="form-check form-switch ms-auto pb-2">

                                <input
                                  className="form-check-input headingOne"
                                  type="checkbox"
                                  id="switchFour"
                                  onChange={(e) => {
                                    setIndividual({ ...individual, isPresent: e.target.checked })
                                    locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual, isPresent: e.target.checked}}
                                 } }
                                  checked={individual?.isPresent}
                                />
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="hourlyprice"
                                      className="form-label "
                                    >
                                      Enter hourly price in INR<span>*</span>{" "}
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>

                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        onChange={(e) =>{
                                          setIndividual({
                                            ...individual,
                                            hourly_rate: parseInt(e.target.value.replace(/\,/, "")),
                                          })
                                          locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual, hourly_rate: parseInt(e.target.value.replace(/\,/, ""))}}
                                        }}
                                        value={individual?.hourly_rate}
                                        autoComplete="off"

                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      // hmlFor="locationcapacity"
                                      className="form-label "
                                    >
                                      No of Attendees Allowed<span>*</span>
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                      <div className="d-flex">
                                        <input 
                                          type="number"
                                          className="form-control"
                                          style={{marginRight : '10px'}}
                                          placeholder="Min"
                                          value={individual.attendees.min}
                                          onChange={(e) =>{
                                            setIndividual({
                                              ...individual,
                                              attendees: {
                                                max :individual.attendees.max,
                                                min :e.target.value
                                              },
                                            })
                                            locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual,attendees: {...locationData?.pricing?.individual?.attendees,min : e.target.value}}}
                                          }}
                                          />
                                          <input 
                                          type="number"
                                          className="form-control"
                                          placeholder="Max"
                                          value={individual.attendees.max}
                                          onChange={(e) =>{
                                            setIndividual({
                                              ...individual,
                                              attendees: {
                                                min :individual.attendees.min,
                                                max :e.target.value
                                              },
                                            })
                                            locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual,attendees: {...locationData?.pricing?.individual?.attendees,max : e.target.value}}}
                                          }}
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label "
                                    >
                                      8 hour Price{" "}
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input style={{width:"100%"}}
                                        type="number"
                                        className="form-control"
                                        disabled
                                        value={individual?.eightHrsPrice}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label "
                                    >
                                      Discount for 12 hour Price
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart d-flex flex-row align-items-center">
                                      <input
                                        type="number"
                                        className="form-control"
                                        onChange={(e) =>{
                                          setIndividual({
                                            ...individual,
                                            hourly_discount: parseInt(e.target.value),
                                          })
                                          locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual, hourly_discount: parseInt(e.target.value)}}
                                        }}
                                        value={individual?.hourly_discount}
                                        autoComplete="off"

                                      />
                                      <i className="percentage">%</i>
                                      {/* <p>% Discount</p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="halfdayprice"
                                      className="form-label "
                                    >
                                      12 hour Price in INR{" "}
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        disabled
                                        value={individual?.halfDayPrice}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="fullday"
                                      className="form-label "
                                    >
                                      Discount for 24 hour Price
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart d-flex flex-row align-items-center">
                                      <input
                                        type="number"
                                        className="form-control"
                                        onChange={(e) =>{
                                          setIndividual({
                                            ...individual,
                                            day_discount: parseInt(e.target.value),
                                          })
                                          locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual, day_discount: parseInt(e.target.value)}}
                                      }  }
                                        value={individual?.day_discount}
                                        autoComplete="off"
                                      />
                                      <i className="percentage">%</i>
                                      {/* <p>% Discount</p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label
                                      htmlFor="fulldayinr"
                                      className="form-label "
                                    >
                                      24 hour Price in INR{" "}
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon d-flex flex-row-reverse align-items-center">
                                      <i className="bi bi-currency-rupee"></i>
                                      <input
                                        type="number"
                                        className="form-control"
                                        disabled
                                        value={individual?.fullDayPrice}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fullday" className="form-label">
                                      Cleaning Fee
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="twopart">
                                      <input 
                                        type="text"
                                        className="form-control"
                                        value={individual.cleaningFee}
                                        onChange={(e) =>{
                                          setIndividual({
                                            ...individual,
                                            cleaningFee: e.target.value,
                                          })
                                          locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual,cleaningFee: e.target.value,}}
                                        }
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <label htmlFor="fulldayinr" className="form-label">
                                      Security Deposit
                                      <a href="#">
                                        <img src={info} />
                                      </a>
                                    </label>
                                    <div className="rupee-icon">
                                      {/* <i className="bi bi-currency-rupee"></i> */}
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={individual.securityDeposite}
                                        onChange={(e) =>{
                                          setIndividual({
                                            ...individual,
                                            securityDeposite: e.target.value,
                                          })
                                          locationData.pricing = {...locationData?.pricing,individual : {...locationData?.pricing?.individual,securityDeposite: e.target.value,}}
                                        }
                                      }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="cleaning mt-4">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-1">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label "
                            >
                              Cleaning Fee<span>*</span>
                              <a href="#">
                                <img src={info} />
                              </a>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={cleaningFee}
                              onChange={(e) => {
                                setCleaningFee(e.target.value.replace(/\,/, ""))
                                locationData.pricing = {...locationData?.pricing,cleaningFee : e.target.value.replace(/\,/, "")}
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-1">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label "
                            >
                              Security Deposit
                              <a href="#">
                                <img src={info} />
                              </a>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={securityDeposite}
                              onChange={(e) =>{
                                setSecurityDeposite(e.target.value.replace(/\,/, ""))
                                locationData.pricing = {...locationData?.pricing,securityDeposite : e.target.value.replace(/\,/, "")}
                             } }
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-check mt-2" style={{ marginLeft: "4px" }}>
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={paymentAgreed}
                          id="checked"
                          onChange={(e) =>{ 
                            setPaymentAgreed(e.target.checked)
                            locationData.pricing = {...locationData?.pricing,paymentAgreed : e.target.checked}
                          }}
                        />
                        <span className="checkbox-text">I read and understood that 2-3% will be deducted for payment gateway charges</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <div className="button-bar">
                    <a className="btn btn-gray" onClick={handleBack}>
                      Back
                    </a>
                    <a className="btn btn-theme" onClick={handleSubmit}>
                      Save & Continue
                    </a>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
          <div className="col-xl-4">
            <div className="detail-desc pricing"></div>
          </div>
        </div >
      </div >
      {showConfirmationModel && (
        <></>
        // <ConfirmationModel
        //   show={showConfirmationModel}
        //   handleClose={() => setShowConfirmationModel(false)}
        //   handleOk={() => setShowConfirmationModel(false)}
        //   title={confirmationTitle}
        //   body={confirmationBody}
        // />
      )}
    </>
  );
};

export default Pricing;