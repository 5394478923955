// Images
import { useState, useEffect} from "react";
import { getListingAmenities } from "../../../services/api";
import info from "../../../../../../_metronic/assets/images/i.svg";
import Select from 'react-select';


const SubAmenities = ({data,getSaved, getBack}) => {
    const [formData,setFormData] = useState([])
    const [amenitiesData,setAmenitiesData] = useState({'Basic Facilities' : ''})
    const [activeHead,setActiveHead] = useState('Basic Facilities')
    const [checkedAmenties,setCheckedAmenites] = useState([])
    const [mainHeadCount,setMainHeadCount] = useState([])
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationBody, setConfirmationBody] = useState("");
    const [inputAmenity, setInputAmenity] = useState("")

    const onClickMainHead = (value) => {
        setActiveHead(value)
    }

    const handleCheckBox = (e) => {
        const {name,checked} = e.target
        let newData;
        let newData2;
        let type;
        if(checked){
            Object.keys(amenitiesData).map((mainHead) => {
                Object.keys(amenitiesData[mainHead]).map((subHead) => {
                    if(subHead && subHead === name){
                        type = amenitiesData[mainHead][subHead].type
                        return null
                    }
                })
            })
            const tempAmenity = {
                name : name,
            }
            if(type) tempAmenity.type = type
            formData.push(tempAmenity)
            newData = [...formData]
            checkedAmenties.push(name)
            newData2 = [...checkedAmenties]
        }else{
            newData = formData.filter((each) => each.name !== name)
            newData2 = checkedAmenties.filter((each) => each !== name)
        }
        setFormData([...newData])
        setCheckedAmenites([...newData2])
    }
    const onChangeSub = (name,type,options) => {
        let values=[];
        if(type === 'single'){
            values = [options]
        }else{
            options.map((each) => values.push(each))
        }
        formData.map((each) => {
            if(each.name === name){
                each.sub = values
            }
        })
    }
    const getSubs = (sub) => {
        let value = []
        formData.map((each) => {
            if(each.name === sub && each.sub){
                value = each.sub
            }
        })
        return value
    }

    const submitForm = () => {
        if(formData.length < 2){
            setShowConfirmationModel(true)
            setConfirmationBody("Select atleast two amenities")
            return null
        }
        let isValid = true
        let subHead = ''
        for (const each of formData) {
            if(each?.type && !each?.sub){
                isValid = false
                subHead = each.name
                break
            }
          }

        if(!isValid){
            setShowConfirmationModel(true)
            setConfirmationBody(`Select sub category in ${subHead}.`)
            return null
        }
        handleSave()
    }

    const handleSave = () => {
        getSaved({amenities : formData })
    }

    const handleBack = () => {
        getBack();
    }
  
    useEffect(() => {
        const callBack = async()=>{
            try{
                const resp = await getListingAmenities()
                const temp = {}
                resp.map((type)=>{
                    temp[type.category] = type.types || {}
                })
                setAmenitiesData({...temp})
                if(data){
                    setFormData(data)
                    let values = [];
                    data.map((each) => {
                        values.push(each.name)
                    })
                    setCheckedAmenites(values)
                    let tempAmenites =[];
                    let activeAmenties = {}
                    if(data){
                       for(const amenityType in temp){
                            for(const amenity in temp[amenityType]){
                                tempAmenites.push(amenity)
                            }
                       }
                       for(const saved of data) {
                        if(!tempAmenites.includes(saved.name)){
                            activeAmenties[saved.name] = false
                        }
                       }
                       setAmenitiesData({
                        ...temp,
                        Others : activeAmenties,
                       })
                    }
                    //for count
                    let tempMainHeadCount = []
                    Object.keys(temp).map((mainHead) => {
                        let count = 0
                        Object.keys(temp[mainHead]).map((subHead) => {
                            if(values.includes(subHead)){
                                count += 1
                            }
                        })
                        tempMainHeadCount.push(count)   
                    })
                    tempMainHeadCount.pop()
                    tempMainHeadCount.push((Object.keys(activeAmenties)).length)
                    setMainHeadCount(tempMainHeadCount)
                }
            }catch(error){
                console.log(error?.response?.data?.message)
            }
    }
    callBack()
    },[data])

    const handleAddNewAmenity = (e) => {
        e.preventDefault()
        let tempAmenityType
        let tempAmenity
        for (const amenityType in amenitiesData) {
            for(const eachAmenity in  amenitiesData[amenityType]){
                if (eachAmenity.toLowerCase() === inputAmenity.toLowerCase()) {
                    tempAmenityType = amenityType
                    tempAmenity = eachAmenity
                    break
                }
            }
        }
        if(tempAmenityType) {
            setActiveHead(tempAmenityType)
            setCheckedAmenites([...checkedAmenties, tempAmenity])
            setInputAmenity("")
            //add in formdata
        } else {
            setAmenitiesData({
                ...amenitiesData,
                Others : {
                    ...amenitiesData?.Others,
                    [inputAmenity] : false,
                }
            })
            setInputAmenity("")
        }
    }

    return(
        <>
        <div className="locationtype-page">
            <h1 className="locationtype-head" > Sub Property Amenities {' '}
                <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title="List enticing amenities, making your sub-property stand out attractively."
                >
                    <img src={info} />
                </a>
            </h1>
            <div className="tabCard row">
                <div className="mainHeadContainer  col-lg-3">
                    {Object.keys(amenitiesData).map((mainHead,i) => {
                        return(
                            <div 
                            className={activeHead === mainHead ? "mainHead  is-active" : 'mainHead'}
                            onClick={()=>onClickMainHead(mainHead)}
                            >
                                <p>{mainHead}{" "}({mainHeadCount[i] || '0'})</p>
                            </div>
                        )
                    })}
                </div>
                <div className="subHeadContainer  col-lg-6">
                    {Object.keys(amenitiesData[activeHead]).map((subHead)=>{
                        return(
                            <div 
                            className='subHead'
                            >
                                <div className="d-flex flex-row  align-items-center" >
                                    <input className="amenities-checkbox" type='checkbox' name={subHead} checked={checkedAmenties.includes(subHead)} id={subHead} onChange={handleCheckBox}/>
                                    <label  className="amenities-label" htmlFor={subHead}>{subHead}</label>
                                </div>
                                <div>
                                    {(checkedAmenties.includes(subHead) && amenitiesData[activeHead][subHead]) && (
                                        <Select
                                            defaultValue={() =>getSubs(subHead)}
                                            isMulti={amenitiesData[activeHead][subHead].type === 'multi'}
                                            options={amenitiesData[activeHead][subHead]?.sub}
                                            onChange={(options)=>onChangeSub(subHead,amenitiesData[activeHead][subHead].type,options)}
                                            className="amenities-select"
                                            classNamePrefix="select"
                                            closeMenuOnSelect={ amenitiesData[activeHead][subHead].type === 'multi' ?  false : true}
                                        />
                                        )}   
                                </div>
                            </div>
                        )
                    })}
                    {activeHead === "Others"  && (
                        <form className="d-flex align-items-center mx-4 mt-1" onSubmit={handleAddNewAmenity}>
                            <div className="d-flex flex-column">
                                <label htmlFor="property_type" className="form-label">
                                    New Amenity
                                </label>
                            <div className="d-flex align-items-center" >
                                <input
                                    type="text"
                                    required
                                    placeholder="Enter new amenity"
                                    id="property_type"
                                    name="property_type"
                                    className="textFiled me-2"
                                    value={inputAmenity}
                                    onChange={(e) => setInputAmenity(e.target.value)}
                                />
                                <button type="submit" className="btn btn-primary" style={{height:"35px"}}>Submit</button>
                            </div>
                            </div>
                        </form>
                        )}
                </div>
            </div>
        </div>
        {/* Save Section Start */}
        <div className="row "    >
            <div className="d-flex flex-row justify-content-between " style={{width : "100%"}} >
            <button
                    type="button"
                    class="btn btn-outline-danger"
                    onClick={handleBack}
                >
                    Previous
                </button>
                <button
                    type="button"
                    className="btn btn-primary me-3"
                    
                    onClick={submitForm}
                >
                    Continue 
                </button>
            </div>
        </div>
        {/* Save Section End */}
        </>
    )
}
export default SubAmenities