import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import RazorPayList from './list/razorpayList'
const breadcrumbs: Array<PageLink> = [
  {
    title: 'Razorpay Management',
    path: '/apps/rozorpay',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RazorPayManagement = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs} description='List'>Payments List</PageTitle>
              <RazorPayList/>
            </>
          }
        />
       </Route> 
       <Route index element={<Navigate to='/apps/razorpay/list' />} />
    </Routes>
  )
}

export default RazorPayManagement
