import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { getBookingDetails } from "../services/api";
import Moment from 'moment';

const BookingDetails = () => {
    const params = useParams();
    const bookingId = params.bookingId;

    const [details, setDetails] = useState<any>({});

    const getReqDetails = async () => {
        try {
            const res = await getBookingDetails(bookingId);
            setDetails(res?.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getReqDetails();
    }, [])


    const bookingSummary = () => {
        return (
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view' >
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Booking Summary</h3>
                    </div>
                </div>

                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Booking Id</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {bookingId}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Location</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.locationAddress?.city},
                                {details?.locationAddress?.state},
                                {details?.locationAddress?.area}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Booking Requested Date</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'> {Moment(details?.bookingRequestedDate).format('DD-MM-YYYY HH:mm:ss')}</span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Booking Status</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.status}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Booking Amount</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                INR {details?.totalPayment}
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    const eventSummary = () => {
        return (
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view' >
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Event Summary</h3>
                    </div>
                </div>

                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Event & Activity Type</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.event}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>No of Attendees</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.attendies}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Date & Time</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>{details?.bookedTimeDates?.map((day, i) => {
                                return (
                                    <span className='fontWeight'>{Moment(day?.date).format('DD-MM-YYYY')}  {day?.start} , {day?.no_of_hours}Hrs</span>
                                )
                            })}</span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>About the Project</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.activity}
                            </span>
                        </div>
                    </div>

                </div>

            </div>
        )
    }

    const userDetails = () => {
        return (
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view' >
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>User Details</h3>
                    </div>
                </div>

                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Name of the Project</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.projectName}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Booking Location as a</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.bookingAs}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Contact Person Name</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'> {details?.contactName}</span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Contact Number</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.mobileNo}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Email Address</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.email}
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    const hostDetails = () => {
        return (
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view' >
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Host Details</h3>
                    </div>
                </div>

                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Host Name</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.hostName}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Host Contact Number</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.hostContactNumber}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Email Address</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'> {details?.hostEmail}</span>
                        </div>
                    </div>

                </div>

            </div>
        )
    }

    const paymentSummary = () => {
        return (
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view' >
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Payment Summary</h3>
                    </div>
                </div>

                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Paid Date & Time</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {Moment(details?.paymentDate).format('DD-MM-YYYY HH:mm:ss')}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Paid Amount</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                INR {details?.totalPayment}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Payment Method</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'> {details?.paymentMethod}</span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Coupon Applied</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.couponApplied === "true" ? "Yes" : "No"}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>Payment Status</label>
                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark '>
                                {details?.paymentStatus}
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div>
            {bookingSummary()}
            {eventSummary()}
            {userDetails()}
            {hostDetails()}
            {details?.status === "Confirmed" && paymentSummary()}
        </div>
    )
}

export default BookingDetails