import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
// import LookupList from './list/LookupList'
import ReviewList from './list/ReviewList'
import LocationReviews from './locationReivews'
const breadcrumbs: Array<PageLink> = [
  {
    title: 'Review Management',
    path: '/apps/review',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReviewManagementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs} description='List'>Review List</PageTitle>
              <ReviewList/>
            </>
          }
        />
        <Route
          path='location-review-view/:locationId'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Details</PageTitle>
              <LocationReviews/>
            </>
          }
        />
       </Route> 
       <Route index element={<Navigate to='/apps/review/list' />} />
    </Routes>
  )
}

export default ReviewManagementPage
