import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState, useEffect, useCallback } from "react";
import Typography from "@mui/material/Typography";
import {
  getLocations,
  getAmenities,
  getDonts,
  getDos,
  getFeatures,
  getRules,
  viewLookup,
  deleteLookUp,
  updateLookup,
} from "../../services/api";
import CircularProgress from "@mui/material/CircularProgress";
import { Table } from "./Table";
import { DeletePopUp } from "./DeletePopUp";
import { UpdatePopUp } from "./UpdatePopUp";
import { AddNewRecordModal } from "./AddNewRecordModal";
import { PageLoader } from "../../services/PageLoader";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useAuth } from "../../../auth";

const numbers = [...Array(10).keys()];

const lookupModels = {
  //0: "list_type",
  0: "location_type",
  1: "amenities_type",
  2: "feature_type",
  3: "dos_type",
  4: "dontdos_type",
  5: "rules_type",
};

export const ManageLookUps = () => {
  const {currentUser} = useAuth()
  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showNewRecord, setShowNewRecord] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [showLoader, setShowLoader] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      let response = [];
      switch (value) {
        // case 0:
        //   response = await getListTypes();
        //   break;
        case 0:
          response = await getLocations();
          break;
        case 1:
          response = await getAmenities();
          break;
        case 2:
          response = await getFeatures();
          break;
        case 3:
          response = await getDos();
          break;
        case 4:
          response = await getDonts();
          break;
        case 5:
          response = await getRules();
          break;

        default:
          break;
      }
      setData(response.data);
      setSearchData(response.data)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [value]);

  useEffect(() => {
    getData();
  }, [getData]);



  const viewLocations = async (data) => {
    setShowLoader(true)
    try {
      const res = await viewLookup({ data, type: lookupModels[value] });
      setShowLoader(false)
      setLocationData(res.data)
      setShowDeletePopup(true)
      getData();
    } catch (error) { 
      console.log(error)
      setShowLoader(true)
    }
  };

  const handleDelete = async (data) => {
    setShowLoader(true)
    try {
      const res = await deleteLookUp({ data, type: lookupModels[value] });
      setShowLoader(false)
      setLocationData(res.data)
      setShowDeletePopup(true)
      getData();
    } catch (error) {
      console.log(error)
      setShowLoader(true)
     }
  };

  const handleUpdate = async (details) => {
    setShowLoader(true)
    try {
      const res = await updateLookup({ ...details, type: lookupModels[value] });
      setShowLoader(false)
      setLocationData(res.data)
      setShowUpdatePopup(true)
      getData();
    } catch (error) {
      console.log(error)
      setShowLoader(true)
     }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const getView = () => {
    if (isLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="85vh"
        >
          <CircularProgress size={50} />
        </Box>
      );
    }

    return (
      <>
        {numbers.map((number) => (
          <TabPanel value={value} index={number}>
            <Table
              data={searchData}
              viewLocations={viewLocations}
              handleDelete={handleDelete}
              createLookUp={handleUpdate}
            />
          </TabPanel>
        ))}
      </>
    );
  };

  const hanldeSearch = (e) => {
    if (e.target.value === '') {
      setSearchData(data)
    }
    const newArrey = [];
    data.map((file, i) => {
      const isIncluded = file.value.includes(e.target.value)
      if (isIncluded) {
        return newArrey.push(file)
      }
    })
    setSearchData(newArrey)
  }

  return (
    <div className="card">
      <div className="card-header border-0 pt-6">
        <div className='card-title'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search Lookup'
              onChange={hanldeSearch}
            />
          </div>
          {/* end::Search */}
        </div>
        {currentUser?.permissions?.Lookup_Management?.write && (
        <div className="card-toolbar">
          <button type='button' className='btn btn-primary' onClick={() => setShowNewRecord(true)}>
            <KTIcon iconName='plus' className='fs-2' />
            Add New Record
          </button>
        </div>
        )}
      </div>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {/* <Tab label="List" /> */}
              <Tab label="Location" />
              <Tab label="Amenities" />
              <Tab label="Feature" />
              <Tab label="Dos" />
              <Tab label="Don'ts" />
              <Tab label="Rules" />
            </Tabs>
            {getView()}
          </div>
        </div>
      </div>
      {showDeletePopup && (
        <DeletePopUp
          open={showDeletePopup}
          data={locationData}
          handleClose={() => setShowDeletePopup(false)}
        />
      )}
      {showUpdatePopup && (
        <UpdatePopUp
          open={showUpdatePopup}
          data={locationData}
          handleClose={() => setShowUpdatePopup(false)}
        />
      )}
      {showNewRecord && (
        <AddNewRecordModal
          open={showNewRecord}
          handleClose={() => setShowNewRecord(false)}
          handleSuccess={() => {
            setShowNewRecord(false);
            getData();
          }}
        />
      )}
      {showLoader && <PageLoader />}
    </div>
  );
};
