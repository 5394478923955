import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';

function VerifyReasonModal({show,title,body,onHide,OnSuccess}) {
    const [formData,setFormData] = useState<any>({})
    const onChange=(e : any)=>{
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value
        })
    }
    const onSubmit = (e) => {
        e.preventDefault()
        OnSuccess(formData)
    }
  return (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <form onSubmit={onSubmit}>
            <p>{body}</p>
            <label className="form-label mb-3" htmlFor="verifyReasonModalReason">
                Reason<span className="required"></span>
            </label>
            <input
                name='reason'
                id='verifyReasonModalReason'
                value={formData?.reason}
                onChange={onChange}
                type='text'
                required
                className='form-control form-control-lg form-control-solid mb-2'
            />
            <button type='reset' className='btn btn-secondary me-2' onClick={onHide}>Cancel</button>
            <button type='submit' className='btn btn-primary'>Continue</button>
        </form>
    </Modal.Body>
    </Modal>
  );
}

export default VerifyReasonModal;