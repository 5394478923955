import { useState,useEffect } from "react";
import  {isEqual, differenceWith } from 'lodash'
import { useParams, useNavigate } from 'react-router-dom';
import {updateIncompleteListing,getIncompleteListing,getListing,updateListing, getEditedListing,updateEditListing } from "../../services/api";
import "./ListYourSpace.css"

//stepper
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

//tabs
import TypeOfProperty from "./TypeOfProperty";
import BasicInfo from "./BasicInfo"
import Location from "./Location"
import Amenities from "./Amenities"
import Features from "./Features"
import PricingAndTimings from "./PricingAndTimings";
import Gallery from "./Gallery";
// import Policies from "./Policies";
import Policies from "./NewPolicies";
import Activities from "./Activities";
import PropertyManagerDetails from "./PropertyManagerDetails";
import SubProperties from "./SubProperties/SubProperties"

const STEPS = [
    {
        label: 'Type of Property',
        value : "location_type"
      },
    {
      label: 'Basic Information',
      value : "basic_info"
    },
    {
      label: 'Location',
      value : "address"
    },
    {
      label: 'All Amenities',
      value : "amenities"
    },
    {
      label: 'Rooms/Spaces',
      value : "features"
    },
    {
      label: `Pricing & Timings`,
      value : "pricing"
    },
    {
      label: 'Gallery',
      value : "images"
    },
    {
      label: 'Policies',
      value : "policies"
    },
    {
      label: 'Activities',
      value : "tags"
    },
    {
      label: 'Property Manager Details',
      value : "property_manager_details"  
    },
  ];

  const ICONS = {1:1,2:2,3:3,4:4,5:5,6:6,7:7,8:8,9:9, 10:10}; 

const PROPRERTY_TYPES = ["Cafe and Mart", "Retail Spaces", "Convention Centers", "College", "School", "University", "Castles", "Forts", "Heritage Building", "Museum", "Hotels", "Resort", "Factory", "Manufacturing Facility", "Condominiums", "Gated Communities", "Sports Complex", "Art studios", "Fashion photography", "Film Studio", "Photo Studio"]

export const ListYourSpace = ({isIncompleteLisitng,edited}) => {
  let params = useParams();
  const navigate = useNavigate();
  const [payload, setPayload] = useState({})
  const [oldPayload, setOldPayload] = useState({})
  const [init, setInit] = useState(null)
  const [init2, setInit2] = useState(null)
  const [changedValues, setChangedValues] = useState({})
  const [changedData, setChangedData] = useState({})

  const [selectedLocationType, setSelectedLocationType] = useState("");
  const [steps, setSteps] = useState(STEPS);
  const [icons, setIcons] = useState(ICONS);
  const [isSubPropertyActive, setIsSubPropertyActive] = useState(false);

    //sidebar start
    const [activeStep, setActiveStep] = useState(0);
    const [tabStep, setTabStep] = useState(0);
    const QontoConnector = styled(StepConnector)(({theme}) => ({
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderWidth : '2px',
              borderColor: '#3e97ff',
              color:'#3e97ff',
            },
          },
          [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderWidth : '2px',
              borderColor: '#3e97ff',
              color:'#3e97ff',
            },
          },
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
          },
    }))
    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 25,
        height: 25,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
          backgroundColor:'#3e97ff',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundColor:'#3e97ff',
        }),
      }));
      function ColorlibStepIcon(props) {
        const { active, completed, className } = props;
      
        return (
          <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
           {completed ? <Check /> :  icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
      }
    const handleNext = (data) => {
      setPayload({
        ...payload,
        ...data,
        step : activeStep <= tabStep ? activeStep + 1 : activeStep
      });
      if (isSubPropertyActive) {
          if(tabStep < 10){
              setTabStep((prevActiveStep) => prevActiveStep + 1);
          }
          if(tabStep === activeStep && activeStep < 10){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
      } else {
        if(tabStep < 9){
            setTabStep((prevActiveStep) => prevActiveStep + 1);
        }
        if(tabStep === activeStep && activeStep < 9){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      handleSubProperty(data?.location_type,payload?.subscription_id);
    };
    const handleBack = () => {
        setTabStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleOnclickStep = (step) => {
      if(step <= activeStep){
        setTabStep(step)
      }
    }
    //sidebar end
    const handleSubPopertySave = (data) => {
      setPayload({
        ...payload,
        sub_properties : data ? [...data] : false,
      })
    }
    const handleSubmit =  (data) => {
      setPayload({
        ...payload,
        ...data,
        status : edited ? 'Under Review' : payload?.status
      });
      alert('location updated successfull')
      window.history.back()
      // navigate(`../requests`);
    }

    const compareChanges = (edited,existing, each) => {
      let result = {};
      // let result2 = {};

      // Iterate through keys in edited object
      Object.keys(edited).forEach(key => {
          // Compare corresponding values in edited and existing objects
          const notRequired = ["_id", "status", "updatedAt"]
          if (!notRequired.includes(key)) {
            result[key] = !isEqual(edited[key], existing[key]);
          }
      });

      // Create result2 by omitting keys with equal values
      // Object.keys(result).forEach(key => {
      //   if (result[key]) {
      //       result2[key] = { oldValue: existing[key], newValue: edited[key] };
      //   }
      // }); 

      if (!each) {
        setChangedValues(result)
      } else {
        return result
      }
      // setChangedData(result2)

    }

    const compareArrayChanges = (edited, existing) => {
      let changed = []
      let deleted = []
      changed = differenceWith(edited, existing, isEqual);
      deleted = differenceWith(existing, edited, isEqual);
      return {changed, deleted}
    } 

    useEffect(()=> {
      const initUpdateListing = async () => {
        try{
          let res;
          if(isIncompleteLisitng){
            res = await updateIncompleteListing(payload);
          }else{
            if(edited){
              res = await updateEditListing(payload)
            }else{
              res = await updateListing(payload);
            }
          }
        }catch(error){
          console.log(error?.response?.data?.message)
        }
      }
      if(init & init2) initUpdateListing();
      if(init) setInit2(true)
    },[payload])

    useEffect(() => {
      const getLocationDetails = async () => {
        try{
          let res;
          let res2
          if(isIncompleteLisitng){
            res = await getIncompleteListing(params?.locationId)
          }else{
            if(edited){
              res = await getEditedListing(params?.locationId)
              res2 = await getListing(params?.locationId)
            }else{
              res = await getListing(params?.locationId)
            }
          }
          setPayload(res?.data)
          setOldPayload(res2?.data)
          if(edited && res?.data?.status === 'Under Review') compareChanges(res?.data,res2?.data)
          setInit(true)
          setActiveStep(parseInt(res?.data?.step))
          handleSubProperty(res?.data?.location_type,res?.data?.subscription_id);
        }catch(error){
          console.log(error?.response?.data?.message)
        }
      }
      if(params?.locationId) getLocationDetails()
    },[]);

    const handleSubProperty = (value,id) => {
      if (PROPRERTY_TYPES.includes(value) && (id !== "Basic") ) { //&& (payload?.subscription_id !== "Basic")
        setIsSubPropertyActive(true);
        setSteps([
          ...STEPS,
         {label: 'Sub Property', value : "sub_properties"}
        ] );
        setIcons({...icons,11:11});
      }
      else{
        setIsSubPropertyActive(false);
        setSteps(STEPS);
        setIcons(ICONS);
      }
    }

    const getPricingData = (data) => {
      const reqData = {
        film : data?.film?.available,
        tv : data?.tv?.available,
        event : data?.event?.available,
        stay : data?.stay?.available,
      }
      return reqData
    }

    return(
        <>
          <div className="mainContainer">
              <div className="sideBar" id="sidebar-wrapper">
              <Stepper activeStep={activeStep} orientation="vertical" connector={<QontoConnector />}>
                  {steps.map((step, index) => (
                      <Step key={step.label} style={{cursor: 'pointer'}}>
                          <StepLabel StepIconComponent={ColorlibStepIcon} onClick={()=>handleOnclickStep(index)}>
                              <span style={{color : tabStep === index ? '#3e97ff' : ''}}>{step.label} {changedValues[step?.value] ? <span className="text-danger" > (Edited) </span> :  ""  }</span>
                          </StepLabel>
                      </Step>
                      ))}
              </Stepper>
              </div>
              <div className="tabsContainer">
                  <div className="tabSection">
                      {tabStep === 0 && (
                          <TypeOfProperty data={payload?.location_type} handleBack={handleBack} handleNext={handleNext} />
                      )}
                      {tabStep === 1 && (
                          <BasicInfo editChanges = {changedValues["basic_info"] ? compareChanges(payload?.basic_info, oldPayload?.basic_info, true)  : null }  edited = {edited} data={payload?.basic_info} handleBack={handleBack} handleNext={handleNext}/>
                      )}
                      {tabStep === 2 && (
                          <Location editChanges = {changedValues["address"] ? compareChanges(payload?.address, oldPayload?.address, true)  : null }  edited = {edited} data={payload?.address} handleBack={handleBack} handleNext={handleNext} />
                      )}
                      {tabStep === 3 && (
                          <Amenities editChanges = {changedValues["amenities"] ? compareArrayChanges(payload?.amenities, oldPayload?.amenities, true)  : null } data={payload?.amenities}  handleBack={handleBack} handleNext={handleNext}/>
                      )}
                      {tabStep === 4 && (
                          <Features editChanges = {changedValues["features"] ? compareArrayChanges(payload?.features, oldPayload?.features)  : null } data={payload}  handleBack={handleBack} handleNext={handleNext}/>
                      )}
                      {tabStep === 5 && (
                          <PricingAndTimings policiesData = {payload?.policies} activitiesData = {payload?.tags} oldData ={oldPayload?.pricing} editChanges = {changedValues["pricing"] ? compareChanges(payload?.pricing, oldPayload?.pricing, true)  : null } data={payload?.pricing} handleBack={handleBack} handleNext={handleNext}/>
                      )}
                      {tabStep ===6 && (
                          <Gallery videoChanges = {changedValues["videos"] ? compareChanges(payload?.videos, oldPayload?.videos, true)  : null }  editChanges = {changedValues["images"] ? compareChanges(payload?.images, oldPayload?.images, true)  : null } data={payload} handleBack={handleBack} handleNext={handleNext}/>
                      )}
                      {tabStep === 7 && (
                          <Policies editChanges = {changedValues["policies"] ? compareChanges(payload?.policies, oldPayload?.policies, true)  : null } data={payload?.policies} pricingData={getPricingData(payload?.pricing)} handleBack={handleBack} handleNext={handleNext}/>
                      )}
                       {tabStep === 8 && (
                            <Activities  editChanges = {changedValues["tags"] ? compareArrayChanges(payload?.tags, oldPayload?.tags)  : null } pricingData = {getPricingData(payload?.pricing)} data={payload?.tags} handleBack={handleBack} handleNext={handleNext} propertyId = {payload?.location_id}/>
                        )}
                      {tabStep === 9 && (
                          <PropertyManagerDetails editChanges = {changedValues["property_manager_details"] ? compareChanges(payload?.property_manager_details, oldPayload?.property_manager_details, true)  : null } data={payload?.property_manager_details} handleBack={handleBack} handleNext={handleNext} isSubPropertyActive = {isSubPropertyActive} handleSubmit={(data) =>  handleSubmit({...payload, ...data})}/>
                      )}
                      {(tabStep === 10 && isSubPropertyActive  && payload?.subscription_id !== "Basic"  ) && (
                          <SubProperties editChanges = {changedValues["sub_properties"] ? compareArrayChanges(payload?.sub_properties, oldPayload?.sub_properties, true)  : null }  oldData = {oldPayload?.sub_properties}  savedSubPropertyData = {payload?.sub_properties} propertyId = {payload?.location_id} handleBack={handleBack} handleSubPopertySave = {handleSubPopertySave} handleSubmit={() =>  handleSubmit(payload)}/>
                      )}
                  </div>
              </div>
            {/* <div className="rightSection">
                <h1>Right section</h1> && payload?.subscription_id !== "Basic"
            </div> */}
          </div>
        </>
    )
};