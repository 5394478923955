import { useEffect, useState } from 'react';
import {addNewAmenity, editAmenity} from "../../../services/api";
import Modal from 'react-bootstrap/Modal';
import { KTIcon } from "../../../../../../_metronic/helpers";

const initial : any = {
    sub : false
}

export function AddNewLookupModal({show,onHide,title,data,editIndex,editValue,editData}) {
    const [formData,setFormData] = useState<any>(initial)
    const [newCat,setNewCat] = useState<any>(false)
    const [newSub,setNewSub] = useState<any>(false)
    const [tempSub,setTempSub] = useState<any>('')
    const [error,setError] = useState<any>()

    useEffect(() => {
        if(editIndex){
            setFormData((prev) => {
                return {
                    ...prev,
                    category : editIndex
                }
            })
        }
        if(editValue){
            setFormData((prev) => {
                return {
                    ...prev,
                    oldValue : editValue
                }
            })
        }
        if(editData?.sub?.length > 0) {
            setFormData((prev) => {
                return {
                    ...prev,
                    sub : editData?.sub,
                    type : editData?.type,
                }
            })
        }
    },[]);

    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value,
        })
        setError('')
    }
    const onChangeAmenity = (e) => {
        setFormData({
            ...formData,
            name : e.target.value
        })
    } 
    const onChangeSub = (e) => {
        setTempSub(e.target.value)
    }
    const addSub = () => {
        const temp = formData.sub
        temp.push({
            label : tempSub,
            value : tempSub,
        })
        setFormData({
                ...formData,
                sub : temp
        })
        setTempSub('')
    }
    const onChangeRadioYes = (e) => {
        const {name,checked} = e.target
        if(checked){
            setFormData({
                ...formData,
                type : 'single',
                sub : []
            })
        }
        }
    const onChangeRadioNo = (e) => {
        const {name,checked} = e.target
        if(checked){
            setFormData({
                ...formData,
                [name] : false,
                type : "",
            })
        }
    }
    const onChangeDrop = (e) => {
        setFormData({
            ...formData,
            type : e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault()
        if (formData?.type === "single") {
            if((formData?.sub?.length < 2) ) return alert('Please add two values in sub items') 
            if((formData?.sub?.length > 2) ) return alert('Please add only two values in sub items') 
        }
        if (formData?.type === "multi") {
            if(formData?.sub?.length < 2) return alert('Please add min two values in sub items')
        }
        // if(formData?.sub?.length < 2) return alert('Please add minimum two values in sub items')
        let resp : any 
        try{
            if(editIndex){
                resp= await editAmenity({...formData,editValue})
            }
            else{
                resp = await addNewAmenity({...formData,newCat})
            }
            alert(resp?.data?.message)
            onHide()
        }catch(error : any){
            // console.log(error) //?.response?.data)
            // setError(error?.response?.data)
            if (typeof(error?.response?.data?.message) === "string" ) {
                setError(error?.response?.data?.message)
            }else{
                console.log(error);
            }
        }
    }

    const onDeleteSubItems = (index : any) => {
        let reqData = formData?.sub
        reqData.splice(index,1)
        setFormData({
            ...formData,
            sub : reqData,
        })
    }

  return (
    <Modal
    show={show}
    onHide={onHide}
    keyboard={false}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form className="col" onSubmit={submitForm}>
                {!editIndex && <span className='text-active-primary active' style={{cursor : 'pointer'}} onClick={()=>{
                    setFormData({
                        ...formData,
                        category : '',
                    })
                    setNewCat(!newCat)}}>{newCat ? '(Want to add in existing Category)' : '(Want to add new Category)'}</span>}
                {!newCat && (
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mt-3">
                            <span  className='required'>Category</span>
                        </label>
                        <div className="w-100">
                            <select disabled={editIndex} required onChange={onChange} value={formData?.category} id="kt_ecommerce_select2_country" className="form-select form-select-solid" name="category" data-kt-ecommerce-settings-type="select2_flags">
                                <option value='' disabled selected>Select a Category</option>
                                {data?.map((key) => (
                                    <option value={key?.category}>{key?.category}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                {newCat && (
                    <div className="col">
                        <div className="fv-row mb-7">
                            <label className="fs-6 fw-semibold form-label mt-3">
                                <span className='required'>Category</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Enter the type of the property">
                                    <i className="ki-duotone ki-information fs-7">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                            </label>
                            <input disabled={editIndex} required type="text"  name="category" onChange={onChange} value={formData?.category}  className="form-control form-control-solid"/>
                        </div>
                    </div>
                )}
                <div className="col">
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mt-3">
                            <span className='required'>Amenity</span>
                            <span className="ms-1" data-bs-toggle="tooltip" title="Enter the type of the property">
                                <i className="ki-duotone ki-information fs-7">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>
                            </span>
                        </label>
                        <input required type="text"  name="type" onChange={onChangeAmenity} value={formData?.name} className="form-control form-control-solid"/>
                    </div>
                </div>
                <span>Do you want to add Sub ?</span>
                <div className='d-flex'>
                    <div>
                        <label htmlFor='yes'>Yes</label>
                        <input type='radio' id='yes' name='sub' checked={formData?.type} onChange={onChangeRadioYes}/>
                    </div>
                    <div>
                        <label htmlFor='no'>No</label>
                        <input type='radio' id='no' name='sub' checked={!formData?.sub} onChange={onChangeRadioNo}/>
                    </div>
                </div>
                {formData?.sub && (
                    <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mt-3">
                            <span  className='required'>Dropdown</span>
                        </label>
                        <div className="w-100">
                            <select  required onChange={onChangeDrop} value={formData?.type} id="kt_ecommerce_select2_country" className="form-select form-select-solid" name="type" data-kt-ecommerce-settings-type="select2_flags">
                                <option value='single'>Single Selection</option>
                                <option value='multi'>Multi Selection</option>
                            </select>
                        </div>
                    </div>
                )}
                {formData?.sub && (
                    <div className="col">
                        <ol>
                                {formData?.sub?.map((item,i) => (
                                    <li><span> {item?.value} </span> <span  onClick = {() =>  onDeleteSubItems(i)} > <KTIcon iconName='trash' className='fs-3' /> </span> </li>
                                    ))}
                            </ol>
                        <div className="fv-row mb-7">
                            <label className="fs-6 fw-semibold form-label mt-3">
                                <span className='required'>Add sub items</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Enter the type of the property">
                                    <i className="ki-duotone ki-information fs-7">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                            </label>
                                <input type="text" onChange={onChangeSub} value={tempSub} className="form-control form-control-solid"/>
                                {/* <span onClick={addSub}><KTIcon iconName='plus' className='fs-2' /></span> */}
                                <button  onClick={addSub} type='button' className='btn btn-danger mt-2'  > Add </button>
                        </div>
                    </div>
                )}
                {error && <span className='text-danger'>{error}</span>}
            <div>
                <button className='btn btn-secondary me-2' onClick={onHide}>Cancel</button>           
                <button className='btn btn-primary' type='submit'>Submit</button>           
            </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}