import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { getBookings, noOfBookings } from "../../services/api";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Moment from 'moment';
import { BookingListFilter } from "../BookingListFilter";
import { Link } from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import Reports from "../../Reports/Reports";

const BookingsList = () => {

    const [data, setData] = useState<any>([]);
    const [dataCount, setDataCount] = useState<any>(0)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [bookingStatus, setBookingStatus] = useState<string | undefined>()
    const [paymentStatus, setPaymentStatus] = useState<string | undefined>()
	const [show,setShow] = useState<any>(false);
    const [modalData, setModalData] = useState<any>();

    const getLocationData = async () => {
        try {
            const countResp = await noOfBookings({ status: bookingStatus, paymentStatus: paymentStatus, searchTerm: searchTerm });
            setDataCount(Math.ceil(countResp?.data?.count / 50)) //items per page is 50 So, dividing with 50 to make no of pages appear
            const resp = await getBookings({ page: 1, items: 50, status: bookingStatus, paymentStatus: paymentStatus, searchTerm: searchTerm });
            setData(resp?.data);
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getLocationData();
    }, []);

    useEffect(() => {
        getLocationData();
    }, [searchTerm, bookingStatus, paymentStatus]);

    const isEmptyObject = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };
    const renderData = (data) => {
        if (data === null || data === undefined || data === '') {
            return <span className='text-info'>**</span>;
        }
    
        if (Array.isArray(data)) {
            if (data.length === 0) {
                return <span className='text-info'>**</span>;
            }
            return (
                <ul className="list-group">
                    {data.map((item, index) => (
                        <li key={index} className="list-group-item">
                            {renderData(item)}
                        </li>
                    ))}
                </ul>
            );
        } else if (typeof data === 'object' && data !== null) {
            if (isEmptyObject(data)) {
                return <span className='text-info'>**</span>;
            }
            return (
                <ul className="list-group">
                    {Object.entries(data).map(([key, value]) => (
                        <li key={key} className="list-group-item d-flex justify-content-between">
                            <strong>{key}:</strong> {renderData(value)}
                        </li>
                    ))}
                </ul>
            );
        } else {
            return <span className='text-info'>{data.toString()}</span>;
        }
    };

    const handleChange = async (event, value) => {
        try {
            const resp = await getBookings({ page: value, items: 50, status: bookingStatus, paymentStatus: paymentStatus, searchTerm: searchTerm });
            setData(resp?.data);
        } catch (error) {
            console.log(error)
        }
    };

    const filterData = async (bookingStatus: string, paymentStatus: string) => {
        setBookingStatus(bookingStatus);
        setPaymentStatus(paymentStatus);
    }

    const onClickViewDetails = (data : any) => {
        setShow(true);
        setModalData(data);
    }

    const bookingRequest = (each) => {
        return(
			<div className='card mb-5 mb-xl-10' >
				<div className='card-header cursor-pointer'>
					<div className='mb-3'>
						{/* begin::Text */}
                        <div className='mt-3'>
						<span  className='fw-bold'>Booking Id :</span> <span>{each?.bookingId}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Location Id :</span> <span>{each?.property_id}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>User Id:</span> <span>{each?.user_id}</span>
						</div>
						<div className='mt-3'>
						<span  className='fw-bold'>Requested Event:</span> <span>{each?.event}</span>
						</div>
                        <div className='mt-3'>
						<span  className='fw-bold'>Sub Property Name : </span> <span>{each?.subPropertyName}</span>
						</div>
						{ 
						each?.event === "stay"  ? (
                            <>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Adults Number:</span> <span>{each?.adults}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Children Number :</span> <span>{each?.children}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Infants Number :</span> <span>{each?.infants}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Check In :</span> <span>{Moment(each?.checkIn).format('DD/MM/YYYY')} </span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Check Out :</span> <span>{Moment(each?.checkOut).format('DD/MM/YYYY')} </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Start Date  :</span> <span>{Moment(each?.startDate).format('DD/MM/YYYY')}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Start time  :</span> <span>{each?.startTime}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>No of Hours  :</span> <span>{each?.noOfHours}</span>
                                </div>
                                <div className='mt-3'>
                                <span  className='fw-bold'>Guests Number  :</span> <span>{each?.guests}</span>
                                </div>
                            </>
                        )
						}
						<div className=' mt-3'>
						<span  className='fw-bold'>Requested Received Date :</span> <span>{Moment(each.createdAt).format('DD/MM/YYYY hh:mm a')}</span>
						</div>
                        {/* Contact Details Start */}
                        <div className='mt-3 mb-2 text-primary'>
						<span  className='fw-bold'>Contact Information :</span>
						</div>
                        {/* <div className='mt-3 mx-4'>
						<span  className='fw-bold'>Booked as a :</span> <span>{each?.booking_as_a}</span>
						</div> */}
                        <div className='mt-3 mx-4'>
						<span  className='fw-bold'>Contact Name :</span> <span>{each?.contact_name}</span>
						</div>
                        <div className='mt-3 mx-4'>
						<span  className='fw-bold'>Email Id :</span> <span>{each?.email}</span>
						</div>
						<div className='mt-3 mx-4'>
						<span  className='fw-bold'>Mobile  Number :</span> <span>{each?.mobile_no}</span>
						</div>
						<div className='mt-3 mx-4'>
						<span  className='fw-bold'>Alternate Contact Name :</span> <span>{each?.alt_contact_name ? each?.alt_contact_name : "N/A" }</span>
						</div>
						<div className='mt-3 mx-4'>
						<span  className='fw-bold'>Alternate Contact Number :</span> <span>{each?.alt_mobile_no ? each?.alt_mobile_no : "N/A"}</span>
						</div>
						{/* <div className='mt-3 mx-4'>
						<span  className='fw-bold'>Project Name :</span> <span>{each?.project_name ? each?.project_name : "N/A"}</span>
						</div> */}
                        {/* Contact Details End */}
                        {each?.paymentDetails && (
                            <>
                                <div className='mt-3 mb-2 text-primary'>
                                <span  className='fw-bold'>Payment Details:</span>
                                </div>
                                <div className="container">{renderData(each?.paymentDetails)}</div>
                            </>
                        )}
						{/* end::Text */}
					</div>
				</div>
			</div>
        )
    }

    return (
        <>
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search booking'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                <div className="card-toolbar">
                    <Reports table='bookings'/>
                    {/* <BookingListFilter filterFormData={filterData} /> */}
                </div>
            </div>

            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    <th className='min-w-100px'>Booking ID</th>
                                    <th className='min-w-100px'>Location Id</th>
                                    <th className='min-w-100px'>Event</th>
                                    {/* <th className='min-w-100px'>Contact name</th> */}
                                    <th className='min-w-100px'>Booking Status</th>
                                    {/* <th className='min-w-100px'>Payment Status</th> */}
                                    <th className='min-w-140px'>Date</th>
                                    <th className='min-w-140px'>Payment Id</th>
                                    <th className='min-w-100px'>Action</th>
                                    {/* <th className='min-w-100px'>Time Duration</th> */}
                                    {/* <th className='min-w-100px'>Total Amount</th> */}
                                    {/* <th className='min-w-100px text-end'>Actions</th> */}
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {data?.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            <td>
                                                {/* <Link to={`../details/${item.bookingId}`} > */}
                                                    <a className='text-dark fw-bold text-hover-primary fs-6'>
                                                        {item.bookingId}
                                                    </a>
                                                {/* </Link> */}
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.property_id}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.event}
                                                </div>
                                            </td>
                                            {/* <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.contact_name}
                                                </div>
                                            </td> */}
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {item?.status}
                                                </div>
                                            </td>
                                            {/* <td>
                                                <div className='badge badge-light-warning'>
                                                    {item?.payment_status}
                                                </div>
                                            </td> */}
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {Moment(item.createdAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.paymentId || '---'}
                                                </div>
                                            </td>
                                            {/* <td>
                                                <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.bookedTimeDates[0]?.no_of_hours} Hrs
                                                </span>
                                            </td> */}
                                            {/* <td>
                                                <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-end'>{item?.totalPayment}</span>
                                            </td> */}
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' >
                                                   <button className="btn btn-light btn-active-primary btn-flex btn-center btn-sm" onClick = {() => onClickViewDetails(item)}> View </button>
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={dataCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                </Stack>
            </div>
        </div>
        {show && (
            <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            >
                <Modal.Header closeButton>
                <Modal.Title>Booking Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        {bookingRequest(modalData)}
                    </div>
                </Modal.Body>
            </Modal>
        )}
        </>
    );
    
};

export default BookingsList;
