import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'

const App = () => {
  
  // for disabling mouse scroll event for all input type numbers start
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      // Check if the focused element is an input of type 'number'
      const activeElement = document.activeElement as HTMLInputElement;
      if (activeElement && activeElement.type === 'number') {
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);
  // for disabling mouse scroll event for all input type numbers end

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
