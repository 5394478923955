import { DataGrid } from "@mui/x-data-grid";
import "./datatable.scss";
import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { getRazorPayPayments,getRazorPayInvoicesById } from "../../services/api";
import moment from 'moment';
import { PageLoader } from "../../services/PageLoader";
import PaymentDetailsModal from "../modals/paymentDetailsModal";
import InvoiceDetailsView from "../modals/invoiceDetailsModal";
import { PaymentFilter } from "../paymentFilter";

const RazorPayList = () => {
    const [data, setData] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
    const [modalData,setModalData] = useState('')
	const [show,setShow] = useState(false);
	const [showInvoice,setShowInvoice] = useState(false);
	// const [searchTerm, setSearchTerm] = useState<string>('')
	const [filterData,setFilterData] = useState<any>({
		start : null,
		end : null,
		count : null,
		skip : 0,
	})

	const userColumns = [
		{
			field: "paymentId", headerName: "Payment Id", width: 200,
			renderHeader: () => (
				<th className='min-w-150px'>Payment Id</th>
			),
			renderCell: (record) => (
				<div className='text-dark text-hover-primary fs-6 fw-bold'>
					{record?.row?.paymentId}
				</div>
			),
		},
		{
			field: "amount",
			headerName: "amount",
			width: 90,
			renderHeader: () => (
				<th className='min-w-90px'>Amount</th>
			),
			renderCell: (record) => {
				return (
					<div className='text-dark text-hover-primary fs-6 fw-bold'					
					>
						{record?.row?.amount}
					</div>
				);
			},
		},
		{
            field: "currency",
			headerName: "currency",
			width: 90,
			renderHeader: () => (
                <th className='min-w-90px'>Currency</th>
			),
			renderCell: (record) => (
                <div className='text-dark text-hover-primary fs-6 fw-bold'>
                    {record?.row?.currency}
				</div>
			),
		},
		{
			field: "method",
			headerName: "method",
			width: 90,
			renderHeader: () => (
				<th className='min-w-90px'>Method</th>
			),
			renderCell: (record) => (
				<div className='text-dark text-hover-primary fs-6 fw-bold'>
					{record?.row?.method}
				</div>
			),
		},
		{
			field: "status",
			headerName: "status",
			width: 90,
			renderHeader: () => (
				<th className='min-w-90px'>Status</th>
			),
			renderCell: (record) => (
				<div className='text-dark text-hover-primary fs-6 fw-bold'>
					{record?.row?.status}
				</div>
			),
		},
        {
			field: "invoice",
			headerName: "invoice",
			width: 200,
			renderHeader: () => (
				<th className='min-w-200px'>Invoice Id</th>
			),
			renderCell: (record) => (
				<div className='text-dark text-hover-primary fs-6 fw-bold'>
					{record?.row?.invoice}
				</div>
			),
		},
		{
			field: "created_at",
			headerName: "created_at",
			width: 150,
			renderHeader: () => (
				<th className='min-w-150px'>Created At</th>
			),
			renderCell: (record) => (
				<div className='text-dark text-hover-primary fs-6 fw-bold'>
                    {record?.row?.created_at}
				</div>
			),
		},
	];

	const getData = async () => {
        setShowLoader(true)
		try{
			const resp = await getRazorPayPayments({...filterData});
			setOriginalData(resp?.data?.items);
			const temp = resp?.data?.items?.map((item)=> {
				return {
					id : item?.id,
					paymentId : item?.id || 'NA',
					amount : item?.amount || "NA" ,
					currency : item?.currency || 'NA',
					method : item?.method || 'NA',
					status : item?.status || 'NA',
                    invoice : item?.invoice_id || 'NA',
					created_at : moment(new Date(item?.created_at * 1000)).format('DD-MM-YYYY hh:mm:ss'),
				}
			})
			setData(temp);
		}catch(error : any){
			console.log(error)
			alert(error?.response?.data?.error)
		}
        setShowLoader(false)
	}
	const handleView = async (data) => {
        try{
            setModalData(data?.id);
            setShow(true)
		}catch(error){
			console.log(error)
		}
	}
	const handleInvoiceView = async (data) => {
        try{
            // setModalData(data?.invoice);
            // setShowInvoice(true)
			const resp : any = await getRazorPayInvoicesById(data?.invoice)
			if(resp?.data?.short_url) window.open(resp?.data?.short_url, "_blank");
		}catch(error){
			console.log(error)
		}
	}
	const handleFilterData = async (data) => {
        setFilterData(data)
    }
	const actionColumn = [
		{
			field: "action",
			headerName: "Action",
			width: 150,
			renderHeader: () => (
				<th className='min-w-150px'>Action</th>
			  ),
			renderCell: (params) => {
				return (
					<div className="d-flex flex-row justify-content-between align-items-center">
						<div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={()=>{
							handleView(params.row)
						}}>
							<KTIcon iconName='shield-search' className='fs-3'/>
						</div>
                        {params.row.invoice !=='NA' && (
                            <button type="button" className="btn btn-light btn-active-light-primary my-1" onClick={() => handleInvoiceView(params.row)}>View invoice</button>
                        )}
					</div>
				);
			},
		},
	];

	useEffect(() => {
		getData()
	}, []);
	useEffect(() => {
		getData()
	}, [filterData]);

	return (
		<div className="card">
			<div className="card-header border-0 pt-6">
                <div className='card-title'>
					{/* begin::Search */}
                    {/* <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div> */}
                    {/* end::Search */}
                </div>
                <div className="card-toolbar">
                	<PaymentFilter filterFormData={handleFilterData}/>
                </div>
            </div>
			<div>
				{/* begin::Body */}
				<div className='card-body'>
					{/* begin::Table container */}
					<div className='table-responsive'>
						{/* begin::Table */}
						<div className="datatable">
							<DataGrid
								className="datagrid"
								rows={data}
								columns={userColumns.concat(actionColumn)}
								pageSize={50}
								rowsPerPageOptions={[9]}
								sx={{
									"& .MuiDataGrid-row:hover": {
										backgroundColor: "inherit"
									},
									"& .MuiDataGrid-cell": {
										borderBottom: "1px solid var(--bs-gray-100)"
									}

								}}
							/>
						</div>
						{/* end::Table */}
					</div>
					{/* end::Table container */}
				</div>
				{/* begin::Body */}
			</div>
			{showLoader && <PageLoader />}
            {show && 
            <PaymentDetailsModal 
                show={show}
                onHide={()=>setShow(false)}
                title={'Payment details'}
                id={modalData}
            />
            }
            {showInvoice && 
            <InvoiceDetailsView 
                show={show}
                onHide={()=>setShowInvoice(false)}
                title={'Invoice details'}
                id={modalData}
            />
            }
		</div>
	);
};

export default RazorPayList;
