import { useState,useEffect } from 'react';
import { getRazorPayPaymentById } from '../../services/api';
import Modal from 'react-bootstrap/Modal';
import { PageLoader } from '../../services/PageLoader';
import moment from 'moment';

const PaymentDetailsModal = ({show,onHide,title,id}) => {
    const [data,setData] = useState<any>({})
    const [loader,setLoader] = useState(false)

    function displayNestedObject(obj, element) {
        // Create an unordered list element
        const ul = document.createElement('ul');
      
        // Iterate over the object properties
        for (const key in obj) {
          // Create list item for each property
          const li = document.createElement('li');
        //   li.style.fontWeight = '600'
          
          // Display property name
          li.textContent = key + ': ';
      
          // Display property value
          const value = obj[key];
          if (typeof value === 'object' && value !== null) {
            // If the value is an object (including arrays), recursively call the function
            displayNestedObject(value, li);
          } else {
            // Otherwise, display the value as is
            if(key === 'created_at' ){
                const date : any = new Date(value * 1000)
                li.textContent += String(moment(date).format('DD-MM-YYYY') || 'NA');
            }else{
                li.textContent += String(value);
            }
          }
      
          // Append list item to the unordered list
          ul.appendChild(li);
        }
      
        // Append the unordered list to the specified element
        element.appendChild(ul);
      }

    const getData = async() => {
        setLoader(true)
        try{
            const resp : any = await getRazorPayPaymentById(id)
            setData(resp?.data)
            const outputElement = document.getElementById('paymentDetailsRoot');
            displayNestedObject(resp?.data, outputElement);
        }catch(error){
            console.log(error)
        }
        setLoader(false)
    }

    useEffect(()=>{
        getData()
    },[])

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{height : "500px",overflowY : 'auto'}}>
                <div id='paymentDetailsRoot'>

                </div>
                {loader && <PageLoader/>}
            </Modal.Body>
        </Modal>
    )
}
export default PaymentDetailsModal;