import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import {getJobRequests} from "../../services/api";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {Modal} from 'react-bootstrap'
import Moment from 'moment';
import { PageLoader } from "../../services/PageLoader";
import Reports from "../../Reports/Reports";

const CareerList = () => {

    const [searchData, setSearchData] = useState<any>([]);
    const [show,setShow] = useState<any>(false)
    const [modelData,setModelData] = useState<any>({})
    const [showLoader, setShowLoader] = useState(false)
    const [dataCount, setDataCount] = useState<any>(0)
    const [searchTerm,setSearchTerm] = useState<string>('');
    const [page, setPage] = useState<number>(1)
    const noOfItems : number = 5

    const getJobData = async () => {
      try{
        const res = await getJobRequests({page,noOfItems, searchTerm});
        setSearchData(res?.data?.jobs)
        setDataCount(res?.data?.count)
        setShowLoader(false)
      }catch(error:any){
        console.log(error?.response?.data?.message)
        setShowLoader(false)
      }
    }

    // const handleDeleteJob = async (data) => {
    //     setShowLoader(true)
    //     const res = await deleteJob(data._id)
    //     getJobData();
    // } 

    useEffect(() => {
        setShowLoader(true)
        getJobData();
    }, [page]);

    const onClickProfile = (data) => {
        setShow(true)
        setModelData(data)
      }

    // const hanldeSearch = (e) => {
    //     if (e.target.value === '') {
    //       setSearchData(data)
    //     }
    //     type Object = {};

    //     const newArrey:  Object[] = [];
    //     data.map((file, i) => {
    //       const isIncluded = file.jobTitle.includes(e.target.value)
    //       if (isIncluded) {
    //         return newArrey.push(file)
    //       }
    //     })
    //     setSearchData(newArrey)
    //   }

    // const handleChange = async (event, value) => {
    //     const resp = await getBookings({page : value,items : 50, status: bookingStatus, paymentStatus: paymentStatus, searchTerm: searchTerm});
    //     setData(resp?.data);
    // };

    // const filterData = async (bookingStatus:string, paymentStatus: string) => {
    //     console.log("filterData bookingStatus = " + bookingStatus);
    //     setBookingStatus(bookingStatus);
    //     setPaymentStatus(paymentStatus);
    // }

    const handleSearch = async (e : any) => {
      setSearchTerm(e.target.value)
  }

  useEffect(() => {
      const timeOut = setTimeout(()=>{
        getJobData();
      },1500)
      return ()=>{
          clearTimeout(timeOut)
      }
  },[searchTerm])

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search'
                            onChange={handleSearch}
                        />
                    </div>
                    <Reports table='requested-jobs'/>
                    {/* end::Search */}
                </div>
                {/* <div className="card-toolbar">
                    <Link to = {"job"}>
                        <button type='button' className='btn btn-primary'>
                            <KTIcon iconName='plus' className='fs-2' />
                            Add New Job
                        </button>
                    </Link>
                </div> */}
            </div>
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    <th className='min-w-100px'>position</th>
                                    <th className='min-w-100px'>Name</th>
                                    <th className='min-w-100px'>Email</th>
                                    <th className='min-w-100px'>Mbl.no</th>
                                    <th className='min-w-100px'>Education</th>
                                    <th className='min-w-100px'>Applied Date</th>
                                    <th className='min-w-100px'>Skills</th>
                                    <th className='min-w-100px'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {searchData?.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    {item.position}
                                                </a>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.name}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.email}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.phoneNumber}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 '>
                                                    {item?.qualification}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='badge badge-light-warning'>
                                                    {Moment(item?.createdAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {item?.skils}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        title="View Locations">
                                                        <KTIcon iconName='magnifier' className='fs-3' />
                                                    </div> */}
                                                    {/* <Link to ={`job/${item._id}`} > */}
                                                        <div className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                            title="View"
                                                            onClick={() => onClickProfile(item)}>
                                                            View Profile
                                                        </div>
                                                    {/* </Link> */}
                                                    {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        // onClick={() =>handleDeleteJob(item)}
                                                        title="Delete">
                                                        <KTIcon iconName='trash' className='fs-3' />
                                                    </div> */}
                                                    
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })}
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            {
              dataCount > 1 && (
              <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                  <Stack spacing={2}>
                      <Pagination count={Math.ceil(dataCount/noOfItems)} variant="outlined" shape="rounded" color="primary" onChange={(e,value)=>setPage(value)} />
                  </Stack>
              </div>
              )
            }
            <Modal
              id='kt_modal_create_app'
              tabIndex={-1}
              aria-hidden='true'
              dialogClassName='modal-dialog modal-dialog-centered mw-900px'
              show={show}
              backdrop={true}
            >
              <div className='modal-header'>
                <h2>Profile details</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => setShow(false)}>
                  <KTIcon className='fs-1' iconName='cross' />
                </div>
                {/* end::Close */}
              </div>

              <div className='modal-body py-lg-10 px-lg-10' >
                  <div>
                  <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Position</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.position}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Name</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.name}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Email</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.email}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Phone Number</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.phoneNumber}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Alt Phone Number</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.altNumber}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Education</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.qualification}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Skills</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.skils}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Experience</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.experience}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Relevent Exp</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.relExperience}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Current Ctc</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.currentCtc}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Expected Ctc</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.expCtc}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Summary</label>
                      <div className='col-lg-6 fv-row'>
                        <span className='fw-bold fs-6'>{modelData?.summary}</span>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <label className='col-lg-6 fw-bold text-muted'>Resume</label>
                      {modelData?.resume?.url && (
                      <div className='col-lg-6 fv-row'>
                        <a href={modelData?.resume?.url} target="_blank" className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                            title="View Resume">
                            View Resume
                        </a>
                      </div>)}
                    </div>
                  </div>
              </div>
            </Modal>
            {showLoader && <PageLoader />}
        </div>

    );
};

export default CareerList;
