import { useEffect, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { getTags,deleteTag, updateTag} from "../../services/api";
import { PageLoader } from "../../services/PageLoader";
import Moment from 'moment';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CreateTagModal from "../modals/CreateTagModal";
import UpdataTagModal from "../modals/UpdateTagModal";
import { useAuth } from "../../../auth";
import Reports from "../../Reports/Reports";

const TagList = () => {
    const {currentUser} = useAuth()
    const [data, setData] = useState<any>([]);
    const [count,setCount] = useState<any>(0)
    const [searchData, setSearchData] = useState<any>('');
    const [showLoader, setShowLoader] = useState(false);
    const [openAddTagModal,setOpenAddTagModal] = useState(false)
    const [openUpdateTagModal,setOpenUpdateTagModal] = useState(false)
    const [updatedData,setUpdatedData] = useState<any>({})
    const [page, setPage] = useState(1)

    const getTagsData = async () => {
        setShowLoader(true)
        try{
            const res = await getTags({page : 1,items : 20, searchTerm: searchData});
            const reqCount = Math.ceil(res?.data?.tagsCount / 20)
            setCount(reqCount) //items per page is 50 So, dividing with 50 to make no of pages appear
            setData(res?.data?.tags)
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }
    
    useEffect(() => {
        getTagsData();
    },[])

    useEffect(() => {
        const timeOut = setTimeout(()=>{
            getTagsData();
        },1500)
        return ()=>{
            clearTimeout(timeOut)
        }
    },[searchData])

    const deletingTag = async (id) => {
        try{
            if(window.confirm('Are you sure you want to delete ?') == true){
                const res = await deleteTag(id)
                alert(res?.data?.message)
                getTagsData();
            }
        }catch(error : any){
            console.log(error?.response?.data?.message)
            alert(error?.response?.data?.message)
        }
    }

    const handleChange = async(value: any) => {
        try{
            setPage(value)
            const resp = await getTags({page : value,items : 20, searchTerm: searchData});
            setData(resp?.data?.tags);
            const reqCount = Math.ceil(resp?.data?.tagsCount / 20)
            setCount(reqCount)
        }catch(error){
            console.log(error)
        }
    };

    const handleUpdate = (data) => {
        setUpdatedData(data)
        setOpenUpdateTagModal(true)
    }

    const handleUpdateStatus = async (data) => {
        try{
            if(window.confirm('Are you sure you want to update status ?') == true){
                const res = await updateTag({...data,status : 'Active'})
                alert(res?.data?.message)
                getTagsData();
            }
        }catch(error : any){
            console.log(error?.response?.data?.message)
            alert(error?.response?.data?.message)
        }
    }

    const getTagType = (type:any) => {
        switch (type) {
            case "film":
                return "FILM / TV SHOOTINGS"
            // case "event":
            //     return "EVENTS"
            case "corporateEvent":
                return "CORPORATE EVENTS"
            case "individualEvent":
                return "PERSONAL EVENTS "
            case "stay":
                return "STAYCATIONS"
            // case "photoshoot":
            //     return "PHOTOSHOOT"
            default:
                return "No Type";
        }
    }

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search Activity'
                            value={searchData}
                            onChange={(e)=>{
                                setSearchData(e.target.value)
                                setPage(1)
                                setCount(0)
                            }}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                {currentUser?.permissions?.Cms_management?.write && (
                <div className="card-toolbar">
                    <Reports table='activities'/>
                    <button type='button' className='btn btn-primary' onClick={()=> setOpenAddTagModal(true)}>
                        <KTIcon iconName='plus' className='fs-2' />
                        Add New Activity
                    </button>
                </div>
                )}
            </div>
            
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    <th className='min-w-100px'>Activity Type</th>
                                    <th className='min-w-100px'>Activity Name</th>
                                    <th className='min-w-100px'>Created Date</th>
                                    <th className='min-w-100px'>Updated Date</th>      
                                    <th className='min-w-100px'>Status</th>      
                                    <th className='min-w-100px'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {data?.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                </div>
                                            </td> */}
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {getTagType(item?.type)}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {item?.name}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {Moment(item?.createdAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {Moment(item?.updatedAt).format('DD-MM-YYYY')}
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                    {item?.status}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        title="View Locations">
                                                        <KTIcon iconName='magnifier' className='fs-3' />
                                                    </div> */}
                                                    {/* <Link to ={`../job-view/${item._id}`} >
                                                        <div className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                            title="View">
                                                            View
                                                        </div>
                                                    </Link> */}
                                                    {currentUser?.permissions?.Tag_management?.write && (
                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            title="Update"
                                                            onClick={()=>handleUpdate(item)}
                                                            >
                                                            <KTIcon iconName='pencil' className='fs-3'/>
                                                        </div>
                                                    )}
                                                    {(currentUser?.permissions?.Tag_management?.write && item?.status === 'Under Review') && (
                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            title="Approve"
                                                            onClick={()=> handleUpdateStatus(item)}
                                                            >
                                                            <KTIcon iconName='verify' className='fs-3'/>
                                                        </div>
                                                    )}
                                                    {currentUser?.permissions?.Tag_management?.delete && (
                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            onClick={() => deletingTag(item._id)}
                                                            title="Delete page">
                                                            <KTIcon iconName='trash' className='fs-3' />
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                })}


                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
                {count > 0 && (
                    <div style={{display : 'flex',flexDirection : 'row-reverse' ,marginRight : "12px"}}>
                        <Stack spacing={2}>
                            <Pagination count={count} variant="outlined" shape="rounded" color="primary"
                            onChange={(event,value)=> {
                                if(value !== page){
                                    handleChange(value)
                                }
                            }}
                            />
                        </Stack>
                    </div>
                )}
            </div>
            {openAddTagModal && (
				<CreateTagModal
					show = {openAddTagModal}
					onHide = {()=>{
						setOpenAddTagModal(false)
					}}
					onSuccess = {()=>{
						setOpenAddTagModal(false)
                        getTagsData();
					}}
					title = 'Add New Activity'
				/>
			)}
            {openUpdateTagModal && (
				<UpdataTagModal
					show = {openUpdateTagModal}
					onHide = {()=>{
						setOpenUpdateTagModal(false)
                        setUpdatedData({})
                        getTagsData();
					}}
					title = 'Update New Activity'
                    data = {updatedData}
				/>
			)}
            {showLoader && <PageLoader />}
        </div>

    );
};

export default TagList;
