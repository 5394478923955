import { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import { KTIcon } from "../../../../../_metronic/helpers";
import { getCoupons,updateCoupon,deleteCoupon} from "../../services/api";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PageLoader } from "../../services/PageLoader";
import Moment from "moment";
import CreateCouponModal from "../modals/createCouponModal";
import UpdateCouponModal from "../modals/updateCouponModal";
import ViewCouponModal from "../modals/viewCouponModal";
import { useAuth } from "../../../auth";
import Reports from "../../Reports/Reports";

const CouponList = () => {
    const {currentUser} = useAuth()
    const [data, setData] = useState<any>([]);
    const [count,setCount] = useState<any>(0)
    const [searchData, setSearchData] = useState<any>("");
    const [showLoader, setShowLoader] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showViewModal, setShowViewModal] = useState(false)
    const [updateModalData, setUpdateModalData] = useState({})
    const [viewModalData, setViewModalData] = useState({})
    const getCouponData = async () => {
        try{
            const res = await getCoupons({page : 1, items_per_page : 20, searchTerm : searchData, status : "", plan : "", userId : "", subscription : ""});
            setData(res.data?.coupons)
            setCount(Math.ceil(res?.data?.count/20)) //items per page is 20 So, dividing with 20 to make no of pages appear
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }
    const handleChange = async(value) => {
        try{
            const resp = await getCoupons({page : value, items_per_page : 20, searchTerm : searchData, status : "", plan : "", userId : "", subscription : ""});
            setData(resp?.data?.coupons);
            window.scrollTo(0,0)
        }catch(error){
            console.log(error)
        }
    };
    const handleUpdatreStatus = async (data) => {
        setShowLoader(true)
        try{
            if(window.confirm('Are you sure you want to update status ?') == true){
                const updatedData = {
                    ...data,
                    status : data.status === 'Active' ? 'Deactivated' : "Active",
                }
                const resp = await updateCoupon(updatedData)
                alert(resp?.data?.message)
                getCouponData()
            }
        }catch(error : any){
            console.log(error)
            alert(error?.response?.data?.message)
        }
        setShowLoader(false)

    } 
    const handleDelete = async (data) => {
        setShowLoader(true)
        try{
            if(window.confirm('Are you sure you want to delete coupon ?') == true){
                const resp = await deleteCoupon(data?._id)
                alert(resp?.data?.message)
                getCouponData()
            }
        }catch(error : any){
            console.log(error)
            alert(error?.response?.data?.message)
        }
        setShowLoader(false)
    }
    const handleEdit = (data) => {
        setUpdateModalData(data)
        setShowUpdateModal(true)
    }
    const handleView = (data : any) => {
        setViewModalData(data)
        setShowViewModal(true)
    }
    useEffect(() => {
        setShowLoader(true)
        getCouponData();
    }, []);
    useEffect(() => {
        const timeOut = setTimeout(()=>{
            getCouponData();
        },1500)
        return ()=>{
            clearTimeout(timeOut)
        }
    },[searchData])

    const getCouponStatus = (start : any, end : any, status : any) => {
        let currentDate = new Date();
        currentDate.setHours(0,0,0,0)
        let startDate = new Date(start)
        startDate.setHours(0,0,0,0)
        let endDate = new Date(end)
        endDate.setHours(0,0,0,0)

        if (status === "Active") {
            if (currentDate < startDate) {
                return "Not Active Yet"
            } else if (currentDate > endDate) {
                return "Expired"
            } else {
                return status
            }
        }
        return status
    }

    return (
        <div className="card">
            <div className="card-header border-0 pt-6">
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search Coupon Name/Id'
                            onChange={(e)=>setSearchData(e.target.value)}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                {currentUser?.permissions?.Coupon_Management?.write && (
                <div className="card-toolbar">
                    <Reports table='coupons'/>
                    {/* <Link to = {"/apps/coupon/new_coupon"}> */}
                        <button type='button' className='btn btn-primary' onClick={()=> setShowCreateModal(true)}>
                            <KTIcon iconName='plus' className='fs-2' />
                            Add New Coupon
                        </button>
                {/* </Link> */}
        </div>
                )}
            </div>
            
            <div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {/* <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-13-check'
                                            />
                                        </div>
                                    </th> */}
                                    {/* <th className='min-w-100px'>Coupon From</th> */}
                                    <th className='min-w-100px'>Coupon Name</th>
                                    <th className='min-w-100px'>Coupon Id</th>
                                    <th className='min-w-100px'>Coupon Type</th>
                                    <th className='min-w-100px'>Created At</th>
                                    <th className='min-w-100px'>Updated At</th>
                                    {/* <th className='min-w-100px'>Activation Date</th>
                                    <th className='min-w-100px'>Expiry Date</th> */}
                                    <th className='min-w-100px'>Status</th>
                                    <th className='min-w-100px'>Actions</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            {data?.length > 0 ? (
                                <tbody>
                                    {data?.map((item, index) => {
                                        return (
                                            <tr>
                                                {/* <td>
                                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                        <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                                    </div>
                                                </td> */}
                                                {/* <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                        {item?.couponFrom}
                                                    </div>
                                                </td> */}
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                        {item?.couponName}
                                                    </div>
                                                </td>
                                                <td>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6"'>
                                                        {item.couponId}
                                                    </a>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary fs-6'>
                                                        {item?.couponType}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                        {/* {item?.createdAt} */}
                                                        {Moment(item?.createdAt).format('DD-MM-YYYY')}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                        {/* {item?.updatedAt} */}
                                                        {Moment(item?.updatedAt).format('DD-MM-YYYY')}
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                        {Moment(item?.activationDate).format('DD-MM-YYYY')}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6"'>
                                                        {Moment(item?.expiryDate).format('DD-MM-YYYY')}
                                                    </div>
                                                </td> */}
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {/* {item?.status} */}
                                                        { getCouponStatus(item?.activationDate, item?.expiryDate, item?.status)  }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {/* <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            title="View Locations">
                                                            <KTIcon iconName='magnifier' className='fs-3' />
                                                        </div> */}
                                                        {/* <Link to ={`../job-view/${item._id}`} > */}
                                                            <div className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                                                title="View" onClick={()=>handleView(item)}>
                                                                View
                                                            </div>
                                                        {/* </Link> */}
                                                        {/* {currentUser?.permissions?.Coupon_Management?.write && (
                                                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                title="Update" onClick={() =>handleEdit(item)}>
                                                                <KTIcon iconName='pencil' className='fs-3'/>
                                                            </div>
                                                        )} */}
                                                        {currentUser?.permissions?.Coupon_Management?.write && (
                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            onClick={() =>handleUpdatreStatus(item)}
                                                            title={item?.status === 'Active' ?  'Deactivate' : 'Activate'}>
                                                            <KTIcon iconName={item?.status === 'Active' ?  'cross-circle' : 'verify'} className='fs-3' />
                                                        </div>
                                                        )}
                                                        {/* {currentUser?.permissions?.Coupon_Management?.delete && (
                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            onClick={() =>handleDelete(item)}
                                                            title='Delete'>
                                                            <KTIcon iconName='trash' className='fs-3' />
                                                        </div>
                                                        )} */}
                                                    </div>
                                                </td>
                                            </tr>
    
                                        )
                                    })}
                                </tbody>
                                ) : (
                                    <tbody>
                                        <tr style={{ textAlign: "center", height: "200px" }}>
                                            <td colSpan={9}>No Data</td>
                                        </tr>
                                    </tbody>
                                )}
                                {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={count} variant="outlined" shape="rounded" color="primary" 
                        onChange={(event,value)=> {
                            if(value !== count){
                                handleChange(value)
                            }
                        }
                        }
                     />
                </Stack>
            </div>
            {showCreateModal && (
                <CreateCouponModal
                    show={showCreateModal}
                    title={'Create new coupon'} 
                    onHide={()=> setShowCreateModal(false)}
                    onSuccess = {()=> {
                        setShowCreateModal(false)
                        getCouponData()
                    }}
                />
            )}
            {showUpdateModal && (
                <UpdateCouponModal
                    show={showUpdateModal}
                    title={'Update new coupon'} 
                    onHide={()=> {
                        setShowUpdateModal(false)
                        setUpdateModalData({})
                    }}
                    onSuccess = {()=> {
                        setShowUpdateModal(false)
                        setUpdateModalData({})
                        getCouponData()
                    }}
                    data = {updateModalData}
                />
            )}
            {showViewModal && (
                <ViewCouponModal
                    show={showViewModal}
                    title={'Coupon Details'} 
                    onHide={()=> {
                        setShowViewModal(false)
                        setViewModalData({})
                    }}
                    data = {viewModalData}
                />
            )}
            {showLoader && <PageLoader />}
        </div>

    );
};

export default CouponList;
