import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import PrioritySupportManagementList from './list/PrioritySupportManagementList'

const locationsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Priority Support Management',
    path: '/apps/prioritysupport/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PrioritySupportManagement = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Priority Support</PageTitle>
              <PrioritySupportManagementList/>
            </>
          }
        />
       </Route> 
      <Route index element={<Navigate to='/apps/prioritysupport/list' />} />
    </Routes>
  )
}

export default PrioritySupportManagement
