import { useEffect, useState } from 'react';
import {addNewCity, updateCity} from "../../../services/api";
import Modal from 'react-bootstrap/Modal';
import { KTIcon } from "../../../../../../_metronic/helpers";

// const initial : any = {
//     sub : false
// }

export function AddNewLookupModal({show,onHide,title,data,editIndex,editValue}) {
    console.log(editIndex)
    console.log(editValue);
    const [formData,setFormData] = useState<any>({})
    const [oldCity, setOldCity] = useState<any>("")
    const [newCity,setNewCity] = useState<any>("")
    const [newCityName, setNewCityName] = useState<any>("");
    const [error,setError] = useState<any>('')

    useEffect(() => {
        if(editIndex || editIndex === 0){
            setFormData((prev) => {
                return {
                    ...prev,
                    "Edit Index" : editIndex,
                }
            })
        }
        if(editValue){
            setFormData((prev) => {
                return {
                    ...prev,
                    "city" : editValue,
                }
            })
            setOldCity(editValue);
        }
    },[]);

    console.log(formData);

    const submitForm = async (e) => {
        e.preventDefault()
        // if(formData?.sub?.length < 2) return alert('Please add minimum two values in sub items')
        let resp : any 
        try{
            if(editIndex){
                resp= await updateCity({oldCity, newCityName})
            }else{
                resp= await addNewCity({label : newCity, value : newCity})
            }
            console.log(resp.data?.message)
            alert(resp?.data?.message)
            onHide()
        }catch(error : any){
            console.log(error?.response?.data?.message)
            setError(error?.response?.data?.message)
        }
    }

  return (
    <Modal
    show={show}
    onHide={onHide}
    keyboard={false}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form className="col" onSubmit={submitForm}>
                {
                    <div className='fv-row mb-7' >
                        <label> City Name </label>
                        <input 
                            disabled = {formData?.city ? true : false }
                            value = {formData?.city}
                            type = "text"
                            className="form-control form-control-solid"
                            onChange = {(e) => {
                                if ( !editValue) setNewCity(e.target.value)
                            } }
                            /> 
                    </div>
                }
                 { editValue && 
                    <div className='fv-row mb-7' >
                        <label>Required City Name </label>
                        <input 
                            type = "text"
                            className="form-control form-control-solid"
                            onChange = {(e) => setNewCityName(e.target.value) }
                        /> 
                    </div>
                }
                {error && <span className='text-danger'>{error}</span>}
            <div>
                <button className='btn btn-secondary me-2' onClick={onHide}>Cancel</button>           
                <button className='btn btn-primary' type='submit'>Submit</button>           
            </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}