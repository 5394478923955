import {useEffect, useState} from 'react'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { KTIcon } from '../../../../_metronic/helpers'

const LogListFilter = ({filterFormData}) => {

  const [date, setDate] = useState<number | string>('')
  const [level, setLevel] = useState<string>('')
  const [category, setCategory] = useState<string>('')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setDate('');
    setLevel('');
    setCategory("");
    filterFormData('', '', '');
  }

  const filterData = () => {
    filterFormData(date, level, category);
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        //disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}


        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Date</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setDate(e.target.value)}
              value={date}
            >
              <option selected hidden value = "">Date</option>
              <option value={1}>Date Asc</option>
              <option value={-1}>Date Desc</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>level</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setLevel(e.target.value)}
              value={level}
            >
              <option selected hidden value = "">Select Value</option>
              <option value='info'>Info</option>
              <option value='error'>Error</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Category</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setCategory(e.target.value)}
              value={category}
            >
              <option selected hidden value = "">Select Category</option>
              <option value='App Notification'>App Notification</option>
              <option value='Admin Notification'>Admin Notification</option>
              <option value='BILLDESK_REQUEST'>Billdesk Request</option>
              <option value='BILLDESK_RESPONSE'>Billdesk Response</option>
              <option value='Email Notification'>Email Notification</option>
              <option value='LOCATION_CREATION'>Location Creation</option>
              <option value='LOCATION_UPDATION'>Location Updation</option>
              <option value='SUBSCRIPTION_AUTOMATION'>Subscription Automation Call</option>
              <option value='USER_CREATE'>User Create</option>
              <option value='USER_UPDATE'>User Update</option>
              <option value='SIGN_IN'>Signin</option>
              <option value='SIGN_UP'>Signup</option>
              <option value='Whatsapp Notification'>Whatsapp Notification</option>
              <option value='Sms Notification'>Sms Notification</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              //disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              //disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {LogListFilter}
