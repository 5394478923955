import { useEffect, useState } from "react"
import { getTypeOfProperties } from "../../../services/api";
import "../ListYourSpace.css"

// Images
import info from "../../../../../../_metronic/assets/images/i.svg"

const SubTypeOfProperty = ({data, getSaved, getBack}) => {

    const [selectedLocation, setSelectedLocation] = useState("");
    const [activeCategory,setActiveCategory] = useState('Commercial Space')
    const [categories,setCategories] = useState({})
    const [locationTypes, setLocationTypes] = useState({});
    const [inputPropertyType,setInputPropertyType] = useState('');
    const [search, setSearch] = useState("");

    const onChangeSearch = (e) => {
        const value = e.target.value
        setSearch(value)
        if(!value) setLocationTypes(categories)
        let tempObj = {}
        for( const mainHead in categories) {
            const tempArrey = []
            for(const subHead of categories[mainHead]){
                if(subHead.toLowerCase().includes(value.toLowerCase())) tempArrey.push(subHead)
            }
            if(tempArrey.length > 0) tempObj[mainHead] = tempArrey
        }
        setLocationTypes(tempObj)
        if(Object.keys(tempObj).length > 0)  setActiveCategory(Object.keys(tempObj)[0])

    }

    const handleAddNewPropertyType = (e) => {
        e.preventDefault()
        let tempPropertyType
        let tempActiveHead
        for( const mainHead in categories) {
            for(const subHead of categories[mainHead]){
                if(subHead.toLowerCase() === inputPropertyType.toLowerCase()){
                    tempPropertyType =  subHead
                    tempActiveHead = mainHead
                    break
                }
            }
        }

        if(tempPropertyType){
            setActiveCategory(tempActiveHead)
            setSelectedLocation(tempPropertyType)
        }else{
            setLocationTypes({
                ...locationTypes,
                Others : [inputPropertyType],
            })
            setSelectedLocation(inputPropertyType)
        }
        setInputPropertyType('')
    }
    const onClickCategory = (value) => {
        setActiveCategory(value)
    }
    const onCheckBoxClick = (e) => {
        const {name,checked} = e.target
        if(checked){
            setSelectedLocation(name)
        }else{
            setSelectedLocation('')
        }
    }

    const handleSave = () => {
        getSaved({location_type : selectedLocation} )
    }

    const handleBack = () => {
        getBack();
    }

    useEffect(()=>{
        const callBack = async()=> {
            try{
                const resp = await getTypeOfProperties()
                const temp = {}
                resp.data.map((type)=>{
                    temp[type.category] = type.types
                })
                setCategories({...temp})
                setLocationTypes({...temp})
                //setData
                console.log(data)
                setSelectedLocation(data)
                if(data){
                    let tempPropertyType ;
                    for (const category in  temp) {
                        if( temp[category].includes(data)){
                            setActiveCategory(category)
                            tempPropertyType = data
                            break
                        }
                    }
                    if(!tempPropertyType){
                        setLocationTypes({
                            ...temp,
                            Others : [data],
                        })
                        setActiveCategory('Others')
                    }
                }
            }catch(error){
                console.log(error?.response?.data?.message)
            }
        }
        callBack()
    },[data])
    

    return (
        <>
            <div className="locationtype-page  ">
                <div className="locationtype-heading-container" >
                    <h1 className="locationtype-head" > Type of Property {' '}
                        <a
                            href="#"
                            data-bs-toggle="tooltip"
                            title="Select: Residential, Commercial, Event Venue, Vacation Rental"
                        >
                            <img src={info} />
                        </a>
                    </h1>
                    <div className="locationtype-searchcontainer">
                        <input
                            type="search"
                            placeholder="Search type of property"
                            onChange={onChangeSearch}
                            className="locationtype-search"
                            value={search}
                        />
                    </div>
                </div>
            <div className="tabCard row mb-2 ">
                <div className="mainHeadContainer  col-lg-3 ">
                    {Object.keys(locationTypes).map((category,i) => {
                        return(
                            <div 
                            key={i}
                            className={activeCategory=== category ? "category  is-active-categorry" : 'category'}
                            onClick={()=>onClickCategory(category)}
                            >
                                <p>{category}</p>
                            </div>
                        )
                    })}
                </div>
                <div className="subHeadContainer  col-lg-6">
                    {locationTypes[activeCategory]?.map((locationType,i)=>{
                        return(
                            <div 
                            key={i}
                            className={selectedLocation === locationType ? 'is-active-locationType' : 'locationType'}
                            >
                                <div className="d-flex flex-row  align-items-center" >
                                    <input className="amenities-checkbox" type='checkbox' name={locationType} checked={selectedLocation === locationType} id={i} onChange={onCheckBoxClick}/>
                                    <label  className="amenities-label" htmlFor={i}>{locationType}</label>
                                </div>
                            </div>
                        )
                    })}
                    {(activeCategory=== "Others" && (locationTypes.Others.length === 0 || !selectedLocation)) && (
                        <form className="d-flex align-items-center mx-2" onSubmit={handleAddNewPropertyType}>
                            <div className="mb-4 d-flex flex-column">
                                <label htmlFor="property_type" className="form-label">
                                    Type of property
                                </label>
                                <input
                                    type="text"
                                    required
                                    placeholder="Enter property type"
                                    id="property_type"
                                    name="property_type"
                                    className="textFiled me-2"
                                    value={inputPropertyType}
                                    onChange={(e) => setInputPropertyType(e.target.value)}
                                />
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            
            {/* Save Section Start */}
            <div className="row "    >
                <div className="d-flex flex-row justify-content-between" >
                <button
                        type="button"
                        class="btn btn-outline-danger"
                        onClick={handleBack}
                    >
                        Discard
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary me-3"
                        
                        onClick={handleSave}
                        disabled={selectedLocation ? false : true}
                    >
                        Continue 
                    </button>
                </div>
            </div>
            {/* Save Section End */}

            </div>
        </>
    )
}

export default SubTypeOfProperty