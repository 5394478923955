import { useEffect, useState } from "react"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Moment from 'moment';
import { getUserLeads } from "../../services/api";
import { KTIcon } from "../../../../../_metronic/helpers";
import { PageLoader } from "../../services/PageLoader";
 


const UserLeads = ({userId, type}) => {
    const [data, setData] = useState<any>([]);
    const [loader, setLoader] = useState<any>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [pageCount, setPageCount] = useState<any>(0);
    const [page, setPage] = useState<any>(1);
    const PAGE_LIMIT = 20;

    const getData = async() => {
        setLoader(true)
        try {
            const res = await getUserLeads({userId, type, searchTerm, page, limit : PAGE_LIMIT});
            setPageCount(Math.ceil(res?.data?.count / PAGE_LIMIT));
            setPage(1);
            setData(res?.data?.leads);
        } catch (error:any) {
            console.log(error);
            alert(error?.response?.data?.message)
        }
        setLoader(false)
    }

    useEffect(() => {
        getData();
    }, [searchTerm, type])

    const handleChange = async (event : any, value : any) => {
        setLoader(true)
        try {
            const res = await getUserLeads({ userId, type, searchTerm, page: value, limit : PAGE_LIMIT});
            setPage(value);
            setPageCount(Math.ceil(res?.data?.count / PAGE_LIMIT)) ;
            setData(res?.data?.leads);
        } catch (error : any) {
            console.log(error);
            alert(error?.response?.data?.message);
        }
        setLoader(false)
    };

    const bookingLeadsTable = () => {
        return(
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                    <tr className='fw-bold text-muted'>
                        {/* <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-13-check'
                                />
                            </div>
                        </th> */}
                        <th className='min-w-100px'>Enquiry Id</th>
                        <th className='min-w-100px'>Location Id</th>
                        <th className='min-w-100px'>Enquiry Date</th>
                        <th className='min-w-100px'>Booking Requested Date</th>
                        <th className='min-w-100px'>Event</th>
                        <th className='min-w-100px'>Status</th>
                        {/* <th className='min-w-100px'>Action</th> */}
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    {data?.map((item : any, index :any) => {
                        return (
                            <tr>
                                {/* <td>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                    </div>
                                </td> */}
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.enquiryId}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.data?.locationId}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {Moment(item.createdAt).format('DD-MM-YYYY')}
                                    </div>
                                </td>
                                { item?.data?.event === "stay" ? (
                                    <td>
                                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            {Moment(item?.data?.checkIn).format('DD-MM-YYYY')}
                                        </div>
                                    </td>
                                ) : (
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {Moment(item?.data?.startDate).format('DD-MM-YYYY')}
                                    </div>
                                </td>
                                )}
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.data?.event}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.status}
                                    </div>
                                </td>
                                {/* <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' >
                                    <button className="btn btn-light btn-active-primary btn-flex btn-center btn-sm" onClick = {() => onClickViewDetails(item)}> View </button>
                                    </div>
                                </td> */}
                            </tr>
                        )
                    })}
                </tbody>
                {/* end::Table body */}
            </table>
        )
    }
    const scheduleVisitLeadsTable = () => {
        return(
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                    <tr className='fw-bold text-muted'>
                        {/* <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-13-check'
                                />
                            </div>
                        </th> */}
                        <th className='min-w-100px'>Enquiry Id</th>
                        <th className='min-w-100px'>Location Id</th>
                        <th className='min-w-100px'>Enquiry Date</th>
                        <th className='min-w-100px'>Schedule Date</th>
                        <th className='min-w-100px'>Status</th>
                        {/* <th className='min-w-100px'>Action</th> */}
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    {data?.map((item : any, index :any) => {
                        return (
                            <tr>
                                {/* <td>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                    </div>
                                </td> */}
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.enquiryId}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.data?.locationId}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {Moment(item.createdAt).format('DD-MM-YYYY')}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.data?.date}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.status}
                                    </div>
                                </td>
                                {/* <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' >
                                    <button className="btn btn-light btn-active-primary btn-flex btn-center btn-sm" onClick = {() => onClickViewDetails(item)}> View </button>
                                    </div>
                                </td> */}
                            </tr>
                        )
                    })}
                </tbody>
                {/* end::Table body */}
            </table>
        )
    }
    const getInTouchLeadsTable = () => {
        return(
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                    <tr className='fw-bold text-muted'>
                        {/* <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-13-check'
                                />
                            </div>
                        </th> */}
                        <th className='min-w-100px'>Enquiry Id</th>
                        <th className='min-w-100px'>Location Id</th>
                        <th className='min-w-100px'>Enquiry Date</th>
                        <th className='min-w-100px'>Visit Requested Date</th>
                        <th className='min-w-100px'>Status</th>
                        {/* <th className='min-w-100px'>Action</th> */}
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    {data?.map((item : any, index :any) => {
                        return (
                            <tr>
                                {/* <td>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                    </div>
                                </td> */}
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.enquiryId}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.data?.locationId}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {Moment(item.createdAt).format('DD-MM-YYYY')}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {Moment(item?.data?.date).format('DD-MM-YYYY')}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.status}
                                    </div>
                                </td>
                                {/* <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' >
                                    <button className="btn btn-light btn-active-primary btn-flex btn-center btn-sm" onClick = {() => onClickViewDetails(item)}> View </button>
                                    </div>
                                </td> */}
                            </tr>
                        )
                    })}
                </tbody>
                {/* end::Table body */}
            </table>
        )
    }
    const contactHostLeadsTable = () => {
        return(
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                    <tr className='fw-bold text-muted'>
                        {/* <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-13-check'
                                />
                            </div>
                        </th> */}
                        <th className='min-w-100px'>Enquiry Id</th>
                        <th className='min-w-100px'>Location Id</th>
                        <th className='min-w-100px'>Enquiry Date</th>
                        <th className='min-w-100px'>Status</th>
                        {/* <th className='min-w-100px'>Action</th> */}
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    {data?.map((item : any, index :any) => {
                        return (
                            <tr>
                                {/* <td>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                    </div>
                                </td> */}
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.enquiryId}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.data?.locationId}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {Moment(item.createdAt).format('DD-MM-YYYY')}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.status}
                                    </div>
                                </td>
                                {/* <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' >
                                    <button className="btn btn-light btn-active-primary btn-flex btn-center btn-sm" onClick = {() => onClickViewDetails(item)}> View </button>
                                    </div>
                                </td> */}
                            </tr>
                        )
                    })}
                </tbody>
                {/* end::Table body */}
            </table>
        )
    }
    const reportIncorrectInfoTable = () => {
        return(
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                    <tr className='fw-bold text-muted'>
                        {/* <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value='1'
                                    data-kt-check='true'
                                    data-kt-check-target='.widget-13-check'
                                />
                            </div>
                        </th> */}
                        <th className='min-w-100px'>Enquiry Id</th>
                        <th className='min-w-100px'>Location Id</th>
                        <th className='min-w-100px'>Report Date</th>
                        <th className='min-w-100px'>Status</th>
                        <th className='min-w-100px'>Report</th>
                        <th className='min-w-100px'>Info</th>
                        {/* <th className='min-w-100px'>Action</th> */}
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    {data?.map((item : any, index :any) => {
                        return (
                            <tr>
                                {/* <td>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                                    </div>
                                </td> */}
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.enquiryId || "NA"}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.data?.locationId}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {Moment(item.createdAt).format('DD-MM-YYYY')}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.status}
                                    </div>
                                </td>
                                <td className="d-flex flex-column justify-content-center align-items-center"  style={{width:"300px"}}>
                                    {item?.data?.reportDetails?.map((eachReport : any) => {
                                        return <span> {eachReport} </span>
                                    } )}
                                </td>
                                <td style={{width:"300px"}}>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                        {item?.data?.additionalInfo || "NA"}
                                    </div>
                                </td>
                                {/* <td>
                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' >
                                    <button className="btn btn-light btn-active-primary btn-flex btn-center btn-sm" onClick = {() => onClickViewDetails(item)}> View </button>
                                    </div>
                                </td> */}
                            </tr>
                        )
                    })}
                </tbody>
                {/* end::Table body */}
            </table>
        )
    }

    const tableView = (type) => {
        switch (type) {
            case "Booking Request":
                return bookingLeadsTable();
            case "Get In Touch":
                return getInTouchLeadsTable();
            case "Schedule Property Visit":
                return scheduleVisitLeadsTable();
            case "Contact Host":
                return contactHostLeadsTable();
            case "Report Incorrect Info":
                return reportIncorrectInfoTable();
            default:
                break;
        }
    }



    return (
        <>
        {data?.length > 0 ? (
            <div className="card">
                <div className="card-header border-0 pt-6">
                    <div className='card-title'>
                        {/* begin::Search */}
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                className='form-control form-control-solid w-250px ps-14'
                                placeholder='Search Leads'
                                value={searchTerm}
                                onChange={(e : any) => setSearchTerm(e?.target?.value) }
                            />
                        </div>
                        {/* end::Search */}
                    </div>
                    <div className="card-toolbar">
                        {/* <BookingListFilter filterFormData={filterData} /> */}
                    </div>
                </div>

                <div>
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            {tableView(type)}
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                    <Stack spacing={2}>
                        <Pagination page={page} count={pageCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                    </Stack>
                </div>
            </div>
        ) : (
            <div className="card">
                <div className="card-header border-0 pt-6 d-flex justify-content-center">
                    <span className="" style={{fontSize: "24px"}} > No Requests </span>
                </div>
            </div>
        )}
            {/* {show && (
                <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Booking Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column">
                            {bookingRequest(modalData)}
                        </div>
                    </Modal.Body>
                </Modal>
            )} */}
            {loader && <PageLoader/> }
        </>
    )
} 

export default UserLeads