import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function DeletePopUp({
  open,
  handleClose,
  data,
}) {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          These locations are linked
        </Typography>
        {data.status ? (
          <p>{data.lookUp} is successfully deleted</p>
        ) : (
        <div style={{display : 'flex', flexDirection : 'row',overflowY : 'scroll', height : '200px'}}>
          <div>
            <p>Approved Listings</p>
            <ul>
              {data.locations.map((file,i) => {
                return(
                  <li key = {i}>{file.location_id}</li>
                )
              })}
            </ul>
          </div>
          <div>
          <p>Temp locations</p>
            <ul>
              {data.tempLocaitons.map((file,i) => {
                return(
                  <li key = {i}>{file.location_id}</li>
                )
              })}
            </ul>
          </div>
        </div>
        )}
          <Stack direction="row" spacing={2} justifyContent="flex-end" pt={2}>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="success" onClick={handleClose}>
              Ok
            </Button>
          </Stack>
        </Box>
    </Modal>
  );
}
