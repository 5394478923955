import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import DeactivatedLocations from './deactivated/DeactivatedLocations'
import EditedLocations from './Edited/EditedLocations'
import ReviewLocations from './reviewed/ReviewLocations'
import { EditLocation } from './edit/EditLocation'
import { ListYourSpace } from './ListYourSpace/ListYourSpace'
import LocationRequests from './requests/LocationRequests'
import IncompleteLocations from './incomplete/IncompleteLocations'
import ApprovedLocations from './approved/ApprovedLocations'
import RejectedLocations from './rejected/RejectedLocations'
import FeaturedList from './featured/featuredList'
import TopRatedList from './topRated/topRatedList'
import TypeOfPropertyLookups from './typeOfProperty/TypeOfPropertyLookups'
import AmenitiesLookups from './amenities/AmenitiesLookups'
import CitiesLookups from './Cities/CitiesLookup'

const locationsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Location Management',
    path: '/apps/location-management/incomplete',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LocationManagementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='incomplete'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Incomplete Listings</PageTitle>
              <IncompleteLocations/>
            </>
          }
        />
        {/* <Route
          path='location/:locationId'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Incomplete Listings</PageTitle>
              <EditLocation />
            </>
          }
        /> */}
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='requests'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Listing Requests</PageTitle>
              <LocationRequests />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='reviewed'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Listing for approval</PageTitle>
              <ReviewLocations />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='approved'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Approved Listings</PageTitle>
              <ApprovedLocations/>
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='rejected'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Rejected Listings</PageTitle>
              <RejectedLocations />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='deactivated'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Deactivated Listings</PageTitle>
              <DeactivatedLocations />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='edited'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Edited Listings</PageTitle>
              <EditedLocations />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='featured'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Featured Listings</PageTitle>
              <FeaturedList />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='exclusive'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Exclusive Listings</PageTitle>
              <TopRatedList />
            </>
          }
        />
      </Route>
      {/* <Route element={<Outlet />}>
        <Route
          path='edit'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Edit location</PageTitle>
              <EditLocation />
            </>
          }
        />
      </Route> */}
      <Route
          path='location/:locationId'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Edit location</PageTitle>
              <EditLocation isTempLocation={false}/>
            </>
          }
        />
        <Route
          path='incompletelocation/:locationId'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Edit Incomplete location</PageTitle>
              <EditLocation isTempLocation={true}/>
            </>
          }
        />
        <Route
          path='listing/:locationId'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Edit listing</PageTitle>
              <ListYourSpace isIncompleteLisitng={false} edited={false}/>
            </>
          }
        />
        <Route
          path='incompletelisting/:locationId'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Edit Incomplete listing</PageTitle>
              <ListYourSpace isIncompleteLisitng={true} edited={false}/>
            </>
          }
        />
        <Route
          path='editedlisting/:locationId'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Edited listing</PageTitle>
              <ListYourSpace isIncompleteLisitng={false} edited={true}/>
            </>
          }
        />
        <Route
          path='typeOFProperty'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Type of Property Lookups</PageTitle>
              <TypeOfPropertyLookups/>
            </>
          }
        />
        <Route
          path='amenities'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Amenities Lookups</PageTitle>
              <AmenitiesLookups/>
            </>
          }
        />
        <Route
          path='cities'
          element={
            <>
              <PageTitle breadcrumbs={locationsBreadcrumbs}>Cities Lookups</PageTitle>
              <CitiesLookups/>
            </>
          }
        />
      <Route index element={<Navigate to='/apps/location-management/incomplete' />} />
    </Routes>
  )
}

export default LocationManagementPage
