import { useEffect,useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './SubscriptionModal.css'
import { createPaymentOrder,saveSubscription,getCoupons,applyCouponCode, getSubscription } from '../../services/api';

const SubscriptionModal = ({show,onHide,plan,handleChangePlan,completeTask,title,selectedSubscription, subscriptionId}) => {
    const SGST = 9
    const CGST = 9
    const [activeTab,setActiveTab] = useState('tab1')
    const [paymentDetails,setPaymentDetails] = useState({})
    const [billingAddress,setBillingAddress] = useState({})
    const [coupons,setCoupons] = useState([])
    const [couponTabActive,setCouponTabActive] = useState(false)
    const [inputCoupon,setInputCoupon] = useState('')
    const [selectedCoupon,setSelectedCoupon] = useState('')
    const [subscription,setSubscription] = useState(false)
    const [error,setError] = useState('')
    const onChange = (e) => {
        const {name,value} = e.target
        setBillingAddress({
            ...billingAddress,
            [name] : value
        })
    }
    const onBack = () => {
        setActiveTab('tab1')
    }
    const onSubmit = () => {
        setActiveTab('tab2')
        
    }
    const onPay = (e) => {
        e.preventDefault()
        displayRazorpay()
    }
    function customRound(number) {
        let decimalPart = number % 1;
        if (decimalPart < 0.5) {
          return Math.floor(number);
        } else {
          return Math.ceil(number);
        }
      }
    const calculatePrice = (price) => {
        let basicPrice = parseFloat((price*100)/(100+SGST+CGST)).toFixed(2)
        let sgst = parseFloat(basicPrice*(SGST/100)).toFixed(2)
        let cgst = parseFloat(basicPrice*(CGST/100)).toFixed(2)
        let totalAmount = parseFloat(basicPrice) + parseFloat(sgst) + parseFloat(cgst)
        setPaymentDetails({
            couponId : '',
            couponDiscount : '',
            basicPrice,
            sgst,
            cgst,
            totalAmount : parseFloat(customRound(totalAmount)).toFixed(2)
        })
    }
    const calculateUpgradePrice = (data,price) => {
        let prevPlanPrice = 0
        let perDayPrice = 0
        let daysUsed = 0
        let usedPrice = 0
        let priceBalance = 0
        let basePrice = 0
        if(data?.planName === 'Standard'){
            prevPlanPrice = 7499.00
        }
        perDayPrice = parseFloat(prevPlanPrice/365).toFixed(2)
        let toDayDate = new Date()
        let activationDate = data?.activationDate ? new Date(data?.activationDate) : new Date()
        // Calculating the time difference of two dates
        let Difference_In_Time = toDayDate.getTime() - activationDate.getTime();
        // Calculating the no. of days between two dates
        daysUsed =Math.round(Difference_In_Time / (1000 * 3600 * 24));
        usedPrice = parseFloat(daysUsed * perDayPrice).toFixed(2)
        priceBalance = parseFloat(prevPlanPrice - usedPrice).toFixed(2)
        basePrice = parseFloat(price - priceBalance).toFixed(2)
        let basicPrice = parseFloat((basePrice*100)/(100+SGST+CGST)).toFixed(2)
        let sgst = parseFloat(basicPrice*(SGST/100)).toFixed(2)
        let cgst = parseFloat(basicPrice*(CGST/100)).toFixed(2)
        let totalAmount = parseFloat(basicPrice) + parseFloat(sgst) + parseFloat(cgst)

        setPaymentDetails({
            couponId : '',
            couponDiscount : '',
            prevPlanPrice,
            perDayPrice,
            daysUsed,
            priceBalance,
            basicPrice,
            sgst,
            cgst,
            totalAmount : parseFloat(customRound(totalAmount)).toFixed(2)
        })


    }
    const calculateCouponValue = (couponId,type,value,percentage) => {
        let couponDiscount = 0
        let price1=0, sgst=0, cgst=0, totalAmount=0
        if(type === 'Flat'){
            couponDiscount =  parseFloat(value).toFixed(2)
        }else if(type === 'Percentage'){
            couponDiscount = parseFloat(paymentDetails?.basicPrice * (percentage/100)).toFixed(2)
        }
        price1 = parseFloat(paymentDetails?.basicPrice - couponDiscount).toFixed(2)
        sgst = parseFloat(price1*(SGST/100)).toFixed(2)
        cgst = parseFloat(price1*(CGST/100)).toFixed(2)
        totalAmount = parseFloat(price1) + parseFloat(sgst) + parseFloat(cgst)
        setPaymentDetails({
            ...paymentDetails,
            couponId : couponId,
            couponDiscount : couponDiscount,
            sgst,
            cgst,
            totalAmount : parseFloat(customRound(totalAmount)).toFixed(2)
        })
        setSelectedCoupon(couponId)
    }
    const getData = async () => {
        try{
            const resp = await getCoupons({status : 'Active', plan : plan?.plan, userId : selectedSubscription?.userId, currentSubscription : selectedSubscription?.planName})
            setCoupons(resp?.data?.coupons)
        }catch(error){
            console.log(error)
        }
    }
    const getSubscriptionDetails = async () => {
        try{
            const resp2 = await getSubscription(subscriptionId)
            setSubscription(resp2?.data)
            if(resp2?.data?.planName === 'Standard'){
                calculateUpgradePrice(resp2?.data,parseFloat(plan?.amount).toFixed(2))
            }else if(resp2?.data?.planName === 'Premium'){
                alert('You are subscribed to maximum plan')
                onHide()
            }else{
                calculatePrice(parseFloat(plan?.amount).toFixed(2))
            }
        }catch(error){
            console.log(error)
        }
    }

    const applyCoupon = async (couponId) => {
        try{
            const resp = await applyCouponCode(couponId, subscription?.planName ? "StdToPrm" : plan?.plan)
            calculateCouponValue(couponId,resp?.data?.discountType,resp?.data?.couponValue,resp?.data?.couponPercentage)
        }catch(error){
            console.log(error)
            setError(error?.response?.data?.message)
        }
    }
    const removeCoupon = () => {
        setSelectedCoupon('')
        setInputCoupon('')
        setError('')
        setCouponTabActive(false)
        calculatePrice(parseFloat(plan?.amount).toFixed(2))
        calculateUpgradePrice(subscription, parseFloat(plan?.amount).toFixed(2))
    }
    useEffect(()=> {
        setSelectedCoupon('')
        setInputCoupon('')
        setError('')
        setCouponTabActive(false)
        if(subscriptionId){
            getSubscriptionDetails()
        }else{
            calculatePrice(parseFloat(plan?.amount).toFixed(2))
        }
        getData()
    },[plan]);

    //razerpay start
    function loadScript(src) {
        return new Promise((resolve) => {
          const script = document.createElement('script');
          script.src = src;
          script.onload = () => {
            resolve(true);
          };
          script.onerror = () => {
            resolve(false);
          };
          document.body.appendChild(script);
        });
      }

    async function displayRazorpay() {
        try {
          const res = await loadScript(
            'https://checkout.razorpay.com/v1/checkout.js'
          );
  
          if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
          }
          const result = await createPaymentOrder({
            amount: paymentDetails?.totalAmount,
            currency: plan?.currency,
          });
          if (!result) {
            alert('Server error. Are you online?');
            return;
          }
          const { amount, id: order_id, currency } = result?.data;
          const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: amount,
            currency: currency,
            // "image":  logo,
            "theme": {
              "color": "#ea4335"
            },
            name: 'SpotLet Subscription',
            description: 'Listing Subscription Amount',
            order_id: order_id,
            handler: async function (response) {
              let newObj = {
                ...paymentDetails,
                orderId : order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
                status : 'Success',
              }
            await saveSubscription({userId: selectedSubscription?.userId,plan,newObj,billingAddress,subcriptionId : selectedSubscription?._id})
            completeTask()
            },
            prefill: {
            //   name: userProfile?.personalInfo?.firstName,
            //   email: userProfile?.personalInfo?.email,
            //   contact: userProfile?.personalInfo?.mobile,
            },
            notes: {
              address: '',
            },
            theme: {
              color: '#61dafb',
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } catch (e) {
          console.log(e);
        }
      }
    //razerpay end

    return(
        <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body style={{backgroundColor : '#f2f2f2',borderRadius : '20px'}}>
        {activeTab ==='tab1' && (
            <>
                <div className='subscriptionModalMainContainer'>
                    <div className='subscriptionModalPlanContainer'>
                        <div>
                            <span className='subscriptionModalValueText SpotLetSubHead'>{title}</span>
                        </div>
                        <hr/>
                        <div className='d-flex flex-row justify-content-between SpotLetContent'>
                            <span className='subscriptionModalKeyText mb-3'>Plan Name:</span>
                            <span className='subscriptionModalValueText mb-3'>{plan?.plan}</span>
                        </div>
                        <div className='d-flex flex-row justify-content-between SpotLetContent'>
                            <span className='subscriptionModalKeyText mb-3'>Price per Annum:{' '}<strong className='subscriptionModalValueText'>(Incl. of GST)</strong></span>
                            <span className='subscriptionModalValueText mb-3'><i class="fa fa-inr"></i>{' '}{parseFloat(plan?.amount).toFixed(2)}</span>
                        </div>
                        {subscription && (
                            <>
                                <div className='d-flex flex-row justify-content-between SpotLetContent'>
                                    <span className='subscriptionModalKeyText mb-3'>Previous Plan Used:{' '}</span>
                                    <span className='subscriptionModalValueText mb-3'>{' '}{paymentDetails?.daysUsed} days</span>
                                </div>
                                <div className='d-flex flex-row justify-content-between SpotLetContent'>
                                    <span className='subscriptionModalKeyText mb-3'>Previous Plan Balance:{' '}</span>
                                    <span className='subscriptionModalValueText mb-3'><i class="fa fa-inr"></i>{' '}{paymentDetails?.priceBalance}</span>
                                </div>
                            </>
                        )}
                        {/* <div className='mb-3'>
                            <span className='subscriptionModalNoteText SpotLetContent'>Note:{' '}<span className='subscriptionModalNoteValue SpotLetContent'>Subscription will start from the date of listing approved by SpotLet</span></span>
                        </div> */}
                         {(!subscriptionId || subscription?.planName !== 'Standard') && (
                            <Button onClick={handleChangePlan} variant="outline-primary" className='SpotLetContent subscriptionModalChangePlan'>Change to {plan?.plan === 'Standard' ? "Premium" :  "Standard"}</Button>
                        )}
                    </div>
                    <div className='subscriptionModalPlanContainer'>
                        <div>
                            <span className='subscriptionModalValueText SpotLetSubHead'>Payment Details</span>
                        </div>
                        <hr/>
                        <div className='d-flex flex-row justify-content-between SpotLetContent'>
                            <span className='subscriptionModalKeyText mb-3'>Basic Price:</span>
                            <span className='mb-3'><i class="fa fa-inr" style={{opacity:"0.6"}}></i>{' '}{paymentDetails?.basicPrice}</span>
                        </div>
                        {!selectedCoupon && (
                            <>
                                {/* {!couponTabActive && ( */}
                                {/* {!subscription && ( */}
                                    <div onClick={()=> setCouponTabActive(!couponTabActive)} className='subscriptionModalApplyCoupon subscriptionModalValueText d-flex flex-row justify-content-between mb-3'>
                                        <span>Apply Coupon</span>
                                        <span>{'>'}</span>
                                    </div>
                                {/* )} */}
                                {couponTabActive && (
                                    <div className='subscriptionModalPlanContainer subscriptionModalCouponContainer'>
                                            <div>
                                                <div className='d-flex flex-row justify-content-between'>
                                                    <input
                                                        type='text'
                                                        name='couponCode'
                                                        value={inputCoupon}
                                                        onChange={(e)=>setInputCoupon(e.target.value)}
                                                        className = 'textFiled w-100  me-2'
                                                        placeholder='Enter Coupon Code'
                                                    />
                                                    <div>
                                                        <button disabled={!inputCoupon} onClick={()=>applyCoupon(inputCoupon)} type='button' className='btn btn-primary' style={{height : '35px'}}>Apply</button>
                                                    </div>
                                                </div>
                                                {error && <span className='subscriptionModalNoteText mb-3' style={{fontSize : '12px'}}>{error}</span>}
                                            </div>
                                            <span className='subscriptionModalValueText mt-1'>Your Coupons{' '}({coupons?.length})</span>
                                            <div className='subscriptionModalCouponMainSection'>
                                                {coupons?.map((item,i) => {
                                                    return(
                                                        <div className='subscriptionModalEachCoupon'>
                                                            <div className='d-flex flex-column'>
                                                                <span className='subscriptionModalEachCouponName'>{item?.couponName}{' '}<span className='subscriptionModalKeyText'>({item?.couponValue && `Flat ${item?.couponValue}`}{item?.couponPercentage && `${item?.couponPercentage} %`})</span></span>
                                                                <span className='subscriptionModalEachCouponDesc'>{item?.description}<span className='subscriptionModalNoteText' title={item?.terms} style={{cursor : 'pointer'}}>{' '}T&C</span></span>
                                                                <span className='subscriptionModalEachCouponId'>{item?.couponId}</span>
                                                            </div>
                                                            <div>
                                                                <button onClick={()=> applyCoupon(item?.couponId)} type='button' className='btn btn-primary' style={{height : '35px'}}>Apply</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {!coupons?.length && (
                                                    <span className='subscriptionModalKeyText'>No Coupons Available</span>
                                                )}
                                            </div>
                                        </div>
                                )}
                            </>
                        )}
                        {selectedCoupon && (
                            <div className='d-flex flex-row justify-content-between SpotLetContent'>
                                <div className='d-flex flex-column'>
                                    <span className='subscriptionModalKeyText mb-1'>Coupon Discount:</span>
                                    <span className='mb-3 subscriptionModalNoteText' style={{fontSize : '10px',cursor:'pointer'}} onClick={removeCoupon}>Remove Coupon</span>
                                </div>
                                <span className='subscriptionModalValueText mb-3'>-{' '}<i class="fa fa-inr"></i>{' '}{paymentDetails?.couponDiscount}</span>
                            </div>
                        )}
                        <div className='d-flex flex-row justify-content-between SpotLetContent'>
                            <span className='subscriptionModalKeyText mb-3'>SGST{' '}@{' '}9%:</span>
                            <span className='mb-3'>+{' '}<i class="fa fa-inr" style={{opacity:"0.6"}}></i>{' '}{paymentDetails?.sgst}</span>
                        </div>
                        <div className='d-flex flex-row justify-content-between SpotLetContent'>
                            <span className='subscriptionModalKeyText mb-3'>CGST{' '}@{' '}9%:</span>
                            <span className='mb-3'>+{' '}<i class="fa fa-inr" style={{opacity:"0.6"}}></i>{' '}{paymentDetails?.cgst}</span>
                        </div>
                        <div className='d-flex flex-row justify-content-between SpotLetContent'>
                            <span className='subscriptionModalKeyText mb-3'>Payable Amount: {' '}(Round off)</span>
                            <span className='subscriptionModalValueText mb-3'><i class="fa fa-inr"></i>{' '}{paymentDetails?.totalAmount}</span>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <Button onClick={onHide} variant='secondary'>Close</Button>
                    <Button onClick={onSubmit}>Continue</Button>
                </div>
            </>
        )}
        {activeTab === 'tab2' && (
            <div className='subscriptionModalMainContainer'>
                <div className='subscriptionModalPlanContainer'>
                    <div>
                        <span className='subscriptionModalValueText SpotLetSubHead'>Billing Info</span>
                    </div>
                    <hr/>
                    <form className='d-flex flex-column SpotLetContent' onSubmit={onPay}>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>Billing Name{' '}<span className='subscriptionModalNoteText'>*</span></label>
                            <input
                                type='text'
                                name='billingName'
                                className='textFiled'
                                value={billingAddress?.billingName}
                                required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>GST Number{' '}(Optional)</label>
                            <input
                                type='text'
                                name='gstNumber'
                                className='textFiled'
                                value={billingAddress?.gstNumber}
                                // required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>Address{' '}<span className='subscriptionModalNoteText'>*</span></label>
                            <input
                                type='text'
                                name='address'
                                className='textFiled'
                                value={billingAddress?.address}
                                required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>City{' '}<span className='subscriptionModalNoteText'>*</span></label>
                            <input
                                type='text'
                                name='city'
                                className='textFiled'
                                value={billingAddress?.city}
                                required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>State{' '}<span className='subscriptionModalNoteText'>*</span></label>
                            <input
                                type='text'
                                name='state'
                                className='textFiled'
                                value={billingAddress?.state}
                                required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-column w-100 mb-2'>
                            <label className='form-label'>Pincode{' '}<span className='subscriptionModalNoteText'>*</span></label>
                            <input
                                type='number'
                                name='pincode'
                                className='textFiled'
                                value={billingAddress?.pincode}
                                required
                                onChange={onChange}
                             />
                        </div>
                        <div className='d-flex flex-row justify-content-between'>
                            <button type='button' onClick={onBack} className='btn btn-secondary'>Back</button>
                            <button type='submit' className='btn btn-primary'>Proceed to Pay</button>
                        </div>
                    </form>
                </div>
            </div>
        )}
      </Modal.Body>
    </Modal>
    )
}
export default SubscriptionModal;