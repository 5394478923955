import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
// import LookupList from './list/LookupList'
import CareerList from './list/CareerList'
import CreateJob from './creatJob'
import ViewJob from './viewJob'
const breadcrumbs: Array<PageLink> = [
  {
    title: 'Career Management',
    path: '/apps/career',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CareerManagementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs} description='List'>Career List</PageTitle>
              <CareerList/>
            </>
          }
        />
        <Route
          path='job'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Create Job</PageTitle>
              <CreateJob/>
            </>
          }
        />
        <Route
          path='job/:id'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Update Job</PageTitle>
              <CreateJob/>
            </>
          }
        />
        <Route
          path='job-view/:id'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>View Job</PageTitle>
              <ViewJob/>
            </>
          }
        />
       </Route> 
       <Route index element={<Navigate to='/apps/career/list' />} />
    </Routes>
  )
}

export default CareerManagementPage
