import { useEffect, useState } from "react";
import Switch from '@mui/material/Switch';
import {getPermissions,getRoles,createRole, updateRole, deleteRole} from "../../services/api";
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../../services/PageLoader";
import { useAuth } from "../../../auth";

const RoleList = () => {

  const initialData = {
    created_by : 'Super Admin',
    name : '',
    active : true,
    department : '',
    permissions : {},
    comments : [],
  } 
  const {currentUser} = useAuth()
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [data, setData] = useState<any>([]);
  const [permissions,setPermissions] = useState<any>([]);
  const [formData,setFormData] = useState<any>(initialData);
  const [updateData,setUpdateData] = useState<any>({})
  const [show,setShow] = useState<any>(false);
  const [modelBody,setModelBody] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const getData = async () => {
    try{
      const res = await getRoles(currentUser?.role_id);
      setData(res.data)
      setShowLoader(false)
    }catch(error){
      console.log(error)
      setShowLoader(false)

    }
  }
  const getPermissionData= async () => {
    try{
      const res = await getPermissions();
      setPermissions(res.data)
    }catch(error){
      console.log(error)
    }

  }

//Add Role
  const onchange = (e) => {
    const {name,value} = e.target
    setFormData({
      ...formData,
      [name] : value,
    })
  }
  const onActive = (e) => {
    const {name,checked} = e.target
    setFormData({
      ...formData,
      [name] : checked,
    })
  }
  const onActivePermission = (e) => {
    const {name,checked} = e.target
    if(checked){
      setFormData({
        ...formData,
        permissions : {
          ...formData.permissions,
          [name] : {
            read : checked,
            write : false,
            delete : false,
          }
        }
      })
    }else{
      setFormData({
        ...formData,
        permissions : {
          ...formData.permissions,
          [name] : {
            read : false,
            write : false,
            delete : false,
          }
        }
      })
    }
  }
  const onAddPermission = (e,permissionName) => {
    const {name,checked} = e.target
    setFormData({
      ...formData,
      permissions : {
        ...formData.permissions,
        [permissionName] : {
          ...formData?.permissions[permissionName],
          [name] : checked,
        }
      }
    })
  }
  const submitForm = async (e)=> {
    e.preventDefault()
    setShowLoader(true)
    try{
      const resp = await createRole(formData)
      setFormData(initialData)
      const model = document.getElementById("role_add_model") as HTMLFormElement
      model.click()
      setShowLoader(false)
      // setModelBody(resp?.data)
      // setShow(true)
      alert(resp.data)
      getData();
    }catch(error){
      console.log(error)
      setShowLoader(false)
    }
  }

  //Update Role
  const onchangeUpdate = (e) => {
    const {name,value} = e.target
    setUpdateData({
      ...updateData,
      [name] : value,
    })
  }
  const onActiveUpdate = (e) => {
    const {name,checked} = e.target
    setUpdateData({
      ...updateData,
      [name] : checked,
    })
  }
  const onActivePermissionUpdate = (e,permissionName) => {
    const {name,checked} = e.target
    if(checked){
      setUpdateData({
        ...updateData,
        permissions : {
          ...updateData.permissions,
          [name] : {
            read : checked,
            write : false,
            delete : false,
          }
        }
      })
    }else{
      setUpdateData({
        ...updateData,
        permissions : {
          ...updateData.permissions,
          [name] : {
            read : false,
            write : false,
            delete : false,
          }
        }
      })
    }
  }
  const onAddPermissionUpdate = (e,permissionName) => {
    const {name,checked} = e.target
    setUpdateData({
      ...updateData,
      permissions : {
        ...updateData.permissions,
        [permissionName] : {
          ...updateData?.permissions?.[permissionName],
          [name] : checked,
        }
      }
    })
  }
  const submitUpdateForm = async (e)=> {
    e.preventDefault()
    setShowLoader(true)
    try{
      const resp = await updateRole(updateData)
      setUpdateData({})
      const model = document.getElementById("role_update_model") as HTMLFormElement
      model.click()
      setShowLoader(false)
      // setModelBody(resp?.data)
      // setShow(true)
      alert(resp.data)
      getData()
    }catch(error){
      console.log(error)
      setShowLoader(false)
    }
  }

  //Opens Edit Model
  const openEditRole = (data) => {
    setUpdateData(data)
  }

  //Delete Role
  const onClickDelete = async (id) => {
    var answer = window.confirm("Are you sure, You want to delete ?")
    if(!answer) return null
    setShowLoader(true)
    try{
      const response : any = await deleteRole(id);
      setShowLoader(false)
      setModelBody(response?.data)
      setShow(true)
      getData();
    }catch(error){
      console.log(error);
      setShowLoader(true)
    }
  }

  useEffect(() => {
      setShowLoader(true)
      getData();
      getPermissionData()
  }, []);

    return (
        <div>
          <div id="kt_app_content" className="app-content flex-column-fluid">
              <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
                {data.map((item)=> {
                  return(
                    <div className="col-md-4">
                      <div className="card card-flush h-md-100">
                        <div className="card-header">
                          <div className="card-title">
                            <h2>{item?.name}</h2>
                          </div>
                        </div>
                        <div className="card-body pt-1">
                          {/* <div className="fw-bold text-gray-600 mb-5">Total users with this role: 5</div> */}
                          <div className="d-flex flex-column text-gray-600" style={{maxHeight: "270px", overflowY: "auto"}}>
                            {/* {Object.keys(item?.permissions).slice(0,5).map((permission)=>{
                              return(
                                <div className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span>{permission.replaceAll('_',' ')}
                                </div>
                              )
                            })} */}
                            {Object.keys(item?.permissions)?.map((permission)=>{
                              return(
                                <div className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span>{permission.replaceAll('_',' ')}
                                </div>
                              )
                            })}
                            {/*{Object.keys(item?.permissions).length > 5 &&  (
                              <div className='d-flex align-items-center py-2'>
                                <span className='bullet bg-primary me-3'></span>
                                <em>and {Object.keys(item?.permissions).length-5} more...</em>
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div className="card-footer flex-wrap pt-0">
                          {/* <a href="" className="btn btn-light btn-active-primary my-1 me-2">View Role</a> */}
                          {currentUser?.permissions?.Role_Management?.write && (<button type="button" className="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role" onClick={()=>openEditRole(item)}>Edit Role</button>)}
                          {currentUser?.permissions?.Role_Management?.delete && (<button type="button" className="btn btn-light btn-active-primary my-1 m-2" onClick={()=>onClickDelete(item?.role_id)}>Delete Role</button>)}
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div className="col-md-4">
                {currentUser?.permissions?.Role_Management?.write && (
                  <div className="card h-md-100">
                    <div className="card-body d-flex flex-center">
                      <button type="button" className="btn btn-clear d-flex flex-column flex-center" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role">
                        <img src="" alt="" className="mw-100 mh-150px mb-7" />
                        <div className="fw-bold fs-3 text-gray-600 text-hover-primary">Add New Role</div>
                      </button>
                    </div>
                  </div>
                )}
          </div>
              </div>
              <div className="modal fade" id="kt_modal_add_role" aria-hidden="true">
              {/* tabindex="-1" */}
                <div className="modal-dialog modal-dialog-centered mw-750px">
                  {/*<!--begin::Modal content-->*/}
                  <div className="modal-content">
                    {/*<!--begin::Modal header-->*/}
                    <div className="modal-header">
                      {/*<!--begin::Modal title-->*/}
                      <h2 className="fw-bold">Add a Role</h2>
                      {/*<!--end::Modal title-->*/}
                      {/*<!--begin::Close-->*/}
                      <div id='role_add_model' className="btn btn-icon btn-sm btn-active-icon-primary "  data-kt-roles-modal-action="close" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role">
                        <i className="ki-duotone ki-cross fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          {/**/}
                        </i>
                      </div>
                      {/*<!--end::Close-->*/}
                    </div>
                    {/*<!--end::Modal header-->*/}
                    {/*<!--begin::Modal body-->*/}
                    <div className="modal-body scroll-y mx-lg-5 my-7" style={{height : '500px',overflowY : 'scroll'}}>
                      {/*<!--begin::Form-->*/}
                      <form id="kt_modal_add_role_form" className="form" onSubmit={submitForm}>
                        {/*<!--begin::Scroll-->*/}
                        <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_role_header" data-kt-scroll-wrappers="#kt_modal_add_role_scroll" data-kt-scroll-offset="300px">
                          {/*<!--begin::Input group-->*/}
                          <div className="fv-row mb-10">
                            {/*<!--begin::Label-->*/}
                            <label className="fs-5 fw-bold form-label mb-2">
                              <span className="required">Role name</span>
                            </label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Input-->*/}
                            <input required value={formData?.name} className="form-control form-control-solid" placeholder="Enter a role name" name="name" onChange={onchange}/>
                            {/*<!--end::Input-->*/}
                          </div>
                          <div className="fv-row mb-10">
                            {/*<!--begin::Label-->*/}
                            <label className="fs-5 fw-bold form-label mb-2">
                              <span className="required">Department</span>
                            </label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Input-->*/}
                            <input required value={formData?.department} className="form-control form-control-solid" placeholder="Enter department" name="department" onChange={onchange}/>
                            {/*<!--end::Input-->*/}
                          </div>
                          <div className="fv-row mb-10">
                            {/*<!--begin::Label-->*/}
                            <label className="fs-5 fw-bold form-label mb-2">
                              <span className="required">Role Active</span>
                            </label>
                            <Switch {...label} name = 'active' checked={formData?.active} onChange={onActive}/>
                            {/*<!--end::Input-->*/}
                          </div>
                          {/*<!--end::Input group-->*/}
                          {/*<!--begin::Permissions-->*/}
                          <div className="fv-row">
                            {/*<!--begin::Label-->*/}
                            <label className="fs-5 fw-bold form-label mb-2">Role Permissions</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Table wrapper-->*/}
                            <div className="table-responsive">
                              {/*<!--begin::Table-->*/}
                              <table className="table align-middle table-row-dashed fs-6 gy-5">
                                {/*<!--begin::Table body-->*/}
                                <tbody className="text-gray-600 fw-semibold">
                                  {/* <tr>
                                    <td className="text-gray-800">Administrator Access 
                                    <span className="ms-2" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Allows a full access to the system">
                                      <i className="ki-duotone ki-information fs-7">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                      </i>
                                    </span></td>
                                    <td>
                                      <label className="form-check form-check-custom form-check-solid me-9">
                                        <input className="form-check-input" type="checkbox" value="" id="kt_roles_select_all" />
                                        <span className="form-check-label" >Select all</span>
                                      </label>
                                    </td>
                                  </tr> */}
                                  {/*<!--end::Table row-->*/}
                                  {/*<!--begin::Table row-->*/}
                                  {permissions?.map((item)=>{
                                    return(
                                      <tr>
                                        {/*<!--begin::Label-->*/}
                                        <td className="text-gray-800 w-50">{item?.name}</td>
                                        {/*<!--end::Label-->*/}
                                        {/*<!--begin::Options-->*/}
                                        <td className="d-flex justify-content-start w-50">
                                          {/*<!--begin::Wrapper-->*/}
                                          <div className="d-flex">
                                            <Switch {...label} name = {item?.value} checked={formData?.permissions?.[item?.value]?.read} onChange={onActivePermission}/>

                                            {formData?.permissions?.[item?.value]?.read && (
                                                <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                  <input className="form-check-input" type="checkbox" checked={formData?.permissions?.[item?.value]?.write} name="write"  onChange={(e) => onAddPermission(e,item.value)}/>
                                                  <span className="form-check-label">Write</span>
                                                </label>
                                            )}
                                            {formData?.permissions?.[item?.value]?.read && (
                                                <label className="form-check form-check-sm form-check-custom form-check-solid">
                                                  <input className="form-check-input" type="checkbox" checked={formData?.permissions?.[item?.value]?.delete} name="delete"  onChange={(e) => onAddPermission(e,item.value)}/>
                                                  <span className="form-check-label">Delete</span>
                                                </label>
                                            )}
                                          </div>
                                          {/*<!--end::Wrapper-->*/}
                                        </td>
                                        {/*<!--end::Options-->*/}
                                      </tr>
                                    )
                                  })}
                                  {/*<!--end::Table row-->*/}
                                </tbody>
                                {/*<!--end::Table body-->*/}
                              </table>
                              {/*<!--end::Table-->*/}
                            </div>
                            {/*<!--end::Table wrapper-->*/}
                          </div>
                          {/*<!--end::Permissions-->*/}
                        </div>
                        {/*<!--end::Scroll-->*/}
                        {/*<!--begin::Actions-->*/}
                        <div className="text-center pt-15">
                          {/* <button type="button" className="btn btn-light me-3">Discard</button> */}
                          <button type="submit" className="btn btn-primary" data-kt-roles-modal-action="submit">
                            <span className="indicator-label">Submit</span>
                          </button>
                        </div>
                        {/*<!--end::Actions-->*/}
                      </form>
                      {/*<!--end::Form-->*/}
                    </div>
                    {/*<!--end::Modal body-->*/}
                  </div>
                  {/*<!--end::Modal content-->*/}
                </div>
              </div>
              <div className="modal fade" id="kt_modal_update_role" aria-hidden="true">
              {/* tabindex="-1" */}
                {/*<!--begin::Modal dialog-->*/}
                <div className="modal-dialog modal-dialog-centered mw-750px">
                  {/*<!--begin::Modal content-->*/}
                  <div className="modal-content">
                    {/*<!--begin::Modal header-->*/}
                    <div className="modal-header">
                      {/*<!--begin::Modal title-->*/}
                      <h2 className="fw-bold">Update Role</h2>
                      {/*<!--end::Modal title-->*/}
                      {/*<!--begin::Close-->*/}
                      <div id='role_update_model' className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-roles-modal-action="close" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">
                        <i className="ki-duotone ki-cross fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </div>
                      {/*<!--end::Close-->*/}
                    </div>
                    {/*<!--end::Modal header-->*/}
                    {/*<!--begin::Modal body-->*/}
                    <div className="modal-body scroll-y mx-5 my-7" style={{height : '500px',overflowY : 'scroll'}}>
                      {/*<!--begin::Form-->*/}
                      <form id="kt_modal_update_role_form" className="form" onSubmit={submitUpdateForm}>
                        {/*<!--begin::Scroll-->*/}
                        <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_update_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_role_header" data-kt-scroll-wrappers="#kt_modal_update_role_scroll" data-kt-scroll-offset="300px">
                          {/*<!--begin::Input group-->*/}
                          <div className="fv-row mb-10">
                            {/*<!--begin::Label-->*/}
                            <label className="fs-5 fw-bold form-label mb-2">
                              <span className="required">Role name</span>
                            </label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Input-->*/}
                            <input disabled required className="form-control form-control-solid" placeholder="Enter a role name" name="name" onChange={onchangeUpdate} value={updateData?.name} />
                            {/*<!--end::Input-->*/}
                          </div>
                          {/*<!--end::Input group-->*/}
                          {/*<!--begin::Input group-->*/}
                          <div className="fv-row mb-10">
                            {/*<!--begin::Label-->*/}
                            <label className="fs-5 fw-bold form-label mb-2">
                              <span className="required">Department</span>
                            </label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Input-->*/}
                            <input required className="form-control form-control-solid" placeholder="Enter department name" name="department" onChange={onchangeUpdate} value={updateData?.department} />
                            {/*<!--end::Input-->*/}
                          </div>
                          <div className="fv-row mb-10">
                            {/*<!--begin::Label-->*/}
                            <label className="fs-5 fw-bold form-label mb-2">
                              <span className="required">Status</span>
                            </label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Input-->*/}
                            <Switch {...label} name = 'active' checked={updateData?.active} onChange={onActiveUpdate}/>
                            {/*<!--end::Input-->*/}
                          </div>
                          {/*<!--end::Input group-->*/}
                          {/*<!--begin::Permissions-->*/}
                          <div className="fv-row">
                            {/*<!--begin::Label-->*/}
                            <label className="fs-5 fw-bold form-label mb-2">Role Permissions</label>
                            {/*<!--end::Label-->*/}
                            {/*<!--begin::Table wrapper-->*/}
                            <div className="table-responsive">
                              {/*<!--begin::Table-->*/}
                              <table className="table align-middle table-row-dashed fs-6 gy-5">
                                {/*<!--begin::Table body-->*/}
                                <tbody className="text-gray-600 fw-semibold">
                                  {/*<!--begin::Table row-->*/}
                                  {/* <tr>
                                    <td className="text-gray-800">Administrator Access 
                                    <span className="ms-1" data-bs-toggle="tooltip" title="Allows a full access to the system">
                                      <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                      </i>
                                    </span></td>
                                    <td>
                                      <label className="form-check form-check-sm form-check-custom form-check-solid me-9">
                                        <input className="form-check-input" type="checkbox" value="" id="kt_roles_select_all" />
                                        <span className="form-check-label" >Select all</span>
                                      </label>
                                    </td>
                                  </tr> */}
                                  {/*<!--end::Table row-->*/}
                                  {permissions?.map((item)=>{
                                    return(
                                      <tr>
                                        {/*<!--begin::Label-->*/}
                                        <td className="text-gray-800">{item?.name}</td>
                                        {/*<!--end::Label-->*/}
                                        {/*<!--begin::Options-->*/}
                                        <td>
                                          {/*<!--begin::Wrapper-->*/}
                                          <div className="d-flex">
                                            <Switch {...label} name = {item?.value} checked={updateData?.permissions?.[item?.value]?.read} onChange={onActivePermissionUpdate}/>

                                            {updateData?.permissions?.[item?.value]?.read && (
                                                <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                  <input className="form-check-input" type="checkbox" checked={updateData?.permissions?.[item?.value]?.write} name="write"  onChange={(e) => onAddPermissionUpdate(e,item.value)}/>
                                                  <span className="form-check-label">Write</span>
                                                </label>
                                            )}
                                            {updateData?.permissions?.[item?.value]?.read && (
                                                <label className="form-check form-check-sm form-check-custom form-check-solid">
                                                  <input className="form-check-input" type="checkbox" checked={updateData?.permissions?.[item?.value]?.delete} name="delete"  onChange={(e) => onAddPermissionUpdate(e,item.value)}/>
                                                  <span className="form-check-label">Delete</span>
                                                </label>
                                            )}
                                          </div>
                                          {/*<!--end::Wrapper-->*/}
                                        </td>
                                        {/*<!--end::Options-->*/}
                                      </tr>
                                    )
                                  })}
                                </tbody>
                                {/*<!--end::Table body-->*/}
                              </table>
                              {/*<!--end::Table-->*/}
                            </div>
                            {/*<!--end::Table wrapper-->*/}
                          </div>
                          {/*<!--end::Permissions-->*/}
                        </div>
                        {/*<!--end::Scroll-->*/}
                        {/*<!--begin::Actions-->*/}
                        <div className="text-center pt-15">
                          {/* <button type="button" className="btn btn-light me-3">Discard</button> */}
                          <button type="submit" className="btn btn-primary" data-kt-roles-modal-action="submit">
                            <span className="indicator-label">Submit</span>
                          </button>
                        </div>
                        {/*<!--end::Actions-->*/}
                      </form>
                      {/*<!--end::Form-->*/}
                    </div>
                    {/*<!--end::Modal body-->*/}
                  </div>
                  {/*<!--end::Modal content-->*/}
                </div>
                {/*<!--end::Modal dialog-->*/}
              </div>
          </div>
          <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Permissions</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modelBody}</Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={() => setShow(false)}>
                Ok
            </Button>
            </Modal.Footer>
        </Modal>
        {showLoader && <PageLoader />}
        </div>
    );
};

export default RoleList;
