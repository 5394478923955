import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import LocationManagementPage from '../modules/apps/location-management/LocationManagementPage'
import UserManagementPage from '../modules/apps/users/UserManagementPage'
import BookingManagementPage from '../modules/apps/bookings/BookingManagementPage'
import SubscriptionManagementPage from '../modules/apps/subscriptions/SubscriptionManagementPage'
import LookupManagementPage from '../modules/apps/lookup-management/LookupManagementPage'
import CareerManagementPage from '../modules/apps/career-management/careerManagementPage'
import ReviewManagementPage from '../modules/apps/review-management/reivewManagementPage'
import CouponManagementPage from '../modules/apps/coupon-management/couponManagementPage'
import JobRequestManagementPage from '../modules/apps/job-management/jobRequestManagementPage'
import RoleManagementPage from '../modules/apps/role-management/roleManagementPage'
import LogManagementPage from '../modules/apps/auditLogs/LogsManagementPage'
import TestimonialPage from '../modules/apps/testimonial-management/Testimionials'
import EmailTemplatePage from '../modules/apps/email-management/EmailTemplate'
import NotificationTemplatePage from '../modules/apps/notification-management/NotificationTemplate'
import PaymentsManagementPage from '../modules/apps/payment-management/PaymentManagementPage'
import CmsManagementPage from '../modules/apps/cms-management/cmsManagementPage'
import EnquiriesManagementPage from '../modules/apps/enquiry-management/EnquiryManagementPage'
import LeadManagement from '../modules/apps/lead-management/LeadManagement'
import PrioritySupportManagement from '../modules/apps/PrioritySupportManagement/PrioritySupportManagement'
import UserDeactivateRequestManagement from '../modules/apps/PrioritySupportManagement/UserDeactivateRequestManagement'
import Notifications from '../modules/apps/Notifications/Notifications'
import Listing from '../modules/apps/listing/Listing'
import TagManagementPage from '../modules/apps/tags-management/TagManagementPage'
import { Version } from '../pages/VersionPage'
import RazorPayManagement from '../modules/apps/razorpay-payaments/RazorpayManagements'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const StaffPage = lazy(() => import('../modules/apps//staff-management/StaffsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='user/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/location-management/*'
          element={
            <SuspensedView>
              <LocationManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/staff-management/*'
          element={
            <SuspensedView>
              <StaffPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/users/*'
          element={
            <SuspensedView>
              <UserManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/prioritysupport/*'
          element={
            <SuspensedView>
              <PrioritySupportManagement />
            </SuspensedView>
          }
        />
        <Route
          path='apps/userDeactivateRequest/*'
          element={
            <SuspensedView>
              <UserDeactivateRequestManagement />
            </SuspensedView>
          }
        />
        <Route
          path='apps/notifications/*'
          element={
            <SuspensedView>
              <Notifications />
            </SuspensedView>
          }
        />
        <Route
          path='apps/lead/*'
          element={
            <SuspensedView>
              <LeadManagement />
            </SuspensedView>
          }
        />
        <Route
          path='apps/bookings/*'
          element={
            <SuspensedView>
              <BookingManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/subscriptions/*'
          element={
            <SuspensedView>
              <SubscriptionManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/lookup/*'
          element={
            <SuspensedView>
              <LookupManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/career/*'
          element={
            <SuspensedView>
              <CareerManagementPage />
            </SuspensedView>
          }
        />
         <Route
          path='apps/review/*'
          element={
            <SuspensedView>
              <ReviewManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/coupon/*'
          element={
            <SuspensedView>
              <CouponManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/jobrequests/*'
          element={
            <SuspensedView>
              <JobRequestManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/role-management/*'
          element={
            <SuspensedView>
              <RoleManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/logs/*'
          element={
            <SuspensedView>
              <LogManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/testimonials/*'
          element={
            <SuspensedView>
              <TestimonialPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/email/*'
          element={
            <SuspensedView>
              <EmailTemplatePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/notification/*'
          element={
            <SuspensedView>
              <NotificationTemplatePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/payments/*'
          element={
            <SuspensedView>
              <PaymentsManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/cms/*'
          element={
            <SuspensedView>
              <CmsManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/enquiries/*'
          element={
            <SuspensedView>
              <EnquiriesManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/listing-management/:id'
          element={
            <SuspensedView>
              <Listing />
            </SuspensedView>
          }
        />
        <Route
          path='apps/activities-management/*'
          element={
            <SuspensedView>
              <TagManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='version/*'
          element={
            <SuspensedView>
              <Version />
            </SuspensedView>
          }
        />
        <Route
          path='apps/razorpay/*'
          element={
            <SuspensedView>
              <RazorPayManagement />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
