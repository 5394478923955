import { useState,useEffect} from "react";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import info from "../../../../../../_metronic/assets/images/i.svg";
import { Button, Modal} from "react-bootstrap";
import "../ListYourSpace"

const timeOptions = [
    { value: "00:00", label: "00:00" },
    { value: "01:00", label: "01:00" },
    { value: "02:00", label: "02:00" },
    { value: "03:00", label: "03:00" },
    { value: "04:00", label: "04:00" },
    { value: "05:00", label: "05:00" },
    { value: "06:00", label: "06:00" },
    { value: "07:00", label: "07:00" },
    { value: "08:00", label: "08:00" },
    { value: "09:00", label: "09:00" },
    { value: "10:00", label: "10:00" },
    { value: "11:00", label: "11:00" },
    { value: "12:00", label: "12:00" },
    { value: "13:00", label: "13:00" },
    { value: "14:00", label: "14:00" },
    { value: "15:00", label: "15:00" },
    { value: "16:00", label: "16:00" },
    { value: "17:00", label: "17:00" },
    { value: "18:00", label: "18:00" },
    { value: "19:00", label: "19:00" },
    { value: "20:00", label: "20:00" },
    { value: "21:00", label: "21:00" },
    { value: "22:00", label: "22:00" },
    { value: "23:00", label: "23:00" },
    { value: "23:59", label: "23:59" },
  ];
  const stayTimeOptions = [
    { value: "00:00 am", label: "00:00 am" },
    { value: "01:00 am", label: "01:00 am" },
    { value: "02:00 am", label: "02:00 am" },
    { value: "03:00 am", label: "03:00 am" },
    { value: "04:00 am", label: "04:00 am" },
    { value: "05:00 am", label: "05:00 am" },
    { value: "06:00 am", label: "06:00 am" },
    { value: "07:00 am", label: "07:00 am" },
    { value: "08:00 am", label: "08:00 am" },
    { value: "09:00 am", label: "09:00 am" },
    { value: "10:00 am", label: "10:00 am" },
    { value: "11:00 am", label: "11:00 am" },
    { value: "12:00 pm", label: "12:00 pm" },
    { value: "01:00 pm", label: "01:00 pm" },
    { value: "02:00 pm", label: "02:00 pm" },
    { value: "03:00 pm", label: "03:00 pm" },
    { value: "04:00 pm", label: "04:00 pm" },
    { value: "05:00 pm", label: "05:00 pm" },
    { value: "06:00 pm", label: "06:00 pm" },
    { value: "07:00 pm", label: "07:00 pm" },
    { value: "08:00 pm", label: "08:00 pm" },
    { value: "09:00 pm", label: "09:00 pm" },
    { value: "10:00 pm", label: "10:00 pm" },
    { value: "11:00 pm", label: "11:00 pm" },
    { value: "11:59 pm", label: "11:59 pm" },
  ];

const SubPricingAndTimings = ({data,getSaved, getBack}) => {

    const [formData,setFormData] = useState({})
    const [currency,setCurrency] = useState(null)
    const [currencyError,setCurrencyError] = useState(false)
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState('Invalid Timings');
    const [confirmationBody, setConfirmationBody] = useState("");
    const [init,setInit] = useState(false);
    const {Header, Body, Title, Footer} = Modal

    const [filmError,setFilmError] = useState({
        hourlyPrice : false,
        attendees : false,
        minBooking : false,
        timings : false,
        message : "",
    })
    const [tvError,setTvError] = useState({
        hourlyPrice : false,
        attendees : false,
        minBooking : false,
        timings : false,
        message : "",
    })
    const [eventError,setEventError] = useState({
        hourlyPrice : false,
        weekendPrice : false,
        attendees : false,
        minBooking : false,
        timings : false,
        message : "",
    })
    const [stayError,setStayError] = useState({
        hourlyPrice : false,
        weekendPrice : false,
        baseAdults : false,
        maxAdults : false,
        timings : false,
        message : "",
        message1 : "",
    })

    //accordion start
    function Toggle({ eventKey,type, callback }) {
        const decoratedOnClick = useAccordionButton(
          eventKey,
          () => callback && callback(eventKey),
        );
        const handleYes = (e) => {
            if(e.target.checked){
                setFormData({
                    ...formData,
                    [type] : {
                        ...formData[type],
                        available : true,
                    }
                })
            }
            if(!formData[type]?.available || formData[type]?.available === false){
                decoratedOnClick()
            }
        }
        const handleNo = (e) => {
            if(formData[type]?.available === true){
                decoratedOnClick()
            }
            if(e.target.checked){
                setFormData({
                    ...formData,
                    [type] : {
                        ...formData[type],
                        available : false,
                    }
                })
            }
        }
        return (
          <div className="d-flex">
            <div className="d-flex">
                <input
                    type="checkbox"
                    className="form-check-input"
                    name={type}
                    id={type}
                    checked={formData[type]?.available}
                    onChange={handleYes}
                />
                <label htmlFor={type} className="me-2">Yes</label>
            </div>
            <div className="d-flex">
                <input
                    type="checkbox"
                    className="form-check-input"
                    name={type+'no'}
                    id={type+'no'}
                    checked={!formData[type]?.available}
                    onChange={handleNo}
                />
                <label htmlFor={type+'no'}>No</label>
            </div>
        </div>
        );
      }
     //accordion end

     const onChangeInput = (event,type) => {
        const {name,value} = event.target
        if (name === "minimumBooking") {
            setFormData({
                ...formData,
                [type] : {
                    ...formData[type],
                    [name] : value,
                    timings : false,
                }
            })
        } else {
            setFormData({
                ...formData,
                [type] : {
                    ...formData[type],
                    [name] : value,
                }
            })
        }
     }

     const handleSubmit = () => {
        let error = false;
        if(!currency){
            setCurrencyError(true)
            document.getElementById("PricingCurrencyId").scrollIntoView({behavior: "smooth"});
            return null 
        }else{
            setCurrencyError(false)
        }
        setFilmError((prev)=>{
            return {
                hourlyPrice : false,
                attendees : false,
                minBooking : false,
                timings : false,
                message : "",
            }
        })
        setTvError((prev)=>{
            return {
                hourlyPrice : false,
                attendees : false,
                minBooking : false,
                timings : false,
                message : "",
            }
        })
        setEventError((prev)=>{
            return {
                hourlyPrice : false,
                weekendPrice : false,
                attendees : false,
                minBooking : false,
                timings : false,
                message : "",
            }
        })
        setStayError((prev)=>{
            return {
                hourlyPrice : false,
                weekendPrice : false,
                baseAdults : false,
                maxAdults : false,
                timings : false,
                message : "",
                message1 : "",
            }
        })
       
        //film error check start
        if(formData?.film?.available){
            if(!formData?.film?.hourlyPrice){
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        hourlyPrice : true,
                    }
                })
            }
            if(!formData?.film?.attendees){
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        attendees : true,
                        message : "Please Enter No. of attendees"
                    }
                })
            }
            // if((parseInt(formData?.film?.min) >= parseInt(formData?.film?.max)) ){
            //     error = true
            //     setFilmError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             attendees : true,
            //             message : "Min.attendees should be less than Max.attendees"
            //         }
            //     })
            // }
            if(!formData?.film?.minimumBooking){
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        minBooking : true,
                    }
                })
            }
            if(!formData?.film?.timings){
                error = true
                setFilmError((prevValue) => {
                    return {
                        ...prevValue,
                        timings : true,
                    }
                })
            }
        }
        //film error check end
        //tv error check start
        if(formData?.tv?.available){
            if(!formData?.tv?.hourlyPrice){
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        hourlyPrice : true,
                    }
                })
            }
            if(!formData?.tv?.attendees){
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        attendees : true,
                        message : "Please Enter no. of attendees"
                    }
                })
            }
            // if((parseInt(formData?.tv?.min) >= parseInt(formData?.tv?.max)) ){
            //     error = true
            //     setTvError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             attendees : true,
            //             message : "Min.attendees should be less than Max.attendees"
            //         }
            //     })
            // }
            if(!formData?.tv?.minimumBooking){
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        minBooking : true,
                    }
                })
            }
            if(!formData?.tv?.timings){
                error = true
                setTvError((prevValue) => {
                    return {
                        ...prevValue,
                        timings : true,
                    }
                })
            }
        }
        //tv error check end
        //evnt error check start
        if(formData?.event?.available){
            if(!formData?.event?.hourlyPrice){
                error = true
                setEventError((prevValue) => {
                    return {
                        ...prevValue,
                        hourlyPrice : true,
                    }
                })
            }
            if(!formData?.event?.weekendPrice){
                error = true
                setEventError((prevValue) => {
                    return {
                        ...prevValue,
                        weekendPrice : true,
                    }
                })
            }
            if(!formData?.event?.attendees ){
                error = true
                setEventError((prevValue) => {
                    return {
                        ...prevValue,
                        attendees : true,
                        message : "Please Enter no. of attendees"
                    }
                })
            }
            // if((parseInt(formData?.event?.min) >= parseInt(formData?.event?.max)) ){
            //     error = true
            //     setEventError((prevValue) => {
            //         return {
            //             ...prevValue,
            //             attendees : true,
            //             message : "Min.attendees should be less than Max.attendees"
            //         }
            //     })
            // }
            if(!formData?.event?.minimumBooking){
                error = true
                setEventError((prevValue) => {
                    return {
                        ...prevValue,
                        minBooking : true,
                    }
                })
            }
            if(!formData?.event?.timings){
                error = true
                setEventError((prevValue) => {
                    return {
                        ...prevValue,
                        timings : true,
                    }
                })
            }
        }
        //event error check end
         //stay error check start
         if(formData?.stay?.available){
            if(!formData?.stay?.hourlyPrice){
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        hourlyPrice : true,
                    }
                })
            }
            if(!formData?.stay?.weekendPrice){
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        weekendPrice : true,
                    }
                })
            }
            if((!formData?.stay?.baseAdults)){ 
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        baseAdults : true,
                        message : "Enter Base Adults", 
                    }
                })
            }
            if((formData?.stay?.baseAdults) > formData?.stay?.maxAdults){ 
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        baseAdults : true,
                        message : "Base adults should be less than max adults"
                    }
                })
            }
            if((!formData?.stay?.maxAdults))  {
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        maxAdults : true,
                        message1 : "Enter Maximum adults"
                    }
                })
            }
            if((formData?.stay?.baseAdults) > formData?.stay?.maxAdults)  {
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        maxAdults : true,
                        message1 : "Max adults should be more than base adults"
                    }
                })
            }
            if(!formData?.stay?.timings){
                error = true
                setStayError((prevValue) => {
                    return {
                        ...prevValue,
                        timings : true,
                    }
                })
            }
        }
        //stay error check end
     }

     useEffect(() => {
        const callBack = () => {
            if ( !formData?.film?.available && !formData?.tv?.available && !formData?.event?.available && !formData?.stay?.available ) {
                setConfirmationBody('Please select any one of the type')
                setShowConfirmationModel(true)
                return null
            }
            let error = false
            for(const err in filmError) {
                if (filmError[err]) {
                    document.getElementById("PricingFilmId").scrollIntoView({behavior: "smooth"});
                    error = true
                    return null
                } 
            } 
            for(const err in tvError) {
                if (tvError[err]) {
                    document.getElementById("PricingTvId").scrollIntoView({behavior: "smooth"});
                    error = true
                    return null
                } 
            } 
            for(const err in eventError) {
                if (eventError[err]) {
                    document.getElementById("PricingEventId").scrollIntoView({behavior: "smooth"});
                    error = true
                    return null
                } 
            } 
            for(const err in stayError) {
                if (stayError[err]) {
                    document.getElementById("PricingStayId").scrollIntoView({behavior: "smooth"});
                    error = true
                    return null
                } 
            }
            if(!error && init){
                formData.currency = currency
                getSaved({pricing : formData})
            }
        }
        if(!init) setInit(true)
        if(init) callBack()
     },[filmError,tvError,eventError,stayError])

     useEffect(() => {
        if(data){
            setFormData(data)
            setCurrency(data?.currency)
        }
    },[data])

    const validateTimings = (open, close, type, day) => {
        const openValue = parseFloat(open.substring(0,2))
        const closeValue = parseFloat(close.substring(0,2))
        const minBookingHours = formData[type]?.minimumBooking
       if (minBookingHours) {
            if ((openValue < closeValue) && ((closeValue - openValue) >= minBookingHours)) {
                setFormData({
                    ...formData,
                    [type] : {
                        ...formData[type],
                        timings : {
                            ...formData[type]?.timings,
                            [day] : {
                                ...formData[type]?.timings?.[day],
                                timings :{
                                    open : open,
                                    close : close,
                                }
                            },
                        }
                        }
                }) 
            } else if (openValue >= closeValue) {
                setConfirmationBody(`End time cannot be before start time. Please adjust your selection`)
                setShowConfirmationModel(true)
            }
            else if((closeValue - openValue) < minBookingHours) {
                setConfirmationBody(`Selected timings should be greater than minimum booking hours. Please adjust your selection`)
                setShowConfirmationModel(true)
            }
            else {
                setConfirmationBody(`Timings error`)
                setShowConfirmationModel(true)
            }
        } else{
            setConfirmationBody("Please select minimum booking hrs first")
            setShowConfirmationModel(true)
        }
    }

    const validateStayTimings = (open, close, type, day) =>{
        // const openValue = open.slice(-2) === "am" ? parseFloat(open.substring(0,2)) : parseFloat(open.substring(0,2)) + 12 ;
        // const closeValue = close.slice(-2) === "am" ?   parseFloat(close.substring(0,2)) :  parseFloat(close.substring(0,2)) + 12 ;
        // console.log("openValue", openValue)
        // console.log("closeValue", closeValue)
        // if (openValue < closeValue) {
            setFormData({
                ...formData,
                [type] : {
                    ...formData[type],
                    timings : {
                        ...formData[type]?.timings,
                        [day] : {
                            ...formData[type]?.timings?.[day],
                            timings :{
                                open : open,
                                close : close,
                            }
                        },
                    }
                    }
            })
        // } else{
        //     alert(`Invalid timings`)
        // }
    }
    const onClickDelete = (index) => {
        if (formData?.event?.buffets?.length === 1) {
            setFormData({
                ...formData,
                event : {
                    ...formData.event,
                    catering: false,
                    buffets : [],
                }
            })
        } 
        else {
            const newData = formData?.event?.buffets
            newData.splice(index, 1);
            setFormData({
                ...formData,
                    event : {
                        ...formData.event,
                        buffets : [...newData]
                    }
            })
        }
    }

    const handleBack = () => {
        getBack();
    }

    return(
        <>
        <div className="locationtype-page container-fluid " >
            <h1 className="locationtype-head" > Pricing & Timings {' '}
                <a
                    href="#"
                    data-bs-toggle="tooltip"
                    title="Competitive rates, specify availability, and check-in/check-out times."
                >
                    <img src={info} />
                </a>
            </h1>
            <div className="row tabCard">
                <div className="col-lg-9">
                    <div className="mb-3 d-flex flex-column" id = "PricingCurrencyId">
                        <label htmlFor="currency" className="form-label">
                            Select currency<span className="required">*</span>
                        </label>
                        <select
                            required
                            id="currency"
                            name="currency"
                            className="textFiledSelect"
                            value={currency}
                            onChange={(e) => {setCurrency(e.target.value)}}
                        >
                            <option hidden selected value="">Select currency</option>
                            <option value='INR'>INR</option>
                            <option value='USD'>USD</option>
                        </select>
                        {currencyError &&<span style={{color : 'red'}}>Select currency</span>}
                    </div>
                </div>
                <div className="col-lg-9">
                {/* accordion start */}

                    {/* film start */}
                    <Accordion defaultActiveKey={data?.film?.available ? "0" : ''} id = "PricingFilmId" >
                        <Card className="pricingCard">
                            <Card.Header className="d-flex justify-content-between">
                                <h6>Are you interested in providing your property for a Film Shoot?</h6>
                                <Toggle eventKey="0" type='film'/>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="film-hourly-price" className="form-label">
                                                    Hourly price<span className="required">*</span>
                                                </label>
                                                <input
                                                    // type="text"
                                                    required
                                                    // min={0}
                                                    placeholder="Enter hourly price"
                                                    id="film-hourly-price"
                                                    name="hourlyPrice"
                                                    className="textFiled"
                                                    value={formData?.film?.hourlyPrice || "" }
                                                    onChange={(e) => {
                                                    const regex= /^[1-9][0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)) {
                                                        onChangeInput(e,'film')
                                                    }
                                                    }}
                                                />
                                                {filmError.hourlyPrice &&<span style={{color : 'red'}}>Enter Hourly price</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label className="form-label">
                                                    No of attendees allowed<span className="required">*</span>
                                                </label>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-2 d-flex flex-column">
                                                            <input
                                                                // type="text"
                                                                required
                                                                // min={0}
                                                                placeholder="No. of attendees"
                                                                name = "attendees"
                                                                className="textFiled"
                                                                value={formData?.film?.attendees || ""}
                                                                onChange={(e) => {
                                                                    const regex= /^[1-9][0-9]*$/;
                                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                                        onChangeInput(e,'film')
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6">
                                                        <div className="mb-2 d-flex flex-column">
                                                            <input
                                                                // type="text"
                                                                required
                                                                // min={0}
                                                                placeholder="Max. attendees"
                                                                name="max"
                                                                className="textFiled"
                                                                value={formData?.film?.max || "" }
                                                                onChange={(e) => {
                                                                    const regex= /^[1-9][0-9]*$/;
                                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                                        onChangeInput(e,'film')
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {filmError.attendees &&<span style={{color : 'red'}}>{filmError?.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="film-minimumBooking" className="form-label">
                                               Minimum booking Hrs<span className="required">*</span>
                                                </label>
                                                <select
                                                    required
                                                    id="film-minimumBooking"
                                                    name="minimumBooking"
                                                    className="textFiledSelect"
                                                    value={formData?.film?.minimumBooking}
                                                    onChange={(e) => {onChangeInput(e,'film')}}
                                                >
                                                    <option hidden selected value="">Select hours</option>
                                                    <option value='1'>1 Hr</option>
                                                    <option value='2'>2 Hrs</option>
                                                    <option value='4'>4 Hrs</option>
                                                    <option value='6'>6 Hrs</option>
                                                    <option value='8'>8 Hrs</option>
                                                    <option value='12'>12 Hrs</option>
                                                    <option value='16'>16 Hrs</option>
                                                    <option value='20'>20 Hrs</option>
                                                    <option value='24'>24 Hrs</option>
                                                </select>
                                                {filmError.minBooking &&<span style={{color : 'red'}}>Select Min booking Hrs</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mb-2 d-flex flex-column">
                                                <label className="form-label">
                                                    Timings available for booking<span className="required">*</span> 
                                                </label>
                                                <div className="row">
                                                    {/*Monday start*/}
                                                    <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="film-timings-monday" className="form-label">Mon</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-monday-fullDay'
                                                                    checked={formData?.film?.timings?.monday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData?.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    monday : {
                                                                                        ...formData?.film?.timings?.monday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-monday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-monday-setHours'
                                                                    checked={formData?.film?.timings?.monday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    monday : {
                                                                                        ...formData?.film?.timings?.monday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-monday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.film?.timings?.monday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.monday?.timings?.open}

                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.film?.timings?.monday?.timings?.close, 'film','monday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.monday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.film?.timings?.monday?.timings?.open, e.target.value, 'film','monday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Monday end*/}
                                                      {/*Tuesday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="film-timings-tuesday" className="form-label">Tue</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-tuesday-fullDay'
                                                                    checked={formData?.film?.timings?.tuesday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData?.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    tuesday : {
                                                                                        ...formData?.film?.timings?.tuesday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-tuesday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-tuesday-setHours'
                                                                    checked={formData?.film?.timings?.tuesday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    tuesday : {
                                                                                        ...formData?.film?.timings?.tuesday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-tuesday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.film?.timings?.tuesday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.tuesday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.film?.timings?.tuesday?.timings?.close, 'film','tuesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.tuesday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.film?.timings?.tuesday?.timings?.open, e.target.value, 'film','tuesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Tuesday end*/}
                                                      {/*Wednesday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="film-timings-wednesday" className="form-label">Wed</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-wednesday-fullDay'
                                                                    checked={formData?.film?.timings?.wednesday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData?.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    wednesday : {
                                                                                        ...formData?.film?.timings?.wednesday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-wednesday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-wednesday-setHours'
                                                                    checked={formData?.film?.timings?.wednesday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    wednesday : {
                                                                                        ...formData?.film?.timings?.wednesday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-wednesday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.film?.timings?.wednesday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.film?.timings?.wednesday?.timings?.close, 'film','wednesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.wednesday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.film?.timings?.wednesday?.timings?.open, e.target.value, 'film','wednesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Wednesday end*/}
                                                      {/*Thursday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="film-timings-thursday" className="form-label">Thu</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-thursday-fullDay'
                                                                    checked={formData?.film?.timings?.thursday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData?.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    thursday : {
                                                                                        ...formData?.film?.timings?.thursday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-thursday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-thursday-setHours'
                                                                    checked={formData?.film?.timings?.thursday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    thursday : {
                                                                                        ...formData?.film?.timings?.thursday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-thursday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.film?.timings?.thursday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.thursday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.film?.timings?.thursday?.timings?.close, 'film','thursday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.thursday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.film?.timings?.thursday?.timings?.open, e.target.value, 'film','thursday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Thursday end*/}
                                                      {/*Friday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="film-timings-friday" className="form-label">Fri</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-friday-fullDay'
                                                                    checked={formData?.film?.timings?.friday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData?.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    friday : {
                                                                                        ...formData?.film?.timings?.friday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-friday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-friday-setHours'
                                                                    checked={formData?.film?.timings?.friday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    friday : {
                                                                                        ...formData?.film?.timings?.friday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-friday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.film?.timings?.friday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.friday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.film?.timings?.friday?.timings?.close, 'film','friday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.friday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.film?.timings?.friday?.timings?.open, e.target.value, 'film','friday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Friday end*/}
                                                      {/*Saturday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="film-timings-saturday" className="form-label">Sat</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-saturday-fullDay'
                                                                    checked={formData?.film?.timings?.saturday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData?.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    saturday : {
                                                                                        ...formData?.film?.timings?.saturday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-saturday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-saturday-setHours'
                                                                    checked={formData?.film?.timings?.saturday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    saturday : {
                                                                                        ...formData?.film?.timings?.saturday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-saturday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.film?.timings?.saturday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.saturday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.film?.timings?.saturday?.timings?.close, 'film','saturday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.saturday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.film?.timings?.saturday?.timings?.open, e.target.value, 'film','saturday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Saturday end*/}
                                                      {/*Sunday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="film-timings-sunday" className="form-label">Sun</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-sunday-fullDay'
                                                                    checked={formData?.film?.timings?.sunday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData?.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    sunday : {
                                                                                        ...formData?.film?.timings?.sunday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-sunday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='film-timings-sunday-setHours'
                                                                    checked={formData?.film?.timings?.sunday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            film : {
                                                                                ...formData.film,
                                                                                timings : {
                                                                                    ...formData?.film?.timings,
                                                                                    sunday : {
                                                                                        ...formData?.film?.timings?.sunday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="film-timings-sunday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.film?.timings?.sunday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.sunday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.film?.timings?.sunday?.timings?.close, 'film','sunday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.film?.timings?.sunday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.film?.timings?.sunday?.timings?.open, e.target.value, 'film','sunday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Sunday end*/}
                                                </div>
                                                {filmError.timings &&<span style={{color : 'red'}}>Select Timings</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-2 d-flex flex-column">
                                                <label htmlFor="film_cleaning" className="form-label">
                                                    Cleaning fee(Optional)
                                                </label>
                                                <input
                                                    // type="text"
                                                    // // min={0}
                                                    placeholder="Enter cleaning fee"
                                                    id="film_cleaning"
                                                    name="cleaningFee"
                                                    className="textFiled"
                                                    value={formData?.film?.cleaningFee || ""}
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)){
                                                            onChangeInput(e,'film')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-2 d-flex flex-column" >
                                                <label htmlFor="film_security_deposit" className="form-label">
                                                    Security deposit(Optional)
                                                </label>
                                                <input
                                                    // type="text"
                                                    // // min={0}
                                                    placeholder="Enter security deposit"
                                                    id="film_security_deposit"
                                                    name="securityDeposit"
                                                    className="textFiled"
                                                    value={formData?.film?.securityDeposit || "" }
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;  
                                                        if (e.target.value === "" || regex.test(e.target.value)){
                                                            onChangeInput(e,'film')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    {/* film end */}

                    {/* tv start */}
                    <Accordion defaultActiveKey={data?.tv?.available ? "1" : ''} id = "PricingTvId" >
                        <Card className="pricingCard">
                            <Card.Header className="d-flex justify-content-between">
                                <h6>Are you interested in providing your property for a TV series?</h6>
                                <Toggle eventKey="1" type='tv'/>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="tv-hourly-price" className="form-label">
                                                    Hourly price<span className="required">*</span>
                                                </label>
                                                <input
                                                    // type="text"
                                                    required
                                                    // min={0}
                                                    placeholder="Enter hourly price"
                                                    id="tv-hourly-price"
                                                    name="hourlyPrice"
                                                    className="textFiled"
                                                    value={formData?.tv?.hourlyPrice || ""}
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)){
                                                            onChangeInput(e,'tv')
                                                        }
                                                    }}
                                                />
                                                {tvError.hourlyPrice &&<span style={{color : 'red'}}>Enter hourly price </span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label className="form-label">
                                                    No of attendees allowed<span className="required">*</span>
                                                </label>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-2 d-flex flex-column">
                                                            <input
                                                                // type="text"
                                                                required
                                                                // min={0}
                                                                placeholder="No. of attendees"
                                                                name = "attendees"
                                                                className="textFiled"
                                                                value={formData?.tv?.attendees || ""}
                                                                onChange={(e) => {
                                                                    const regex= /^[1-9][0-9]*$/;
                                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                                        onChangeInput(e,'tv')
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6">
                                                        <div className="mb-2 d-flex flex-column">
                                                            <input
                                                                // type="text"
                                                                required
                                                                // min={0}
                                                                placeholder="Max. attendees"
                                                                name="max"
                                                                className="textFiled"
                                                                value={formData?.tv?.max || ""}
                                                                onChange={(e) => {
                                                                    const regex= /^[1-9][0-9]*$/;
                                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                                        onChangeInput(e,'tv')
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {tvError.attendees &&<span style={{color : 'red'}}>{tvError.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="tv-minimumBooking" className="form-label">
                                               Minimum booking Hrs<span className="required">*</span>
                                                </label>
                                                <select
                                                    required
                                                    id="tv-minimumBooking"
                                                    name="minimumBooking"
                                                    className="textFiledSelect"
                                                    value={formData?.tv?.minimumBooking}
                                                    onChange={(e) => {onChangeInput(e,'tv')}}
                                                >
                                                    <option hidden selected value="">Select hours</option>
                                                    <option value='1'>1 Hr</option>
                                                    <option value='2'>2 Hrs</option>
                                                    <option value='4'>4 Hrs</option>
                                                    <option value='6'>6 Hrs</option>
                                                    <option value='8'>8 Hrs</option>
                                                    <option value='12'>12 Hrs</option>
                                                    <option value='16'>16 Hrs</option>
                                                    <option value='20'>20 Hrs</option>
                                                    <option value='24'>24 Hrs</option>
                                                </select>
                                                {tvError.minBooking &&<span style={{color : 'red'}}>Select Min booking Hrs</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mb-2 d-flex flex-column">
                                                <div className ="d-flex" >
                                                    <label className="form-label">
                                                        Timings available for booking<span className="required">*</span> 
                                                    </label>
                                                    { formData?.film?.timings && 
                                                        <div className="mx-3" >
                                                            <label className="form-label" > {"(Fetch from film)"} </label>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input mx-2"
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        tv : {
                                                                            ...formData?.tv,
                                                                            minimumBooking : e.target.checked ? formData?.film?.minimumBooking : "",
                                                                            timings : e.target.checked ? formData?.film?.timings : {}
                                                                        }
                                                                    })
                                                                } }
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    {/*Monday start*/}
                                                    <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="tv-timings-monday" className="form-label">Mon</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-monday-fullDay'
                                                                    checked={formData?.tv?.timings?.monday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData?.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    monday : {
                                                                                        ...formData?.tv?.timings?.monday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-monday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-monday-setHours'
                                                                    checked={formData?.tv?.timings?.monday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    monday : {
                                                                                        ...formData?.tv?.timings?.monday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-monday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.tv?.timings?.monday?.fullDay === false && (
                                                            // formData?.film?.timings?.sunday?.timings?.open
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.monday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(e.target.value ,formData?.tv?.timings?.monday?.timings?.close, 'tv','monday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.monday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.tv?.timings?.monday?.timings?.open, e.target.value, 'tv','monday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Monday end*/}
                                                      {/*Tuesday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="tv-timings-tuesday" className="form-label">Tue</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-tuesday-fullDay'
                                                                    checked={formData?.tv?.timings?.tuesday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData?.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    tuesday : {
                                                                                        ...formData?.tv?.timings?.tuesday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-tuesday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-tuesday-setHours'
                                                                    checked={formData?.tv?.timings?.tuesday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    tuesday : {
                                                                                        ...formData?.tv?.timings?.tuesday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-tuesday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.tv?.timings?.tuesday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.tuesday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.tv?.timings?.tuesday?.timings?.close, 'tv','tuesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.tuesday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.tv?.timings?.tuesday?.timings?.open, e.target.value, 'tv','tuesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Tuesday end*/}
                                                      {/*Wednesday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="tv-timings-wednesday" className="form-label">Wed</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-wednesday-fullDay'
                                                                    checked={formData?.tv?.timings?.wednesday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData?.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    wednesday : {
                                                                                        ...formData?.tv?.timings?.wednesday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-wednesday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-wednesday-setHours'
                                                                    checked={formData?.tv?.timings?.wednesday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    wednesday : {
                                                                                        ...formData?.tv?.timings?.wednesday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-wednesday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.tv?.timings?.wednesday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.wednesday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.tv?.timings?.wednesday?.timings?.close, 'tv','wednesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.wednesday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.tv?.timings?.wednesday?.timings?.open, e.target.value, 'tv','wednesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Wednesday end*/}
                                                      {/*Thursday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="tv-timings-thursday" className="form-label">Thu</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-thursday-fullDay'
                                                                    checked={formData?.tv?.timings?.thursday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData?.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    thursday : {
                                                                                        ...formData?.tv?.timings?.thursday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-thursday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-thursday-setHours'
                                                                    checked={formData?.tv?.timings?.thursday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    thursday : {
                                                                                        ...formData?.tv?.timings?.thursday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-thursday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.tv?.timings?.thursday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.thursday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.tv?.timings?.thursday?.timings?.close, 'tv','thursday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.thursday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.tv?.timings?.thursday?.timings?.open, e.target.value, 'tv','thursday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Thursday end*/}
                                                      {/*Friday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="tv-timings-friday" className="form-label">Fri</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-friday-fullDay'
                                                                    checked={formData?.tv?.timings?.friday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData?.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    friday : {
                                                                                        ...formData?.tv?.timings?.friday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-friday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-friday-setHours'
                                                                    checked={formData?.tv?.timings?.friday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    friday : {
                                                                                        ...formData?.tv?.timings?.friday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-friday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.tv?.timings?.friday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.friday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.tv?.timings?.friday?.timings?.close, 'tv','friday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.friday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.tv?.timings?.friday?.timings?.open, e.target.value, 'tv','friday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Friday end*/}
                                                      {/*Saturday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="tv-timings-saturday" className="form-label">Sat</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-saturday-fullDay'
                                                                    checked={formData?.tv?.timings?.saturday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData?.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    saturday : {
                                                                                        ...formData?.tv?.timings?.saturday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-saturday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-saturday-setHours'
                                                                    checked={formData?.tv?.timings?.saturday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    saturday : {
                                                                                        ...formData?.tv?.timings?.saturday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-saturday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.tv?.timings?.saturday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.saturday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.tv?.timings?.saturday?.timings?.close, 'tv','saturday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.saturday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.tv?.timings?.saturday?.timings?.open, e.target.value, 'tv','saturday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Saturday end*/}
                                                      {/*Sunday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="tv-timings-sunday" className="form-label">Sun</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-sunday-fullDay'
                                                                    checked={formData?.tv?.timings?.sunday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData?.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    sunday : {
                                                                                        ...formData?.tv?.timings?.sunday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-sunday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='tv-timings-sunday-setHours'
                                                                    checked={formData?.tv?.timings?.sunday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tv : {
                                                                                ...formData.tv,
                                                                                timings : {
                                                                                    ...formData?.tv?.timings,
                                                                                    sunday : {
                                                                                        ...formData?.tv?.timings?.sunday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="tv-timings-sunday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.tv?.timings?.sunday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.sunday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.tv?.timings?.sunday?.timings?.close, 'tv','sunday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.tv?.timings?.sunday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.tv?.timings?.sunday?.timings?.open, e.target.value, 'tv','sunday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Sunday end*/}
                                                </div>
                                                {tvError.timings &&<span style={{color : 'red'}}>Select Timings</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-2 d-flex flex-column">
                                                <label htmlFor="tv-cleaning" className="form-label">
                                                    Cleaning fee(Optional)
                                                </label>
                                                <input
                                                    // type="text"
                                                    // min={0}
                                                    placeholder="Enter cleaning fee"
                                                    id="tv-cleaning"
                                                    name="cleaningFee"
                                                    className="textFiled"
                                                    value={formData?.tv?.cleaningFee || ""}
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)){
                                                            onChangeInput(e,'tv')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6"  >
                                            <div className="mb-2 d-flex flex-column">
                                                <label htmlFor="tv-security-deposit" className="form-label">
                                                    Security deposit(Optional)
                                                </label>
                                                <input
                                                    // type="text"
                                                    // min={0}
                                                    placeholder="Enter security deposit"
                                                    id="tv-security-deposit"
                                                    name="securityDeposit"
                                                    className="textFiled"
                                                    value={formData?.tv?.securityDeposit || ""}
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)){
                                                            onChangeInput(e,'tv')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    {/* tv end */}

                    {/* event start */}
                    <Accordion defaultActiveKey={data?.event?.available ? "2" : ''} id = "PricingEventId">
                        <Card className="pricingCard">
                            <Card.Header className="d-flex justify-content-between">
                                <h6>Are you interested in providing your property for an Event?</h6>
                                <Toggle eventKey="2" type='event'/>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="event-hourly-price" className="form-label">
                                                    Weekday hourly price(Mon to Thu)<span className="required">*</span>
                                                </label>
                                                <input
                                                    // type="text"
                                                    required
                                                    // min={0}
                                                    placeholder="Enter hourly price"
                                                    id="event-hourly-price"
                                                    name="hourlyPrice"
                                                    className="textFiled"
                                                    value={formData?.event?.hourlyPrice || ""}
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)){
                                                            onChangeInput(e,'event')
                                                        }
                                                    }}
                                                />
                                                {eventError.hourlyPrice &&<span style={{color : 'red'}}>Enter Weekday hourly price</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="event-weekend-price" className="form-label">
                                                Weekend hourly price(Fri to Sun)<span className="required">*</span>
                                                </label>
                                                <input
                                                    // type="text"
                                                    required
                                                    // min={0}
                                                    placeholder="Enter weekend price"
                                                    id="event-weekend-price"
                                                    name="weekendPrice"
                                                    className="textFiled"
                                                    value={formData?.event?.weekendPrice || ""}
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)){
                                                            onChangeInput(e,'event')
                                                        }
                                                    }}
                                                />
                                                {eventError.weekendPrice &&<span style={{color : 'red'}}>Enter Weekend hourly price</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label className="form-label">
                                                    No of attendees allowed<span className="required">*</span>
                                                </label>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-2 d-flex flex-column">
                                                            <input
                                                                // type="text"
                                                                required
                                                                // min={0}
                                                                placeholder="No. of attendees"
                                                                name = "attendees"
                                                                className="textFiled"
                                                                value={formData?.event?.attendees || ""}
                                                                onChange={(e) => {
                                                                    const regex= /^[1-9][0-9]*$/;
                                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                                        onChangeInput(e,'event')
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6">
                                                        <div className="mb-2 d-flex flex-column">
                                                            <input
                                                                // type="text"
                                                                required
                                                                // min={0}
                                                                placeholder="Max. attendees"
                                                                name="max"
                                                                className="textFiled"
                                                                value={formData?.event?.max || ""}
                                                                onChange={(e) => {
                                                                    const regex= /^[1-9][0-9]*$/;
                                                                    if (e.target.value === "" || regex.test(e.target.value)) {
                                                                        onChangeInput(e,'event')
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {eventError.attendees &&<span style={{color : 'red'}}>{eventError?.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="event-minimumBooking" className="form-label">
                                               Minimum booking Hrs<span className="required">*</span>
                                                </label>
                                                <select
                                                    required
                                                    id="event-minimumBooking"
                                                    name="minimumBooking"
                                                    className="textFiledSelect"
                                                    value={formData?.event?.minimumBooking}
                                                    onChange={(e) => {onChangeInput(e,'event')}}
                                                >
                                                    <option hidden selected value="">Select hours</option>
                                                    <option value='1'>1 Hr</option>
                                                    <option value='2'>2 Hrs</option>
                                                    <option value='4'>4 Hrs</option>
                                                    <option value='6'>6 Hrs</option>
                                                    <option value='8'>8 Hrs</option>
                                                    <option value='12'>12 Hrs</option>
                                                    <option value='16'>16 Hrs</option>
                                                    <option value='20'>20 Hrs</option>
                                                    <option value='24'>24 Hrs</option>
                                                </select>
                                                {eventError.minBooking &&<span style={{color : 'red'}}>Select Min booking Hrs</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mb-2 d-flex flex-column">
                                                <div className="d-flex" >
                                                    <label className="form-label">
                                                        Timings available for booking<span className="required">*</span>
                                                    </label>
                                                    { formData?.tv?.timings  && 
                                                        <div className="mx-3" >
                                                            <label className="form-label" > {"(Fetch from tv)"} </label>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input mx-2"
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        event : {
                                                                            ...formData?.event,
                                                                            minimumBooking : e.target.checked ? formData?.tv?.minimumBooking : "",
                                                                            timings : e.target.checked ? formData?.tv?.timings : {}
                                                                        }
                                                                    })
                                                                } }
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    {/*Monday start*/}
                                                    <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="event-timings-monday" className="form-label">Mon</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-monday-fullDay'
                                                                    checked={formData?.event?.timings?.monday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData?.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    monday : {
                                                                                        ...formData?.event?.timings?.monday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-monday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-monday-setHours'
                                                                    checked={formData?.event?.timings?.monday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    monday : {
                                                                                        ...formData?.event?.timings?.monday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-monday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.event?.timings?.monday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.monday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.event?.timings?.monday?.timings?.close, 'event', 'monday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.monday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.event?.timings?.monday?.timings?.open, e.target.value, 'event','monday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Monday end*/}
                                                      {/*Tuesday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="event-timings-tuesday" className="form-label">Tue</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-tuesday-fullDay'
                                                                    checked={formData?.event?.timings?.tuesday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData?.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    tuesday : {
                                                                                        ...formData?.event?.timings?.tuesday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-tuesday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-tuesday-setHours'
                                                                    checked={formData?.event?.timings?.tuesday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    tuesday : {
                                                                                        ...formData?.event?.timings?.tuesday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-tuesday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.event?.timings?.tuesday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.tuesday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.event?.timings?.tuesday?.timings?.close, 'event','tuesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.tuesday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.event?.timings?.tuesday?.timings?.open, e.target.value, 'event','tuesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Tuesday end*/}
                                                      {/*Wednesday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="event-timings-wednesday" className="form-label">Wed</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-wednesday-fullDay'
                                                                    checked={formData?.event?.timings?.wednesday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData?.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    wednesday : {
                                                                                        ...formData?.event?.timings?.wednesday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-wednesday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-wednesday-setHours'
                                                                    checked={formData?.event?.timings?.wednesday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    wednesday : {
                                                                                        ...formData?.event?.timings?.wednesday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-wednesday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.event?.timings?.wednesday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.wednesday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.event?.timings?.wednesday?.timings?.close, 'event','wednesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.wednesday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.event?.timings?.wednesday?.timings?.open, e.target.value, 'event','wednesday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Wednesday end*/}
                                                      {/*Thursday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="event-timings-thursday" className="form-label">Thu</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-thursday-fullDay'
                                                                    checked={formData?.event?.timings?.thursday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData?.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    thursday : {
                                                                                        ...formData?.event?.timings?.thursday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-thursday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-thursday-setHours'
                                                                    checked={formData?.event?.timings?.thursday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    thursday : {
                                                                                        ...formData?.event?.timings?.thursday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-thursday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.event?.timings?.thursday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.thursday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.event?.timings?.thursday?.timings?.close, 'event','thursday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.thursday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.event?.timings?.thursday?.timings?.open, e.target.value, 'event','thursday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Thursday end*/}
                                                      {/*Friday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="event-timings-friday" className="form-label">Fri</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-friday-fullDay'
                                                                    checked={formData?.event?.timings?.friday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData?.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    friday : {
                                                                                        ...formData?.event?.timings?.friday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-friday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-friday-setHours'
                                                                    checked={formData?.event?.timings?.friday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    friday : {
                                                                                        ...formData?.event?.timings?.friday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-friday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.event?.timings?.friday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.friday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.event?.timings?.friday?.timings?.close, 'event','friday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.friday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.event?.timings?.friday?.timings?.open, e.target.value, 'event','friday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Friday end*/}
                                                      {/*Saturday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="event-timings-saturday" className="form-label">Sat</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-saturday-fullDay'
                                                                    checked={formData?.event?.timings?.saturday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData?.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    saturday : {
                                                                                        ...formData?.event?.timings?.saturday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-saturday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-saturday-setHours'
                                                                    checked={formData?.event?.timings?.saturday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    saturday : {
                                                                                        ...formData?.event?.timings?.saturday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-saturday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.event?.timings?.saturday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.saturday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.event?.timings?.saturday?.timings?.close, 'event','saturday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.saturday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.event?.timings?.saturday?.timings?.open, e.target.value, 'event','saturday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Saturday end*/}
                                                      {/*Sunday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-2 col-2" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <label htmlFor="event-timings-sunday" className="form-label">Sun</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-sunday-fullDay'
                                                                    checked={formData?.event?.timings?.sunday?.fullDay === true}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData?.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    sunday : {
                                                                                        ...formData?.event?.timings?.sunday,
                                                                                        fullDay : true
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-sunday-fullDay" className="form-label">24 Hrs</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-5" style={{paddingRight : '0px',paddingLeft : '0px'}}>
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='event-timings-sunday-setHours'
                                                                    checked={formData?.event?.timings?.sunday?.fullDay === false}
                                                                    onChange={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            event : {
                                                                                ...formData.event,
                                                                                timings : {
                                                                                    ...formData?.event?.timings,
                                                                                    sunday : {
                                                                                        ...formData?.event?.timings?.sunday,
                                                                                        fullDay : false,
                                                                                        timings: { open: "00:00", close: "23:59" }
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <label htmlFor="event-timings-sunday-setHours" className="form-label">Set hrs</label>
                                                            </div>
                                                        </div>
                                                        {formData?.event?.timings?.sunday?.fullDay === false && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.sunday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateTimings(  e.target.value ,formData?.event?.timings?.sunday?.timings?.close, 'event','sunday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        className="textFiledSelect"
                                                                        value={formData?.event?.timings?.sunday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateTimings(formData?.event?.timings?.sunday?.timings?.open, e.target.value, 'event','sunday')
                                                                        }}
                                                                    >
                                                                        {timeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Sunday end*/}
                                                </div>
                                                {eventError.timings &&<span style={{color : 'red'}}>Select Timings</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-2 d-flex flex-column">
                                                <label htmlFor="event-cleaning" className="form-label">
                                                    Cleaning fee(Optional)
                                                </label>
                                                <input
                                                    // type="text"
                                                    // min={0}
                                                    placeholder="Enter cleaning fee"
                                                    id="event-cleaning"
                                                    name="cleaningFee"
                                                    className="textFiled"
                                                    value={formData?.event?.cleaningFee || ""}
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)){
                                                            onChangeInput(e,'event')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6"></div> */}
                                        <div className="col-lg-6">
                                            <div className="mb-2 d-flex flex-column">
                                                <label htmlFor="event-security-deposit" className="form-label">
                                                    Security deposit(Optional)
                                                </label>
                                                <input
                                                    // type="text"
                                                    // min={0}
                                                    placeholder="Enter security deposit"
                                                    id="event-security-deposit"
                                                    name="securityDeposit"
                                                    className="textFiled"
                                                    value={formData?.event?.securityDeposit || "" }
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)){
                                                            onChangeInput(e,'event')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6"></div> */}
                                        <div className="col-lg-12 mt-2">
                                            <div className="mb-2 d-flex justify-content-between">
                                                <label className="form-label">
                                                Catering service available
                                                </label>
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name='catering'
                                                            id='event-catering-yes'
                                                            checked={formData?.event?.catering}
                                                            onChange={() => {
                                                                setFormData({
                                                                    ...formData,
                                                                    event : {
                                                                        ...formData.event,
                                                                        catering : true,
                                                                        buffets :[ {
                                                                            name : '',
                                                                            veg : '',
                                                                            nonVeg : '',
                                                                        }]
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                        <label htmlFor="event-catering-yes" className="me-2">Yes</label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name='catering'
                                                            id='event-catering-no'
                                                            checked={!formData?.event?.catering}
                                                            onChange={() => {
                                                                setFormData({
                                                                    ...formData,
                                                                    event : {
                                                                        ...formData.event,
                                                                        catering : false,
                                                                        buffets : [],
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                        <label htmlFor='event-catering-no'>No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12"></div>
                                        {formData?.event?.catering &&(
                                            <>
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <label className="col-4 form-label" ></label>
                                                        <label className="col-4 form-label text-center"  >Veg <br/> (Per plate) </label>
                                                        <label className="col-4 form-label  text-center" >Non veg <br/> (Per plate)  </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12"></div>
                                            </>
                                        )}
                                        {formData?.event?.catering && formData?.event?.buffets.map((buffet,i) => {
                                            return(
                                                <>
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-4 mb-2">
                                                            <input
                                                                placeholder='Buffet name'
                                                                className="textFiled w-100"
                                                                style={{paddingLeft : '0px',paddingRight : '0px'}}
                                                                value={buffet?.name || ""}
                                                                onChange={(e) => {
                                                                    const temp = {...formData?.event?.buffets[i],name : e.target.value}
                                                                    formData?.event?.buffets.splice(i,1,temp)
                                                                    setFormData({
                                                                        ...formData,
                                                                    })
                                                                }}
                                                                />
                                                            </div>
                                                            <div className="col-3 mb-2">
                                                                <input
                                                                    placeholder='00'
                                                                    className="textFiled w-100"
                                                                    value={buffet?.veg}
                                                                    onChange={(e) => {
                                                                        const regex= /^[1-9][0-9]*$/;
                                                                    if (e.target.value === "" || regex.test(e.target.value)){
                                                                        const temp = {...formData?.event?.buffets[i],veg : e.target.value}
                                                                        formData?.event?.buffets.splice(i,1,temp)
                                                                        setFormData({
                                                                            ...formData,
                                                                            // event : {
                                                                            //     ...formData?.event,
                                                                            //     buffets : [...formData?.event?.buffets],
                                                                            // }
                                                                        })
                                                                    }
                                                                }}
                                                                />
                                                            </div>
                                                            <div className="col-3 mb-2">
                                                                <input
                                                                    placeholder='00'
                                                                    className="textFiled w-100"
                                                                    value={buffet?.nonVeg}
                                                                    onChange={(e) => {
                                                                        const regex= /^[1-9][0-9]*$/;
                                                                        if (e.target.value === "" || regex.test(e.target.value)){
                                                                            const temp = {...formData?.event?.buffets[i],nonVeg : e.target.value}
                                                                            formData?.event?.buffets.splice(i,1,temp)
                                                                            setFormData({
                                                                                ...formData,
                                                                                // event : {
                                                                                //     ...formData?.event,
                                                                                //     buffets : [...formData?.event?.buffets],
                                                                                // }
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-2 mb-2">
                                                                <button type="button" style={{width:"45px"}} className="btn btn-outline-secondary  feature-buttons" onClick={() => onClickDelete(i)}  > <i class="bi bi-trash"></i> </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12"></div>
                                                </>
                                            )
                                        })}
                                        {formData?.event?.catering &&(
                                            <div>
                                                <button type="button"
                                                className="btn btn-primary"
                                                onClick={()=>{
                                                    setFormData({
                                                        ...formData,
                                                        event : {
                                                            ...formData.event,
                                                            buffets : [...formData?.event?.buffets,{name:'',veg:'',nonVeg:''}]
                                                        }
                                                    })
                                                }}>Add more</button>
                                            </div>
                                        )}
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    {/* event end */}

                    {/* stay start */}
                    <Accordion defaultActiveKey={data?.stay?.available ? "3" : ''} id = "PricingStayId" >
                        <Card className="pricingCard">
                            <Card.Header className="d-flex justify-content-between">
                                <h6>Are you interested in providing your property for a Stay?</h6>
                                <Toggle eventKey="3" type='stay'/>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="stay-hourly-price" className="form-label">
                                                    Weekday price(Mon to Thu)24Hrs<span className="required">*</span>
                                                </label>
                                                <input
                                                    // type="text"
                                                    required
                                                    // min={0}
                                                    placeholder="Enter weekday price"
                                                    id="stay-hourly-price"
                                                    name="hourlyPrice"
                                                    className="textFiled"
                                                    value={formData?.stay?.hourlyPrice || ""}
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)) {
                                                            onChangeInput(e,'stay')
                                                        }
                                                    }}
                                                />
                                                {stayError.hourlyPrice &&<span style={{color : 'red'}}>Enter Weekday price</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="stay-weekend-price" className="form-label">
                                                Weekend price(Fri to Sun)24Hrs<span className="required">*</span>
                                                </label>
                                                <input
                                                    // type="text"
                                                    required
                                                    // min={0}
                                                    placeholder="Enter weekend price"
                                                    id="stay-weekend-price"
                                                    name="weekendPrice"
                                                    className="textFiled"
                                                    value={formData?.stay?.weekendPrice || "" }
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)) {
                                                            onChangeInput(e,'stay')
                                                        }
                                                    }}
                                                />
                                                 {stayError.weekendPrice &&<span style={{color : 'red'}}>Enter Weekend price</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6 mb-2">
                                            <label className="form-label">
                                                Occupancy
                                            </label>
                                            <div className="row d-flex justify-content-between">
                                                <div className="col-lg-6">
                                                    <label className="pricing-label">
                                                        Base adults<span className="required">*</span>
                                                    </label>
                                                    <label className="pricing-label-sub">
                                                        (Ideal number of adults that can be accommodated in this property)
                                                    </label>
                                                </div>
                                                <div className="col-lg-6 d-flex pricing-button-container mx-2">
                                                    {/* <button
                                                        type="button"
                                                        onClick={() => {
                                                            if(formData?.stay?.baseAdults > 0){
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay,
                                                                        baseAdults : formData?.stay?.baseAdults -1,
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >-</button>
                                                    <div>{formData?.stay?.baseAdults || 0}</div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay,
                                                                        baseAdults : formData?.stay?.baseAdults ?  formData?.stay?.baseAdults+1 : 1,
                                                                        maxAdults : formData?.stay?.maxAdults ?  formData?.stay?.maxAdults+1 : 1,
                                                                    }
                                                                })

                                                        }}
                                                    >+</button> */}
                                                    <input
                                                        required
                                                        placeholder="Enter No."
                                                        className="textFiled w-100"
                                                        value={formData?.stay?.baseAdults || ""}
                                                        onChange={(e) => {
                                                            const regex = /^[0-9]+$/;
                                                            if (regex.test(e?.target?.value)) {
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay, 
                                                                        baseAdults : parseInt(e?.target?.value)
                                                                    }
                                                                })
                                                            } else if (e?.target?.value === "") {
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay, 
                                                                        baseAdults : e?.target?.value
                                                                    }
                                                                })
                                                            }
                                                        } }
                                                    />
                                                </div>
                                                {stayError.baseAdults &&<span style={{color : 'red'}}> {stayError?.message} </span>}
                                            </div>
                                            <div className="row d-flex justify-content-between">
                                                <div className="col-lg-6">
                                                    <label className="pricing-label">
                                                        Maximum adults<span className="required">*</span>
                                                    </label>
                                                    <label className="pricing-label-sub">
                                                        (Maximum number of adults that can be accommodated in this property)
                                                    </label>
                                                </div>
                                                <div className="col-lg-6 d-flex pricing-button-container mx-2">
                                                    {/* <button
                                                        type="button"
                                                        onClick={() => {
                                                            if(formData?.stay?.maxAdults > 0){
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay,
                                                                        maxAdults : formData?.stay?.maxAdults -1,
                                                                        baseAdults : formData?.stay?.baseAdults ===  formData?.stay?.maxAdults ? formData?.stay?.baseAdults - 1  :  formData?.stay?.baseAdults
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >-</button>
                                                    <div>{formData?.stay?.maxAdults || 0}</div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay,
                                                                        maxAdults : formData?.stay?.maxAdults ?  formData?.stay?.maxAdults+1 : 1,
                                                                    }
                                                                })

                                                        }}
                                                    >+</button> */}
                                                    <input
                                                        required
                                                        placeholder="Enter No."
                                                        className="textFiled w-100"
                                                        value={formData?.stay?.maxAdults || ''}
                                                        onChange={(e) => {
                                                            const regex = /^[0-9]+$/;
                                                            if (regex.test(e?.target?.value)) {
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay, 
                                                                        maxAdults : parseInt(e.target.value),
                                                                    }
                                                                })
                                                            } else if (e?.target?.value === "") {
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay, 
                                                                        maxAdults : e.target.value,
                                                                    }
                                                                })
                                                            }
                                                        } }
                                                    />
                                                </div>
                                                {stayError.maxAdults &&<span style={{color : 'red'}}> {stayError?.message1} </span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-6 mb-2">
                                            <div className="col-lg-6">
                                                <label className="pricing-label">
                                                Extra Bed or Cot
                                                </label>
                                                <label className="pricing-label-sub">
                                                    (Help your guests understand if there are any Extra Bed or Cot available on request)
                                                </label>
                                            </div>
                                            <div className="row d-flex justify-content-between mb-2">
                                                <div className="col-lg-6">
                                                    <label className="pricing-label">
                                                    Mattress 
                                                    </label>
                                                </div>
                                                <div className="col-lg-6 d-flex pricing-button-container">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if(formData?.stay?.extraBed > 0){
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay,
                                                                        extraBed : formData?.stay?.extraBed -1
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >-</button>
                                                    <div>{formData?.stay?.extraBed || 0}</div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay,
                                                                        extraBed : formData?.stay?.extraBed ?  formData?.stay?.extraBed+1 : 1,
                                                                    }
                                                                })

                                                        }}
                                                    >+</button>
                                                </div>
                                            </div>
                                            <div className="row d-flex justify-content-between">
                                                <div className="col-lg-6">
                                                    <label className="pricing-label">
                                                    Cot 
                                                    </label>
                                                </div>
                                                <div className="col-lg-6 d-flex pricing-button-container">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if(formData?.stay?.extraCot > 0){
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay,
                                                                        extraCot : formData?.stay?.extraCot -1
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >-</button>
                                                    <div>{formData?.stay?.extraCot || 0}</div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData?.stay,
                                                                        extraCot : formData?.stay?.extraCot ?  formData?.stay?.extraCot+1 : 1,
                                                                    }
                                                                })

                                                        }}
                                                    >+</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        {/* <div className="col-lg-6">
                                            <div className="mb-3 d-flex flex-column">
                                                <label htmlFor="stay-minimumBooking" className="form-label">
                                               Minimum booking Hrs<span className="required">*</span>
                                                </label>
                                                <select
                                                    required
                                                    id="stay-minimumBooking"
                                                    name="minimumBooking"
                                                    className="textFiledSelect"
                                                    value={formData?.stay?.minimumBooking}
                                                    onChange={(e) => {onChangeInput(e,'stay')}}
                                                >
                                                    <option hidden selected value="">Select hours</option>
                                                    <option value='12'>12 Hrs</option>
                                                    <option value='24'>24 Hrs</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-12">
                                            <div className="mb-2 d-flex flex-column">
                                                <label className="form-label">
                                                    Timings available for booking<span className="required">*</span>
                                                </label>
                                                <div className="row">
                                                    {/*Monday start*/}
                                                    <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <label htmlFor="stay-timings-monday" className="form-label">Monday</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='stay-timings-monday-setHours'
                                                                    checked={formData?.stay?.timings?.monday?.fullDay === true}
                                                                    onChange={(e) => {
                                                                        let timings = {}
                                                                        if(e.target.checked){
                                                                            timings = { open: "12:00 pm", close: "11:00 am" }
                                                                        }
                                                                        setFormData({
                                                                            ...formData,
                                                                            stay : {
                                                                                ...formData.stay,
                                                                                timings : {
                                                                                    ...formData?.stay?.timings,
                                                                                    monday : {
                                                                                        fullDay : e.target.checked,
                                                                                        timings: timings
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                {/* <label htmlFor="stay-timings-monday-setHours" className="form-label">Set hrs</label> */}
                                                            </div>
                                                        </div>
                                                        {formData?.stay?.timings?.monday?.fullDay && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.monday?.timings?.open}
                                                                        // onChange={(e) => {
                                                                        //     setFormData({
                                                                        //         ...formData,
                                                                        //         stay : {
                                                                        //             ...formData.stay,
                                                                        //             timings : {
                                                                        //                 ...formData?.stay?.timings,
                                                                        //                 monday : {
                                                                        //                     ...formData?.stay?.timings?.monday,
                                                                        //                     timings :{
                                                                        //                         ...formData?.stay?.timings?.monday?.timings,
                                                                        //                         open : e.target.value
                                                                        //                     }
                                                                        //                 },
                                                                        //             }
                                                                        //             }
                                                                        //     })
                                                                        // }}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(  e.target.value, formData?.stay?.timings?.monday?.timings?.close, 'stay', 'monday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.monday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(formData?.stay?.timings?.monday?.timings?.open, e.target.value, 'stay','monday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Monday end*/}
                                                      {/*Tuesday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <label htmlFor="stay-timings-tuesday" className="form-label">Tuesday</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='stay-timings-tuesday-setHours'
                                                                    checked={formData?.stay?.timings?.tuesday?.fullDay === true}
                                                                    onChange={(e) => {
                                                                        let timings = {}
                                                                        if(e.target.checked){
                                                                            timings = { open: "12:00 pm", close: "11:00 am" }
                                                                        }
                                                                        setFormData({
                                                                            ...formData,
                                                                            stay : {
                                                                                ...formData.stay,
                                                                                timings : {
                                                                                    ...formData?.stay?.timings,
                                                                                    tuesday : {
                                                                                        fullDay : e.target.checked,
                                                                                        timings: timings
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                {/* <label htmlFor="stay-timings-tuesday-setHours" className="form-label">Set hrs</label> */}
                                                            </div>
                                                        </div>
                                                        {formData?.stay?.timings?.tuesday?.fullDay && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.tuesday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(  e.target.value, formData?.stay?.timings?.tuesday?.timings?.close, 'stay', 'tuesday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.tuesday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(formData?.stay?.timings?.tuesday?.timings?.open, e.target.value, 'stay','tuesday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Tuesday end*/}
                                                      {/*Wednesday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <label htmlFor="stay-timings-wednesday" className="form-label">Wednesday</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='stay-timings-wednesday-setHours'
                                                                    checked={formData?.stay?.timings?.wednesday?.fullDay === true}
                                                                    onChange={(e) => {
                                                                        let timings = {}
                                                                        if(e.target.checked){
                                                                            timings = { open: "12:00 pm", close: "11:00 am" }
                                                                        }
                                                                        setFormData({
                                                                            ...formData,
                                                                            stay : {
                                                                                ...formData.stay,
                                                                                timings : {
                                                                                    ...formData?.stay?.timings,
                                                                                    wednesday : {
                                                                                        fullDay : e.target.checked,
                                                                                        timings: timings
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                {/* <label htmlFor="stay-timings-wednesday-setHours" className="form-label">Set hrs</label> */}
                                                            </div>
                                                        </div>
                                                        {formData?.stay?.timings?.wednesday?.fullDay && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.wednesday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(  e.target.value, formData?.stay?.timings?.wednesday?.timings?.close, 'stay', 'wednesday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.wednesday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(formData?.stay?.timings?.wednesday?.timings?.open, e.target.value, 'stay','wednesday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Wednesday end*/}
                                                      {/*Thursday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <label htmlFor="stay-timings-thursday" className="form-label">Thursday</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='stay-timings-thursday-setHours'
                                                                    checked={formData?.stay?.timings?.thursday?.fullDay === true}
                                                                    onChange={(e) => {
                                                                        let timings = {}
                                                                        if(e.target.checked){
                                                                            timings = { open: "12:00 pm", close: "11:00 am" }
                                                                        }
                                                                        setFormData({
                                                                            ...formData,
                                                                            stay : {
                                                                                ...formData.stay,
                                                                                timings : {
                                                                                    ...formData?.stay?.timings,
                                                                                    thursday : {
                                                                                        fullDay : e.target.checked,
                                                                                        timings: timings
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                {/* <label htmlFor="stay-timings-thursday-setHours" className="form-label">Set hrs</label> */}
                                                            </div>
                                                        </div>
                                                        {formData?.stay?.timings?.thursday?.fullDay && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.thursday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(  e.target.value, formData?.stay?.timings?.thursday?.timings?.close, 'stay', 'thursday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.thursday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(formData?.stay?.timings?.thursday?.timings?.open, e.target.value, 'stay','thursday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Thursday end*/}
                                                      {/*Friday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <label htmlFor="stay-timings-friday" className="form-label">Friday</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='stay-timings-friday-setHours'
                                                                    checked={formData?.stay?.timings?.friday?.fullDay === true}
                                                                    onChange={(e) => {
                                                                        let timings = {}
                                                                        if(e.target.checked){
                                                                            timings = { open: "12:00 pm", close: "11:00 am" }
                                                                        }
                                                                        setFormData({
                                                                            ...formData,
                                                                            stay : {
                                                                                ...formData.stay,
                                                                                timings : {
                                                                                    ...formData?.stay?.timings,
                                                                                    friday : {
                                                                                        fullDay : e.target.checked,
                                                                                        timings: timings
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                {/* <label htmlFor="stay-timings-friday-setHours" className="form-label">Set hrs</label> */}
                                                            </div>
                                                        </div>
                                                        {formData?.stay?.timings?.friday?.fullDay && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.friday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(  e.target.value, formData?.stay?.timings?.friday?.timings?.close, 'stay', 'friday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.friday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(formData?.stay?.timings?.friday?.timings?.open, e.target.value, 'stay','friday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Friday end*/}
                                                      {/*Saturday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <label htmlFor="stay-timings-saturday" className="form-label">Saturday</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='stay-timings-saturday-setHours'
                                                                    checked={formData?.stay?.timings?.saturday?.fullDay === true}
                                                                    onChange={(e) => {
                                                                        let timings = {}
                                                                        if(e.target.checked){
                                                                            timings = { open: "12:00 pm", close: "11:00 am" }
                                                                        }
                                                                        setFormData({
                                                                            ...formData,
                                                                            stay : {
                                                                                ...formData.stay,
                                                                                timings : {
                                                                                    ...formData?.stay?.timings,
                                                                                    saturday : {
                                                                                        fullDay : e.target.checked,
                                                                                        timings: timings
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                {/* <label htmlFor="stay-timings-saturday-setHours" className="form-label">Set hrs</label> */}
                                                            </div>
                                                        </div>
                                                        {formData?.stay?.timings?.saturday?.fullDay && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.saturday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(  e.target.value, formData?.stay?.timings?.saturday?.timings?.close, 'stay', 'saturday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.saturday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(formData?.stay?.timings?.saturday?.timings?.open, e.target.value, 'stay','saturday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Saturday end*/}
                                                      {/*Sunday start*/}
                                                      <div className="mb-2 col-lg-12 row">
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <label htmlFor="stay-timings-sunday" className="form-label">Sunday</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-6">
                                                            <div className="d-flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id='stay-timings-sunday-setHours'
                                                                    checked={formData?.stay?.timings?.sunday?.fullDay === true}
                                                                    onChange={(e) => {
                                                                        let timings = {}
                                                                        if(e.target.checked){
                                                                            timings = { open: "12:00 pm", close: "11:00 am" }
                                                                        }
                                                                        setFormData({
                                                                            ...formData,
                                                                            stay : {
                                                                                ...formData.stay,
                                                                                timings : {
                                                                                    ...formData?.stay?.timings,
                                                                                    sunday : {
                                                                                        fullDay : e.target.checked,
                                                                                        timings: timings
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                {/* <label htmlFor="stay-timings-sunday-setHours" className="form-label">Set hrs</label> */}
                                                            </div>
                                                        </div>
                                                        {formData?.stay?.timings?.sunday?.fullDay && (
                                                            <>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.sunday?.timings?.open}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(  e.target.value, formData?.stay?.timings?.sunday?.timings?.close, 'stay', 'sunday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3 col-6 d-flex flex-column">
                                                                    <select
                                                                        disabled
                                                                        className="textFiledSelect"
                                                                        value={formData?.stay?.timings?.sunday?.timings?.close}
                                                                        onChange={(e) => {
                                                                            validateStayTimings(formData?.stay?.timings?.sunday?.timings?.open, e.target.value, 'stay','sunday')
                                                                        }}
                                                                    >
                                                                        {stayTimeOptions.map((item) => (
                                                                        <option value={item.value}>{item.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                     </div>
                                                     {/*Sunday end*/}
                                                </div>
                                                {stayError.timings &&<span style={{color : 'red'}}>Select Timings</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-2 d-flex flex-column">
                                                <label htmlFor="stay-cleaning" className="form-label">
                                                    Cleaning fee(Optional)
                                                </label>
                                                <input
                                                    // type="text"
                                                    // min={0}
                                                    placeholder="Enter cleaning fee"
                                                    id="stay-cleaning"
                                                    name="cleaningFee"
                                                    className="textFiled"
                                                    value={formData?.stay?.cleaningFee || ""}
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)) {
                                                            onChangeInput(e,'stay')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-2 d-flex flex-column">
                                                <label htmlFor="stay-security-deposit" className="form-label">
                                                    Security deposit(Optional)
                                                </label>
                                                <input
                                                    // type="text"
                                                    // min={0}
                                                    placeholder="Enter security deposit"
                                                    id="stay-security-deposit"
                                                    name="securityDeposit"
                                                    className="textFiled"
                                                    value={formData?.stay?.securityDeposit || "" }
                                                    onChange={(e) => {
                                                        const regex= /^[1-9][0-9]*$/;
                                                        if (e.target.value === "" || regex.test(e.target.value)) {
                                                            onChangeInput(e,'stay')
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 mt-2">
                                            <div className="mb-2 d-flex justify-content-between">
                                                <label className="form-label">
                                                Catering Service Available
                                                </label>
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name='catering'
                                                            id='stay-catering-yes'
                                                            checked={formData?.stay?.catering}
                                                            onChange={() => {
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData.stay,
                                                                        catering : true,
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                        <label htmlFor="stay-catering-yes" className="me-2">Yes</label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name='catering'
                                                            id='stay-catering-no'
                                                            checked={!formData?.stay?.catering}
                                                            onChange={() => {
                                                                setFormData({
                                                                    ...formData,
                                                                    stay : {
                                                                        ...formData.stay,
                                                                        catering : false,
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                        <label htmlFor='stay-catering-no'>No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    {/* stay end */}
                    {/* accordion end */}
                </div>
            </div>
        </div>
        {/* Save Section Start */}
       <div className="row "    >
            <div className="d-flex flex-row justify-content-between" style={{width : "100%"}} >
            <button
                    type="button"
                    class="btn btn-outline-danger"
                    onClick={handleBack}
                >
                    Previous
                </button>
                <button
                    type="button"
                    className="btn btn-primary  me-3 "
                    
                    onClick={handleSubmit}
                    disabled = {!(Object.keys(formData).length > 0)}
                >
                    Continue
                </button>
            </div>
        </div>
        {/* Save Section End */}
        {showConfirmationModel &&
        <Modal show={showConfirmationModel} onHide={() => setShowConfirmationModel(false)}>
          <Header closeButton>
            <Title>{confirmationTitle}</Title>
          </Header>
          <Body className="verify-email-modal-body">
            {confirmationBody}
          </Body>
          <Footer>
            <Button variant="secondary" onClick={() => setShowConfirmationModel(false)}>
              OK
            </Button>
          </Footer>
        </Modal>
      }
        </>
    )
}

export default  SubPricingAndTimings
