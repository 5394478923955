import { useState, useEffect } from 'react';
import { uploadLocationVideos, deleteVid } from "../../../services/api";
import { Modal, Button } from 'react-bootstrap'
import ReactPlayer from 'react-player'


function urltoFile(url, filename, mimeType) {
    return fetch(url)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], Date.now() + filename, { type: mimeType });
        });
}


const Videos = ({ locationData }) => {

    const [videos, setVideos] = useState([]);
    const [files, setFiles] = useState([]);
    const [videosData, setVideosData] = useState([]);
    let x = window.matchMedia("(max-width: 576px)");
    const [noOfFiles, setNoOfFiles] = useState(0);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState();

    const { subscriptionPlan } = locationData;

    // for Modal
    const [show, setShow] = useState(false);
    const [modelBody, setModelBody] = useState('');

    useEffect(() => {
        if (locationData && locationData?.videosData) {
            const { videosData } = locationData;
            setVideosData(videosData);
            setFiles(videosData);

            if (files.length < 1) {
                videosData?.map((videoData, id) => {
                    const obj = {
                        video: videoData?.image,
                        uploaded: true,
                        name: videoData?.name
                    }
                    files.push(obj)
                });
            }
        }
        switch (locationData?.subscriptionPlan) {
            case 'premium':
                setNoOfFiles(1);
                break;
            case 'standard':
                setNoOfFiles(1);
                break;
            case 'basic':
                setNoOfFiles(0);
                break;
            default:
                setNoOfFiles(0)
        }
    }, [locationData]);

    const uploadFiles = async (videoFile, e) => {
        if (videoFile.uploaded) {
            return {
                video: videoFile.imageSrc,
                videoRef: videoFile.fileRef,
                name: videoFile.name,
                uploaded: videoFile.uploaded
            };
        }
        const file = await urltoFile(
            videoFile.videoSrc,
            videoFile.name,
            "text/plain"
        );

        const videoFormData = new FormData();
        videoFormData.append("vid", file);
        videoFormData.append("fileName", videoFile.name);
        videoFormData.append("locationId", locationData?.location_id);

        try {
            const response = await uploadLocationVideos(videoFormData);
            response.name = videoFile.name;
            videoFile.uploaded = true;
            videoFile.image = response.url;
            locationData.videosData = [...locationData?.videosData, { name: response.name, video: response.url }]
            return response;

        } catch (error) {
            console.log(error)
        }
    }

    const onDrop = async (event) => {
        // Handle the dropped files
        const videoFile = event.target.files[0];
        if (event.target.files && event.target.files.length > 0) {
            if (subscriptionPlan === "standard" && videoFile.size > 10485760) {
                setModelBody("For standard plan video file size should not be morethan 10mb");
                setShow(true);
                return null;
            }
            else if (subscriptionPlan === "premium" && videoFile.size > 15728640) {
                setModelBody("For premium plan video file size should not be morethan 15mb");
                setShow(true);
                return null;
            }
            else {
                const videoObj = {
                    videoFile,
                    videoSrc: URL.createObjectURL(videoFile),
                    preview: false,
                    uploaded: false,
                    name: videoFile.name
                };

                const promise = uploadFiles(videoObj)

                try {
                    const result = await Promise.resolve(promise);
                    result.video = result.url
                    result.uploaded = true
                    setFiles((prevFiles) => {
                        return [...prevFiles, result]
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        }

    };


    const showVideo = (name, url) => {
        setVideoUrl(url);
        setShowVideoModal(true);
    }

    const deleteVideo = async (index) => {

        const filteredList = files.filter((each, i) =>
            index !== i
        )
        const delVid = files.filter((each, i) => index === i);
        const reqUrlToDel = delVid[0].video

        setFiles(filteredList);
        setVideosData(filteredList);

        try {
            const res = await deleteVid({ video: reqUrlToDel });
            locationData.videosData = locationData.videosData.filter((each, i) => { return index !== i })
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpload = async (e) => {
        e.preventDefault();
        try {

            const tdata = files.map((item) => {
                if (!item)
                    return;
                return {
                    video: item.url ? item.url : item.video,
                    videoRef: item.fileRef,
                    name: item.name,
                };
            });

            setVideos([...videos, tdata]);
            handleSubmit(tdata);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = (payload) => {
        setVideos([...videos, payload]);
        setVideosData([...videosData, payload]);
    };

    const videoUploadView = () => {
        return (
            <div className='upload__img-box'  >
                <label className="upload__btn">
                    <p>Drag 'n' drop a video </p>
                    <input
                        type="file"
                        multiple="false"
                        data-max_length="20"
                        id="upload__inputfile"
                        className="upload__inputfile"
                        accept='video/mp4, video/mkv'
                        onChange={onDrop}
                    />
                </label>
            </div>
        )
    }

    const noVideoUploadView = () => {
        return (
            <p> Uploading a video is only available for standard and premium properties. </p>
        )
    }

    const displayView = () => {

        switch (locationData?.subscriptionPlan) {
            case "premium":
                return videoUploadView()
            case "standard":
                return videoUploadView()
            case "basic":
                return noVideoUploadView()
            default:
                return noVideoUploadView()
        }

    }


    return (
        <>
            <form onSubmit={handleUpload}
                className='tab-pane h-100 fade photo-form show active  video-page'
                id="v-pills-videos"
                role="tabpanel"
                aria-labelledby="v-pills-videos-tab"
            >
                <div className='row' >
                    <h4> Video Upload  </h4>
                    <div className='row' >
                        <div className='p-3 col-xl-12' style={{ border: "1px black solid", width: "48vw", height: "50vh" }} >
                            {files.length > 0 && files.map((each, index) => {
                                return (

                                    <div className="d-flex flex-row justify-content-start" key={index}
                                        onClick={() => {
                                            showVideo(each.name, each.video);
                                        }}
                                    >
                                        <div >
                                            <ReactPlayer
                                                url={each.video}
                                                width='80%'
                                                height='300px'
                                                controls
                                            />
                                        </div>
                                        {/* {each.name.substring(0, 5)} */}
                                        <p
                                            onClick={(e) => {
                                                deleteVideo(0);
                                                e.stopPropagation();
                                            }}
                                            className='cursor-pointer'
                                            style={{ marginLeft: "5px", fontWeight: "bold" }}
                                        > X </p>
                                    </div>
                                )
                            })}
                            {files.length < 1 && displayView()
                            }
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Videos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modelBody}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShow(false)}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

            </form>
        </>
    );
};



export default Videos;