import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { sendCredMail,getUsers, deleteUser} from "../../services/api";
import { KTIcon } from "../../../../../_metronic/helpers";
import {AddUserModal} from "../modals/AddUserModel";
import { UpdateUserModal } from "../modals/UpdateUserModal";
import VerifyModal from "../../Modals/VerifyModal";
import { useAuth } from "../../../auth";
import { PageLoader } from "../../services/PageLoader";
import Reports from "../../Reports/Reports";

const UsersList = () => {
	const { currentUser } = useAuth();
	const [searchTerm, setSearchTerm] = useState('')
	const [openAddUserModal,setOpenAddUserModal] = useState(false)
	const [openUpdateUserModal,setOpenUpdateUserModal] = useState(false)
	const [modalBody, setModalBody] = useState("");
	const [updateId,setUpdateId] = useState('')
	const [id,setId] = useState('')
	const [showVerify, setShowVerify] = useState(false);
	const [loader, setLoader] = useState(false);
	const [userType, setUserType] = useState('user');

	const handleSearch = (e) => {
		setSearchTerm(e.target.value)
		const filteredData = filterData?.filter((each) => {
			if ( ((each?.email?.toLowerCase())?.includes((e.target.value).toLowerCase())) ||
				((each?.username?.toLowerCase())?.includes((e.target.value).toLowerCase())) ||
				((each?.id?.toLowerCase())?.includes((e.target.value).toLowerCase())) ||
				((each?.createdBy?.toLowerCase())?.includes((e.target.value).toLowerCase())) ||
				((each?.mobile?.toLowerCase())?.includes((e.target.value).toLowerCase())) )
				{
				return each
			}
		})
		setData(filteredData)
	}

	const userColumns = [
		{
			field: "id", headerName: "ID", width: 250,
			renderHeader: () => (
				<th className='min-w-150px'>ID</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record.row.id}
				</div>
			),
		},
		{
			field: "username",
			headerName: "Name",
			width: 150,
			renderHeader: () => (
				<th className='min-w-150px'>Name</th>
			),
			renderCell: (params) => {
				return (
					<div className='text-dark fw-bold text-hover-primary fs-6'>
						{/* <img className="cellImg" src={params.row.img} alt="" /> */}
						{params.row.username}
					</div>
				);
			},
		},
		{
			field: "lastActive",
			headerName: "last Active",
			width: 200,
			renderHeader: () => (
				<th className='min-w-150px'>Last Active</th>
			),
			renderCell: (params) => {
				return (
					<div className='text-dark fw-bold text-hover-primary fs-6'>
						{/* <img className="cellImg" src={params.row.img} alt="" /> */}
						{params.row.lastActive}
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			width: 230,
			renderHeader: () => (
				<th className='min-w-150px'>Email</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record.row.email}
				</div>
			),
		},
		{
			field: "mobile",
			headerName: "Phone Number",
			width: 150,
			renderHeader: () => (
				<th className='min-w-150px'>Phone Number</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record.row.mobile}
				</div>
			),
		},
		{
			field: "date",
			headerName: "Date Of Creation",
			width: 175,
			renderHeader: () => (
				<th className='min-w-150px'>Date Of Creation</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record.row.date}
				</div>
			),
		},
		{
			field: "createdBy",
			headerName: "Created By",
			width: 220,
			renderHeader: () => (
				<th className='min-w-150px'>Created By</th>
			),
			renderCell: (record) => (
				<div className='text-dark fw-bold text-hover-primary fs-6'>
					{record.row.createdBy}
				</div>
			),
		},
		// {
		// 	field: "signuptype",
		// 	headerName: "Signup Type",
		// 	width: 130,
		// 	renderHeader: () => (
		// 		<th className='min-w-150px'>Signup Type</th>
		// 	),
		// 	renderCell: (record) => (
		// 		<div className='text-dark fw-bold text-hover-primary fs-6'>
		// 			{record.row.signupAs}
		// 		</div>
		// 	),
		// },
	];
	const [data, setData] = useState([]);
	const [filterData,setFilterData] = useState([]);
	// const getSignUpAs = (data) => {
	// 	if (data?.googleLogin) {
	// 		return "Google"
	// 	} else if (data?.emailVerified && data?.mobileVerified) {
	// 		return "Email & Mobile"
	// 	}  else if (data?.emailVerified) {
	// 		return "Email"
	// 	} else if (data?.mobileVerified) {
	// 		return "Mobile"
	// 	} else {
	// 		return "NA"
	// 	}
	// }
	const getData = async() => {
		setLoader(true)
		try{
			var data2 = [];
			const response = await getUsers(userType)
			const data = response.data;
			for (let i = 0; i < data.length; i++) {
				// const date = new Date(data[i].createdAt);
				// const yyyy = date.getFullYear();
				// let mm = date.getMonth() + 1; // Months start at 0!
				// let dd = date.getDate();
				// if (dd && dd < 10) dd = "0" + dd;
				// if (mm && mm < 10) mm = "0" + mm;
				// const formattedToday = dd + "/" + mm + "/" + yyyy

				const getFormattedDate = (givenDate) => {
					const date = new Date(givenDate);
					const yyyy = date.getFullYear();
					let mm = date.getMonth() + 1; // Months start at 0!
					let dd = date.getDate();
					let hh = date.getHours();
					let min = date.getMinutes();
					let ss = date.getSeconds();
	
					if (dd && dd < 10) dd = "0" + dd;
					if (mm && mm < 10) mm = "0" + mm;
					if (min && min < 10) min = "0" + min;
					if (ss && ss < 10) ss = "0" + ss;
	
					const period = hh >= 12 ? 'PM' : 'AM';
					hh = hh % 12;
					hh = hh ? hh : 12; // the hour '0' should be '12'
					if (hh && hh < 10) hh = "0" + hh;
	
					const formattedDate = dd + "/" + mm + "/" + yyyy + " " + hh + ":" + min + ":" + ss + " " + period;
					return formattedDate
				}

				const user = {
					id: data[i]._id,
					email: data[i]?.personalInfo?.email,
					username: data[i]?.personalInfo?.fullName,
					mobile: data[i]?.personalInfo?.mobile,
					img: data[i]?.personalInfo?.profile_pic,
					JoinedAs: data[i]?.personalInfo?.booking_type,
					createdBy : data[i]?.employee?.full_name || data[i]?.createdBy || "NA",
					Address: "",
					Country: "India",
					BankName: "lorem ipsum 1",
					IFSC_Code: "ABC12345",
					AcNo: "123456",
					UPI: "ABC123",
					UserID: data[i].id,
					bookingInfo: data[i].listedLocations,
					date: getFormattedDate(data[i].createdAt),
					lastActive : data[i]?.lastActive ? getFormattedDate(data[i]?.lastActive) : "NA",
					// signupAs : getSignUpAs(data[i]?.personalInfo),  // data[i]?.personalInfo?.googleLogin ? "Google" : null || data[i]?.personalInfo?.whatsappLogin ? "WhatsApp" : "Email" 
				};
				data2 = [...data2, user]
			}
			setData(data2);
			setFilterData(data2)
		}catch(error){
			console.log(error)
		}
		setLoader(false)
	}
	useEffect(() => {
		getData()
	}, [userType]);

	const onClickDelete = async(id, status) => {
		setLoader(true)
		try{
			const resp = await deleteUser(id, status);
			if (status === "false") {
				setModalBody(resp?.data?.message)
				setShowVerify(true)
			} else {
				alert(resp?.data?.message)
				setModalBody("")
				getData()
			}
		}catch(error){
			console.log(error?.response?.data?.message)
			setModalBody("");
			alert(error?.response?.data?.message)
		}
		setLoader(false)
	}


	const handleDelete = (id) => {
		setId(id)
		onClickDelete(id, "false")
		// setShowVerify(true)
		// setData(data.filter((item) => item.id !== id));
	};

	const confirmVerify = () => {
		onClickDelete(id, "true");
	}

	const handleUser = (type) => {
		if(!(userType === type)){
			setUserType(type)
			setSearchTerm('')
		}
	}
	// const confirmVerify = async() => {
	// 	try{
	// 		const resp = await deleteUser(id)
	// 		alert(resp?.data?.message)
	// 		getData()
	// 	}catch(error){
	// 		console.log(error?.response?.data?.message)
	// 		alert(error?.response?.data?.message)
	// 	}
	// }
	
	const handleUpdate = (data) => {
		setUpdateId(data)
		setOpenUpdateUserModal(true)
	}

	const handleSendCred = async (id) => {
		setLoader(true)
		if (window.confirm('Are you sure to send credentials to user ?') == true) {
			try{
				const resp = await sendCredMail(id)
				alert(resp?.data?.message)
			}catch(error){
				console.log(error)
				alert(error?.response?.data?.message)
			}
		  }
		  setLoader(false)
	}

	const actionColumn = [
		{
			field: "action",
			headerName: "Action",
			width: 250,
			renderHeader: () => (
				<th className='min-w-150px'>Action</th>
			  ),
			renderCell: (params) => {
				return (
					<div className="d-flex justify-content-between">
						<div className="">
							<div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
								<Link to={`/apps/users/${params.id}`} style={{ textDecoration: "none" }}>
									<KTIcon iconName='magnifier' className='fs-3' />
								</Link>
							</div>
						</div>
						<div className="">
							<div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'  onClick={()=>handleUpdate(params?.row?.id)}>
									<KTIcon iconName='pencil' className='fs-3'/>
							</div>
						</div>
						{currentUser?.permissions?.User_Management?.delete && (
							<div className="">
								<div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'  onClick={()=>handleDelete(params?.row?.id)}>
										<KTIcon iconName='trash' className='fs-3'/>
								</div>
							</div>
						)}
						{
							params?.row?.signupAs === "Email" && 
							<div className="">
										<button type="button"  className="btn btn-light btn-active-light-primary my-1" onClick={()=> handleSendCred(params?.row?.id)} disabled = {params?.row?.email ? false : true} >send credentials</button>
							</div>
						}
					</div>
					// <div className="cellAction">
					// 	<Link to={`/apps/users/${params.id}`} style={{ textDecoration: "none" }}>
					// 		<div className="viewButton">View</div>
					// 	</Link>
					// </div>
				);
			},
		},
	];

	return (


		<div className="card">
			<div className="card-header border-0 pt-6">
				<div className='card-title'>
					{/* begin::Search */}
					<button type='button' className={`me-2 btn ${userType === 'user' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={()=>handleUser('user')}>
						Users
					</button>
					<button type='button' className={`me-2 btn ${userType === 'host' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={()=>handleUser('host')}>
						Hosts
					</button>
					<button type='button' className={`me-2 btn ${userType === 'deactivatedUser' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={()=>handleUser('deactivatedUser')}>
						Deactivated Users
					</button>
					<div className='d-flex align-items-center position-relative my-1'>
						<KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
						<input
							type='text'
							data-kt-user-table-filter='search'
							className='form-control form-control-solid w-250px ps-14'
							placeholder='Search'
							value={searchTerm}
							onChange={handleSearch}
						/>
					</div>
					{/* end::Search */}
				</div>
					{/* <LocationsListFilter filterFormData={filterData}/> */}
				<div className="card-toolbar">
					<Reports table={userType === 'user' ? 'users' : 'hosts'}/>
					<button type='button' className='btn btn-primary' onClick={()=>setOpenAddUserModal(true)}>
						<KTIcon iconName='plus' className='fs-2' />
						Add User
					</button>
				</div>
			</div>
			<div>
				{/* begin::Body */}
				<div className='card-body py-3'>
					{/* begin::Table container */}
					<div className='table-responsive'>
						{/* begin::Table */}
						<div className="datatable">
							<DataGrid
								className="datagrid"
								rows={data}
								columns={userColumns.concat(actionColumn)}
								pageSize={10}
								rowsPerPageOptions={[9]}
								sx={{
									"& .MuiDataGrid-row:hover": {
										backgroundColor: "inherit"
									},
									"& .MuiDataGrid-cell": {
										borderBottom: "1px solid var(--bs-gray-100)"
									}

								}}
							/>
						</div>
						{/* end::Table */}
					</div>
					{/* end::Table container */}
				</div>
				{/* begin::Body */}
			</div>
			<div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
				{/* <Stack spacing={2}>
                        <Pagination count={locationCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange}/>
                    </Stack> */}
			</div>
			{openAddUserModal && (
				<AddUserModal
					show = {openAddUserModal}
					onHide = {()=>{
						setOpenAddUserModal(false)
					}}
					onSucces = {()=>{
						setOpenUpdateUserModal(false)
						getData()
					}}
					title = 'Add New User'
				/>
			)}
			{openUpdateUserModal && (
				<UpdateUserModal
					show = {openUpdateUserModal}
					onHide = {()=>{
						setOpenUpdateUserModal(false)
					}}
					onSucces = {()=>{
						setOpenUpdateUserModal(false)
						getData()
					}}
					title = 'Update User'
					id={updateId}
				/>
			)}
			{showVerify && (
				<VerifyModal
				show={showVerify}
				title={'User'}
				// body={'Are you sure, You want to delete user ?'}
				body={modalBody}
				onHide={() => setShowVerify(false)}
				OnSuccess={() => {
					setShowVerify(false);
					confirmVerify();
				}}
				/>
			)}
			{loader && <PageLoader/>}
		</div>


	);
};

export default UsersList;
