import { useState,useEffect } from 'react';
import { getRazorPayInvoicesById } from '../../services/api';
import Modal from 'react-bootstrap/Modal';
import { PageLoader } from '../../services/PageLoader';
import moment from 'moment';

const InvoiceDetailsView = ({show,onHide,title,id}) => {
    const [data,setData] = useState<any>({})
    const [loader,setLoader] = useState(false)

    function displayNestedObject(obj, element) {
        // Create an unordered list element
        const ul = document.createElement('ul');
      
        // Iterate over the object properties
        for (const key in obj) {
          // Create list item for each property
          const li = document.createElement('li');
        //   li.style.fontWeight = '600'
          
          // Display property name
          li.textContent = key + ': ';
      
          // Display property value
          const value = obj[key];
          if (typeof value === 'object' && value !== null) {
            // If the value is an object or array, recursively call the function
            if (Array.isArray(value)) {


                if(value.length === 0 ){
                    console.log(key,value)
                    // const arrayLi = document.createElement('li');
                    // arrayLi.textContent = String('Null');
                    li.textContent = 'Null'
                }else{
                    // For arrays, display each element
                    const arrayUl = document.createElement('ul');
                    value.forEach((item) => {
                    const arrayLi = document.createElement('li');
                    if (typeof item === 'object' && item !== null) {
                        displayNestedObject(item, arrayLi);
                    } else {
                        arrayLi.textContent = String(item);
                    }
                    arrayUl.appendChild(arrayLi);
                    });
                    li.appendChild(arrayUl);
                }




            } else {
                // For objects, recursively call the function
                displayNestedObject(value, li);
            }
          } else {
            // Otherwise, display the value as is
            if(key === 'created_at' ){
                const date : any = new Date(value * 1000)
                li.textContent += String(moment(date).format('DD-MM-YYYY') || 'NA');
            }else{
                li.textContent += String(value);
            }
          }
          // Append list item to the unordered list
          ul.appendChild(li);
        }
      
        // Append the unordered list to the specified element
        element.appendChild(ul);
      }

    const getData = async() => {
        setLoader(true)
        try{
            const resp : any = await getRazorPayInvoicesById('inv_O4NPsbRRPTZUxP')
            console.log(resp)
            setData(resp?.data)
            const outputElement = document.getElementById('invoiceDetailsRoot');
            displayNestedObject(resp?.data, outputElement);
        }catch(error){
            console.log(error)
        }
        setLoader(false)
    }

    useEffect(()=>{
        getData()
    },[])

    return(
        <Modal
            show={show}
            onHide={onHide}
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{height : "500px",overflowY : 'auto'}}>
                <div id='invoiceDetailsRoot'>

                </div>
                {loader && <PageLoader/>}
            </Modal.Body>
        </Modal>
    )
}
export default InvoiceDetailsView;