import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import BookingsList from './list/BookingsList'
import BookingDetails from './BookingDetails'

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Booking Management',
    path: '/apps/bookings/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BookingManagementPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Booking Requests</PageTitle>
              <BookingsList />
            </>
          }
        />
        <Route
          path='details/:bookingId'
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Booking Details</PageTitle>
              <BookingDetails />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/bookings/list' />} />
    </Routes>
  )
}

export default BookingManagementPage
