import React, { FC, useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { getAmenities, getFeatures, createLookUp } from "../../../services/api";
import Multiselect from "multiselect-react-dropdown";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

type Props = {
  locationData?: any;
};

const Amenities: FC<Props> = ({ locationData }) => {

  const [amenitiesData, setAmenitiesData] = useState<any>([]);
  const [featuresData, setFeaturesData] = useState<any>([]);
  const [amenities, setAmenities] = useState<any>([]);
  const [features, setFeatures] = useState<any>([]);

  const [openNew, setOpenNew] = useState(false);
  const [newAmenity, setNewAmenity] = useState("");

  const [openNewFeature, setOpenNewFeature] = useState(false);
  const [newFeature, setNewFeature] = useState("");

  const [showDialog,setShowDialog] = useState(false)
  const[dialogContent,setDialogContent] = useState('')

  // const amenity = [{value:"abc" , label : "abc"},{value:"def" , label : "def"}]

  useEffect(() => {
    
    const fetchAmenities = async () => {
      try {
        const response = await getAmenities();
        const options = response?.data?.map((item) => ({
          value: item.value,
          label: item.name,
        }));
        setAmenitiesData(options);
      } catch (error) {
        console.error("Error fetching amenities:", error);
      }
    };

    const fetchFeatures = async () => {
      try {
        const response = await getFeatures();
        const options = response?.data?.map((item) => ({
          value: item.value,
          label: item.name,
        }));
        setFeaturesData(options);
      } catch (error) {
        console.error("Error fetching amenities:", error);
      }
    };

    fetchFeatures();

    fetchAmenities();

    if (locationData?.amenities) {
      const amenity = locationData?.amenities.map((item) => ({
        value: item,
        label: item,
      }));
      setAmenities(amenity);
    }
    if (locationData?.features) {
      const feature = locationData?.features.map((item) => ({
        value: item,
        label: item,
      }));
      setFeatures(feature);
    }
  }, [locationData]);

  const handleAmenitiesSelect = (selectedList, selectedItem) => {
    locationData.amenities = selectedList.map((opt) => opt.value);
    setAmenities(
      selectedList.map((opt) => ({ value: opt.value, label: opt.value }))
    );
  };
  const handleFeaturesSelect = (selectedList, selectedItem) => {
    locationData.features = selectedList.map((opt) => opt.value);
    setFeatures(
      selectedList.map((opt) => ({ value: opt.value, label: opt.value }))
    );
  };

  const handleCreateNew = async () => {
    let isExisted = true
    amenitiesData.map((obj) => {
      if (obj.value === newAmenity) {
        setOpenNew(false);
        isExisted  = false
        setDialogContent('Amenity already existed')
        setShowDialog(true)
        return null;
      }
    });

    if (isExisted) {
      try {
        await createLookUp({
          name: newAmenity,
          value: newAmenity,
          type: "amenities_type",
        });

        locationData.amenities.push(newAmenity);
        const amenity = locationData?.amenities.map((item) => ({
          value: item,
          label: item,
        }));
        setAmenities(amenity);

        const amentyResponse = await getAmenities();
        const options = amentyResponse?.data?.map((item) => ({
          value: item.value,
          label: item.name,
        }));
        setAmenitiesData(options);
      } catch (error) {
        alert(error || "Error adding amenity");
      }
    }
    setNewAmenity("");
    setOpenNew(false);
  };

  const handleCreateNewFeature = async () => {
    let isExisted = true
    featuresData.map((obj) => {
      if (obj.value === newFeature) {
        setOpenNewFeature(false);
        isExisted  = false
        setDialogContent('Feature already existed')
        setShowDialog(true)
        return null
      }
    });

    if (isExisted) {
      try {
        await createLookUp({
          name: newFeature,
          value: newFeature,
          type: "feature_type",
        });

        locationData.features.push(newFeature);
        const features = locationData?.features.map((item) => ({
          value: item,
          label: item,
        }));
        setFeatures(features);

        const featureResponse = await getFeatures();
        const options = featureResponse?.data?.map((item) => ({
          value: item.value,
          label: item.name,
        }));
        setFeaturesData(options);
      } catch (error) {
        alert(error || "Error adding amenity");
      }
    }
    setNewFeature("");
    setOpenNewFeature(false);
  };

  const removeAmenity = (item,i) => {
    locationData?.amenities.splice(i,1)

    const newAminities = amenities.filter((file) => {
      return file !== item
    })
    setAmenities(newAminities)
  }

  const removeFeature = (item,i) => {

    locationData?.features.splice(i,1)

    const newFeatures = features.filter((file) => {
      return file !== item
    })
    setFeatures(newFeatures)
  }

  return (
    <div className="w-100">
      <div className="pb-10 pb-lg-12">
        <h2 className="fw-bolder text-dark">Amenities & Features</h2>
      </div>

      <div className="fv-row mb-10">
        <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <label className="d-flex align-items-center form-label">
                <span className="required">Amenities</span>
              </label>
            </div>
            <div >
              <button
                type="button"
                className="btn btn-lg btn-primary p-2 mb-1"
                onClick={() => setOpenNew(true)}
              >
                Add New Amenity
              </button>
            </div>
          </div>

        <Multiselect
          displayValue="value"
          showArrow={true}
          hideSelectedList
          options={amenitiesData}
          onSelect={handleAmenitiesSelect}
          onRemove={handleAmenitiesSelect}
          showCheckbox
          selectedValues={locationData?.amenities?.map((item) => ({
            value: item,
          }))}
        />
        {amenities &&
          amenities.map((item, i) => (
            <div className="chips mt-1" key={i}>
              <span key={i}>{item.value}</span>
              <button
                title="Remove"
                aria-label="Remove"
                key={i}
                type="button"
                onClick={() => removeAmenity(item,i)}
              >
                {/* <i className="material-icons"></i> */}
                <CancelIcon/>
              </button>
            </div>
          ))}

        <div className="text-danger mt-2">
          <ErrorMessage name="amenities" />
        </div>
      </div>
      <Dialog
        open={openNew}
        onClose={() => setOpenNew(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add New Amenity</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              id="filled-select-currency"
              name="contact_name"
              value={newAmenity}
              // onClick={handleClickname}
              fullWidth
              size="small"
              sx={{
                padding: "8px",
              }}
              onChange={(e) => setNewAmenity(e.target.value)}
              variant="outlined"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNew(false)} color="error">
            Close
          </Button>
          <Button onClick={handleCreateNew} autoFocus variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <div className="fv-row mb-10">
      <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
              <label className="d-flex align-items-center form-label">
                <span className="required">Features</span>
              </label>
            </div>
            <div >
              <button
                type="button"
                className="btn btn-lg btn-primary p-2 mb-1"
                onClick={() => setOpenNewFeature(true)}
              >
                Add New Feature
              </button>
            </div>
          </div>

        <Multiselect
          displayValue="value"
          showArrow={true}
          hideSelectedList
          options={featuresData}
          showCheckbox
          onSelect={handleFeaturesSelect}
          onRemove={handleFeaturesSelect}
          selectedValues={locationData?.features?.map((item) => ({
            value: item,
          }))}
        />
        {features &&
          features.map((item, i) => (
            <div className="chips mt-1" key={i}>
              <span key={i}>{item.value}</span>
              <button
                title="Remove"
                aria-label="Remove"
                key={i}
                type="button"
                onClick={() => removeFeature(item,i)}
              >
                {/* <i className="material-icons"></i> */}
                <CancelIcon/>
              </button>
            </div>
          ))}

        <div className="text-danger mt-2">
          <ErrorMessage name="features" />
        </div>
      </div>
      <Dialog
        open={openNewFeature}
        onClose={() => setOpenNewFeature(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add New Feature</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              id="filled-select-currency"
              name="contact_name"
              value={newFeature}
              // onClick={handleClickname}
              fullWidth
              size="small"
              sx={{
                padding: "8px",
              }}
              onChange={(e) => setNewFeature(e.target.value)}
              variant="outlined"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewFeature(false)} color="error">
            Close
          </Button>
          <Button onClick={handleCreateNewFeature} autoFocus variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogContent}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { Amenities };
