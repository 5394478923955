// Images
import { useState, useEffect} from "react";
import {map, intersection } from 'lodash'
import { getListingAmenities } from "../../services/api";
import info from "../../../../../_metronic/assets/images/i.svg";
import { Button, Modal } from "react-bootstrap";
import Select from 'react-select';

// { editChanges?.property_size ? <span className="text-danger" > (Edited) </span> : "" }
const Amenities = ({data,handleBack,handleNext, editChanges}) => {
    const changedNames = map(editChanges?.changed, 'name');
    const deletedNames = map(editChanges?.deleted, 'name');
    const [formData,setFormData] = useState([])
    const [amenitiesData,setAmenitiesData] = useState({'Basic Facilities' : ''})
    const [activeHead,setActiveHead] = useState('Basic Facilities')
    const [activeHeadIndex,setActiveHeadIndex] = useState(0)
    const [checkedAmenties,setCheckedAmenites] = useState([])
    const [mainHeadCount,setMainHeadCount] = useState([])
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationBody, setConfirmationBody] = useState("");
    const [inputAmenity, setInputAmenity] = useState("")
    const [transformedData, setTransformedData] = useState([]);
    const [search, setSearch] = useState("");
    const [allAmenitiesData, setAllAmenitiesData] = useState([])
    const [showNoDataView, setShowNoDataView] = useState(false)
    const { Header, Footer, Title, Body } = Modal;

    const onClickMainHead = (value, i) => {
        setActiveHead(value)
        setActiveHeadIndex(i)
    }

    const handleCheckBox = (e, i) => {
        const {name,checked} = e.target
        let newData;
        let newData2;
        let type;
        if(checked){
            Object.keys(amenitiesData).map((mainHead) => {
                Object.keys(amenitiesData[mainHead]).map((subHead) => {
                    if(subHead && subHead === name){
                        type = amenitiesData[mainHead][subHead].type
                        return null
                    }
                })
            })
            const tempAmenity = {
                name : name,
            }
            if(type) tempAmenity.type = type
            formData.push(tempAmenity)
            newData = [...formData]
            checkedAmenties.push(name)
            newData2 = [...checkedAmenties]
            //for count update
            const newCount = parseInt(mainHeadCount[i]) + 1
            mainHeadCount.splice(i, 1, newCount);
            setMainHeadCount([...mainHeadCount])
        }else{
            newData = formData.filter((each) => each.name !== name)
            newData2 = checkedAmenties.filter((each) => each !== name)
            //for count update
            const newCount = parseInt(mainHeadCount[i]) - 1
            mainHeadCount.splice(i, 1, newCount);
            setMainHeadCount([...mainHeadCount])
        }
        setFormData([...newData])
        setCheckedAmenites([...newData2])
    }
    const onChangeSub = (name,type,options) => {
        let values=[];
        if(type === 'single'){
            values = [options]
        }else{
            options.map((each) => values.push(each))
        }
        formData.map((each) => {
            if(each.name === name){
                each.sub = values
            }
        })
    }
    const getSubs = (sub) => {
        let value = []
        formData.map((each) => {
            if(each.name === sub && each.sub){
                value = each.sub
            }
        })
        return value
    }

    // for setting main Head in error message 
    const getMainHead = (value) => {
        let mainCategory = "";
        transformedData?.map((each) => {
            const key = Object.keys(each)[0]
            const valuesArray = Object.values(each)[0]
            if (valuesArray.includes(value)) {
                mainCategory = key;
                return mainCategory
            }
        } )
        return mainCategory
    }

    const transformData = (data) => {
        // Extract the category name
        const categoryName = data?.category;
    
        // Extract the type names into an array
        let typeNames
        if (data?.types) {
            typeNames = Object.keys(data?.types);
        } else {
            typeNames = []
        }
    
        // Make the result object
        const categoryObj = {
            [categoryName]: typeNames
        };
    
        // Return the result in an array
        return categoryObj;
    };

    const submitForm = () => {
        if(formData.length < 2){
            setShowConfirmationModel(true)
            setConfirmationTitle("All Amenities");
            setConfirmationBody("Select atleast two amenities")
            return null
        }
        let isValid = true
        let subHead = ''
        for (const each of formData) {
            if(each?.type && !each?.sub){
                isValid = false
                subHead = each.name
                break
            }
          }

        if(!isValid){
            setShowConfirmationModel(true)
            const mainCategory = getMainHead(subHead)
            setConfirmationTitle("Sub Amenity Missing");
            setConfirmationBody( mainCategory !== "" ? `Select sub category within the ${subHead} under main category ${mainCategory}` : `Select sub category within the ${subHead}` )
            return null
        }
        handleNext({amenities : formData})
    }
  
    useEffect(() => {
        const callBack = async()=>{
            try{
                const resp = await getListingAmenities()
                const temp = {}
                resp.map((type)=>{
                    if (type?.types) {
                        const sorted = Object.keys(type.types)
                        .sort()
                        .reduce((acc, key) => ({
                            ...acc, [key]: type.types[key]
                        }), {})
                        temp[type.category] = sorted ;
                    } else {
                        temp[type.category] = {};
                    }
                })
                setAmenitiesData({...temp})
                setAllAmenitiesData({...temp})
                if(data){
                    setFormData(data)
                    let values = [];
                    data.map((each) => {
                        values.push(each.name)
                    })
                    setCheckedAmenites(values)
                    let tempAmenites =[];
                    let activeAmenties = {}
                    if(data){
                        setFormData(data)
                        let values = [];
                        data.map((each) => {
                            values.push(each.name)
                        })
                        setCheckedAmenites(values)
                        let tempAmenites =[];
                        let activeAmenties = {}
                        if(data){
                           for(const amenityType in temp){
                                for(const amenity in temp[amenityType]){
                                    tempAmenites.push(amenity)
                                }
                           }
                           for(const saved of data) {
                            if(!tempAmenites.includes(saved.name)){
                                activeAmenties[saved.name] = false
                            }
                           }
                           setAmenitiesData({
                            ...temp,
                            Others : activeAmenties,
                           })
                           setAllAmenitiesData({
                            ...temp,
                            Others : activeAmenties,
                           })
                        }
                        //for count
                        let tempMainHeadCount = []
                        Object.keys(temp).map((mainHead) => {
                            let count = 0
                            Object.keys(temp[mainHead]).map((subHead) => {
                                if(values.includes(subHead)){
                                    count += 1
                                }
                            })
                            tempMainHeadCount.push(count)   
                        })
                        tempMainHeadCount.pop()
                        tempMainHeadCount.push((Object.keys(activeAmenties)).length)
                        setMainHeadCount(tempMainHeadCount)
                    }else{
                        Promise.all(resp?.map((item)=>{
                            return 0
                          })).then((values)=> setMainHeadCount(values))
                    }
                    // for transforming the data to check the main category for error message
                    let reqDataFormat = []
                    resp?.map((each) => {
                        const reqObj = transformData(each);
                        reqDataFormat.push(reqObj)
                    } )
                    setTransformedData(reqDataFormat)
                }
            }catch(error){
                console.log(error?.response?.data?.message)
            }
    }
    callBack()
    const element = document.getElementById(`listAmenities`)
    if (element) element.scrollIntoView({behavior: "smooth",block: "start"});
    },[data])

    const handleAddNewAmenity = (e) => {
        e.preventDefault()
        let tempAmenityType
        let tempAmenity
        for (const amenityType in amenitiesData) {
            for(const eachAmenity in  amenitiesData[amenityType]){
                if (eachAmenity.toLowerCase() === inputAmenity.toLowerCase()) {
                    tempAmenityType = amenityType
                    tempAmenity = eachAmenity
                    break
                }
            }
        }
        if(tempAmenityType) {
            setActiveHead(tempAmenityType)
            setCheckedAmenites([...checkedAmenties, tempAmenity])
            setInputAmenity("")
            //add in formdata
        } else {
            setAmenitiesData({
                ...amenitiesData,
                Others : {
                    ...amenitiesData?.Others,
                    [inputAmenity] : false,
                }
            })
            setAllAmenitiesData({
                ...amenitiesData,
                Others : {
                    ...amenitiesData?.Others,
                    [inputAmenity] : false,
                }
            })
            setInputAmenity("")
        }
    }

    const checkAddedEditedRemoved = (amenity, category) => {

        if (amenity) {
            if (changedNames.includes(amenity) && deletedNames.includes(amenity) ) {
                return "Edited"
            } else if (changedNames.includes(amenity)) {
                return "Added"
            } else if (deletedNames.includes(amenity)) {
                return "Removed"
            } else {
                return ""
            }
        }

        if (category) {
            const allCategoryAmenities = Object.keys(amenitiesData?.[category]);
            const changedCommonElements = intersection(changedNames, allCategoryAmenities);
            const deletedCommonElements = intersection(deletedNames, allCategoryAmenities);
            if ( changedCommonElements?.length > 0 ||  deletedCommonElements?.length > 0 ) {
                return 'Edited'
            } else {
                return ""
            }
        }
    }

    const searchFormData = (e) => {
        const searchKey = e?.target?.value || '';
        setSearch(searchKey);
        if (searchKey === '') {
            setAmenitiesData(allAmenitiesData);
            return;
        }
        const lowerCaseSearchKey = searchKey?.toLowerCase();
        const result = Object?.keys(allAmenitiesData)?.reduce((acc, mainHead) => {
            const filteredSubHeads = Object?.keys(allAmenitiesData[mainHead])?.filter(subHead => {
                return subHead?.toLowerCase()?.includes(lowerCaseSearchKey)
            } 
            );
            if (filteredSubHeads?.length > 0) {
                acc[mainHead] = filteredSubHeads?.reduce((subAcc, subHead) => {
                    subAcc[subHead] = allAmenitiesData?.[mainHead]?.[subHead];
                    return subAcc;
                }, {});
            }
            return acc;
        }, {});
        if (Object?.keys(result)?.length === 0) {
            setShowNoDataView(true)
            // setAmenitiesData(allAmenitiesData); // Restore original data when no matches are found
        } else {
            setAmenitiesData(result);
            setShowNoDataView(false);
            setActiveHead(Object.keys(result)[0])
        }
    };

    const noDataView = () => {
        return(
            <div className="text-center">
                <span className="SpotLetSubHead text-center" > No Amenities present with search value, you can add custom amenities in others section </span>
            </div>
        )
      }

    return(
        <>
        <div className="locationtype-page">
            <div className="locationtype-heading-container" >
                <h1 className="locationtype-head" > All Amenities {' '}
                    <a
                        href="#"
                        data-bs-toggle="tooltip"
                        title="List enticing amenities, making your property stand out attractively."
                    >
                        <img src={info} />
                    </a>
                </h1>
                <div className="locationtype-searchcontainer">
                    <input
                        type="search"
                        placeholder="Search amenity"
                        onChange={searchFormData}
                        className="locationtype-search"
                        value={search}
                    />
                </div>
            </div>
            {
                showNoDataView && noDataView()
            }
            {
                !showNoDataView &&  (
                    <div className="tabCard row">
                        <div className="mainHeadContainer  col-lg-3 col-xl-2">
                            {Object.keys(amenitiesData).map((mainHead,i) => {
                                return(
                                    <div 
                                    className={activeHead === mainHead ? "mainHead  is-active" : 'mainHead'}
                                    onClick={()=>onClickMainHead(mainHead)}
                                    >
                                        <p>{mainHead}{" "}({mainHeadCount[i] || '0'}) <span className="text-danger">  {checkAddedEditedRemoved("", mainHead) ?  `(${checkAddedEditedRemoved("", mainHead)})`  : ""  } </span> </p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="subHeadContainer  col-lg-7 col-xl-6">
                            {amenitiesData?.[activeHead] && Object?.keys(amenitiesData[activeHead]).map((subHead, i)=>{
                                return(
                                    <div 
                                    className='subHead'
                                    >
                                        <div className="d-flex flex-row  align-items-center" >
                                        <input className="amenities-checkbox" type='checkbox' name={subHead} checked={checkedAmenties.includes(subHead)} id={subHead} onChange={(e) =>handleCheckBox(e,activeHeadIndex)}/>
                                            <label  className="amenities-label" htmlFor={subHead}>{subHead} <span className="text-danger ml-2">{checkAddedEditedRemoved(subHead, "") ?  `(${checkAddedEditedRemoved(subHead, "")})`  : ""  }</span> </label>
                                        </div>
                                        <div>
                                            {(checkedAmenties.includes(subHead) && amenitiesData[activeHead][subHead]) && (
                                                <Select
                                                    defaultValue={() =>getSubs(subHead)}
                                                    isMulti={amenitiesData[activeHead][subHead].type === 'multi'}
                                                    options={amenitiesData[activeHead][subHead]?.sub}
                                                    onChange={(options)=>onChangeSub(subHead,amenitiesData[activeHead][subHead].type,options)}
                                                    className="amenities-select"
                                                    classNamePrefix="select"
                                                    closeMenuOnSelect={ amenitiesData[activeHead][subHead].type === 'multi' ?  false : true}
                                                />
                                                )}   
                                        </div>
                                    </div>
                                )
                            })}
                            {activeHead === "Others"  && (
                                <form className="d-flex align-items-center mx-4 mt-1" onSubmit={handleAddNewAmenity}>
                                    <div className="d-flex flex-column">
                                        <label htmlFor="property_type" className="form-label">
                                            New Amenity
                                        </label>
                                    <div className="d-flex align-items-center" >
                                        <input
                                            type="text"
                                            required
                                            placeholder="Enter new amenity"
                                            id="property_type"
                                            name="property_type"
                                            className="textFiled me-2"
                                            value={inputAmenity}
                                            onChange={(e) => setInputAmenity(e.target.value)}
                                        />
                                        <button type="submit" className="btn btn-primary" style={{height:"35px"}}>Submit</button>
                                    </div>
                                    </div>
                                </form>
                                )}
                        </div>
                    </div>
                )
            }
        </div>
        <div className="controlContainer">
        <div>
            <button
            type="button"
            class="btn btn-outline-danger"
            onClick={handleBack}
            >
                Back
            </button>
        </div>
        <div>
            <button
            type="button"
            className="btn btn-primary"
            onClick={submitForm}
            >
                Save & Continue
            </button>
        </div>
    </div>
    {showConfirmationModel && (
        <Modal show={showConfirmationModel} onHide={() => setShowConfirmationModel(false)}>
            <Header closeButton>
                <Title style={{fontSize : '16px'}}>{confirmationTitle}</Title>
            </Header>
            <Body className="verify-email-modal-body" style={{fontSize : '13px'}}>
                <p>{confirmationBody}</p>
            </Body>
            <Footer>
                <Button variant="secondary" onClick={() => setShowConfirmationModel(false)} style={{fontSize : '13px'}}>
                Cancel
                </Button>
                <Button variant="primary" onClick={() => setShowConfirmationModel(false)} style={{fontSize : '13px'}}>
                OK
                </Button>
            </Footer>
        </Modal>
      )}
        </>
    )
}
export default Amenities